import {LicenseManager as AgGridLicenseManager} from '@ag-grid-enterprise/core';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {DataStatus, Schema, useTranslationContext} from 'platform/components';
import {useLocale} from 'platform/locale';

import {
  ForwardedRef,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {AgGridWrapper} from './AgGridWrapper';
import {DatagridFiltersModelProvider} from './context/DatagridFiltersModelProvider';
import {DataQueryIdProvider} from './context/DataQueryIdProvider';
import {DataGridContext} from './context/types';
import {useDataGridConfig} from './context/useDatagridConfig';
import {DataGridContextProvider} from './context/useDataGridContext';
import {DataGridErrorContextProvider} from './context/useDataGridErrorContext';
import {useHttpCalls} from './hooks/useHttpCalls';
import {useLoadPresets} from './hooks/useLoadPresets';
import {
  ActivePreset,
  GetDataQueryResponse,
  Preset,
  TransformedDefinitionApiResponse,
  VirtualPreset,
} from './types/Api';
import {DataGridProps} from './types/DataGridProps';
import {DataGridRef} from './types/DataGridRef';
import {getActions} from './utils/getActions';
import {getApiErrorMessage} from './utils/getApiErrorMessage';
import {transformDefinition} from './utils/transformDefinition';
import {transformDefinitionBehavior} from './utils/transformDefinitionBehavior';
import {getSearchParamsByGridCode} from './utils/url/getSearchParamsByGridCode';
import {setSearchParamsByGridCode} from './utils/url/setSearchParamsByGridCode';

// Datagrid rerenders are expensive, so we want to avoid them as much as possible
const dataGridPropsComparator = (a: unknown, b: unknown) => a?.toString() === b?.toString();

/*
 * This component is responsible for fetching data from API and calculating data for AgGridWrapper.
 * */
export const DataGrid = memo(
  forwardRef((props: DataGridProps, ref: ForwardedRef<DataGridRef>) => {
    const t = useTranslationContext();
    const {localeConfig, language} = useLocale();
    const innerRef = useRef<Pick<DataGridRef, 'refreshData'>>(null);

    const [dataQuery, setDataQuery] = useState<GetDataQueryResponse | Nullish>(null);
    const [customPresets, setCustomPresets] = useState<Preset[] | Nullish>(null);
    const [virtualPreset, setVirtualPreset] = useState<VirtualPreset | Nullish>(null);
    const [definition, setDefinition] = useState<TransformedDefinitionApiResponse | Nullish>(null);
    const [schema, setSetSchema] = useState<Schema | Nullish>(null);
    const [activePreset, setActivePreset] = useState<ActivePreset | Nullish>(null);
    const [apiError, setApiError] = useState<string | Nullish>(null);
    const {entityResourceResolver, agGridEnterpriseLicenseKey} = useDataGridConfig();
    const searchParams = getSearchParamsByGridCode(props.gridCode);

    const onApiError = (error: FetchBaseQueryError) => {
      const errorMessage = getApiErrorMessage(
        error,
        t('general.notifications.defaultErrorMessage')
      );

      setApiError(errorMessage);
    };

    const isDatagridLoading =
      !customPresets ||
      !virtualPreset ||
      !definition ||
      !activePreset ||
      !dataQuery ||
      isNotNil(apiError);

    useImperativeHandle(ref, () => ({
      isLoading: isDatagridLoading,
      refreshData: () => innerRef.current?.refreshData?.(),
    }));

    const http = useHttpCalls({
      gridCode: props.gridCode,
      presetInstanceId: props.presetInstanceId,
      connectionOverride: props._useAsLastResort_connectionOverride,
      onApiError,
    });

    const loadPresets = useLoadPresets({
      gridCode: props.gridCode,
      presetInstanceId: props.presetInstanceId,
      connectionOverride: props._useAsLastResort_connectionOverride,
      onApiError,
      queryModifier: props.queryModifier,
    });

    if (agGridEnterpriseLicenseKey) {
      AgGridLicenseManager.setLicenseKey(agGridEnterpriseLicenseKey);
    }

    useEffect(() => {
      loadPresets().then((response) => response && setCustomPresets(response));
      http.getVirtualPreset().then((response) => response && setVirtualPreset(response));
      http.getDefinition().then((response) => {
        if (!response) {
          return;
        }

        setDefinition(response);
        const filterMode =
          props._useAsLastResort_definitionBehaviorOverrides?.filterMode ??
          response.behavior.filterMode;
        if (filterMode === 'NATIVE') {
          return;
        }
        http
          .getFilterSchema({
            externalFilterResourceId: props.externalFilterResourceId,
            externalFilterRecordId: props.externalFilterRecordId,
          })
          .then((response) => {
            if (!response) {
              return;
            }

            setSetSchema(response.root);
          });
      });
    }, [props.gridCode]);

    useEffect(() => {
      if (virtualPreset && customPresets) {
        http.getActivePreset(virtualPreset, customPresets).then((activePreset) => {
          if (!activePreset) {
            return;
          }

          setActivePreset(activePreset);
          if (isNilOrEmpty(searchParams)) {
            setSearchParamsByGridCode(props.gridCode, activePreset.dataQueryId);
          }
          http
            .getDataQuery(searchParams?.queryId ?? activePreset.dataQueryId)
            .then((dataQuery) => dataQuery && setDataQuery(dataQuery))
            .catch((error) => {
              if (isNotNil(searchParams?.queryId)) {
                setSearchParamsByGridCode(props.gridCode, activePreset.dataQueryId);

                http
                  .getDataQuery(activePreset.dataQueryId)
                  .then((dataQuery) => dataQuery && setDataQuery(dataQuery))
                  .catch(onApiError);
              } else {
                onApiError(error);
              }
            });
        });
      }
    }, [customPresets, virtualPreset]);

    if (isNotNil(apiError)) {
      return (
        <DataStatus isError errorMessage={apiError} minHeight={props.autoHeight ? 20 : '100%'} />
      );
    }

    if (isDatagridLoading) {
      return <DataStatus isLoading minHeight={props.autoHeight ? 20 : '100%'} />;
    }

    const transformedSetting = activePreset.gridSettings;

    const transformedDefinition = transformDefinitionBehavior(
      definition,
      props._useAsLastResort_definitionBehaviorOverrides
    );

    const actions = getActions(definition.actions);

    const columnConfig = transformDefinition({
      definition,
      colDefOverrides: props._useAsLastResort_colDefOverrides,
      activePreset,
      entityResourceResolver,
      dataQuery,
      localeConfig,
      language,
      ...props,
    });

    const contextValue: DataGridContext = {
      gridCode: props.gridCode,
      connectionOverride: props._useAsLastResort_connectionOverride,
      presetInstanceId: props.presetInstanceId,
      externalFilterId: props.externalFilterId,
      customPresets,
      virtualPreset,
      activePreset,
      settings: transformedSetting,
      filterSchema: schema,
      onExternalFilterChange: props.onExternalFilterChange,
      onActivePresetChange: setActivePreset,
      queryModifier: props.queryModifier,
      filterComponent: props.filterComponent,
    };

    const staticFilters = props.queryModifier ? props.queryModifier({}) : {};

    return (
      <DataGridErrorContextProvider value={{onApiError}}>
        <DatagridFiltersModelProvider
          initialValues={{...dataQuery.filters, smartSearch: dataQuery?.smartSearch as string}}
          staticFilters={staticFilters}
        >
          <DataGridContextProvider value={contextValue}>
            <DataQueryIdProvider dataQueryId={dataQuery.id}>
              <AgGridWrapper
                ref={innerRef}
                gridCode={props.gridCode}
                presetInstanceId={props.presetInstanceId}
                emptyState={props.emptyState}
                actionCallback={props.actionCallback}
                onRowSelectionChange={props.onRowSelectionChange}
                externalFilterId={props.externalFilterId}
                autoHeight={props.autoHeight}
                queryModifier={props.queryModifier}
                definition={transformedDefinition}
                columnConfig={columnConfig}
                actionsDefinition={actions}
                onFilterChanged={props.onFilterChanged}
                data-testid={props['data-testid']}
                dataQuery={dataQuery}
                setVirtualPreset={setVirtualPreset}
              />
            </DataQueryIdProvider>
          </DataGridContextProvider>
        </DatagridFiltersModelProvider>
      </DataGridErrorContextProvider>
    );
  }),
  dataGridPropsComparator
);

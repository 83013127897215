import {Dropdown, DropdownItem, Option, Search} from 'platform/components';
import {Box, Show} from 'platform/foundation';

import {useState} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, useOnMount, useTimeout} from 'shared';

import {SMART_SEARCH_KEY} from '../constants/smartSearchKey';
import {useGridApiEventListener} from '../hooks/useGridApiEventListener';
import {useHttpCalls} from '../hooks/useHttpCalls';
import {AgGridEvent} from '../types/AgGridTypes';
import {QuickFilterRendererProps} from '../types/QuickFilterRenderer';

const ENTER_KEY = 'Enter';

export function QuickFilterRenderer({
  submitQuickFilter,
  cancelQuickFilter,
  quickFilterValue = '',
  gridCode,
  gridApi,
  ...rest
}: QuickFilterRendererProps) {
  const http = useHttpCalls();

  const [searchOptions, setSearchOptions] = useState<Option[] | Nullish>(null);

  useOnMount(() =>
    http.getSmartSearch().then((response) => response && setSearchOptions(response))
  );

  const [value, setValue] = useState<string | null>(quickFilterValue);

  const [menuOpen, setMenuOpen] = useState(false);
  const [isMenuDisabled, setIsMenuDisabled] = useState(isNotNilOrEmpty(value));

  const handleFilterChange = (event: AgGridEvent) => {
    const filterModel = event.api.getFilterModel();
    setValue(filterModel[SMART_SEARCH_KEY]);
  };

  const {addListeners} = useGridApiEventListener(gridApi, ['filterChanged'], handleFilterChange);

  useTimeout(() => addListeners(), 100);

  const handleOnValueClick = (value: string) => {
    setValue(value);
    setMenuOpen(false);
    submitQuickFilter(value ?? '');
  };

  const handleCancelQuickFilter = () => {
    setValue(null);
    cancelQuickFilter();
  };

  return (
    <form
      // onSubmit causes parent forms to be submitted as well, this is a hack to prevent that
      onKeyDown={(e) => {
        if (e.key === ENTER_KEY) {
          e.preventDefault();
          setMenuOpen(false);
          setValue(value);
          submitQuickFilter(value ?? '');
        }
      }}
    >
      <Search
        data-testid={suffixTestId('quickFilterInput', rest)}
        value={value}
        onChange={(value) => {
          !menuOpen && setMenuOpen(true);
          setValue(value);
        }}
        onFocus={() => {
          isMenuDisabled ? setIsMenuDisabled(false) : setMenuOpen(true);
        }}
        onClear={handleCancelQuickFilter}
        highlightOnSearch
        isAutoFocused={isNotNilOrEmpty(value)}
      />
      <Show when={searchOptions?.length} key={searchOptions?.length}>
        <Dropdown
          dropdownControl={<Box />}
          isOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          data-testid={suffixTestId('quickFilterDropdown', rest)}
          strategy="fixed"
        >
          {searchOptions?.map((option) => (
            <DropdownItem
              key={option.value}
              label={option.label}
              onClick={() => handleOnValueClick(option.value)}
            />
          ))}
        </Dropdown>
      </Show>
    </form>
  );
}

import {Form, FormField, FormSubmitHandler, Label, showNotification} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {useGetProformaGenerationQuery, usePutProformaGenerationMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useBranches} from '@omnetic-dms/shared';

import {useRequiredParams} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string;
  unify: boolean;
};

export function ProformaInvoiceGenerationDetail() {
  const {id} = useRequiredParams();
  const navigate = useNavigate();
  const [updateProformaGeneration] = usePutProformaGenerationMutation();

  const {
    data: proformaGeneration,
    isLoading: isProformaGenerationLoading,
    isError: isProformaGenerationError,
  } = useGetProformaGenerationQuery({id});
  const {
    data: branches,
    branchOptions,
    isLoading: isBranchesLoading,
    isError: isBranchesError,
  } = useBranches();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) =>
    await updateProformaGeneration({...data, id})
      .unwrap()
      .then(() => showNotification.success())
      .then(() => navigate(settingsRoutes.proformaGeneration))
      .catch(handleApiError);

  const isLoading = isProformaGenerationLoading || isBranchesLoading;
  const isError = isProformaGenerationError || isBranchesError;

  const branchName =
    branches?.branchListItems.find((branch) => branch.id === proformaGeneration?.branchId)
      ?.marketingName ?? i18n.t('page.accounting.labels.tenantDefinition');

  const defaultValues = {
    unify: proformaGeneration?.unify ?? false,
    branchId: proformaGeneration?.branchId,
  };

  return (
    <SettingsTemplate
      header={{
        title: branchName,
      }}
      data-testid={testIds.settings.proformaGeneration('page')}
      isLoading={isLoading}
      isError={isError}
    >
      <Form<FormValues> defaultValues={defaultValues} onSubmit={handleSubmit}>
        {(control) => (
          <VStack spacing={4}>
            <SettingsSection>
              <Grid columns={2}>
                <FormField
                  control={control}
                  type="choice"
                  name="branchId"
                  isNotClearable
                  options={branchOptions}
                  isDisabled={proformaGeneration?.isSystem}
                  label={i18n.t('entity.cashRegister.parameters.branch')}
                  placeholder={
                    proformaGeneration?.isSystem
                      ? i18n.t('page.accounting.labels.tenantDefinition')
                      : undefined
                  }
                  isRequired
                />
              </Grid>
            </SettingsSection>
            <VStack spacing={1}>
              <Label>{i18n.t('page.accountingSettings.labels.generationType')}</Label>
              <FormField
                control={control}
                type="checkbox"
                name="unify"
                label={i18n.t('page.accountingSettings.labels.unify')}
              />
            </VStack>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discardChanges'),
                  onClick: () => navigate(settingsRoutes.proformaGeneration),
                  variant: 'secondary',
                },
                {
                  type: 'form-button',
                  control,
                  buttonType: 'submit',
                  title: i18n.t('general.actions.saveChanges'),
                },
              ]}
            />
          </VStack>
        )}
      </Form>
    </SettingsTemplate>
  );
}

import {Box, Center, CSSDimension, getCssSize, HStack, Integer} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {LightboxAction} from '../types/LightboxAction';
import {IconButton} from './IconButton';

export type ActionPosition = {
  top: CSSDimension | Integer;
  right: CSSDimension | Integer;
  left: CSSDimension | Integer;
};

type CommonProps = RequiredTestIdProps & {
  actions: LightboxAction[][];
  zIndex: 'LIGHTBOX_ACTIONS' | 'LIGHTBOX_DETAIL_ACTIONS';
  isHidden?: boolean;
};

type LightboxActionsProps =
  | (CommonProps & {
      actionPosition: ActionPosition;
      disablePosition?: boolean;
    })
  | (CommonProps & {
      actionPosition?: ActionPosition;
      disablePosition: true;
    });

export function Actions(props: LightboxActionsProps) {
  const theme = useTheme();

  return (
    <div
      css={css`
        position: ${props.disablePosition ? 'none' : 'absolute'};
        top: ${getCssSize(props.actionPosition?.top)};
        left: ${getCssSize(props.actionPosition?.left)};
        right: ${getCssSize(props.actionPosition?.right)};
        z-index: ${theme.zIndices[props.zIndex]};
        opacity: ${props.isHidden ? 0 : 1};
        transition: opacity 0.15s ease-out;
      `}
    >
      <HStack spacing={2}>
        {props.actions.map((iconsGroup, index) => (
          <Box borderRadius="xSmall" overflow="hidden" key={`${index}-${iconsGroup.length}`}>
            <Center height={9}>
              {iconsGroup.map((action) => (
                <IconButton
                  data-testid={suffixTestId(`actions-[${action.id}]`, props)}
                  key={action.id}
                  icon={action.icon}
                  isDisabled={action.isDisabled}
                  onClick={() => {
                    action.onClick(action.id);
                  }}
                />
              ))}
            </Center>
          </Box>
        ))}
      </HStack>
    </div>
  );
}

import {Separator, showNotification} from 'platform/components';
import {Grid, Show} from 'platform/foundation';

import {FC} from 'react';
import {useSelector} from 'react-redux';

import {concat, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AuditParamDefinition,
  useSendMultipartUploadCompletedMutation,
  useSendMultipartUploadRequestMutation,
} from '@omnetic-dms/api';

import {suffixTestId, TestIdProps} from 'shared';

import {VideoUploadError, VideoUploadQueueProvider} from 'features/video-upload-queue';

import {selectPhotoDocumentation} from '../../../store/carAudit/selectors';
import {AuditParamType} from '../types/AuditParamType';
import {AuditCategoryUniqueKey} from '../types/UniqueKey';
import {findByParamType} from '../utils/findByParamType';
import {findCategoryByUniqueKey} from '../utils/findCategoryByUniqueKey';
import {getVideoErrorDescription} from '../utils/getVideoErrorDescription';
import {getWebBase64} from '../utils/getWebBase64';
import {PhotoDocumentationCard} from './PhotoDocumentationCard';

export const PhotoDocumentation: FC<TestIdProps> = (props) => {
  const photoDocumentation = useSelector(selectPhotoDocumentation);
  const [sendMultiPartUploadRequest] = useSendMultipartUploadRequestMutation();
  const [sendMultipartUploadCompleted] = useSendMultipartUploadCompletedMutation();

  const handleError = (error: VideoUploadError) => {
    showNotification.error(getVideoErrorDescription(error));
  };

  if (!photoDocumentation) {
    return null;
  }

  const morePhotos = findCategoryByUniqueKey(
    photoDocumentation,
    AuditCategoryUniqueKey.MORE_PHOTOS
  );
  const morePhotosParam = findByParamType(
    morePhotos?.paramDefinitions?.relatedActions,
    AuditParamType.MORE_PHOTOS
  );

  const videos = findCategoryByUniqueKey(photoDocumentation, AuditCategoryUniqueKey.VIDEOS);
  const videosParam = findByParamType(
    videos?.paramDefinitions?.relatedActions,
    AuditParamType.VIDEO
  );

  const paramDefinitions = photoDocumentation.childCategories?.reduce(
    (relatedActions: AuditParamDefinition[], category) => {
      if (isNotNil(category.paramDefinitions?.relatedActions) && category.uniqueKey !== 'VIDEOS') {
        const actions = category.paramDefinitions.relatedActions.filter(
          (relatedAction) => relatedAction.type !== AuditParamType.MORE_PHOTOS
        );
        return isNotNilOrEmpty(actions) ? concat(relatedActions, actions) : relatedActions;
      }
      return relatedActions;
    },
    []
  );

  return (
    <VideoUploadQueueProvider
      api={{sendMultipartUploadCompleted, sendMultiPartUploadRequest}}
      getBase64={getWebBase64}
      handleError={handleError}
    >
      <Grid columns={8} align="flex-end">
        {paramDefinitions?.map((paramDefinition, index) => (
          <PhotoDocumentationCard
            key={`${paramDefinition.id}-${paramDefinition.label}`}
            categoryId={photoDocumentation.id}
            paramDefinition={paramDefinition}
            label={paramDefinition.label}
            data-testid={suffixTestId(`photoDocumentationCard-[${index}]`, props)}
          />
        ))}
      </Grid>
      <Separator />
      <Show when={isNotNilOrEmpty(morePhotosParam) && isNotNilOrEmpty(morePhotos)}>
        <PhotoDocumentationCard
          categoryId={morePhotos?.id!}
          paramDefinition={morePhotosParam!}
          label={morePhotosParam?.label}
          multiple
          data-testid={suffixTestId('morePhotoDocumentationCard', props)}
        />
      </Show>
      <Show when={isNotNilOrEmpty(videosParam) && isNotNilOrEmpty(videos)}>
        <Separator />
        <PhotoDocumentationCard
          categoryId={videos?.id!}
          paramDefinition={videosParam!}
          label={videosParam?.label}
          multiple
          isVideo
          data-testid={suffixTestId('videosDocumentationCard', props)}
        />
      </Show>
    </VideoUploadQueueProvider>
  );
};

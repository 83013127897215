import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Option,
  showNotification,
} from 'platform/components';
import {VStack} from 'platform/foundation';
import {formatPhoneNumber} from 'platform/locale';
import * as Yup from 'yup';

import {UseFormReturn} from 'react-hook-form';

import {equals, forEach, isNil, isNotNil, map} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  PersonResponseBodyV2,
  PhoneNumberResponseBody,
  PostKonzultaSmsApiArg,
  useGetCustomerV2Query,
  usePostKonzultaSmsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {getCustomerContacts} from '../../utils/getCustomerContacts';
import {getNaturalPersonFullName} from '../../utils/getNaturalPersonFullName';
import {handleApiError} from '../../utils/handleApiError';

type SendSmsForm = {
  personId?: string;
  phone: string;
  text: string;
};

interface SendSmsProps extends TestIdProps {
  customerId: string;
  selectedContactId?: string;
}

export function SendSms(props: SendSmsProps) {
  const {data} = useGetCustomerV2Query(
    {customerId: props.customerId},
    {
      selectFromResult: (res) => ({
        ...res,
        data: getCustomerContacts(res.data),
      }),
    }
  );

  const [postSms, {isLoading}] = usePostKonzultaSmsMutation();

  const onSubmit: FormSubmitHandler<SendSmsForm> = async ({phone, text}) => {
    const args: PostKonzultaSmsApiArg = {
      konzultaSmsSenderRequestBody: {
        phone,
        text,
      },
    };
    await postSms(args)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.smsSendSuccessfully'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const getPhoneOptions = (id: string | Nullish): Option[] | null => {
    if (isNil(id) || isNil(data)) {
      return null;
    }

    return data!.reduce((filteredItems: Option[], item) => {
      if (equals(item.id, id)) {
        forEach((p: PhoneNumberResponseBody) => {
          filteredItems.push({
            label: formatPhoneNumber(`${p.prefix}${p.number}`) ?? '',
            value: `${p.prefix}${p.number}`,
          });
        }, item.phoneNumbers);
      }
      return filteredItems;
    }, []);
  };

  const personOptions = map(
    (c: PersonResponseBodyV2) => ({
      label: getNaturalPersonFullName(c),
      value: c.id,
    }),
    data ?? []
  );

  const selectedPersonId = isNotNil(props.selectedContactId)
    ? personOptions?.find((person) => person.value === props.selectedContactId)?.value
    : personOptions[0]?.value;

  const defaultValues = {
    personId: selectedPersonId,
    phone: getPhoneOptions(selectedPersonId)?.[0]?.value,
  };

  const onPersonChange =
    (formApi: UseFormReturn<SendSmsForm>) => (person: string | number | null) => {
      if (isNil(person)) {
        return;
      }
      const phoneNumber = getPhoneOptions(person as string);

      formApi.setValue('phone', phoneNumber?.[0].value ?? '');
    };

  return (
    <Form<SendSmsForm> schema={schema} onSubmit={onSubmit} defaultValues={defaultValues}>
      {(control, formApi) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            name="personId"
            type="choice"
            isRequired
            label={i18n.t('general.labels.addressee')}
            options={personOptions}
            onChange={onPersonChange(formApi)}
            data-testid={suffixTestId('addressee', props)}
          />
          <FormField
            control={control}
            name="phone"
            type="choice"
            isRequired
            isDisabled={isNilOrEmpty(formApi.watch('personId'))}
            label={i18n.t('entity.person.labels.phoneNumber')}
            options={getPhoneOptions(formApi.watch('personId')) ?? []}
            data-testid={suffixTestId('sendTo', props)}
          />
          <FormField
            control={control}
            type="textarea"
            name="text"
            isRequired
            maxLength={160}
            minRows={4}
            label={i18n.t('general.labels.message')}
            data-testid={suffixTestId('message', props)}
          />
          <ButtonGroup align="right">
            <Button
              variant="secondary"
              onClick={closeCurrentDialog}
              isDisabled={isLoading}
              title={i18n.t('general.actions.discard')}
              data-testid={suffixTestId('discard', props)}
            />
            <FormButton
              variant="primary"
              type="submit"
              control={control}
              isLoading={isLoading}
              title={i18n.t('general.actions.send')}
              data-testid={suffixTestId('send', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

const schema = Yup.object().shape({
  personId: Yup.string().required(),
  phone: Yup.string().required(),
  text: Yup.string().required().max(160),
});

import {createSelector} from '@reduxjs/toolkit';

import {AdvertisingPlatformCodeEnum} from '../../types/AdvertisingPlatformCodeEnum';
import type {TeasState} from '../../types/TeasState';
import {selectGeneralSettings} from '../generalSettings/selectors';

export const selectGeneralSettingsAd = createSelector(
  selectGeneralSettings,
  (state) => state?.advertisementSettings
);
export const selectPlatforms = createSelector(selectGeneralSettingsAd, (state) => state?.platforms);
export const listSelector = createSelector(selectPlatforms, (state) => state?.list);
export const orderSelector = createSelector(selectPlatforms, (state) => state?.order);
export const loadingSelector = createSelector(selectPlatforms, (state) => state?.loading);
export const specificPlatformSelector = (platformCode: AdvertisingPlatformCodeEnum) =>
  createSelector(selectGeneralSettingsAd, (state) => state?.detailedPlatforms?.[platformCode]);

export const selectTipCarsData = (state: TeasState) =>
  state?.generalSettings.advertisementSettings.tipCarsClientData;

export const selectUpdatingTipCarsClientData = (state: TeasState) =>
  state?.generalSettings.advertisementSettings.updatingTipCarsClientData;

export const getAdPlatformsSettingsSelector = createSelector(
  [listSelector, orderSelector],
  (list, order) => order.map((i) => list[i])
);

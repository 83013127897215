import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {
  Button,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  Separator,
  showNotification,
} from 'platform/components';
import {Grid, GridItem, Right, Space, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';
import * as Yup from 'yup';

import {useNavigate} from 'react-router-dom';

import {always, has, isNil} from 'ramda';
import {isFalse, isNotNil} from 'ramda-adjunct';

import {
  TestDriveRequestBody,
  useCreateTestDriveMutation,
  useGetBusinessCaseQuery,
  useGetTestDriveFromBusinessCaseQuery,
  useGetUsersQuery,
  useUpdateTestDriveMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';
import {Main, NoPermissionTooltip, handleApiError, usePermissions} from '@omnetic-dms/shared';

import {Nullish, composePath, parseDate, useRequiredParams} from 'shared';

import {BusinessCaseOverviewVehicle} from '../BusinessCaseOverview/BusinessCaseOverviewVehicle';

type YesOrNoType = 'yes' | 'no';
type FormValues = {
  isDone: YesOrNoType[];
  date: Date;
  time: string;
  userId: string;
  note: string | Nullish;
};

export function BusinessCaseTestDrive() {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});

  const saleVehicle = businessCase?.offers?.[0]?.saleVehicles?.[0];
  const purchaseVehicle =
    businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE'
      ? businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0]
      : businessCase?.offers?.[0]?.purchaseVehicles?.[0];

  const vehicle = saleVehicle || purchaseVehicle;

  const {
    data: testDrive,
    refetch,
    isLoading,
    isError,
    error,
  } = useGetTestDriveFromBusinessCaseQuery({
    businessCaseId,
  });

  const navigate = useNavigate();
  const formatDateTime = useDateTimeFormatter();
  const scheduledAtDate = testDrive?.scheduledAt ? parseDate(testDrive.scheduledAt) : null;

  const [createTestDrive] = useCreateTestDriveMutation();
  const [updateTestDrive] = useUpdateTestDriveMutation();

  const [
    hasCreateBusinessCaseSellingTestDrivePermission,
    hasEditBusinessCaseSellingTestDrivePermission,
  ] = usePermissions({
    permissionKeys: ['createBusinessCaseSellingTestDrive', 'editBusinessCaseSellingTestDrive'],
  });

  const canSaveTestDrive = isNotNil(testDrive?.id)
    ? hasEditBusinessCaseSellingTestDrivePermission
    : hasCreateBusinessCaseSellingTestDrivePermission;

  const {data: users, isLoading: usersIsLoading} = useGetUsersQuery();
  const usersOptions = users?.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const handleSubmit = async (values: FormValues) => {
    const [hours, minutes] = values.time?.split(':') ?? [];

    if (isNil(hours) || isNil(minutes)) {
      return;
    }

    const scheduledAt = values.date;
    scheduledAt.setHours(Number(hours), Number(minutes));

    const testDriveRequestBody: TestDriveRequestBody = {
      userId: values.userId,
      scheduledAt: scheduledAt.toISOString(),
      state: testDrive?.state ?? 'planned',
      note: values.note || null,
    };

    if (!testDrive?.id) {
      await createTestDrive({
        businessCaseId,
        testDriveRequestBody,
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('entity.testDrive.notifications.createSuccess'))
        )
        .then(refetch)
        .catch(handleApiError);
    } else {
      await updateTestDrive({
        testDriveId: testDrive.id,
        testDriveRequestBody,
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('entity.testDrive.notifications.updateSuccess'))
        )
        .then(refetch)
        .catch(handleApiError);
    }
  };

  const errorStatus = match(error)
    .when(
      (err) => isNotNil(err) && has('status')(err),
      (err: FetchBaseQueryError) => err && err.status
    )
    .when(
      (err) => isNotNil(err) && has('code')(err),
      (err: SerializedError) => isNotNil(err) && err.code
    )
    .otherwise(always(null));

  return (
    <Main>
      <VStack spacing={4}>
        <Card>
          <BusinessCaseOverviewVehicle
            isSimple
            data-testid={testIds.businessCase.testDrive('vehicleWidget')}
            vehicle={vehicle}
          />
          <Space vertical={4} />
          <DataStatus isLoading={isLoading} isError={isError && errorStatus !== 404}>
            <Form<FormValues>
              schema={schema}
              defaultValues={{
                date: scheduledAtDate ? parseDate(scheduledAtDate.toISOString()) : undefined,
                time: scheduledAtDate ? formatDateTime('timeShort', scheduledAtDate) : undefined,
                userId: testDrive?.userId,
                note: testDrive?.note,
              }}
              onSubmit={handleSubmit}
            >
              {(control, formApi) => (
                <>
                  <Grid columns={4}>
                    <FormField
                      isRequired
                      control={control}
                      name="date"
                      type="date"
                      label={i18n.t('entity.businessCase.labels.testDriveDate')}
                      data-testid={testIds.businessCase.testDrive('testDriveDate')}
                    />
                    <FormField
                      isRequired
                      control={control}
                      name="time"
                      type="time"
                      label={i18n.t('entity.businessCase.labels.testDriveTime')}
                      data-testid={testIds.businessCase.testDrive('testDriveTime')}
                    />
                    <FormField
                      isRequired
                      control={control}
                      name="userId"
                      type="choice"
                      label={i18n.t('entity.businessCase.labels.testDriveEmployee')}
                      data-testid={testIds.businessCase.testDrive('testDriveEmployee')}
                      options={!usersIsLoading ? usersOptions : []}
                    />
                    <GridItem span={2}>
                      <FormField
                        control={control}
                        name="note"
                        type="textarea"
                        label={i18n.t('entity.businessCase.labels.testDriveNote')}
                        data-testid={testIds.businessCase.testDrive('testDriveNote')}
                        minRows={2}
                      />
                    </GridItem>
                  </Grid>
                  <Separator />
                  <Right>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canSaveTestDrive)}>
                      <FormButton
                        type="submit"
                        isDisabled={isFalse(canSaveTestDrive)}
                        control={control}
                        onClick={formApi.handleSubmit(handleSubmit)}
                        variant="primary"
                        title={i18n.t('general.actions.save')}
                        data-testid={testIds.businessCase.testDrive('testDriveSaveBtn')}
                      />
                    </NoPermissionTooltip>
                  </Right>
                </>
              )}
            </Form>
          </DataStatus>
        </Card>
        <Right>
          <Button
            data-testid={testIds.businessCase.buying('continue')}
            onClick={() =>
              navigate(composePath(businessCaseRoutes.reservations, {params: {id: businessCaseId}}))
            }
            variant="outlined"
            rightIcon="navigation/chevron_right"
            title={i18n.t('general.actions.continue')}
          />
        </Right>
      </VStack>
    </Main>
  );
}

const schema = Yup.object().shape({
  isDone: Yup.array(),
  date: Yup.date().required(),
  time: Yup.string(),
  userId: Yup.string().required(),
  note: Yup.string().optional().nullable(),
});

import {Card, Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Grid, Right, Space, Text} from 'platform/foundation';
import {object} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {Nullish, yupString} from 'shared';

type PlatformAuthenticationFormData = {
  username: string;
  password: string;
  dealerId: string;
};

export type PlatformAuthenticationFormProps = {
  defaultValues: PlatformAuthenticationFormData | Nullish;
  onSubmit: FormSubmitHandler<PlatformAuthenticationFormData>;
};

export function PlatformAuthenticationFormHyundaiPromise(props: PlatformAuthenticationFormProps) {
  return (
    <Card title={i18n.t('general.labels.authentication')}>
      <Text>{i18n.t('page.settings.labels.authenticationDescription')}</Text>
      <Space vertical={4} />
      <Form<PlatformAuthenticationFormData>
        defaultValues={props.defaultValues}
        schema={schema}
        onSubmit={props.onSubmit}
      >
        {(control) => (
          <>
            <Grid columns={2}>
              <FormField
                control={control}
                name="username"
                type="text"
                label={i18n.t('page.advertisement.labels.username')}
              />
              <FormField
                control={control}
                name="password"
                type="password"
                label={i18n.t('general.labels.password')}
              />
            </Grid>
            <Space vertical={4} />
            <Grid columns={2}>
              <FormField
                control={control}
                name="dealerId"
                type="text"
                label={i18n.t('page.settings.labels.dealerId')}
              />
            </Grid>
            <Space vertical={4} />
            <Right>
              <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
            </Right>
          </>
        )}
      </Form>
    </Card>
  );
}

const schema = object({
  username: yupString.required(),
  password: yupString.required(),
  dealerId: yupString.required(),
});

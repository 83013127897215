import {Icon} from 'platform/foundation';

import {
  MenuItemPin as MenuItemPinObject,
  MenuItemProps,
  usePinUserMenuItemMutation,
  useUnpinUserMenuItemMutation,
} from '@omnetic-dms/api';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId} from 'shared';

import {MenuItemActionButton} from './MenuItemActionButton';

interface PinProps extends Pick<MenuItemProps, 'path' | 'id'> {
  isPinned?: boolean;
}

export function MenuItemPin(props: PinProps) {
  const [unpinUserMenuItem] = useUnpinUserMenuItemMutation();
  const [pinUserMenuItem] = usePinUserMenuItemMutation();

  const testIdBase = `routePath=${props.path};id=${props.id}-menuItem`;

  const handlePin = () => {
    const arg = {item: props.id} satisfies MenuItemPinObject;
    const pinningAction = props.isPinned ? unpinUserMenuItem(arg) : pinUserMenuItem(arg);

    pinningAction.unwrap().catch(handleApiError);
  };

  return (
    <MenuItemActionButton
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handlePin();
      }}
    >
      <Icon
        size={4}
        color="text.white"
        value={props.isPinned ? 'custom/push_pin' : 'custom/push_pin_outlined'}
        data-testid={suffixTestId('pin', {'data-testid': testIdBase})}
      />
    </MenuItemActionButton>
  );
}

import {Button, ButtonGroup, Dialog, Flag, useDialog} from 'platform/components';
import {Box, Heading, Hide, HStack, Show, Space, Spinner, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {isNotNil} from 'ramda-adjunct';

import {
  cebiaApi,
  FullVehicle,
  FullVehicleResponseBody,
  getApiErrorMessages,
  GetManufactureYearApiResponse,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {composePath, EMPTY_PLACEHOLDER, suffixTestId, TestIdProps} from 'shared';

import {useThunkDispatch} from '../../hooks/useThunkDispatch';
import {partiallyUpdateVehicleRequest} from '../../store/carDetails/actions';
import {CebiaWidgetAlert} from './components/CebiaWidgetAlert';
import {CebiaWidgetLogo} from './components/CebiaWidgetLogo';
import {useProductionYearWidgetState} from './hooks/useProductionYearWidgetState';

interface CebiaProductionYearVehicleParams {
  id?: string;
  vin?: string;
  manufacturedOnMonth?: number;
  manufacturedOnYear?: number;
}

export interface CebiProductionYearWidgetProps extends TestIdProps {
  vehicle: FullVehicle | CebiaProductionYearVehicleParams | FullVehicleResponseBody;
  onSuccess?: (response?: GetManufactureYearApiResponse) => void;
}
export function CebiaProductionYearWidget(props: CebiProductionYearWidgetProps) {
  const {vehicle, onSuccess} = props;
  const formatDateTime = useDateTimeFormatter();
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  const {
    apiResponse,
    errorMessages,
    lastChecked,
    setApiResponse,
    setErrorMessages,
    setLastChecked,
  } = useProductionYearWidgetState();
  const [isDialogOpen, openDialog, closeDialog] = useDialog();
  const [getManufactureYear, {isFetching}] = cebiaApi.endpoints.getManufactureYear.useLazyQuery();

  const {id: vehicleId, vin, manufacturedOnMonth, manufacturedOnYear} = vehicle;

  useEffect(() => {
    setApiResponse(undefined);
  }, [vin]);

  const handleModalConfirm = (apiResponse?: GetManufactureYearApiResponse) => {
    handlePatchManufactureDate(apiResponse);
    closeDialog();
  };

  const onCebiaWidgetAlertCallback = () => {
    if (!vehicleId) {
      return;
    }
    navigate(composePath(vehiclesRoutes.edit, {params: {id: vehicleId}}));
  };

  const handlePatchManufactureDate = async (apiResponse?: GetManufactureYearApiResponse) => {
    if (vehicleId && apiResponse) {
      await dispatch(
        partiallyUpdateVehicleRequest({
          vehicleId,
          payload: apiResponse,
        })
      );
    }

    onSuccess?.(apiResponse);
    setLastChecked(new Date());
    setApiResponse(undefined);
  };

  // TODO: refactor, it's ugly
  const handleSubmit = () => {
    setErrorMessages(undefined);
    setApiResponse(undefined);

    getManufactureYear({vin: vehicle.vin as string})
      .unwrap()
      .then((response) => {
        setApiResponse(response);

        if (
          (isNotNil(manufacturedOnMonth) &&
            manufacturedOnMonth !== response?.manufacturedOnMonth) ||
          (isNotNil(manufacturedOnYear) && manufacturedOnYear !== response?.manufacturedOnYear)
        ) {
          openDialog();
          return;
        }

        // vehicle manufacture won't be overridden so we can fill them in straight away
        handlePatchManufactureDate(response);
      })
      .catch((e) =>
        handleApiError(e, {callback: ({error}) => setErrorMessages(getApiErrorMessages(error))})
      );
  };

  return (
    <VStack spacing={2}>
      <Box
        backgroundColor="palettes.neutral.10.100"
        borderRadius="medium"
        overflow="hidden"
        position="relative"
      >
        <HStack>
          <CebiaWidgetLogo alt={i18n.t('page.integrations.labels.cebiaReportTitle')} />
          <Box padding={4} width="100%" backgroundColor="palettes.neutral.10.100">
            <VStack spacing={3}>
              <VStack spacing={2}>
                <Heading size={4}>
                  {i18n.t('page.integrations.labels.cebiaProductionYearTitle')}
                </Heading>
                <Text size="xSmall">
                  {i18n.t('page.integrations.labels.cebiaProductionYearWidgetDescription')}
                </Text>
              </VStack>
              <Show when={lastChecked}>
                <VStack spacing={3}>
                  <div>
                    <Text color="tertiary" size="small">
                      {i18n.t('general.labels.checkedOn')}{' '}
                      {lastChecked ? formatDateTime('dateTimeShort', lastChecked) : null}
                    </Text>
                  </div>
                  <HStack spacing={3}>
                    <Flag
                      colorScheme="green"
                      label={i18n.t('general.labels.checked')}
                      size="large"
                      data-testid={suffixTestId('cebiaProductionYearWidget-checked', props)}
                    />
                    <Button
                      isDisabled={!vin}
                      onClick={handleSubmit}
                      variant="secondary"
                      leftIcon="action/autorenew"
                      title={i18n.t('general.actions.update')}
                      data-testid={suffixTestId('cebiaProductionYearWidget-update', props)}
                    />
                  </HStack>
                </VStack>
              </Show>
              <Hide when={lastChecked}>
                <div>
                  <Button
                    isDisabled={!vin}
                    onClick={handleSubmit}
                    variant="secondary"
                    title={i18n.t('general.actions.check')}
                    data-testid={suffixTestId('cebiaProductionYearWidget-check', props)}
                  />
                </div>
              </Hide>
            </VStack>
          </Box>
        </HStack>
      </Box>
      <Show when={!vin}>
        <CebiaWidgetAlert
          colorScheme="warning"
          message={i18n.t('entity.vehicle.notifications.vinIsRequired')}
          actionText={i18n.t('entity.vehicle.labels.editVehicle')}
          actionCallback={onCebiaWidgetAlertCallback}
        />
      </Show>
      <Show
        when={apiResponse?.manufacturedOnYear === null && apiResponse?.manufacturedOnMonth === null}
      >
        <CebiaWidgetAlert
          colorScheme="warning"
          message={i18n.t('entity.vehicle.notifications.noProductionYearForVIN', {vin})}
        />
      </Show>
      <Show when={errorMessages}>
        <CebiaWidgetAlert
          colorScheme="error"
          message={Array.isArray(errorMessages) ? errorMessages.join(', ') : errorMessages}
        />
      </Show>
      <Show when={isFetching}>
        <Spinner variant="overlay" />
      </Show>

      <Dialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        data-testid={suffixTestId('cebiaProductionYearWidget-dialog', props)}
      >
        {i18n.t('entity.vehicle.notifications.productionYearOverride', {
          sourceMonth: manufacturedOnMonth || EMPTY_PLACEHOLDER,
          sourceYear: manufacturedOnYear || EMPTY_PLACEHOLDER,
          targetMonth: apiResponse?.manufacturedOnMonth ?? EMPTY_PLACEHOLDER,
          targetYear: apiResponse?.manufacturedOnYear ?? EMPTY_PLACEHOLDER,
        })}

        <Space vertical={4} />
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={closeDialog}
            title={i18n.t('general.actions.cancel')}
            data-testid={suffixTestId('cebiaProductionYearWidgetDialog-cancel', props)}
          />
          <Button
            variant="danger"
            onClick={() => handleModalConfirm(apiResponse)}
            title={i18n.t('general.notifications.replaceButton')}
            data-testid={suffixTestId('cebiaProductionYearWidgetDialog-confirm', props)}
          />
        </ButtonGroup>
      </Dialog>
    </VStack>
  );
}

export type SimilarVehiclesRequestBody = {
  size?: number | null;
  offset?: number | null;
  vin?: string | null;
  registrationPlateContains?: string | null;
  authorization?: string;
};

export enum HintTypeEnum {
  ErrorVin = 'ErrorVin',
  InfoLicense = 'InfoLicense',
}

export type HintType = `${HintTypeEnum}` | null;

import {css} from 'styled-components';

import {useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {
  FormFieldProps,
  FormContext,
  useFormRenderer,
  getVehicleMakeModels,
  selectVehicleCatalogue,
  selectVehicleMakeModels,
  PossibleObject,
  VehicleCatalogueMakeModelItem,
  VehicleType,
  useApiDispatch,
} from '@omnetic-dms/teas';

import {TestIdProps, appendSuffix} from 'shared';

import {makeModelInputFilter} from '../../../../../utils/makeModelInputFilter';

type GroupedByModelGroupType = {
  label: string;
  id: string | undefined;
  options: VehicleCatalogueMakeModelItem['models'];
};

type ModelSelectProps<
  T extends PossibleObject = undefined,
  K extends PossibleObject = undefined,
  M extends boolean = false,
> = Pick<
  FormFieldProps<T, K, M>,
  'as' | 'defaultValue' | 'disabled' | 'label' | 'name' | 'multiple' | 'onChange' | 'required'
> & {
  vehicleType: VehicleType;
  make: string;
  closeMenuOnSelect?: boolean;
  handleCreateOption?: (vehicleType: string, make: string, value: string) => void;
};

export const ModelSelect = <
  T extends PossibleObject = undefined,
  K extends PossibleObject = undefined,
  M extends boolean = false,
>({
  vehicleType,
  make,
  closeMenuOnSelect,
  handleCreateOption,
  ...props
}: ModelSelectProps<T, K, M> & TestIdProps) => {
  const {Field} = useFormRenderer();

  const {formId} = useContext(FormContext);

  // eslint-disable-next-line no-restricted-syntax
  const _name = props.name as unknown as string | string[];
  const fullName = typeof _name === 'string' ? _name : _name.join('.');

  const testId = props['data-testid'] ?? [formId, fullName.replace('.', '-')].join('-');

  const apiDispatch = useApiDispatch();
  const lang = i18n?.resolvedLanguage?.split('-')?.[0];
  const {loading} = getVehicleMakeModels.useThunkState(selectVehicleCatalogue);
  const makeModelOptions = useSelector(selectVehicleMakeModels);

  useEffect(() => {
    if (Array.isArray(make)) {
      make.forEach((make) => {
        apiDispatch(getVehicleMakeModels.action, {lang, vehicleType, make}, {throwOff: true});
      });
    } else if (isNotNilOrEmpty(make)) {
      apiDispatch(getVehicleMakeModels.action, {lang, vehicleType, make}, {throwOff: true});
    }
  }, [lang, vehicleType, make]);

  const groupByModelGroup = (
    arr: VehicleCatalogueMakeModelItem['models'] = []
  ): GroupedByModelGroupType[] =>
    arr.reduce((groupedArr, model) => {
      const index = groupedArr.findIndex(({id}) => id === model?.modelGroup?.value);

      if (index > -1) {
        groupedArr[index]?.options?.push(model);
      } else {
        groupedArr.push({
          options: [model],
          id: model?.modelGroup?.value,
          label: model?.modelGroup?.label ?? makeModelOptions[make]?.label,
        });
      }

      return groupedArr;
    }, [] as GroupedByModelGroupType[]);

  const options = Array.isArray(make)
    ? make.map((make) => ({
        label: makeModelOptions[make]?.label,
        options: makeModelOptions[make]?.models,
      }))
    : groupByModelGroup(makeModelOptions[make]?.models);

  return (
    <Field
      {...props}
      as="select"
      isLoading={loading}
      options={options as any[]}
      selectProps={{
        closeMenuOnSelect,
        creatable: Boolean(make),
        handleCreate: (newOption) => {
          if (newOption) {
            handleCreateOption?.(vehicleType, make, newOption);
          }
        },
        formatCreateLabel: (inputValue: string) => (
          <div
            css={css`
              color: ${({theme}) => theme.colors.general.accent};
            `}
            data-testid={appendSuffix('add', testId)}
          >
            {i18n.t('general.actions.add')} {inputValue}
          </div>
        ),
        isValidNewOption: (inputValue) => {
          if (isNilOrEmpty(inputValue)) {
            return false;
          }

          return !makeModelOptions[make]?.models?.some(
            ({label}) => label?.toLowerCase() === inputValue?.toLowerCase()
          );
        },
        filterOption: makeModelInputFilter ?? undefined,
      }}
      data-testid={`${testId}-model`}
    />
  );
};

import {GridApi} from '@ag-grid-community/core';

import {useDataGridContext} from '../context/useDataGridContext';
import {CellsV5, TransformedDefinitionApiResponse} from '../types/Api';
import {useGridApiEventListener} from './useGridApiEventListener';
import {useHttpCalls} from './useHttpCalls';

type RowData = Record<string, CellsV5>[];

export const useSummaryRow = (
  gridApi: GridApi | undefined,
  definition: TransformedDefinitionApiResponse
) => {
  const http = useHttpCalls();
  const {activePreset} = useDataGridContext();

  const updateSummaryRowData = (data: RowData) =>
    gridApi?.setGridOption('pinnedBottomRowData', data);

  const getSummaryRow = () => {
    if (!definition?.behavior?.summaryRowEnabled || !gridApi) {
      updateSummaryRowData([]);
      return;
    }

    http
      .getSummaryRow(activePreset.dataQueryId)
      .then((data) => {
        if (data) {
          updateSummaryRowData([data]);
          gridApi?.redrawRows({});
        }
      })
      .catch(() => updateSummaryRowData([]));
  };

  const {addListeners: addSummaryRowListener} = useGridApiEventListener(
    gridApi,
    ['filterChanged', 'gridReady', 'storeRefreshed', 'settingsChanged'],
    getSummaryRow
  );

  const {addListeners: addSettingsChangedListener} = useGridApiEventListener(
    gridApi,
    ['columnPinned'],
    () => {
      // this is the only way to change styles of the pinned row:
      // https://www.ag-grid.com/javascript-data-grid/row-height/#height-for-pinned-rows
      const currentBottomRowData = gridApi?.getPinnedBottomRow(0);
      if (!currentBottomRowData) {
        return;
      }
      gridApi?.refreshCells({rowNodes: [currentBottomRowData]});
    }
  );

  addSettingsChangedListener();
  addSummaryRowListener();
};

import {Box, Hide, Show, VStack} from 'platform/foundation';

import {useLocation} from 'react-router-dom';

import {MenuItemProps} from '@omnetic-dms/api';
import featureFlags from '@omnetic-dms/feature-flags';

import {suffixTestId, TestIdProps} from 'shared';

import {useSidebarMenuItemsOverflow} from '../hooks/useSidebarMenuItemsOverflow';
import {SidebarCreateEntityShortcut} from './SidebarCreateEntityShortcut';
import {SidebarEditDialog} from './SidebarEditDialog/SidebarEditDialog';
import {SidebarLink} from './SidebarLink';
import {SidebarOverflowMenu} from './SidebarOverflowMenu';

interface SidebarProps extends TestIdProps {
  menuItems: MenuItemProps[];
  createMenuItems: MenuItemProps[];
  menuItemsById: Record<string, MenuItemProps>;
  pinnedMenuItems: string[];
}

export function Sidebar(props: SidebarProps) {
  const location = useLocation();

  const {
    containerRef,
    additionalContentContainerRef,
    visiblePinnedMenuItems,
    overflownPinnedMenuItems,
  } = useSidebarMenuItemsOverflow({
    pinnedMenuItems: props.pinnedMenuItems,
  });

  return (
    <Box
      ref={containerRef}
      backgroundColor="palettes.neutral.900.100"
      width={12}
      flexGrow={1}
      overflow="hidden"
    >
      <VStack height="100%" align="center" justify="space-between" spacing={1}>
        <Box width="100%">
          {visiblePinnedMenuItems.map((id) => {
            const menuItem = props.menuItemsById[id];
            if (!menuItem) {
              return null;
            }

            return (
              <SidebarLink
                key={menuItem.id}
                path={menuItem.path}
                icon={menuItem.layoutProps.iconV2 ?? menuItem.layoutProps.icon}
                title={menuItem.layoutProps.title}
                isActive={location.pathname === menuItem.path}
                data-testid={suffixTestId(`sidebar-link-${menuItem.id}`, props)}
              />
            );
          })}

          <Show when={overflownPinnedMenuItems.length > 0}>
            <SidebarOverflowMenu
              key={String(overflownPinnedMenuItems?.length ?? '')}
              pinnedMenuItems={overflownPinnedMenuItems}
              menuItems={props.menuItems}
              menuItemsById={props.menuItemsById}
            />
          </Show>
        </Box>

        <Box width="100%" ref={additionalContentContainerRef}>
          <Box width="100%">
            <Hide
              when={props.createMenuItems.length === 0}
              whenFeatureEnabled={featureFlags.SIDEBAR_REMOVE_QUICK_ACTIONS}
            >
              <SidebarCreateEntityShortcut
                menuItems={props.createMenuItems}
                menuItemsById={props.menuItemsById}
                data-testid={props['data-testid']}
              />
            </Hide>
            <SidebarEditDialog
              menuItemsById={props.menuItemsById}
              pinnedMenuItems={props.pinnedMenuItems}
              data-testid={props['data-testid']}
            />
          </Box>
        </Box>
      </VStack>
    </Box>
  );
}

import {Box, Heading, HStack, Icon, Show, Text, TextProps, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {DOT_CHARACTER, Nullish, suffixTestId, TestIdProps} from 'shared';

import {FlagProps} from '../../Flag/Flag';
import {Flags} from '../../Flags/Flags';
import {Parameters} from '../../Parameters/Parameters';
import {Parameter} from '../../Parameters/types/Parameter';
import {Tooltip} from '../../Tooltip/Tooltip';

export interface CardProps extends TestIdProps {
  title?: string | Nullish;
  subtitle?: string | Nullish;
  parameters?: Parameter[];
  flags?: Omit<FlagProps, 'size'>[];
  tooltip?: string | Nullish;
  parametersProps?: TextProps;
}

export function CardHeadings(props: CardProps) {
  return (
    <Box minWidth={0} paddingVertical={4}>
      <VStack spacing={1}>
        <HStack spacing={2} align="center">
          <Show when={!!props.title}>
            <Heading size={4} data-testid={suffixTestId('cardHeadingTitle', props)}>
              {props.title}
            </Heading>
          </Show>
          <Show when={!!props.title && !!props.subtitle}>
            <Heading size={4}>{DOT_CHARACTER}</Heading>
          </Show>
          <Show when={!!props.subtitle}>
            <Text size="base" data-testid={suffixTestId('cardHeadingSubtitle', props)}>
              {props.subtitle}
            </Text>
          </Show>
          <Show when={!!props.flags}>
            <Flags
              flags={props.flags}
              spacing={2}
              data-testid={suffixTestId('cardHeadingFlags', props)}
            />
          </Show>
          <Show when={isNotNil(props.tooltip)}>
            <Tooltip label={props.tooltip}>
              <Icon value="action/help" size={4} color="text.secondary" />
            </Tooltip>
          </Show>
        </HStack>
        <Parameters
          data-testid={suffixTestId('cardHeadingParameters', props)}
          parameters={props.parameters}
          parametersProps={props.parametersProps}
          size="small"
          color="secondary"
        />
      </VStack>
    </Box>
  );
}

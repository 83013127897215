import {match} from 'ts-pattern';

import {useEffect} from 'react';

import {isNil, path} from 'ramda';

import {warehouseRoutes} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions, useWarehouseDataGridTreeFolder} from '@omnetic-dms/shared';

import {composePath, suffixTestId, TestIdProps} from 'shared';

import {ActionCallback, DataGrid, ExternalFilters} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../../../../components/FullscreenDataGridWrapper';
import {MaterialWarehouseTabExternalFilters} from './MaterialWarehouseTabExternalFilters';

const SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID = 'supplier-order-material-external-filter';

interface MaterialWarehouseTabProps extends TestIdProps {
  onAddMaterialToBasket: (articleId: string) => Promise<void>;
  onActiveFolderIdChange?: (activeFolderId?: string) => void;
  onExternalFilterChange: (filters: ExternalFilters) => void;
}

export function MaterialWarehouseTab(props: MaterialWarehouseTabProps) {
  const [folderTreeRef, dataGridModifier, activeFolderId] = useWarehouseDataGridTreeFolder();

  useEffect(() => {
    props.onActiveFolderIdChange?.(activeFolderId);
  }, [activeFolderId]);

  const warehouseActionCallback: ActionCallback = ({actionKey, refreshData, rowData}) => {
    const articleId = path(['articleId', 'value'], rowData) as string;

    if (isNil(articleId)) {
      throw new Error('Article id is not defined');
    }

    match(actionKey)
      .with('toBasket', () => props.onAddMaterialToBasket(articleId).then(refreshData))
      .with('articleDetail', () => {
        const warehouseId = path(['warehouseId', 'value', 'key'], rowData) as string;

        if (isNil(warehouseId)) {
          throw new Error('Warehouse id is not defined');
        }

        const routePath = composePath(warehouseRoutes.articleDetailOverview, {
          params: {warehouseId, id: articleId},
          isSearchParamsPreserved: false,
        });
        window.open(routePath, '_blank')?.focus();
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper
      externalFilterId={SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID}
      externalFilterHeight={100}
      folderTreeRef={folderTreeRef}
      hideCardWrapper
      data-testid={props['data-testid']}
    >
      <DataGrid
        // DG must be re-rendered on folder change to update the query modifier
        // eslint-disable-next-line no-restricted-syntax
        key={dataGridModifier?.key}
        gridCode="warehouse-supplier-order-article-cart"
        actionCallback={warehouseActionCallback}
        externalFilterId={SUPPLIER_ORDER_MATERIAL_EXTERNAL_FILTER_ID}
        queryModifier={dataGridModifier?.queryModifier}
        _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
        data-testid={suffixTestId('warehouseSupplierOrderArticleBasket', props)}
        filterComponent={MaterialWarehouseTabExternalFilters}
        onExternalFilterChange={props.onExternalFilterChange}
      />
    </FullscreenDataGridWrapper>
  );
}

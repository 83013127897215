import {isFeatureEnabled} from 'feature-flags';

import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {usePermissions} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {
  SettingsStackItemProps,
  SettingsStackItems,
} from '../../components/SettingsStackItems/SettingsStackItems';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';

export function Advertising() {
  const [hasConfigureBackgroundRemovalPermission, hasRemoveVehiclePhotoBackgroundPermission] =
    usePermissions({
      permissionKeys: ['configureBackgroundRemoval', 'removeVehiclePhotoBackground'],
    });

  const header: SettingsTemplateHeader = {title: i18n.t('page.salesSettings.labels.advertising')};

  const items = buildArray<SettingsStackItemProps>([
    {
      cyId: testIds.settings.advertising('platforms-link'),
      title: i18n.t('page.salesSettings.labels.platforms'),
      url: settingsRoutes.advertisingPlatforms,
    },
    {
      cyId: testIds.settings.advertising('promoPhotos-link'),
      title: i18n.t('page.salesSettings.labels.promotionalPhotos'),
      url: settingsRoutes.advertisingPromoPhotos,
    },
    {
      cyId: testIds.settings.advertising('watermark-link'),
      title: i18n.t('page.salesSettings.labels.watermark'),
      url: isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
        ? settingsRoutes.advertisingWatermarking
        : settingsRoutes.advertisingWatermarkingOld,
    },
    {
      cyId: testIds.settings.advertising('qrCodeDefinition-link'),
      title: i18n.t('page.salesSettings.labels.qrCodeDefinitionTitle'),
      url: settingsRoutes.advertisingQrCodeDefinition,
    },
  ]).when(
    isFeatureEnabled(featureFlags.SALES_BACKGROUND_REMOVAL) &&
      hasConfigureBackgroundRemovalPermission &&
      hasRemoveVehiclePhotoBackgroundPermission,
    {
      cyId: testIds.settings.advertising('backgroundRemoval-link'),
      title: i18n.t('page.salesSettings.labels.backgroundRemoval'),
      url: isFeatureEnabled(featureFlags.SETTINGS_BACKGROUND_REMOVAL)
        ? settingsRoutes.backgroundRemoval
        : settingsRoutes.backgroundRemovalOld,
    }
  );

  return (
    <SettingsTemplate header={header} data-testid="settings-advertising">
      <SettingsStackItems items={items} />
    </SettingsTemplate>
  );
}

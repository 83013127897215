import {minutesToHours} from 'date-fns';
import {AttributesRow} from 'platform/components';
import {HStack, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {useSelector} from 'react-redux';

import {always} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {GetVehicleApiResponseV2} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  ColorCircle,
  selectBatteryOwnershipType,
  selectBatteryType,
  selectBodyColor,
  selectBodyStyle,
  selectChargingConnectorType,
  selectColorType,
  selectDoorCount,
  selectDrive,
  selectEmissionClass,
  selectFuelType,
  selectHybridType,
  selectInteriorColor,
  selectInteriorMaterial,
  selectTransmission,
  selectVehicleMakeModel,
  selectVehicleType,
  FuelTypeEnum,
  HybridTypeEnum,
  VehicleCatalogueEnumOption,
  VehicleCatalogueFuelTypeProperties,
  useGetVehicleEnums,
} from '@omnetic-dms/teas';

import {parseDate, buildArray} from 'shared';

import {MAKE_OTHER, MODEL_FAMILY_OTHER_END} from '../../../../../constants/makes';
import {getRealProductionDate} from '../../../../../utils/getRealProductionDate';
import {getSecondaryFuelType} from '../utils/getSecondaryFuelType';

/**
 *
 * @param vehicle
 * @returns set of vehicle parameters, to display in vehicle detail set by
 * https://www.figma.com/file/uyvxXnfc6w7UXwVA3tHISj/Business-case-2.0?node-id=7605%3A265021&t=smZD0cmw84l649K9-3
 *
 * @example
 * const [
    getModelSpecifications,
    engineSpecifications,
    consumptionAndEmissionSpecifications,
    metricsSpecifications
  ] = useVehicleDetailsParameters(vehicle)
 *
 */
export const useVehicleDetailsParameters = (vehicle: GetVehicleApiResponseV2) => {
  const formatDateTime = useDateTimeFormatter();

  const vehicleType = useSelector(selectVehicleType(vehicle.type));
  const transmission = useSelector(selectTransmission(vehicle.transmission));
  const doorCountCategory = useSelector(selectDoorCount(vehicle.doorCountCategory));
  const driveType = useSelector(selectDrive(vehicle.drive));
  const exteriorColor = useSelector(selectBodyColor(vehicle?.exteriorColor?.code, vehicle?.type));
  const colorType = useSelector(
    selectColorType(vehicle?.exteriorColorSpecification, vehicle?.type)
  );
  const interiorColor = useSelector(
    selectInteriorColor(vehicle?.interiorColor?.code, vehicle?.type)
  );
  const interiorMaterial = useSelector(
    selectInteriorMaterial(vehicle?.interiorMaterial, vehicle?.type)
  );
  const hybridType = useSelector(selectHybridType(vehicle?.hybridType, vehicle?.type));
  const emissionClass = useSelector(selectEmissionClass(vehicle.emissionClass));
  const bodyStyle = useSelector(selectBodyStyle(vehicle.bodyStyle, vehicle?.type));
  const makeModel = useSelector(selectVehicleMakeModel(vehicle.make));
  const fuelType = useSelector(selectFuelType(vehicle.fuelType, vehicle?.type));
  const BatteryOwnershipType = useSelector(
    selectBatteryOwnershipType(vehicle?.batteryOwnershipType, vehicle.type)
  );
  const batteryType = useSelector(selectBatteryType(vehicle?.batteryType, vehicle.type));
  const chargingConnectorType = useSelector(
    selectChargingConnectorType(vehicle?.chargingConnectorType, vehicle.type)
  );
  const secondaryFuelType = useSelector(
    selectFuelType(
      getSecondaryFuelType(
        fuelType as VehicleCatalogueEnumOption<VehicleCatalogueFuelTypeProperties>,
        vehicle.secondaryFuelType as string
      ),
      vehicle.type
    )
  );
  const {dimensions_height, dimensions_length} = useGetVehicleEnums(vehicle.type);

  const model = makeModel?.models.find((model) => model.value === vehicle.modelFamily);

  const firstRegistrationOn = isNotNil(vehicle.firstRegistrationOn)
    ? parseDate(vehicle.firstRegistrationOn ?? '')
    : null;

  const vehicleReservationDateString = firstRegistrationOn
    ? formatDateTime('dateShort', firstRegistrationOn)
    : null;

  const isSecondaryFuelLPGorCNG = match(fuelType?.value)
    .with(FuelTypeEnum.FUELTYPE_LPG, FuelTypeEnum.FUELTYPE_CNG, always(true))
    .otherwise(always(false));

  const isSecondaryFuelLPGorCNGorHydrogen = match(fuelType?.value)
    .with(
      FuelTypeEnum.FUELTYPE_LPG,
      FuelTypeEnum.FUELTYPE_CNG,
      FuelTypeEnum.FUELTYPE_HYDROGEN,
      always(true)
    )
    .otherwise(always(false));

  const isHybrid = match(fuelType?.value)
    .with(
      FuelTypeEnum.FUELTYPE_HYBRID,

      always(true)
    )
    .otherwise(always(false));

  const isElectric = fuelType?.value === FuelTypeEnum.FUELTYPE_ELECTRIC && vehicle?.batteryType;

  const isFullyHybrid =
    fuelType?.value === FuelTypeEnum.FUELTYPE_HYBRID &&
    hybridType?.value === HybridTypeEnum.HYBRIDTYPE_FULL &&
    vehicle?.batteryType;

  const isPHEVHybrid =
    fuelType?.value === FuelTypeEnum.FUELTYPE_HYBRID &&
    hybridType?.value === HybridTypeEnum.HYBRIDTYPE_PHEV &&
    vehicle?.batteryType;

  const isNotElectricNorHydrogen =
    fuelType?.value !== FuelTypeEnum.FUELTYPE_ELECTRIC &&
    fuelType?.value !== FuelTypeEnum.FUELTYPE_HYDROGEN;

  const isElectricOrHybrid =
    fuelType?.value === FuelTypeEnum.FUELTYPE_ELECTRIC ||
    fuelType?.value === FuelTypeEnum.FUELTYPE_HYBRID;

  const isSecondaryFuelPetrolOrDiesel =
    fuelType?.value &&
    (vehicle.secondaryFuelType === FuelTypeEnum.FUELTYPE_DIESEL ||
      vehicle.secondaryFuelType === FuelTypeEnum.FUELTYPE_PETROL);
  const isPrimaryFuelPetrolOrDiesel =
    fuelType?.value === FuelTypeEnum.FUELTYPE_DIESEL ||
    fuelType?.value === FuelTypeEnum.FUELTYPE_PETROL;

  const isVanOrTruck = vehicle.type === 'VEHICLETYPE_VAN' || vehicle.type === 'VEHICLETYPE_TRUCK';

  const canHaveParticularFilter =
    (isPrimaryFuelPetrolOrDiesel || isSecondaryFuelPetrolOrDiesel) && isNotNil(vehicle.hasDpf);

  const getModelSpecifications = buildArray<AttributesRow>()
    .add({
      label: i18n.t('entity.vehicle.labels.licensePlate'),
      value: vehicle?.registrationPlate,
      testId: 'licensePlate',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.vin'),
      value: vehicle.vin,
      testId: 'vin',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.type'),
      value: vehicleType?.label,
      testId: 'type',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.make'),
      value:
        vehicle.make === MAKE_OTHER ? (vehicle.customMake ?? makeModel?.label) : makeModel?.label,
      testId: 'make',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.model'),
      value: vehicle.modelFamily?.includes(MODEL_FAMILY_OTHER_END)
        ? vehicle?.customModelFamily || model?.label || vehicle?.modelFamily
        : model?.label || vehicle?.modelFamily,
      testId: 'model',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.trim'),
      value: vehicle.trim,
      testId: 'trim',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.variantName'),
      value: vehicle?.variant,
      testId: 'variantName',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.firstRegistration'),
      value: vehicleReservationDateString,
      testId: 'firstRegistration',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.realProduction'),
      value: getRealProductionDate(vehicle.manufacturedOn),
      testId: 'realProduction',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.body'),
      value: bodyStyle?.label,
      testId: 'body',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.fuel'),
      value: fuelType?.label,
      testId: 'fuel',
    })
    .when(isNotNilOrEmpty(secondaryFuelType), {
      label: i18n.t('entity.vehicle.labels.secondaryFuelType'),
      value: secondaryFuelType?.label,
      testId: 'secondaryFuelType',
    })
    .when(isSecondaryFuelLPGorCNG, {
      label: i18n.t('entity.vehicle.labels.gasRevisionValidUntil'),
      value: vehicle?.gasRevisionValidUntil
        ? formatDateTime('dateShort', parseDate(vehicle?.gasRevisionValidUntil))
        : null,
      testId: 'gasRevisionValidUntil',
    })
    .when(isSecondaryFuelLPGorCNGorHydrogen, {
      label: i18n.t('entity.vehicle.labels.pressureVesselExpires'),
      value: vehicle?.pressureVesselValidUntil
        ? formatDateTime('dateShort', parseDate(vehicle?.pressureVesselValidUntil))
        : null,
      testId: 'pressureVesselExpires',
    })
    .when(isHybrid, {
      label: i18n.t('general.labels.hybridType'),
      value: hybridType?.label,
      testId: 'hybridType',
    })
    .when(isElectric, {
      label: i18n.t('general.labels.hybridType'),
      value: hybridType?.label,
      testId: 'hybridType',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingAc')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.chargingAC?.toString(),
      testId: 'batteryChargingAc',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingTimeAc')} (${i18n.t(
        'general.metric.h'
      )})`,
      value: vehicle?.chargingTimeAC?.toString(),
      testId: 'batteryChargingTimeAc',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingDc')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.chargingDC?.toString(),
      testId: 'batteryChargingDc',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingTimeDc')} (${i18n.t(
        'general.metric.min'
      )})`,
      value: vehicle?.chargingTimeDC?.toString(),
      testId: 'batteryChargingTimeDc',
    })
    .when(isElectric, {
      label: i18n.t('entity.vehicle.labels.batteryType'),
      value: batteryType?.label,
      testId: 'batteryType',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.batteryCapacityWh?.toString(),
      testId: 'batteryCapacityWh',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.Ah')})`,
      value: vehicle?.batteryCapacityAh?.toString(),
      testId: 'batteryCapacityAh',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryVoltage')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.batteryVoltage?.toString(),
      testId: 'batteryVoltage',
    })
    .when(isElectric, {
      label: i18n.t('entity.vehicle.labels.batteryCount'),
      value: vehicle?.batteryCount?.toString(),
      testId: 'batteryCount',
    })
    .when(isElectric, {
      label: i18n.t('entity.vehicle.labels.batteryChargingConnectoryType'),
      value: chargingConnectorType?.label,
      testId: 'batteryChargingConnectoryType',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryElectricRange')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.electricRange?.toString(),
      testId: 'batteryElectricRange',
    })
    .when(isElectric, {
      label: `${i18n.t('entity.vehicle.labels.batteryMileageLimit')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.batteryMileageLimit?.toString(),
      testId: 'batteryMileageLimit',
    })
    .when(isElectric, {
      label: i18n.t('entity.vehicle.labels.batteryWarrantyUntil'),
      value: vehicle?.batteryWarrantyUntil
        ? formatDateTime('dateShort', parseDate(vehicle?.batteryWarrantyUntil))
        : null,
      testId: 'batteryWarrantyUntil',
    })
    .when(isElectric, {
      label: i18n.t('entity.vehicle.labels.batteryOwnershipType'),
      value: BatteryOwnershipType?.label,
      testId: 'batteryOwnershipType',
    })
    .when(isElectric, {
      label: i18n.t('entity.vehicle.labels.hasRangeExtender'),
      value: vehicle?.hasRangeExtender ? i18n.t('general.labels.yes') : i18n.t('general.labels.no'),
      testId: 'hasRangeExtender',
    })
    .when(isFullyHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryType'),
      value: batteryType?.label,
      testId: 'batteryType',
    })
    .when(isFullyHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.batteryCapacityWh?.toString(),
      testId: 'batteryCapacityWh',
    })
    .when(isFullyHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.Ah')})`,
      value: vehicle?.batteryCapacityAh?.toString(),
      testId: 'batteryCapacityAh',
    })
    .when(isFullyHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryVoltage')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.batteryVoltage?.toString(),
      testId: 'batteryVoltage',
    })
    .when(isFullyHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryCount'),
      value: vehicle?.batteryCount?.toString(),
      testId: 'batteryCount',
    })
    .when(isFullyHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryElectricRange')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.electricRange?.toString(),
      testId: 'batteryElectricRange',
    })
    .when(isFullyHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryMileageLimit')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.batteryMileageLimit?.toString(),
      testId: 'batteryMileageLimit',
    })
    .when(isFullyHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryWarrantyUntil'),
      value: isNotNil(vehicle?.batteryWarrantyUntil)
        ? formatDateTime('dateShort', parseDate(vehicle?.batteryWarrantyUntil ?? 0))
        : null,
      testId: 'batteryWarrantyUntil',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingAc')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.chargingAC?.toString(),
      testId: 'batteryChargingAc',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingTimeAc')} (${i18n.t(
        'general.metric.h'
      )})`,
      value: isNotNil(vehicle?.chargingTimeAC)
        ? (convertMinutesToTime(vehicle?.chargingTimeAC ?? 0) ?? null)
        : null,
      testId: 'batteryChargingTimeAc',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingDc')} (${i18n.t(
        'general.metric.kW'
      )})`,
      value: vehicle?.chargingDC?.toString(),
      testId: 'batteryChargingDc',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryChargingTimeDc')} (${i18n.t(
        'general.metric.min'
      )})`,
      value: isNotNil(vehicle?.chargingTimeDC)
        ? (convertMinutesToTime(vehicle?.chargingTimeDC ?? 0) ?? null)
        : null,
      testId: 'batteryChargingTimeDc',
    })
    .when(isPHEVHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryType'),
      value: batteryType?.label,
      testId: 'batteryType',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.batteryCapacityWh?.toString(),
      testId: 'batteryCapacityWh',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryCapacity')} (${i18n.t('general.metric.Ah')})`,
      value: vehicle?.batteryCapacityAh?.toString(),
      testId: 'batteryCapacityAh',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryVoltage')} (${i18n.t('general.metric.kW')})`,
      value: vehicle?.batteryVoltage?.toString(),
      testId: 'batteryVoltage',
    })
    .when(isPHEVHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryCount'),
      value: vehicle?.batteryCount?.toString(),
      testId: 'batteryCount',
    })
    .when(isPHEVHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryChargingConnectoryType'),
      value: chargingConnectorType?.label,
      testId: 'batteryChargingConnectoryType',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryElectricRange')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.electricRange?.toString(),
      testId: 'batteryElectricRange',
    })
    .when(isPHEVHybrid, {
      label: `${i18n.t('entity.vehicle.labels.batteryMileageLimit')} (${i18n.t(
        'general.metric.km'
      )})`,
      value: vehicle?.batteryMileageLimit?.toString(),
      testId: 'batteryMileageLimit',
    })
    .when(isPHEVHybrid, {
      label: i18n.t('entity.vehicle.labels.batteryWarrantyUntil'),
      value: isNotNil(vehicle?.batteryWarrantyUntil)
        ? formatDateTime('dateShort', parseDate(vehicle?.batteryWarrantyUntil ?? 0))
        : null,
      testId: 'batteryWarrantyUntil',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.transmission'),
      value: transmission?.label,
      testId: 'transmission',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.drive'),
      value: driveType?.label,
      testId: 'drive',
    })
    .add({
      label: i18n.t('entity.sourcing.vehiclePreview.labels.power'),
      value: isNotNil(vehicle.power)
        ? `${vehicle.power.toString()} ${i18n.t('general.metric.kW')}`
        : null,
      testId: 'power',
    })
    .when(isHybrid, {
      label: `${i18n.t('entity.vehicle.labels.powerCombustionEngine')} (${i18n.t('general.metric.kW')})`,
      value: isNotNil(vehicle.powerCombustionEngine)
        ? vehicle.powerCombustionEngine.toString()
        : null,
      testId: 'powerCombustionEngine',
    })
    .when(isHybrid, {
      label: `${i18n.t('entity.vehicle.labels.powerElectricEngine')} (${i18n.t('general.metric.kW')})`,
      value: isNotNil(vehicle.powerElectricEngine) ? vehicle.powerElectricEngine.toString() : null,
      testId: 'powerElectricEngine',
    })
    .when(isNotElectricNorHydrogen, {
      label: `${i18n.t('entity.vehicle.labels.engineCapacity')} (${i18n.t('general.metric.ccm')})`,
      value: isNotNil(vehicle.engineVolume) ? vehicle.engineVolume.toString() : null,
      testId: 'engineCapacity',
    })
    .when(isNotElectricNorHydrogen, {
      label: i18n.t('entity.vehicle.labels.numberOfCylinders'),
      value: isNotNil(vehicle.cylinderCount) ? vehicle.cylinderCount.toString() : null,
      testId: 'numberOfCylinders',
    })
    .when(fuelType?.value, {
      label: i18n.t('entity.vehicle.labels.numberOfGears'),
      value: isNotNil(vehicle.gearCount) ? vehicle.gearCount.toString() : null,
      testId: 'numberOfGears',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.numberOfDoors'),
      value: doorCountCategory?.label,
      testId: 'numberOfDoors',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.numberOfSeats'),
      value: vehicle.seatCount?.toString(),
      testId: 'numberOfSeats',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.bodyColorType'),
      value: colorType?.label,
      testId: 'bodyColorType',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.bodyColor'),
      content: exteriorColor ? (
        <HStack align="center" spacing={1}>
          <ColorCircle color={exteriorColor} needBorder={exteriorColor?.value === 'COLOR_WHITE'} />
          <Text size="xSmall">{exteriorColor?.label}</Text>
        </HStack>
      ) : null,
      testId: 'bodyColor',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.manufacturerColorName'),
      value: vehicle?.manufacturerColorName,
      testId: 'manufacturerColorName',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.manufacturerColorCode'),
      value: vehicle?.manufacturerColorCode,
      testId: 'manufacturerColorCode',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.interiorMaterial'),
      value: interiorMaterial?.label,
      testId: 'interiorMaterial',
    })
    .add({
      label: i18n.t('entity.vehicle.labels.interiorColor'),
      content: interiorColor ? (
        <HStack align="center" spacing={1}>
          <ColorCircle color={interiorColor} needBorder={interiorColor?.value === 'COLOR_WHITE'} />
          <Text size="xSmall">{interiorColor?.label}</Text>
        </HStack>
      ) : null,
      testId: 'interiorColor',
    });

  const engineSpecifications = buildArray<AttributesRow>([
    {
      label: i18n.t('entity.vehicle.labels.engineName'),
      value: vehicle.engineName || null,
      testId: 'engineName',
    },
    {
      label: i18n.t('entity.vehicle.labels.engineNumber'),
      value: vehicle.engineNumber || null,
      testId: 'engineNumber',
    },
    {
      label: i18n.t('entity.vehicle.labels.engineCode'),
      value: vehicle.engineCode || null,
      testId: 'engineCode',
    },
  ])
    .when(isNotNil(fuelType?.value), {
      label: i18n.t('entity.vehicle.labels.maxTorque'),
      value: vehicle?.maxTorque || null,
      testId: 'maxTorque',
    })
    .when(isElectricOrHybrid, {
      label: i18n.t('entity.vehicle.labels.electricEngineNote'),
      value: vehicle.electricEngineNote,
      testId: 'electricEngineNote',
    });

  const consumptionAndEmissionSpecifications = buildArray<AttributesRow>()
    .when(isNotNil(fuelType?.value), {
      label: i18n.t('entity.vehicle.labels.fuelConsumptionCombined'),
      value: isNotNil(vehicle.fuelConsumptionCombined)
        ? `${vehicle.fuelConsumptionCombined.toString()} ${i18n.t('general.metric.l100km')}`
        : null,
      testId: 'fuelConsumptionCombined',
    })
    .when(isNotNil(fuelType?.value), {
      label: i18n.t('entity.vehicle.labels.fuelConsumptionUrban'),
      value: isNotNil(vehicle.fuelConsumptionUrban)
        ? `${vehicle.fuelConsumptionUrban.toString()} ${i18n.t('general.metric.l100km')}`
        : null,
      testId: 'fuelConsumptionUrban',
    })
    .when(isNotNil(fuelType?.value), {
      label: i18n.t('entity.vehicle.labels.fuelConsumptionExtraUrban'),
      value: isNotNil(vehicle.fuelConsumptionExtraUrban)
        ? `${vehicle.fuelConsumptionExtraUrban.toString()} ${i18n.t('general.metric.l100km')}`
        : null,
      testId: 'fuelConsumptionExtraUrban',
    })
    .when(hybridType?.value === HybridTypeEnum.HYBRIDTYPE_FULL, {
      label: i18n.t('entity.vehicle.labels.powerConsumptionCombined'),
      value: isNotNil(vehicle.powerConsumptionCombined)
        ? `${vehicle.powerConsumptionCombined.toString()} ${i18n.t('general.metric.kwh100km')}`
        : null,
      testId: 'powerConsumptionCombined',
    })
    .when(isNotElectricNorHydrogen, {
      label: i18n.t('entity.vehicle.labels.emissionClass'),
      value: emissionClass?.label,
      testId: 'emissionClass',
    })
    .when(isNotElectricNorHydrogen, {
      label: i18n.t('entity.vehicle.labels.emissionsCo2WO'),
      value: isNotNil(vehicle.carbonDioxideEmission)
        ? `${vehicle.carbonDioxideEmission.toString()} ${i18n.t('general.metric.gkm')}`
        : null,
      testId: 'emissionsCo2WO',
    })
    .when(canHaveParticularFilter, {
      label: i18n.t('entity.vehicle.labels.dieselParticulateFilter'),
      value: vehicle.hasDpf ? i18n.t('general.labels.yes') : i18n.t('general.labels.no'),
      testId: 'dieselParticulateFilter',
    });

  const metricsSpecifications = buildArray<AttributesRow>([
    {
      label: i18n.t('general.labels.length'),
      value: isNotNilOrEmpty(vehicle.dimensions?.length)
        ? `${vehicle.dimensions?.length} ${i18n.t('general.metric.mm')}`
        : null,
      testId: 'length',
    },
    {
      label: i18n.t('general.labels.width'),
      value: isNotNilOrEmpty(vehicle.dimensions?.width)
        ? `${vehicle.dimensions?.width} ${i18n.t('general.metric.mm')}`
        : null,
      testId: 'width',
    },
    {
      label: i18n.t('general.labels.height'),
      value: isNotNilOrEmpty(vehicle.dimensions?.height)
        ? `${vehicle.dimensions?.height} ${i18n.t('general.metric.mm')}`
        : null,
      testId: 'height',
    },
    {
      label: i18n.t('entity.vehicle.labels.operatingWeight'),
      value: isNotNilOrEmpty(vehicle?.operatingWeight)
        ? `${vehicle?.operatingWeight} ${i18n.t('general.metric.kg')}`
        : null,
      testId: 'operatingWeight',
    },
    {
      label: i18n.t('general.labels.maxWeight'),
      value: isNotNilOrEmpty(vehicle?.weight)
        ? `${vehicle?.weight} ${i18n.t('general.metric.kg')}`
        : null,
      testId: 'maxWeight',
    },
    {
      label: i18n.t('entity.vehicle.labels.maxLoadCapacity'),
      value: isNotNilOrEmpty(vehicle?.maximalLoadCapacity)
        ? `${vehicle?.maximalLoadCapacity} ${i18n.t('general.metric.kg')}`
        : null,
      testId: 'maxLoadCapacity',
    },
    {
      label: i18n.t('entity.vehicle.labels.wheelBase'),
      value: isNotNilOrEmpty(vehicle?.wheelBase)
        ? `${vehicle?.wheelBase} ${i18n.t('general.metric.mm')}`
        : null,
      testId: 'wheelBase',
    },
    {
      label: i18n.t('entity.vehicle.labels.trunkSpace'),
      value: isNotNilOrEmpty(vehicle?.trunkSpace)
        ? `${vehicle?.trunkSpace} ${i18n.t('general.metric.l')}`
        : null,
      testId: 'trunkSpace',
    },
  ])
    .when(isVanOrTruck, {
      label: i18n.t('entity.vehicle.labels.vehicleLength'),
      value: dimensions_length?.[vehicle?.lengthCategory ?? '']?.label,
      testId: 'vehicleLength',
    })
    .when(isVanOrTruck, {
      label: i18n.t('entity.vehicle.labels.vehicleHeight'),
      value: dimensions_height?.[vehicle?.heightCategory ?? '']?.label,
      testId: 'vehicleHeight',
    })
    .when(isVanOrTruck, {
      label: i18n.t('entity.vehicle.labels.loadSpaceLength'),
      value: isNotNilOrEmpty(vehicle?.dimensionsLoadingSpace?.length)
        ? `${vehicle?.dimensionsLoadingSpace?.length} ${i18n.t('general.metric.m')}`
        : null,
      testId: 'loadSpaceLength',
    })
    .when(isVanOrTruck, {
      label: i18n.t('entity.vehicle.labels.loadSpaceWidth'),
      value: isNotNilOrEmpty(vehicle?.dimensionsLoadingSpace?.width)
        ? `${vehicle?.dimensionsLoadingSpace?.width} ${i18n.t('general.metric.m')}`
        : null,
      testId: 'loadSpaceWidth',
    })
    .when(isVanOrTruck, {
      label: i18n.t('entity.vehicle.labels.loadSpaceHeight'),
      value: isNotNilOrEmpty(vehicle?.dimensionsLoadingSpace?.height)
        ? `${vehicle?.dimensionsLoadingSpace?.height} ${i18n.t('general.metric.m')}`
        : null,
      testId: 'loadSpaceHeight',
    })
    .when(isVanOrTruck, {
      label: i18n.t('entity.vehicle.labels.loadArea'),
      value: isNotNilOrEmpty(vehicle?.loadingArea)
        ? `${vehicle?.loadingArea} ${i18n.t('general.metric.m2')}`
        : null,
      testId: 'loadArea',
    });

  return [
    getModelSpecifications,
    engineSpecifications,
    consumptionAndEmissionSpecifications,
    metricsSpecifications,
  ] as const;
};

const convertMinutesToTime = (minutes: number): string | null => {
  if (minutes < 0) {
    return null;
  }

  const timeHours = minutesToHours(minutes).toString().padStart(2, '0');
  const timeMinutes = (minutes % 60).toString().padStart(2, '0');

  return `${timeHours}:${timeMinutes}`;
};

import {
  Card,
  DataStatus,
  FormSubmitHandler,
  openDialog,
  showNotification,
  TabProps,
  TabsHeader,
} from 'platform/components';
import {HStack, Show, Space, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {FC, PropsWithChildren, useState} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  PatchVehicleAdvertisingSettingsRequestBody,
  useEditGeneralSettingsMutation,
  useGetAuditsQuery,
  useGetGeneralSettingsQuery,
  useGetGenerationsQuery,
  useGetParticipationQuery,
  useGetSalesPlatformsQuery,
  useGetVersionsQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  PredefinedNotes,
  queryParams,
  useAdPlatformsVehicleUpdateDialog,
  usePermissions,
} from '@omnetic-dms/shared';

import {parseDate, suffixTestId, TestIdProps, useQueryState, useRequiredParams} from 'shared';

import {GeneralSettingsFormValues} from '../types/GeneralSettingsFormValues';
import {AdPlatforms} from './AdPlatforms';
import {AdSettings} from './AdSettingsEdit';
import {GeneralSettingsForm} from './GeneralSettingsForm';
import {PublishedImages} from './PublishedImages';

interface AdvertisementDataProps extends TestIdProps {
  isPoolingEnabled: boolean;
}

const TABS_IDS = {
  LISTING_ATTRIBUTES: 'listingAttributes',
  PUBLISHED_IMAGES: 'publishedImages',
};

export const AdvertisementTab: FC<PropsWithChildren<AdvertisementDataProps>> = (props) => {
  const formatDateTime = useDateTimeFormatter();

  const {id: vehicleId} = useRequiredParams();
  const [activeTabId, setActiveTabId] = useQueryState(queryParams.VEHICLE_ADVERTISEMENT_PLATFORMS);
  const [updateVehicleAds] = useAdPlatformsVehicleUpdateDialog();
  const [description, setDescription] = useState<string | null>(null);
  const {data: versionsData} = useGetVersionsQuery({vehicleId});
  const {data: generationsData} = useGetGenerationsQuery({vehicleId});
  const {data: auditsData} = useGetAuditsQuery({vehicleId});

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: vehicleId,
  });

  const [hasEditGeneralSettingsPermission] = usePermissions({
    permissionKeys: ['editGeneralSettings'],
    scopes: {
      editGeneralSettings: vehicleParticipation,
    },
  });

  const versionOptions = versionsData?.map((item) => ({value: item.code, label: item.name})) ?? [];
  const generationOptions =
    generationsData?.map((item) => ({value: item.code, label: item.name})) ?? [];
  const digitalCertificateOptions =
    auditsData?.map((item) => ({
      value: item.id,
      label:
        item.updatedAt !== null
          ? `${item.name} (${formatDateTime('dateShort', parseDate(item.updatedAt))})`
          : item.name,
    })) ?? [];

  const {
    data: settings,
    isLoading,
    isError,
  } = useGetGeneralSettingsQuery({vehicleId}, {skip: isNilOrEmpty(vehicleId)});

  const {isLoading: isPlatformsLoading} = useGetSalesPlatformsQuery({vehicleId});

  const [editSettings] = useEditGeneralSettingsMutation();

  const handleSubmit: FormSubmitHandler<GeneralSettingsFormValues> = (values, _, reset) => {
    const body: PatchVehicleAdvertisingSettingsRequestBody = {
      general: {
        attributes: [
          {
            code: 'description',
            value: values.description,
            override: true,
            publish: true,
          },
          {
            code: 'title_extension',
            value: values.title,
            override: true,
            publish: true,
          },
          {
            code: 'version',
            value: values.version,
            override: true,
            publish: true,
          },
          {
            code: 'generation',
            value: values.generation,
            override: true,
            publish: true,
          },
          {
            code: 'digital_certificate',
            value: values.digitalCertificate,
            override: true,
            publish: true,
          },
          {
            code: 'video_url',
            value: values.videoUrl,
            override: true,
            publish: true,
          },
          {
            code: 'condition',
            value: isNotNilOrEmpty(values.condition) ? Number(values.condition) : null,
            override: true,
            publish: true,
          },
        ],
      },
    };

    return editSettings({vehicleId, patchVehicleAdvertisingSettingsRequestBody: body})
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.advertisement.dataEditSubtitle'));
        updateVehicleAds(vehicleId);
        reset(undefined, {keepValues: true});
      })
      .catch(handleApiError);
  };

  const handleEdit = () => {
    openDialog(
      <AdSettings vehicleId={vehicleId} isDisabled={!hasEditGeneralSettingsPermission} />,
      {
        title: i18n.t('entity.vehicle.labels.listingAttributes'),
      }
    );
  };

  const tabs: TabProps[] = [
    {
      id: TABS_IDS.LISTING_ATTRIBUTES,
      title: i18n.t('entity.vehicle.labels.advertisementTabGeneralTitle'),
      'data-testid': testIds.vehicles.publishing('listingAttributes'),
      content: (
        <DataStatus isLoading={isLoading || isPlatformsLoading} isError={isError}>
          <GeneralSettingsForm
            data={settings?.general}
            vehicleId={vehicleId}
            descriptionLabel={i18n.t('page.advertisement.labels.platformsDescription')}
            prefilledNote={description}
            onDescriptionChange={setDescription}
            versionOptions={versionOptions}
            generationOptions={generationOptions}
            digitalCertificateOptions={digitalCertificateOptions}
            onSubmit={handleSubmit}
            onHandleEdit={handleEdit}
            isDisabled={!hasEditGeneralSettingsPermission}
            data-testid={props['data-testid']}
          />
        </DataStatus>
      ),
    },
    {
      id: TABS_IDS.PUBLISHED_IMAGES,
      title: i18n.t('entity.vehicle.labels.photos'),
      'data-testid': testIds.vehicles.publishing('publishedImages'),
      content: <PublishedImages />,
    },
  ];

  return (
    <VStack spacing={4}>
      <Card>
        <HStack>
          <TabsHeader
            variant="condensed"
            onChange={setActiveTabId}
            activeTabId={activeTabId}
            tabs={tabs}
          />
          <Show when={isNil(activeTabId) || activeTabId === TABS_IDS.LISTING_ATTRIBUTES}>
            <Space fillAvailable />
            <PredefinedNotes
              note={description}
              onPrefill={setDescription}
              resource="VEHICLE"
              context="advertisement"
              data-testid={suffixTestId('predefinedNotes', props)}
            />
          </Show>
        </HStack>

        <Space vertical={4} />
        {(tabs.find((tab) => tab.id === activeTabId) ?? tabs[0])?.content}
      </Card>
      <AdPlatforms vehicleId={vehicleId} isPoolingEnabled={props.isPoolingEnabled} />
    </VStack>
  );
};

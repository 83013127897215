import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {REHYDRATE} from 'redux-persist';

import {cachedApi} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {VehicleCatalogueMakeItem} from '../../types/VehicleCatalogueMakeItem';
import {VehicleCatalogueMakeModelItem} from '../../types/VehicleCatalogueMakeModelItem';
import {VehicleCatalogueType} from '../../types/VehicleCatalogueType';
import {getMakeModelOptions, getMakeOptions} from '../../utils/getMakeModelItems';
import {AsyncThunkState, getAsyncThunkReducer} from '../../utils/reduxThunkUtils';
import {getCustomTenantCatalogue, getVehicleMakeModels, getVehicleMakes} from './actions';
import {parseApiEnumsResponse} from './parseApiEnums';

/**
 * Vehicle catalogue module state interface
 */
export type VehicleCatalogueState = AsyncThunkState &
  VehicleCatalogueType & {
    makes: Record<string, VehicleCatalogueMakeItem[]>;
    customMakes: Record<string, VehicleCatalogueMakeItem[]>;
    makeModels: Record<string, VehicleCatalogueMakeModelItem>;
    customMakeModels: Record<string, VehicleCatalogueMakeModelItem>;
  };

/* Initial state */
const initialState: VehicleCatalogueState = {
  loading: {},
  errors: {},
  batteryTypes: [],
  batteryOwnershipTypes: [],
  bodyColors: [],
  busStyles: [],
  carStyles: [],
  caravanStyles: [],
  colorTypes: [],
  conditions: [],
  costs: [],
  doorCounts: [],
  drives: [],
  emissionClasses: [],
  features: [],
  featureCategories: [],
  fuelTypes: [],
  fuelUnits: [],
  handDriveTypes: [],
  hybridTypes: [],
  interiorColors: [],
  interiorMaterials: [],
  sellerTypes: [],
  serviceBooks: [],
  serviceBookStates: [],
  vehicleTypes: [],
  tireAspectRatios: [],
  tireMakes: [],
  treModels: [],
  tireRimDiameters: [],
  tierSeasons: [],
  tireWidth: [],
  transmissions: [],
  chargingConnectorTypes: [],
  motorcycleStyles: [],
  truckStyles: [],
  vanStyles: [],
  makes: {},
  customMakes: {},
  makeModels: {},
  customMakeModels: {},
};

/* Create slice */
const vehicleCatalogue = createSlice({
  name: 'vehicleCatalogue',
  initialState,
  reducers: {
    createCustomMake: (state, {payload}: PayloadAction<{makeKey: string; vehicleType: string}>) => {
      const {makeKey, vehicleType} = payload;

      if (!state.makes[vehicleType]) {
        state.makes[vehicleType] = [];
      }

      state.makes[vehicleType].push({
        isCustom: true,
        value: makeKey,
        label: makeKey,
      });

      if (!state.makeModels[makeKey]) {
        state.makeModels[makeKey] = {
          value: makeKey,
          label: makeKey,
          isCustom: true,
          models: [],
        };
      }
    },
    createCustomModel: (
      state,
      {payload}: PayloadAction<{makeKey: string; modelKey: string; vehicleType: string}>
    ) => {
      const {modelKey, makeKey} = payload;

      if (!(state.makeModels[makeKey] || state.customMakeModels[makeKey])) {
        return;
      }

      const customModel = {
        isCustom: true,
        value: modelKey,
        label: modelKey,
      };

      if (state.makeModels[makeKey]) {
        state.makeModels[makeKey].models.push(customModel);
      }

      if (state.customMakeModels[makeKey]) {
        state.customMakeModels[makeKey].models.push(customModel);
      }
    },
  },
  extraReducers(builder) {
    const {asyncThunkReducer, handleThunkStates} = getAsyncThunkReducer(builder);

    builder.addCase(REHYDRATE, (state, action: any) => {
      const {data} = cachedApi.endpoints.getAlphaCatalogue.select({lang: i18n.language})({
        cachedApi: action.payload,
      });

      if (!data) {
        return state;
      }

      return {
        ...state,
        ...parseApiEnumsResponse(data),
      };
    });

    asyncThunkReducer(getVehicleMakes.action, (state, {payload, meta}) => {
      state.makes[meta.arg.vehicleType] = payload.map(getMakeOptions);
    });

    asyncThunkReducer(getVehicleMakeModels.action, (state, {payload}) => {
      const make = payload[0]?.make;
      if (make) {
        state.makeModels[make] = getMakeModelOptions(payload[0]);
      }
    });

    asyncThunkReducer(getCustomTenantCatalogue.action, (state, {payload}) => {
      Object.keys(payload).forEach((vehicleType) => {
        state.customMakes[vehicleType] = Object.keys(payload[vehicleType].makes).map((make) => {
          state.customMakeModels[make] = {
            value: make,
            label: make,
            isCustom: true,
            models: Object.keys(payload[vehicleType].makes[make].models).map((model) => ({
              value: model,
              label: model,
              isCustom: true,
            })),
          };

          return {
            value: make,
            label: make,
            isCustom: true,
          };
        });
      });
    });

    handleThunkStates();

    builder.addMatcher(
      cachedApi.endpoints.getAlphaCatalogue.matchFulfilled,
      (state, action: any) => ({
        ...state,
        ...parseApiEnumsResponse(action.payload),
      })
    );
  },
});

const {reducer, actions} = vehicleCatalogue;

export const vehicleCatalogueActions = actions;
export const vehicleCatalogueReducer = reducer;
export const {createCustomMake, createCustomModel} = vehicleCatalogueActions;

import {Button, Flag, Parameters} from 'platform/components';
import {Box, Heading, HStack, Link, Show, getSize} from 'platform/foundation';
import styled from 'styled-components';

import {useMemo} from 'react';

import {SimilarVehicleResponseBody} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {getVehicleCoverPhoto, thumbnailUrl} from '@omnetic-dms/shared';

import {suffixTestId, composePath, TestIdProps} from 'shared';

interface MatchingVehicleProps extends TestIdProps {
  vehicle: SimilarVehicleResponseBody;
  onSelect?: (vehicle: SimilarVehicleResponseBody) => void;
}

const PHOTO_SIZE = {
  width: 15,
  height: 11,
} as const;

export function MatchingVehicle(props: MatchingVehicleProps) {
  const coverPhoto = useMemo(() => getVehicleCoverPhoto(props.vehicle.photos), [props.vehicle]);

  const onSelect = () => props.onSelect?.(props.vehicle);

  return (
    <HStack spacing={4} align="center" data-testid={props['data-testid']}>
      <CoverPhoto
        data-testid={suffixTestId('photo', props)}
        src={coverPhoto ? `${coverPhoto.url}&height=72` : thumbnailUrl}
        $isArchived={!!props.vehicle.isArchived}
      />

      <Box flex={1}>
        <Show when={props.vehicle.isArchived}>
          <Flag
            size="small"
            colorScheme="neutral"
            label={i18n.t('entity.vehicle.labels.archived')}
          />
        </Show>
        <Heading size={4} data-testid={suffixTestId('title', props)}>
          {props.vehicle.title}
        </Heading>
        <Parameters
          data-testid={suffixTestId('information', props)}
          color="secondary"
          size="small"
          parameters={[
            {
              title: props.vehicle.vin || i18n.t('entity.vehicle.notifications.noVIN'),
              tooltip: i18n.t('entity.vehicle.labels.vin'),
            },
            {
              title:
                props.vehicle.registrationPlate ||
                i18n.t('entity.vehicle.notifications.noLicencePlate'),
              tooltip: i18n.t('entity.vehicle.labels.licensePlate'),
            },
          ]}
        />
      </Box>

      <Link
        target="_blank"
        leftIcon="action/launch"
        href={composePath(vehiclesRoutes.detail, {params: {id: props.vehicle.id}})}
        data-testid={suffixTestId('link-detail', props)}
        title={i18n.t('entity.vehicle.labels.detail')}
      />
      <Show when={props.onSelect}>
        <Button
          variant="primary"
          onClick={onSelect}
          data-testid={suffixTestId('button-select', props)}
          title={i18n.t('general.labels.select')}
        />
      </Show>
    </HStack>
  );
}

const CoverPhoto = styled.img<{$isArchived: boolean}>`
  object-fit: cover;
  width: ${getSize(PHOTO_SIZE.width)};
  height: ${getSize(PHOTO_SIZE.height)};
  border-radius: ${({theme}) => theme.radii.xSmall};
  filter: ${(props) => (props.$isArchived ? 'grayscale(100%)' : undefined)};
`;

import {FormControl, FormField, Separator} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {
  BaseServiceOrderVariant,
  GetDirectSaleVariantsResponse,
  GetSeriesListApiResponse,
  GetTemplatesApiResponse,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {getOptionsFromSeries} from '../../../utils/getOptionsFromSeries';
import {getOptionsFromTemplates} from '../../../utils/getOptionsFromTemplates';
import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';
import {AllowDirectSales} from './AllowDirectSales';
import {AllowServiceOrderIssue} from './AllowServiceOrderIssue';

interface DocumentsFormProps extends TestIdProps {
  control: FormControl<WarehouseSettingsForm>;
  series: GetSeriesListApiResponse | Nullish;
  receiveNoteTemplates: GetTemplatesApiResponse | Nullish;
  issueNoteTemplates: GetTemplatesApiResponse | Nullish;
  creditNoteTemplates: GetTemplatesApiResponse | Nullish;
  serviceOrderVariants: BaseServiceOrderVariant[] | Nullish;
  directSaleVariants: GetDirectSaleVariantsResponse | Nullish;
}

export function DocumentsForm(props: DocumentsFormProps) {
  return (
    <VStack spacing={6}>
      <Heading size={3}>{i18n.t('entity.warehouse.labels.documents')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="receiveNoteSeriesId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.receiveNoteSeries')}
            options={getOptionsFromSeries(props.series, 'warehouse/receipt')}
            control={props.control}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.receiveNoteSeriesId', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            name="receiveNoteTemplateId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.receiveNoteTemplate')}
            options={getOptionsFromTemplates(props.receiveNoteTemplates)}
            control={props.control}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.receiveNoteTemplateId', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="creditNoteSeriesId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.creditNoteSeries')}
            options={getOptionsFromSeries(props.series, 'warehouse/corrective-receipt')}
            control={props.control}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.creditNoteSeriesId', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            name="creditNoteTemplateId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.creditNoteTemplate')}
            options={getOptionsFromTemplates(props.creditNoteTemplates)}
            control={props.control}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.creditNoteTemplateId', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="issueNoteSeriesId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.issueNoteSeries')}
            options={getOptionsFromSeries(props.series, 'warehouse/dispense')}
            control={props.control}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.issueNoteSeriesId', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            name="issueNoteTemplateId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.issueNoteTemplate')}
            options={getOptionsFromTemplates(props.issueNoteTemplates)}
            control={props.control}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.issueNoteTemplateId', props)}
          />
        </GridItem>
      </Grid>

      <AllowServiceOrderIssue
        control={props.control}
        serviceOrderVariants={props.serviceOrderVariants}
        data-testid={suffixTestId('inputs.allowServiceOrderIssue', props)}
      />

      <Grid columns={2}>
        <Separator spacing={0} />
      </Grid>

      <AllowDirectSales
        control={props.control}
        directSaleVariants={props.directSaleVariants}
        data-testid={suffixTestId('inputs.allowDirectSales', props)}
      />
    </VStack>
  );
}

import {IconButton, useDialog} from 'platform/components';

import {suffixTestId, TestIdProps} from 'shared';

import {SharePresetDialog} from './SharePresetDialog';

export function SharePresetButton(props: TestIdProps) {
  const [isOpen, onOpen, onClose] = useDialog(false);

  const onClick = () => {
    onOpen();
  };

  return (
    <>
      <IconButton
        icon="social/share"
        onClick={onClick}
        data-testid={suffixTestId('sharePreset', props)}
      />
      <SharePresetDialog
        isOpen={isOpen}
        onClose={onClose}
        data-testid={suffixTestId('sharePreset', props)}
      />
    </>
  );
}

import {FlagProps} from 'platform/components';

import i18n from '@omnetic-dms/i18n';

import {Nullish, buildArray, LiteralUnionAutocomplete} from 'shared';

type MarkerType = LiteralUnionAutocomplete<'EXTERNAL_SOURCE'>;

export function getMarkersFlags(markers: MarkerType[] | Nullish) {
  return buildArray<FlagProps>().when(markers?.includes('EXTERNAL_SOURCE'), {
    label: i18n.t('general.labels.external'),
    colorScheme: 'orange',
  });
}

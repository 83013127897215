import {Show, VStack} from 'platform/foundation';

import {testIds} from '@omnetic-dms/routes';
import {usePermissions, VehicleCostsGridCard} from '@omnetic-dms/shared';

import {useRequiredParams} from 'shared';

import {EarningsCostsSummary} from './EarningsCostsSummary';
import {VehicleEarningsGridCard} from './VehicleEarningsGridCard';

export function VehicleEarningsAndCosts() {
  const {id: vehicleId} = useRequiredParams();

  const [canReadCosts, canReadEarnings] = usePermissions({
    permissionKeys: ['vehicleReadCosts', 'vehicleReadEarnings'],
  });

  return (
    <VStack spacing={4}>
      <EarningsCostsSummary
        vehicleId={vehicleId}
        data-testid={testIds.vehicles.finances('summary')}
      />
      <Show when={canReadEarnings}>
        <VehicleEarningsGridCard
          vehicleId={vehicleId}
          data-testid={testIds.vehicles.finances('earnings')}
          isCostsEditable
        />
      </Show>
      <Show when={canReadCosts}>
        <VehicleCostsGridCard
          vehicleId={vehicleId}
          data-testid={testIds.vehicles.finances('costs')}
        />
      </Show>
    </VStack>
  );
}

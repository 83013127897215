import {motion, Variants} from 'framer-motion';

import {useCallback, useMemo, useState} from 'react';

import {Box, useMultiStyleConfig} from '@chakra-ui/react';

import {useActionCallbackProxy} from '../hooks/useActionCallbackProxy';
import {useBulkActions} from '../hooks/useBulkActions';
import {useGridApiEventListener} from '../hooks/useGridApiEventListener';
import {AgGridEvent, GridApi} from '../types/AgGridTypes';
import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';
import {BulkActionsPanelRenderer} from './BulkActionsPanelRenderer';

export interface BulkActionsPanelWrapperProps {
  gridProps: DataGridProps;
  api: GridApi;
  settings: FeGridSettings;
  definition: TransformedDefinitionApiResponse;
}

export function BulkActionsPanelWrapper({
  gridProps,
  api,
  definition,
}: BulkActionsPanelWrapperProps) {
  const {actionCallback, gridCode} = gridProps;

  const {bulkActionsPanel: theme} = useMultiStyleConfig('DataGrid', gridProps);

  const [selectedNodes, setSelectedNodes] = useState(api.getSelectedNodes());

  const actionsOfSelectedNodes = selectedNodes.map((node) => node.data.actions);
  const validBulkActions = useBulkActions(
    // @ts-ignore
    gridProps.actionsDefinition || {},
    actionsOfSelectedNodes
  );

  const deselectAll = useCallback(() => {
    api.deselectAll();
    api.refreshCells({
      suppressFlash: true,
      columns: ['eag-col-select'],
    });
    api.refreshHeader();
  }, [api]);

  const selectedRows = useMemo(() => selectedNodes.map((node) => node.data), [selectedNodes]);
  const selectedNodeIds = useMemo(
    () => selectedNodes.map((node) => node.id as string),
    [selectedNodes]
  );

  const actionCallbackProxy = useActionCallbackProxy(
    actionCallback,
    api,
    selectedNodeIds,
    // @ts-ignore
    selectedRows,
    gridCode
  );

  const selectableRows = api.getDisplayedRowCount();

  const {addListeners} = useGridApiEventListener(
    api,
    ['rowSelected', 'storeUpdated'],
    ({api}: AgGridEvent) => {
      setSelectedNodes(api.getSelectedNodes());
    }
  );
  addListeners();

  if (
    Object.keys(validBulkActions).length === 0 &&
    definition.behavior.rowSelectMode === 'SINGLE'
  ) {
    return null;
  }

  return (
    <MotionBox
      variants={barMotionVariants}
      animate={selectedNodeIds.length > 0 ? 'open' : 'close'}
      height={0}
      __css={theme}
    >
      <Box>
        <BulkActionsPanelRenderer
          deselectAll={deselectAll}
          selectableRows={selectableRows}
          selectedRows={selectedNodeIds.length}
          bulkActions={validBulkActions}
          actionCallback={actionCallbackProxy}
          rowSelection={definition.behavior.rowSelectMode}
        />
      </Box>
    </MotionBox>
  );
}

const MotionBox = motion(Box);

const barMotionVariants: Variants = {
  open: {
    height: '70px',
    marginBottom: 0,
    transitionEnd: {
      marginBottom: 0,
      height: '70px',
    },
    transition: {
      duration: 0.65,
      easings: [0.4, 0.14, 0.3, 1],
    },
  },
  close: {
    height: '0',
    marginBottom: '-1px',
    transitionEnd: {
      marginBottom: '-1px',
      height: '0',
    },
    transition: {
      duration: 0.65,
      easings: [0.4, 0.14, 0.3, 1],
    },
  },
};

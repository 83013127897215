import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {Button, DataStatus, FormSubmitHandler, Separator, useDialog} from 'platform/components';
import {Box, Heading, Hide, HStack, Show, Space, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty, isPositive} from 'ramda-adjunct';

import {
  FullVehicle,
  FullVehicleResponseBody,
  GenerateCebiaReportApiArg,
  getApiErrorMessages,
  useGenerateCebiaReportMutation,
  useGetCebiaReportsQuery,
  useGetUserQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {getNaturalPersonFullName, handleApiError} from '@omnetic-dms/shared';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {selectIntegrationsSettings} from '../../store/generalSettings/selectors';
import {CebiaReportDialog, CebiaReportFormType} from './components/CebiaReportDialog';
import {CebiaReportLine} from './components/CebiaReportLine';
import {CebiaReportPublishSwitch} from './components/CebiaReportPublishSwitch';
import {CebiaWidgetAlert} from './components/CebiaWidgetAlert';
import {CebiaWidgetLogo} from './components/CebiaWidgetLogo';

interface CebiaReportVehicleParams {
  id: string;
  firstRegistrationOnYear?: number;
  manufacturedOnYear?: number;
  state?: {
    mileage?: number;
  };
}

export interface CebiaReportWidgetProps extends TestIdProps {
  vehicle: FullVehicle | CebiaReportVehicleParams | FullVehicleResponseBody;
}

export function CebiaReportWidget(props: CebiaReportWidgetProps) {
  const {vehicle} = props;
  const {id: vehicleId} = vehicle;
  const navigate = useNavigate();
  const formatDateTime = useDateTimeFormatter();

  const [isDialogOpen, openDialog, closeDialog] = useDialog();

  const {data: reports = [], isLoading: isDataLoading} = useGetCebiaReportsQuery(
    {vehicleId},
    {skip: isNil(vehicleId)}
  );

  const [newestReport, ...olderReports] = reports;

  const {data: user} = useGetUserQuery(
    {id: newestReport?.createdBy ?? ''},
    {skip: isNilOrEmpty(newestReport?.createdBy) || isNil(vehicleId)}
  );

  const userFullName = getNaturalPersonFullName(user);

  const [generateReport, {isLoading: isGeneratingCebiaReport}] = useGenerateCebiaReportMutation();

  const [generateError, setGenerateError] = useState<string | string[]>();

  const integrationsSettings = useSelector(selectIntegrationsSettings);

  const handleSubmit: FormSubmitHandler<CebiaReportFormType> = async (values, setErrors) => {
    if (isNil(values.registrationYear) || isNil(values.manufactureYear) || isNil(values.mileage)) {
      closeDialog();
      return;
    }

    const arg: GenerateCebiaReportApiArg = {
      vehicleId,
      generateCebiaReportRequestBody: {
        firstRegistrationOnYear: Number.parseInt(values.registrationYear),
        manufacturedOnYear: Number.parseInt(values.manufactureYear),
        mileage: values.mileage,
      },
    };

    await generateReport(arg)
      .unwrap()
      .then(() => closeDialog())
      .catch((error: FetchBaseQueryError) =>
        handleApiError(error, {
          setErrors,
          callback: ({error}) => setGenerateError(getApiErrorMessages(error)),
        })
      );
  };

  const isWidgetEnabled = integrationsSettings?.cebiaAutotraceAndReport?.enabled;
  const isCredentialsValid = integrationsSettings?.cebiaAutotraceAndReport?.credentials?.username;

  const {firstRegistrationOnYear, manufacturedOnYear, state} = vehicle;

  const cebiaReportDialogDefaultValues = useMemo(
    () => ({
      registrationYear: firstRegistrationOnYear?.toString() ?? undefined,
      manufactureYear: manufacturedOnYear?.toString() ?? undefined,
      mileage: isNotNilOrEmpty(state?.mileage) ? state?.mileage : 0,
    }),
    [firstRegistrationOnYear, manufacturedOnYear, state?.mileage]
  );

  if (!isWidgetEnabled) {
    return null;
  }

  return (
    <DataStatus isLoading={isDataLoading}>
      <VStack spacing={2}>
        <Box
          backgroundColor="palettes.neutral.10.100"
          borderRadius="medium"
          overflow="hidden"
          position="relative"
        >
          <HStack>
            <CebiaWidgetLogo alt={i18n.t('page.integrations.labels.cebiaReportTitle')} />
            <Box padding={4} width="100%" backgroundColor="palettes.neutral.10.100">
              <VStack spacing={3}>
                <VStack spacing={2}>
                  <Heading size={4}>{i18n.t('page.integrations.labels.cebiaReportTitle')}</Heading>
                  <Text size="xSmall">
                    {i18n.t('page.integrations.labels.cebiaReportWidgetDescription')}
                  </Text>
                </VStack>
                <Show when={newestReport}>
                  <Show
                    whenFeatureEnabled={
                      featureFlags.SALES_CEBIA_REPORT_CLASSIFIED_PUBLISHING_SWITCH
                    }
                  >
                    <CebiaReportPublishSwitch
                      vehicleId={vehicleId}
                      label={i18n.t('page.integrations.labels.cebiaReportPublishInAdvertisement')}
                    />
                    <Space vertical={3} />
                  </Show>
                  <HStack spacing={3}>
                    <Button
                      onClick={() => window.open(newestReport!.url)}
                      variant="secondary"
                      rightIcon="action/launch"
                      title={i18n.t('page.integrations.labels.cebiaReportViewResults')}
                      isDisabled={isGeneratingCebiaReport}
                      data-testid={suffixTestId('cebiaReportWidget-viewResults', props)}
                    />
                    <Button
                      isDisabled={!isCredentialsValid || isGeneratingCebiaReport}
                      onClick={openDialog}
                      variant="secondary"
                      title={i18n.t('page.integrations.labels.cebiaReportGetNew')}
                      data-testid={suffixTestId('cebiaReportWidget-getNewDialog', props)}
                    />
                  </HStack>
                  <HStack spacing={3}>
                    <Text size="xSmall" color="tertiary">
                      {`${i18n.t('entity.cebiaReport.recentReport')} ${
                        newestReport?.createdAt
                          ? formatDateTime('dateShort', parseDate(newestReport.createdAt))
                          : ''
                      }`}
                      {isNotNilOrEmpty(userFullName) ? `, ${userFullName}` : ''}
                    </Text>
                  </HStack>
                </Show>
                <Show when={isPositive(olderReports.length)}>
                  <Separator spacing={1} />
                  <VStack spacing={3}>
                    {olderReports.map((report) => (
                      <CebiaReportLine key={report.id} report={report} />
                    ))}
                  </VStack>
                </Show>
                <Hide when={newestReport}>
                  <Box>
                    <Button
                      isDisabled={!isCredentialsValid || isGeneratingCebiaReport}
                      onClick={openDialog}
                      variant="secondary"
                      title={i18n.t('page.integrations.labels.cebiaAutotraceGetReport')}
                      data-testid={suffixTestId('cebiaReportWidget-getDialog', props)}
                    />
                  </Box>
                </Hide>
              </VStack>
            </Box>
          </HStack>
        </Box>
        {generateError ? (
          <CebiaWidgetAlert
            colorScheme="error"
            message={String(
              generateError && Array.isArray(generateError)
                ? generateError.toString()
                : generateError
            )}
          />
        ) : null}
        {!isCredentialsValid ? (
          <CebiaWidgetAlert
            colorScheme="error"
            message={i18n.t('general.errors.invalidCredentials')}
            actionText={i18n.t('general.labels.settings')}
            actionCallback={() => navigate(settingsRoutes.vehicleServicesCebia)}
          />
        ) : null}

        <CebiaReportDialog
          isOpen={isDialogOpen}
          onSubmit={handleSubmit}
          onClose={closeDialog}
          defaultValues={cebiaReportDialogDefaultValues}
        />
      </VStack>
    </DataStatus>
  );
}

import {NumberInput, TextInput} from 'platform/components';
import {Box, HStack, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isNotNil, not} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {Nullish, yupNumber, RequiredTestIdProps, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../../constants/currency';
import {useBasketItemQuantity} from '../../../hooks/useBasketItemQuantity';
import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {CorrectionBasketItem} from '../types/CorrectionBasketItem';

interface AfterSalesCorrectionBasketItemQuantityWithPriceProps<T extends CorrectionBasketItem>
  extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  handlingUnit: string | Nullish;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
}

export function AfterSalesCorrectionBasketItemQuantityWithPrice<T extends CorrectionBasketItem>(
  props: AfterSalesCorrectionBasketItemQuantityWithPriceProps<T>
) {
  const formatCurrency = useFormatCurrency();

  const {quantity, quantityError, handleQuantityChange} = useBasketItemQuantity({
    itemId: props.item.id,
    currentQuantity: props.item.quantity,
    validationSchema: quantitySchema,
    onQuantityChange: props.onQuantityChange,
  });

  const totalPrice = formatCurrency(
    props.item.totalPrice.withoutVat.amount ?? 0,
    props.item.totalPrice.withoutVat.currency ?? DEFAULT_CURRENCY,
    2
  );

  return (
    <HStack justify="space-between">
      <HStack spacing={2}>
        <Box width={29}>
          <NumberInput
            value={quantity}
            errorMessage={quantityError}
            decimalPlaces={4}
            onChange={handleQuantityChange}
            isStepperVisible
            isInvalid={isNotNil(quantityError)}
            isDisabled={not(props.item.quantityEditingAllowed)}
            data-testid={suffixTestId('quantity', props)}
          />
        </Box>
        <Box width={11}>
          <TextInput
            value={props.handlingUnit ?? DEFAULT_HANDLING_UNIT}
            isDisabled
            data-testid={suffixTestId('unit', props)}
          />
        </Box>
      </HStack>

      <VStack align="flex-end">
        <Text size="xSmall" color="tertiary">
          {i18n.t('general.labels.sellingPrice')}
        </Text>
        <Text size="small" alternative data-testid={suffixTestId('price', props)}>
          {totalPrice}
        </Text>
      </VStack>
    </HStack>
  );
}

const DEFAULT_HANDLING_UNIT = 'pcs';

const quantitySchema = yupNumber.min(0.0001, (params) =>
  i18n.t('general.errors.number.greaterOrEqual', {min: params.min})
);

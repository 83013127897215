import {
  AssignLicenseToRoleApiArg,
  AssignLicenseToRoleApiResponse,
  CreateRoleApiArg,
  CreateRoleApiResponse,
  DeleteRoleApiArg,
  DeleteRoleApiResponse,
  GetPermissionDefinitionsApiArg,
  GetPermissionDefinitionsApiResponse,
  GetResourceGroupsApiArg,
  GetResourceGroupsApiResponse,
  GetRoleApiArg,
  GetRoleApiResponse,
  GetRolesApiArg,
  GetRolesApiResponse,
  GetRolesForRoleManagementApiArg,
  GetRolesForRoleManagementApiResponse,
  PatchRoleApiArg,
  PatchRoleApiResponse,
  RemoveLicenseFromRoleApiArg,
  RemoveLicenseFromRoleApiResponse,
} from '../types/api';
import {omneticApi} from './baseApi/omneticApi';

export const accessControlApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getPermissionDefinitions: build.query<
      GetPermissionDefinitionsApiResponse,
      GetPermissionDefinitionsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/acl/permission-definitions`,
        headers: {Authorization: queryArg.authorization},
      }),
    }),
    getResourceGroups: build.query<GetResourceGroupsApiResponse, GetResourceGroupsApiArg>({
      query: () => ({
        url: `/dms/v1/acl/resource-groups`,
      }),
    }),
    assignLicenseToRole: build.mutation<AssignLicenseToRoleApiResponse, AssignLicenseToRoleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/acl/role/${queryArg.roleId}/license`,
        method: 'POST',
        body: queryArg.assignLicenseToRoleRequestBody,
      }),
    }),
    createRole: build.mutation<CreateRoleApiResponse, CreateRoleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/acl/role`,
        method: 'POST',
        body: queryArg.createRoleRequestBody,
      }),
      invalidatesTags: ['Roles'],
    }),
    getRoles: build.query<GetRolesApiResponse, GetRolesApiArg>({
      query: () => ({
        url: `/dms/v1/acl/role`,
      }),
      providesTags: ['Roles'],
    }),
    deleteRole: build.mutation<DeleteRoleApiResponse, DeleteRoleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/acl/role/${queryArg.roleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Roles'],
    }),
    getRole: build.query<GetRoleApiResponse, GetRoleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/acl/role/${queryArg.roleId}`,
      }),
      keepUnusedDataFor: 0,
    }),
    patchRole: build.mutation<PatchRoleApiResponse, PatchRoleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/acl/role/${queryArg.roleId}`,
        method: 'PATCH',
        body: queryArg.patchRoleRequestBody,
      }),
      invalidatesTags: ['Roles'],
    }),
    getRolesForRoleManagement: build.query<
      GetRolesForRoleManagementApiResponse,
      GetRolesForRoleManagementApiArg
    >({
      query: () => ({
        url: `/dms/v1/user-management/roles`,
      }),
    }),
    removeLicenseFromRole: build.mutation<
      RemoveLicenseFromRoleApiResponse,
      RemoveLicenseFromRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/acl/role/${queryArg.roleId}/license/${queryArg.licenseId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPermissionDefinitionsQuery,
  useGetResourceGroupsQuery,
  useAssignLicenseToRoleMutation,
  useCreateRoleMutation,
  useGetRolesQuery,
  useDeleteRoleMutation,
  useGetRoleQuery,
  usePatchRoleMutation,
  useGetRolesForRoleManagementQuery,
  useRemoveLicenseFromRoleMutation,
} = accessControlApi;

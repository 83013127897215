import {isNil, isNotNil, reject} from 'ramda';
import {padCharsStart} from 'ramda-adjunct';

import {Nullish} from 'shared';

export const getManufacturedOn = (
  manufacturedOnYear: number | Nullish,
  manufacturedOnMonth: number | Nullish
) => {
  if (isNil(manufacturedOnYear)) {
    return null;
  }

  return reject(isNil, [
    manufacturedOnYear,
    isNotNil(manufacturedOnMonth)
      ? padCharsStart('0', 2, manufacturedOnMonth?.toString() ?? '')
      : null,
  ]).join('-');
};

import {mergeAll} from 'ramda';

import {useGetTireOrderQuery} from '@omnetic-dms/api';
import {testIds} from '@omnetic-dms/routes';
import {Section, useGetDocumentDatagridActions} from '@omnetic-dms/shared';

import {DataGrid, QueryFilterObject} from 'features/datagrid';

import {useTiresInventoryUrl} from '../../hooks/useTiresInventoryUrl';

export function TireOrderDocuments() {
  const {orderId} = useTiresInventoryUrl();

  const {data: tireOrder} = useGetTireOrderQuery({orderId});

  const [actionCallback] = useGetDocumentDatagridActions(tireOrder?.customerId);

  const queryModifier = (filter: QueryFilterObject) => mergeAll([filter, {tireOrderId: orderId}]);

  return (
    <Section data-testid={testIds.tiresInventory.tireOrderDetail('documents')}>
      <DataGrid
        autoHeight
        gridCode="document-context-list-tire-order"
        actionCallback={actionCallback}
        data-testid={testIds.tiresInventory.tireOrderDetail('documents')}
        queryModifier={queryModifier}
      />
    </Section>
  );
}

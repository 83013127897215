import {showNotification} from 'platform/components';
import {match, Pattern} from 'ts-pattern';

import {always, isNil, isNotNil} from 'ramda';

import {
  IssuedType,
  OrderResponseBody,
  useCreateManuallyTaxDocumentForPaymentMutation,
  useGetBusinessCaseQuery,
  useGetBusinessCaseSeriesDefinitionListQuery,
  useGetTaxDocumentSettingListQuery,
} from '@omnetic-dms/api';
import {handleApiError, useGetCurrentBranch} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

export const useGetCreateTaxDocumentForPayment = (
  order: OrderResponseBody | Nullish,
  businessCaseId: string | Nullish
) => {
  const {data: taxDocumentGenerationSettings} = useGetTaxDocumentSettingListQuery();
  const {activeBranchId} = useGetCurrentBranch();
  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: businessCaseId ?? ''},
    {skip: isNil(businessCaseId)}
  );

  const isBrokerageBusinessCase = isNotNil(businessCase?.brokerageBusinessCase);

  const {data: businessCaseDefinitions} = useGetBusinessCaseSeriesDefinitionListQuery();

  const activeDefinition =
    businessCaseDefinitions?.find((item) => item.branchId === activeBranchId) ??
    businessCaseDefinitions?.find((item) => item.systemDefault) ??
    null;

  const seriesId = match([order?.orderDiscriminator, isBrokerageBusinessCase])
    .with(['SALE', false], always(activeDefinition?.taxDocumentSeriesId))
    .with(['SALE', true], always(activeDefinition?.saleBrokerageTaxDocumentSeriesId))
    .with(
      ['PURCHASE_BROKERAGE_SALE', Pattern.boolean],
      ['PURCHASE_BROKERAGE_FEES', Pattern.boolean],
      always(activeDefinition?.brokerageFeesTaxDocumentSeriesId)
    )
    .otherwise(always(activeDefinition?.taxDocumentSeriesId));

  const [createDocument] = useCreateManuallyTaxDocumentForPaymentMutation();

  const defaultSettings = taxDocumentGenerationSettings?.find((item) => item.isSystem);
  const branchSettings = taxDocumentGenerationSettings?.find(
    (item) => item.branchId === activeBranchId
  );

  const usedTaxDocumentGenerationSettings = branchSettings ?? defaultSettings;

  const issuedType: IssuedType = usedTaxDocumentGenerationSettings?.issuedType ?? 'not_create';

  const createTaxDocumentFormPayment = async (transactionId: string) => {
    if (issuedType !== 'manual') {
      return;
    }
    // should never happen, only to satisfy typescript
    if (isNil(seriesId) || isNil(usedTaxDocumentGenerationSettings)) {
      return showNotification.error('Cannot create document. Generation settings are not found');
    }

    await createDocument({
      transactionId,
      seriesId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  return [createTaxDocumentFormPayment, issuedType] as const;
};

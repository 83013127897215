import {subDays} from 'date-fns';
import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isCurrency,
  openConfirmDialog,
  showNotification,
} from 'platform/components';
import {Box, Center, Grid, Heading, Hide, HStack, Show, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {always} from 'ramda';
import {isFalse, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  applyCommissionRuleApiArg,
  OfferResponseBody,
  PatchBusinessCaseVehicleToBrokeragePricingApiArg,
  PatchBusinessCaseVehicleToBrokeragePricingRequestBody,
  PricePurchaseBusinessCaseRequestBody,
  PriceWithVatDirection,
  saleVehicleApi,
  selectTenant,
  useApplyCommissionRulesMutation,
  useGetBusinessCaseQuery,
  useLazyGetCommissionRulesQuery,
  usePatchBusinessCaseVehicleToBrokeragePricingMutation,
  VatTypeEnum,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  areFormFieldsDifferent,
  CalculatePrice,
  handleApiError,
  NoPermissionTooltip,
  useGetComplexPermissions,
  usePermissions,
} from '@omnetic-dms/shared';
import {
  AppDispatch,
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  selectVatRatesByCountryCode,
} from '@omnetic-dms/teas';

import {buildObject, Nullish, useRequiredParams} from 'shared';

import {$BrokeragePricingFormScheme} from './utils/$PricingFormScheme';

type PricePurchaseBusinessCaseRequestBodyForBrokerage = Omit<
  PricePurchaseBusinessCaseRequestBody,
  'expectedPurchasePrice' | 'maxPurchasePrice'
>;

interface BrokeragePricingFormProps {
  toggleDetail: () => void;
  offer: OfferResponseBody;
}

type LastTouchedDirection = 'withVat' | 'withoutVat' | undefined;

const MIN_INPUT_WIDTH = 35;

const YESTERDAY_DATE = subDays(new Date(), 1);

export function BrokeragePricingForm(props: BrokeragePricingFormProps) {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase} = useGetBusinessCaseQuery({businessCaseId});
  const firstPurchaseVehicle = businessCase?.offers?.[0]?.purchaseBrokerageVehicles?.[0];
  const {canReadSalePrice} = useGetComplexPermissions();

  const {data: selectedTenant} = useSelector(selectTenant);
  const country = selectedTenant?.country ?? DEFAULT_COUNTRY;
  const currency = isCurrency(selectedTenant?.currency)
    ? (selectedTenant?.currency ?? DEFAULT_CURRENCY)
    : DEFAULT_CURRENCY;

  const dispatch = useDispatch<AppDispatch>();

  const [lastTouchedSellPriceField, setLastTouchedSellPriceField] = useState<LastTouchedDirection>(
    match<PriceWithVatDirection | Nullish, LastTouchedDirection>(
      firstPurchaseVehicle?.pricing?.sellingPrice?.vatDirection
    )
      .with('FROM_PRICE_WITH_VAT', always('withVat'))
      .with('FROM_PRICE_WITHOUT_VAT', always('withoutVat'))
      .otherwise(always(undefined))
  );

  const [getCommissionRules] = useLazyGetCommissionRulesQuery();
  const [applyCommissionRules] = useApplyCommissionRulesMutation();
  const [patchPricingOfferPurchaseBrokerageVehicle] =
    usePatchBusinessCaseVehicleToBrokeragePricingMutation();

  const vatRates = useSelector(selectVatRatesByCountryCode(selectedTenant?.country))?.rates?.map(
    (vatRate) => ({
      ...vatRate,
      value: vatRate.type,
      label: `${vatRate.rate} % ${vatRate.name}`,
      fieldLabel: `${vatRate.rate}%`,
    })
  );

  const [canEditSalePrice, canEditPurchasePrice, canEditBrokerageFees] = usePermissions({
    permissionKeys: [
      'vehicleEditSalePrice',
      'vehicleEditPurchasePrice',
      'editBusinessCasePurchaseBrokerageFees',
    ],
  });

  const requestedPriceArgs: Omit<
    PatchBusinessCaseVehicleToBrokeragePricingApiArg,
    'patchBusinessCaseVehicleToBrokeragePricingRequestBody'
  > = {
    businessCaseId,
    vehicleId: firstPurchaseVehicle?.vehicleId ?? '',
  };

  const applyCommissionRule = () => {
    const vehicleId = firstPurchaseVehicle?.id;

    if (!vehicleId) {
      showNotification.error();
      return;
    }

    const applyRulesRequestBody: applyCommissionRuleApiArg = {
      businessCaseId,
      offerId: props.offer.id,
      vehicleId,
    };

    applyCommissionRules(applyRulesRequestBody)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.brokersFeeCalculated'));
        closeCurrentDialog();
      })
      .catch(handleApiError);
  };

  const handleSubmit: FormSubmitHandler<PricePurchaseBusinessCaseRequestBodyForBrokerage> = async (
    data
  ) => {
    const isVatDeductibleDifferent = areFormFieldsDifferent(
      data.isVatDeductible,
      defaultValues.isVatDeductible
    );

    const isSellingPriceDifferent = data.isVatDeductible
      ? areFormFieldsDifferent(
          data.expectedSellingPrice?.priceWithVat?.amount?.toString(),
          defaultValues.expectedSellingPrice?.priceWithVat?.amount
        ) ||
        areFormFieldsDifferent(
          data.expectedSellingPrice?.priceWithoutVat?.amount?.toString(),
          defaultValues.expectedSellingPrice?.priceWithoutVat?.amount
        ) ||
        areFormFieldsDifferent(
          data.expectedSellingPrice?.vatType,
          defaultValues.expectedSellingPrice?.vatType
        )
      : areFormFieldsDifferent(
          data.expectedSellingPrice?.priceNotDeductible?.amount?.toString(),
          defaultValues.expectedSellingPrice?.priceNotDeductible?.amount
        );

    const isExpectedPriceDifferent =
      areFormFieldsDifferent(
        isNotNilOrEmpty(data.customerExpectedPrice?.amount)
          ? String(data.customerExpectedPrice!.amount)
          : undefined,
        defaultValues.customerExpectedPrice?.amount
      ) ||
      (isNotNilOrEmpty(defaultValues.customerExpectedPrice?.currency)
        ? areFormFieldsDifferent(currency, defaultValues.customerExpectedPrice?.currency)
        : false);

    const isExpectedPurchaseDateDifferent = areFormFieldsDifferent(
      data.expectedPurchaseDate,
      defaultValues.expectedPurchaseDate
    );

    const patchBusinessCaseVehicleToBrokeragePricingRequestBody =
      buildObject<PatchBusinessCaseVehicleToBrokeragePricingRequestBody>()
        .sellingPriceReason(data.updateReasonNote ?? null, data.updateReasonNote)
        .isVatDeductible(data.isVatDeductible, isVatDeductibleDifferent)
        .sellingPrice(
          {
            priceNotDeductible:
              !data.isVatDeductible && data.expectedSellingPrice?.priceNotDeductible?.amount
                ? {
                    amount: String(data.expectedSellingPrice.priceNotDeductible.amount),
                    currency,
                  }
                : null,
            priceWithoutVat:
              data.isVatDeductible && data.expectedSellingPrice?.priceWithoutVat?.amount
                ? {
                    amount: String(data.expectedSellingPrice.priceWithoutVat.amount),
                    currency,
                  }
                : null,
            priceWithVat:
              data.isVatDeductible && data.expectedSellingPrice?.priceWithVat?.amount
                ? {
                    amount: String(data.expectedSellingPrice.priceWithVat.amount),
                    currency,
                  }
                : null,
            vatDirection: match<[LastTouchedDirection, boolean], PriceWithVatDirection | null>([
              lastTouchedSellPriceField,
              data.isVatDeductible ?? false,
            ])
              .with(['withVat', true], always('FROM_PRICE_WITH_VAT'))
              .with(['withoutVat', true], always('FROM_PRICE_WITHOUT_VAT'))
              .with([undefined, true], always('FROM_PRICE_WITH_VAT'))
              .otherwise(always('FROM_PRICE_WITH_VAT')),
            vatType:
              data.isVatDeductible && data.expectedSellingPrice?.vatType !== undefined
                ? data.expectedSellingPrice.vatType
                : (null ?? null),
          },
          isSellingPriceDifferent
        )
        .customerRequestedPrice(
          isNotNilOrEmpty(data.customerExpectedPrice?.amount)
            ? {
                amount: String(data.customerExpectedPrice!.amount),
                currency,
              }
            : null,
          isExpectedPriceDifferent
        )
        .expectedPurchaseDate(data.expectedPurchaseDate ?? null, isExpectedPurchaseDateDifferent)
        .build();

    const partialSubmitData: PatchBusinessCaseVehicleToBrokeragePricingApiArg = {
      ...requestedPriceArgs,
      patchBusinessCaseVehicleToBrokeragePricingRequestBody,
    };

    await patchPricingOfferPurchaseBrokerageVehicle(partialSubmitData)
      .unwrap()
      .then(async () => {
        showNotification.success();

        // no rtq-query, eslint bug?
        // eslint-disable-next-line no-restricted-syntax
        const shouldApplyCommissionFees = match([
          data.expectedSellingPrice,
          props.offer.purchaseBrokerageVehicles,
        ])
          .with(
            [
              {priceWithVat: {amount: Pattern.union(Pattern.string, Pattern.number)}},
              Pattern.not([]),
            ],
            [
              {priceNotDeductible: {amount: Pattern.union(Pattern.string, Pattern.number)}},
              Pattern.not([]),
            ],
            always(true)
          )
          .otherwise(always(false));

        if (shouldApplyCommissionFees && canEditBrokerageFees) {
          const {data: commissionRules} = await getCommissionRules({
            amount:
              data.expectedSellingPrice?.priceWithVat?.amount ??
              data.expectedSellingPrice?.priceNotDeductible?.amount ??
              '',
            currency,
          });

          if (commissionRules?.length) {
            openConfirmDialog({
              onConfirm: applyCommissionRule,
              text: i18n.t('entity.businessCase.labels.calculateSaleCommission'),
              'data-testid': testIds.businessCase.selling('pricing-vatDeductible'),
            });
          }
        }

        if (firstPurchaseVehicle?.vehicleId) {
          await dispatch(
            saleVehicleApi.endpoints.getSaleVehicle.initiate(
              {
                vehicleId: firstPurchaseVehicle?.vehicleId,
              },
              {forceRefetch: true}
            )
          );
        }
      })
      .catch(handleApiError);

    props.toggleDetail();
  };

  const defaultValues: Partial<PricePurchaseBusinessCaseRequestBodyForBrokerage> = {
    isVatDeductible: firstPurchaseVehicle?.pricing?.vatDeductible ?? false,
    expectedSellingPrice: isNotNilOrEmpty(firstPurchaseVehicle?.pricing?.sellingPrice)
      ? {
          ...firstPurchaseVehicle!.pricing.sellingPrice,
          vatType: firstPurchaseVehicle!.pricing.vatType ?? VatTypeEnum.S,
        }
      : undefined,
    updateReasonNote: firstPurchaseVehicle?.pricing.reason ?? null,
    expectedPurchaseDate: firstPurchaseVehicle?.expectedPurchaseOn ?? null,
    customerExpectedPrice: firstPurchaseVehicle?.costs?.customerRequestedPrice,
  };

  const handleCalculationChange =
    (formApi: UseFormReturn<PricePurchaseBusinessCaseRequestBodyForBrokerage>) =>
    (res: {withVat?: string} | {withoutVat?: string}) => {
      if ('withVat' in res) {
        formApi.setValue('expectedSellingPrice.priceWithVat.amount', res?.withVat ?? '');
      }
      if ('withoutVat' in res) {
        formApi.setValue('expectedSellingPrice.priceWithoutVat.amount', res?.withoutVat ?? '');
      }
    };

  const syncDeductiblePricesChange = (
    formApi: UseFormReturn<PricePurchaseBusinessCaseRequestBodyForBrokerage>
  ) => {
    const isDeductible = formApi.getValues('isVatDeductible');

    if (isDeductible) {
      formApi.setValue(
        'expectedSellingPrice.priceWithVat.amount',
        formApi.getValues('expectedSellingPrice.priceNotDeductible.amount')
      );
      formApi.setValue('expectedSellingPrice.vatType', VatTypeEnum.S);
      setLastTouchedSellPriceField('withVat');
    } else {
      formApi.setValue(
        'expectedSellingPrice.priceNotDeductible.amount',
        formApi.getValues('expectedSellingPrice.priceWithVat.amount')
      );
      setLastTouchedSellPriceField('withoutVat');
    }
  };

  return (
    <Form<PricePurchaseBusinessCaseRequestBodyForBrokerage>
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={$BrokeragePricingFormScheme()}
    >
      {(control, formApi) => {
        const [isVatDeductible, vatType, sellingPriceWithoutVat, sellingPriceWithVat] =
          formApi.watch([
            'isVatDeductible',
            'expectedSellingPrice.vatType',
            'expectedSellingPrice.priceWithoutVat.amount',
            'expectedSellingPrice.priceWithVat.amount',
          ]);

        return (
          <VStack spacing={4}>
            <HStack spacing={4}>
              <Box flexGrow={1} width={MIN_INPUT_WIDTH}>
                <Center justify="flex-start" height={8}>
                  <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                    <FormField
                      control={control}
                      name="isVatDeductible"
                      type="checkbox"
                      label={i18n.t('general.labels.vatDeductible')}
                      data-testid={testIds.businessCase.selling('pricing-vatDeductible')}
                      onChange={() => syncDeductiblePricesChange(formApi)}
                      isDisabled={isFalse(canEditPurchasePrice)}
                    />
                  </NoPermissionTooltip>
                </Center>
              </Box>
            </HStack>
            <Grid columns={2}>
              <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                <FormField
                  control={control}
                  type="currency"
                  currency={currency}
                  decimalPlaces={2}
                  name="customerExpectedPrice.amount"
                  label={i18n.t('entity.businessCase.labels.customerRequestedPrice')}
                  data-testid={testIds.businessCase.selling('pricing-customerRequestedPrice')}
                  isDisabled={isFalse(canEditPurchasePrice)}
                />
              </NoPermissionTooltip>
              <FormField
                control={control}
                type="apiDate"
                name="expectedPurchaseDate"
                label={i18n.t('entity.businessCase.labels.expectedPurchaseOn')}
                minDate={YESTERDAY_DATE}
                data-testid={testIds.businessCase.selling('pricing-expectedPurchaseOn')}
              />
            </Grid>
            <Hide whenFeatureEnabled={featureFlags.BUSINESS_CASE_PRICING_SIMPLIFIED}>
              <Heading size={5}>{i18n.t('general.labels.sellingPrice')}</Heading>
            </Hide>
            <Grid columns={2}>
              <Hide when={isVatDeductible}>
                <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                  <FormField
                    isRequired
                    control={control}
                    name="expectedSellingPrice.priceNotDeductible.amount"
                    type="currency"
                    currency={currency}
                    decimalPlaces={2}
                    label={i18n.t('general.labels.sellingPrice')}
                    onChange={() => setLastTouchedSellPriceField('withVat')}
                    data-testid={testIds.businessCase.selling('pricing-sellingPrice')}
                    isDisabled={isFalse(canEditSalePrice)}
                  />
                </NoPermissionTooltip>
              </Hide>
              <Show when={isVatDeductible}>
                {vatType && (
                  <CalculatePrice
                    amount={
                      lastTouchedSellPriceField === 'withVat'
                        ? sellingPriceWithVat
                        : sellingPriceWithoutVat
                    }
                    countryCode={country}
                    type={lastTouchedSellPriceField}
                    vatCode={vatType}
                    onChange={handleCalculationChange(formApi)}
                  />
                )}
                <NoPermissionTooltip
                  shouldShowTooltip={isFalse(canEditSalePrice) || isFalse(canReadSalePrice)}
                >
                  <FormField
                    isRequired
                    control={control}
                    name="expectedSellingPrice.priceWithoutVat.amount"
                    type="currency"
                    currency={currency}
                    decimalPlaces={2}
                    label={i18n.t('general.labels.sellingPriceWithoutVat')}
                    onChange={() => setLastTouchedSellPriceField('withoutVat')}
                    data-testid={testIds.businessCase.selling(
                      'pricing-sellingPrice-priceWithoutVat'
                    )}
                    isDisabled={isFalse(canEditSalePrice) || isFalse(canReadSalePrice)}
                  />
                </NoPermissionTooltip>
                <HStack spacing={4}>
                  <Box width={18} minWidth={18} maxWidth={18}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                      <FormField
                        control={control}
                        options={vatRates}
                        name="expectedSellingPrice.vatType"
                        type="choice"
                        label={i18n.t('general.labels.vat')}
                        placeholder={i18n.t('general.labels.select')}
                        data-testid={testIds.businessCase.selling('pricing-vatType')}
                        isNotClearable
                        isDisabled={isFalse(canEditPurchasePrice)}
                      />
                    </NoPermissionTooltip>
                  </Box>
                  <Box flexGrow={1}>
                    <NoPermissionTooltip
                      shouldShowTooltip={isFalse(canEditSalePrice) || isFalse(canReadSalePrice)}
                    >
                      <FormField
                        isRequired
                        control={control}
                        name="expectedSellingPrice.priceWithVat.amount"
                        type="currency"
                        currency={currency}
                        decimalPlaces={2}
                        label={i18n.t('general.labels.sellingPrice')}
                        onChange={() => setLastTouchedSellPriceField('withVat')}
                        data-testid={testIds.businessCase.selling(
                          'pricing-sellingPrice-priceWithVat'
                        )}
                        isDisabled={isFalse(canEditSalePrice) || isFalse(canReadSalePrice)}
                      />
                    </NoPermissionTooltip>
                  </Box>
                </HStack>
              </Show>
            </Grid>
            <FormField
              name="updateReasonNote"
              type="textarea"
              control={control}
              label={i18n.t('general.labels.reason')}
              data-testid={testIds.businessCase.selling('pricing-reason')}
            />
            <ButtonGroup align="right">
              <Button
                onClick={props.toggleDetail}
                title={i18n.t('general.labels.dismiss')}
                variant="secondary"
                data-testid={testIds.businessCase.selling('cancel')}
              />
              <FormButton
                title={i18n.t('general.actions.save')}
                control={control}
                type="submit"
                data-testid={testIds.businessCase.selling('submit')}
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}

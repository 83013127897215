import {Grid} from 'platform/foundation';

import {useMemo, ReactElement} from 'react';

import i18n from '@omnetic-dms/i18n';
import {useFormRenderer, PlatformInformation, AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

export const PlatformAuthField = ({platform}: {platform: PlatformInformation}): ReactElement => {
  const {Field} = useFormRenderer();

  const keys = useMemo(() => {
    if (platform?.credentials != null) {
      return Object.keys(platform.credentials);
    }

    // TODO: Temporary fix - fallback when platform.credentials field is missing
    switch (platform.code) {
      case AdvertisingPlatformCodeEnum.MYWEB:
      case AdvertisingPlatformCodeEnum.SAUTO:
      case AdvertisingPlatformCodeEnum.FACEBOOK:
      case AdvertisingPlatformCodeEnum.AUTOCARIS:
        return ['username', 'password'];
      case AdvertisingPlatformCodeEnum.TIPCARS:
        return ['companyCode', 'password'];
      case AdvertisingPlatformCodeEnum.MOBILE_DE:
        return ['sellerId', 'sellerUsername', 'sellerPassword'];
      case AdvertisingPlatformCodeEnum.AUTOSOFT:
        return [];
      default:
        return;
    }
  }, [platform]);

  return (
    <Grid columns={2}>
      {keys?.includes('username') && (
        <Field label={i18n.t('page.advertisement.labels.username')} name="username" as="text" />
      )}
      {keys?.includes('companyCode') && (
        <Field
          label={i18n.t('page.advertisement.labels.companyCode')}
          name="companyCode"
          as="text"
        />
      )}
      {keys?.includes('sellerId') && (
        <Field label={i18n.t('page.advertisement.labels.sellerId')} name="sellerId" as="text" />
      )}
      {keys?.includes('sellerUsername') && (
        <Field label={i18n.t('page.advertisement.labels.username')} name="username" as="text" />
      )}
      {keys?.includes('password') && (
        <Field
          as="password"
          label={i18n.t('general.labels.password')}
          name="password"
          maxLength={30}
        />
      )}
      {keys?.includes('sellerPassword') && (
        <Field
          as="password"
          label={i18n.t('general.labels.password')}
          name="password"
          maxLength={30}
        />
      )}
    </Grid>
  );
};

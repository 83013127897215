import {DataStatus} from 'platform/components';
import {Show, VStack} from 'platform/foundation';

import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetServiceCaseScheduleRepairQuery,
  useGetServiceCheckInQuery,
  useGetServiceHandOverQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Section} from '@omnetic-dms/shared';

import {RepairScheduledCard} from '../../../../components/RepairScheduledCard/RepairScheduledCard';
import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {CheckinHandoverCard} from './components/CheckinHandoverCard';

export function Overview() {
  const {serviceCaseId} = useWorkshopUrl();

  const {
    data: checkin,
    isLoading: isCheckinLoading,
    isError: isCheckinError,
  } = useGetServiceCheckInQuery({serviceCaseId});
  const {
    data: handover,
    isLoading: isHandoverLoading,
    isError: isHandoverError,
  } = useGetServiceHandOverQuery({serviceCaseId});
  const {
    data: scheduleRepair,
    isLoading: isScheduleRepairLoading,
    isError: isScheduleRepairError,
  } = useGetServiceCaseScheduleRepairQuery({serviceCaseId}, {refetchOnMountOrArgChange: true});

  const handleOpenPlanner = () => {
    if (isNotNil(scheduleRepair?.url)) {
      window.open(scheduleRepair?.url, '_self');
    }
  };

  const isLoading = isCheckinLoading || isHandoverLoading || isScheduleRepairLoading;
  const isError = isCheckinError || isHandoverError || isScheduleRepairError;

  return (
    <Section data-testid={testIds.workshop.serviceCaseDetail('overview')}>
      <DataStatus isLoading={isLoading} isError={isError} minHeight={140}>
        <VStack spacing={4}>
          <CheckinHandoverCard
            checkin={checkin}
            handover={handover}
            data-testid={testIds.workshop.serviceCaseDetail('scheduled')}
          />
          <Show when={isNotNilOrEmpty(scheduleRepair)}>
            <RepairScheduledCard
              data={scheduleRepair}
              cardActions={[
                {
                  type: 'button',
                  title: i18n.t('entity.serviceScheduler.actions.openPlanner'),
                  leftIcon: 'action/open_in_new',
                  onClick: handleOpenPlanner,
                  variant: 'link',
                  isDisabled: isNilOrEmpty(scheduleRepair?.url),
                },
              ]}
              data-testid={testIds.workshop.serviceCaseDetail('scheduled')}
            />
          </Show>
        </VStack>
      </DataStatus>
    </Section>
  );
}

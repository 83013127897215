import {useCallback, useMemo} from 'react';

import {isNil} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {
  defaultColDefConst,
  getActionsColDef,
  getCheckBoxColDef,
  quickFilterColDef,
} from '../constants/columnDefinitions';
import {ColDef, ColGroupDef} from '../types/AgGridTypes';
import {AgGridWrapperProps} from '../types/AgGridWrapperProps';
import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {isColDef} from '../utils/isColDef';
import {ColOrColGroupDef} from '../utils/transformDefinition';

export type useColumnDefsReturnType = {
  defaultColDef: ColDef;
  colDefs: Array<ColDef | ColGroupDef>;
};

/**
 * Transform provided column definitions.
 * 1. add quick filter utility column
 * 2. add expander utility column (when needed)
 * 3. add row selection utility column (when needed)
 * 4. add custom filter utility column (when needed)
 * 5. add actions filter utility column (when needed)
 * RESOURCES:
 * - https://www.ag-grid.com/javascript-data-grid/column-definitions/
 */
export const useColumnDefs = (
  props: AgGridWrapperProps,
  settings: FeGridSettings,
  colDef: ColOrColGroupDef[],
  definition: TransformedDefinitionApiResponse
): useColumnDefsReturnType => {
  const getColProperties = useCallback(
    (def: ColDef) => {
      // Some renderers can't be wrap because component didn't support that
      const canBeWrapped = (col: ColDef) =>
        isArray(col.cellClass) && col.cellClass[0] !== 'FlagCellRenderer';

      return {
        colId: def.colId || def.field,
        lockPinned: !definition.behavior.columnPinningAllowed,
        lockPosition: !definition.behavior.columnMovingAllowed,
        lockVisible: !definition.behavior.columnHidingAllowed,
        resizable: definition.behavior.columnResizingAllowed,
        suppressMenu: !definition.behavior.columnMenuEnabled,
        sortable: definition.behavior.columnSortMode !== 'NONE' && def.sortable,
        autoHeight: settings.valueWrapMode === 'WRAP' && canBeWrapped(def),
        wrapText: settings.valueWrapMode === 'WRAP' && canBeWrapped(def),
      };
    },
    [definition, settings]
  );
  // enhance column definitions passed to DG
  // col defs automatically includes checkbox selection column and actions column after enhancement
  const colDefs = useMemo<Array<ColDef | ColGroupDef>>(() => {
    const actionsColDef = getActionsColDef(props);

    const checkBoxColDef = getCheckBoxColDef(props, settings, definition);
    const newColDefs: (ColDef | ColGroupDef)[] = [quickFilterColDef];

    if (
      definition.behavior.rowSelectMode === 'MULTIPLE' ||
      definition.behavior.rowSelectMode === 'SINGLE'
    ) {
      newColDefs.push(checkBoxColDef);
    }

    newColDefs.push(...colDef);

    if (
      definition.behavior.actionColumnEnabled &&
      // @ts-ignore4
      props.actionsDefinition &&
      // @ts-ignore
      Object.keys(props.actionsDefinition).length > 0
    ) {
      newColDefs.push(actionsColDef);
    }

    return newColDefs.map((def) => {
      if (!isColDef(def) && !isNil(def.children)) {
        def.children = def.children.map((children: ColDef) => ({
          ...children,
          ...getColProperties(children),
        }));
      }

      return {...def, ...getColProperties(def)};
    });
  }, [colDef, props.actionCallback, settings.valueWrapMode]);

  const defaultColDef = useMemo(
    () => ({
      ...defaultColDefConst,
    }),
    [settings.valueWrapMode]
  );

  return {
    defaultColDef,
    colDefs,
  };
};

import {ApiEnumPropertyName} from '../../types/ApiEnumPropertyName';
import {VehicleCatalogueColorProperties} from '../../types/VehicleCatalogueColorProperties';
import {VehicleCatalogueEnumOption} from '../../types/VehicleCatalogueEnumOption';
import {VehicleCatalogueFuelTypeProperties} from '../../types/VehicleCatalogueFuelTypeProperties';
import {VehicleCatalogueType} from '../../types/VehicleCatalogueType';
import {getEnumOptions} from '../../utils/getVehicleCatalogueEnumItems';

export const parseApiEnumsResponse = (payload: any): Partial<VehicleCatalogueType> => {
  const catalogue: Partial<VehicleCatalogueType> = {};

  payload.forEach((apiObjectResponse: any) => {
    switch (apiObjectResponse.property_name) {
      case ApiEnumPropertyName.BATTERY_OWNERSHIP_TYPE: {
        if (!catalogue.batteryOwnershipTypes) {
          catalogue.batteryOwnershipTypes = [];
        }
        catalogue.batteryOwnershipTypes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.BATTERY_TYPE: {
        if (!catalogue.batteryTypes) {
          catalogue.batteryTypes = [];
        }
        catalogue.batteryTypes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.BODY_COLOR: {
        if (!catalogue.bodyColors) {
          catalogue.bodyColors = [];
        }
        catalogue.bodyColors.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys) as Array<
            VehicleCatalogueEnumOption<VehicleCatalogueColorProperties>
          >,
        });
        break;
      }
      case ApiEnumPropertyName.BUS_STYLE: {
        if (!catalogue.busStyles) {
          catalogue.busStyles = [];
        }
        catalogue.busStyles.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.CAR_STYLE: {
        if (!catalogue.carStyles) {
          catalogue.carStyles = [];
        }
        catalogue.carStyles.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.CARAVAN_STYLE: {
        if (!catalogue.caravanStyles) {
          catalogue.caravanStyles = [];
        }
        catalogue.caravanStyles.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.CHARGING_CONNECTOR_TYPE: {
        if (!catalogue.chargingConnectorTypes) {
          catalogue.chargingConnectorTypes = [];
        }
        catalogue.chargingConnectorTypes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.COST: {
        if (!catalogue.costs) {
          catalogue.costs = [];
        }
        catalogue.costs.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.FUEL_UNIT: {
        if (!catalogue.fuelUnits) {
          catalogue.fuelUnits = [];
        }
        catalogue.fuelUnits.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.SELLER_TYPE: {
        if (!catalogue.sellerTypes) {
          catalogue.sellerTypes = [];
        }
        catalogue.sellerTypes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.MOTORCYCLE_STYLE: {
        if (!catalogue.motorcycleStyles) {
          catalogue.motorcycleStyles = [];
        }
        catalogue.motorcycleStyles.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.TRUCK_STYLE: {
        if (!catalogue.truckStyles) {
          catalogue.truckStyles = [];
        }
        catalogue.truckStyles.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.VAN_STYLE: {
        if (!catalogue.vanStyles) {
          catalogue.vanStyles = [];
        }
        catalogue.vanStyles.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.COLOR_TYPE: {
        if (!catalogue.colorTypes) {
          catalogue.colorTypes = [];
        }
        catalogue.colorTypes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.CONDITION: {
        if (!catalogue.conditions) {
          catalogue.conditions = [];
        }
        catalogue.conditions.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.DOOR_COUNT: {
        if (!catalogue.doorCounts) {
          catalogue.doorCounts = [];
        }
        catalogue.doorCounts.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.DRIVE: {
        if (!catalogue.drives) {
          catalogue.drives = [];
        }
        catalogue.drives.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.EMISSION_CLASS: {
        if (!catalogue.emissionClasses) {
          catalogue.emissionClasses = [];
        }
        catalogue.emissionClasses.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.FEATURE: {
        if (!catalogue.features) {
          catalogue.features = [];
        }

        catalogue.features.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.FEATURE_CATEGORY: {
        if (!catalogue.featureCategories) {
          catalogue.featureCategories = [];
        }
        catalogue.featureCategories.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.FUEL_TYPE: {
        if (!catalogue.fuelTypes) {
          catalogue.fuelTypes = [];
        }
        catalogue.fuelTypes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys) as Array<
            VehicleCatalogueEnumOption<VehicleCatalogueFuelTypeProperties>
          >,
        });
        break;
      }
      case ApiEnumPropertyName.HAND_DRIVE_TYPE: {
        if (!catalogue.handDriveTypes) {
          catalogue.handDriveTypes = [];
        }
        catalogue.handDriveTypes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.HYBRID_TYPE: {
        if (!catalogue.hybridTypes) {
          catalogue.hybridTypes = [];
        }
        catalogue.hybridTypes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.INTERIOR_COLOR: {
        if (!catalogue.interiorColors) {
          catalogue.interiorColors = [];
        }
        catalogue.interiorColors.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys) as Array<
            VehicleCatalogueEnumOption<VehicleCatalogueColorProperties>
          >,
        });
        break;
      }
      case ApiEnumPropertyName.INTERIOR_MATERIAL: {
        if (!catalogue.interiorMaterials) {
          catalogue.interiorMaterials = [];
        }
        catalogue.interiorMaterials.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.SERVICE_BOOK_TYPE: {
        if (!catalogue.serviceBooks) {
          catalogue.serviceBooks = [];
        }
        catalogue.serviceBooks.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.SERVICE_BOOK_STATE: {
        if (!catalogue.serviceBookStates) {
          catalogue.serviceBookStates = [];
        }
        catalogue.serviceBookStates.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.TIRE_ASPECT_RATIO: {
        if (!catalogue.tireAspectRatios) {
          catalogue.tireAspectRatios = [];
        }
        catalogue.tireAspectRatios.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.TIRE_MAKE: {
        if (!catalogue.tireMakes) {
          catalogue.tireMakes = [];
        }
        catalogue.tireMakes.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.TIRE_RIM_DIAMETER: {
        if (!catalogue.tireRimDiameters) {
          catalogue.tireRimDiameters = [];
        }
        catalogue.tireRimDiameters.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.TIRE_SEASON: {
        if (!catalogue.tierSeasons) {
          catalogue.tierSeasons = [];
        }
        catalogue.tierSeasons.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.TIRE_WIDTH: {
        if (!catalogue.tireWidth) {
          catalogue.tireWidth = [];
        }
        catalogue.tireWidth.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.TRANSMISSION: {
        if (!catalogue.transmissions) {
          catalogue.transmissions = [];
        }
        catalogue.transmissions.push({
          vehicleTypes: apiObjectResponse.vehicle_type,
          options: getEnumOptions(apiObjectResponse.keys),
        });
        break;
      }
      case ApiEnumPropertyName.VEHICLE_TYPE: {
        catalogue.vehicleTypes = getEnumOptions(apiObjectResponse.keys);
        break;
      }

      default:
        break;
    }
  });

  return catalogue;
};

import type {PermissionsRecordType} from '../permissions';

export const serviceCasePermissions = {
  serviceCaseRead: {
    resourceId: 'SERVICE_CASE',
    actionId: 'READ',
    scopes: [],
  },
  serviceOrderCreate: {
    resourceId: 'SERVICE_ORDER',
    actionId: 'CREATE',
    scopes: [],
  },
  serviceOrderEdit: {
    resourceId: 'SERVICE_ORDER',
    actionId: 'EDIT',
    scopes: [],
  },
} satisfies PermissionsRecordType;

import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Box} from 'platform/foundation';

import {useNavigate} from 'react-router-dom';

import {useGetRoleQuery, usePatchRoleMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';

type FormValues = {
  title: string;
};

interface RoleNameFormProps {
  roleId: string;
}
export function RoleNameForm({roleId}: RoleNameFormProps) {
  const navigate = useNavigate();

  const {data: role, isLoading, isError} = useGetRoleQuery({roleId});
  const [updateRole] = usePatchRoleMutation();

  const redirectToList = () => navigate(settingsRoutes.roleManagement);

  const handleUpdateRole: FormSubmitHandler<FormValues> = async (patchRoleRequestBody) =>
    await updateRole({roleId, patchRoleRequestBody})
      .unwrap()
      .then(() => showNotification.success())
      .then(redirectToList)
      .catch(handleApiError);

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Form<FormValues> defaultValues={{title: role?.title}} onSubmit={handleUpdateRole}>
        {(control) => (
          <>
            <Box maxWidth={100}>
              <FormField
                control={control}
                name="title"
                type="text"
                isDisabled={role?.system}
                label={i18n.t('page.settings.labels.roleName')}
                data-testid={testIds.settings.roleManagementDetail('roleName')}
              />
            </Box>
            <SettingsFooter
              actions={[
                {
                  type: 'button',
                  title: i18n.t('general.actions.discard'),
                  variant: 'secondary',
                  onClick: redirectToList,
                },
                {
                  type: 'form-button',
                  control,
                  title: i18n.t('general.actions.saveChanges'),
                  buttonType: 'submit',
                  isDisabled: role?.system,
                },
              ]}
            />
          </>
        )}
      </Form>
    </DataStatus>
  );
}

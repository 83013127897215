import {BaseQueryApi} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {captureException} from '@sentry/browser';
import axios from 'axios';
import {AxiosCacheInstance, setupCache} from 'axios-cache-interceptor';
import flagsmith from 'flagsmith';

import {appWorkspaceKey, browserStorageKey} from '@omnetic-dms/config';
import {environment} from '@omnetic-dms/environment';
import {loginRoutes} from '@omnetic-dms/routes';

import {selectActiveBranchId} from '../../features/branchSelectors';
import {RefreshTokenApiArg} from '../../types/api';
import {publicApi} from '../publicApi';
import {getArgsWithAuthorization} from './autorizationHeaders';
import {buildUrl} from './utils/buildUrl';
import {getWorkspaceFromUri} from './utils/getWorkspaceFromUri';
import {redirectToLoginPage} from './utils/redirect';

const URL_PATH_PREFIX = 'dms';

export const initializeAxios = (api: BaseQueryApi) => {
  axios.defaults.baseURL = `${environment.API_URL}/${URL_PATH_PREFIX}`;
  axios.interceptors.request.use((config) => {
    const activeBranchId = selectActiveBranchId(api.getState() as any);

    // @ts-ignore
    config.headers = getArgsWithAuthorization({headers: config.headers}, activeBranchId).headers;

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status >= 500) {
        captureException(error);
      }

      if (error.response.status === 401) {
        const getRefreshToken = localStorage.getItem(browserStorageKey.REFRESH_TOKEN);
        const {workspace, shouldRedirectToAppWorkspace} = getWorkspaceFromUri();

        if (!workspace || shouldRedirectToAppWorkspace) {
          window.location.href = buildUrl(loginRoutes.loginWorkspace, appWorkspaceKey);
          return error;
        }

        if (!getRefreshToken) {
          window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
          return error;
        }

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('directLink');

        const refreshTokenArgs: RefreshTokenApiArg = {
          workspace,
          refreshTokenRequestBody: {
            refreshToken: getRefreshToken,
          },
        };

        const token = await api
          .dispatch(publicApi.endpoints.refreshToken.initiate(refreshTokenArgs))
          .unwrap()
          .then(({refreshToken, token}) => {
            sessionStorage.setItem(browserStorageKey.ACCESS_TOKEN, token);
            localStorage.setItem(browserStorageKey.REFRESH_TOKEN, refreshToken);
            return token;
          })
          .catch(() => {
            sessionStorage.removeItem(browserStorageKey.ACCESS_TOKEN);
            localStorage.removeItem(browserStorageKey.REFRESH_TOKEN);
            flagsmith.logout();
            window.location.href = buildUrl(redirectToLoginPage(), undefined, false);
          });

        // We need to override Authorization manually, because we are using override for DEV DG connection
        return axios.request({
          ...error.response.config,
          headers: {...error.response.config.headers, Authorization: `Bearer ${token}`},
        });
      }

      throw error;
    }
  );
  // eslint-disable-next-line no-restricted-syntax
  if (!(axios as unknown as AxiosCacheInstance).storage) {
    setupCache(axios, {
      ttl: 750,
      cacheTakeover: false,
    });
  }
};

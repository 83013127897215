import {usePermissions} from './usePermissions/usePermissions';

export const useGetComplexPermissions = () => {
  const [
    hasVehicleProfitPermission,
    hasVehicleSellingPricePermission,
    hasVehicleRealSellingPricePermission,
    hasBusinessCaseSaleVehiclePurchasePricePermission,
    hasBusinessCaseSaleVehicleSalePricePermission,
    hasBusinessCaseSaleProfitPermission,
    hasBusinessCaseSaleTotalPricePermission,
    hasVehiclePurchasePricePermission,
    hasBusinessCasePurchaseVehiclePurchasePricePermission,
    hasBusinessCasePurchaseVehicleSellingPricePermission,
    hasBusinessCasePurchaseExpectedProfitPermission,
    hasBusinessCasePurchaseTotalPricePermission,
  ] = usePermissions({
    permissionKeys: [
      'vehicleProfit',
      'vehicleSellingPrice',
      'vehicleRealSellingPrice',
      'businessCaseSaleVehiclePurchasePrice',
      'businessCaseSaleVehicleSalePrice',
      'businessCaseSaleProfit',
      'businessCaseSaleTotalPrice',
      'vehiclePurchasePrice',
      'businessCasePurchaseVehiclePurchasePrice',
      'businessCasePurchaseVehicleSellingPrice',
      'businessCasePurchaseExpectedProfit',
      'businessCasePurchaseTotalPrice',
    ],
  });

  const canReadSalePrice =
    hasVehicleProfitPermission ||
    hasVehicleSellingPricePermission ||
    hasVehicleRealSellingPricePermission ||
    hasBusinessCaseSaleVehiclePurchasePricePermission ||
    hasBusinessCaseSaleVehicleSalePricePermission ||
    hasBusinessCaseSaleProfitPermission ||
    hasBusinessCaseSaleTotalPricePermission;

  const canReadPurchasePrice =
    hasVehicleProfitPermission ||
    hasVehiclePurchasePricePermission ||
    hasBusinessCasePurchaseVehiclePurchasePricePermission ||
    hasBusinessCasePurchaseVehicleSellingPricePermission ||
    hasBusinessCasePurchaseExpectedProfitPermission ||
    hasBusinessCasePurchaseTotalPricePermission;

  return {canReadSalePrice, canReadPurchasePrice};
};

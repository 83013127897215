import {Card} from 'platform/components';
import {Box, Grid, Space, Text, Spinner} from 'platform/foundation';
import styled from 'styled-components';

import {useEffect, useCallback, ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {queryParams} from '@omnetic-dms/shared';
import {
  Form,
  requestTipCarsClientData,
  requestUpdateTipCarsClientData,
  selectTipCarsData,
  selectUpdatingTipCarsClientData,
  EditTipCarsClientDataRequestBody,
} from '@omnetic-dms/teas';

import {useQueryState} from 'shared';

import {$TipCarsClientDataRequestBody} from '../../utils/TipCarsClientDataValidation';
import {PlatformAuthenticationProps} from './PlatformAuthentication';

const Item = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  display: flex;
  padding: 16px;

  &:first-child {
    border-top: none;
  }
`;

const PlatformItemContent = styled(Item)`
  display: flex;
  border-top: 0;
  flex-direction: column;
`;

export const PlatformTipCarsInfo = ({platform}: PlatformAuthenticationProps): ReactElement => {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const dispatch = useDispatch();
  const {loading, data} = useSelector(selectTipCarsData);
  const {updating} = useSelector(selectUpdatingTipCarsClientData);
  const code = platform?.code;

  useEffect(() => {
    dispatch(requestTipCarsClientData({branchId: branchId ?? undefined}));
  }, [dispatch, branchId]);

  const submit = useCallback(
    (data: EditTipCarsClientDataRequestBody, branchId?: string) => {
      dispatch(requestUpdateTipCarsClientData({clientData: data, branchId}));
    },
    [dispatch]
  );

  return (
    <Form<EditTipCarsClientDataRequestBody>
      onSubmit={(data: EditTipCarsClientDataRequestBody) => submit(data, branchId ?? undefined)}
      initialValues={data}
      schema={$TipCarsClientDataRequestBody}
      render={({Field, handleSubmit}) => (
        <Box>
          <Card
            data-testid={testIds.settings.advertisingPlatforms(`card-${code}-tenantInfo`)}
            title={i18n.t('page.settings.labels.tenantInfo')}
            actions={[
              {
                variant: 'link',
                type: 'button',
                isLoading: updating,
                onClick: handleSubmit,
                'data-testid': testIds.settings.advertisingPlatforms(`saveTenantInfo-${code}`),
                title: i18n.t('general.actions.save'),
              },
            ]}
          >
            <Text>{i18n.t('page.settings.labels.tenantInfoDescription')}</Text>
            <Space vertical={4} />
            {loading ? (
              <Spinner variant="overlay" size="small" />
            ) : (
              <PlatformItemContent>
                <Grid columns={2}>
                  <Field
                    as="text"
                    label={i18n.t('entity.customer.labels.companyName')}
                    name="companyName"
                  />
                  <Field as="text" label={i18n.t('entity.address.labels.street')} name="address" />
                </Grid>
                <Grid columns={2}>
                  <Field
                    as="text"
                    label={i18n.t('entity.address.labels.postalCode')}
                    name="postalCode"
                  />
                  <Field as="text" label={i18n.t('entity.address.labels.city')} name="city" />
                </Grid>
                <Grid columns={2}>
                  <Field as="text" label={i18n.t('general.labels.phone')} name="phone" />
                  <Field as="text" label={i18n.t('general.labels.fax')} name="fax" />
                </Grid>
                <Grid columns={2}>
                  <Field as="text" label={i18n.t('general.labels.email')} name="email" />
                  <Field as="text" label={i18n.t('general.labels.website')} name="website" />
                </Grid>
                <Grid columns={1}>
                  <Field as="text" multiline label={i18n.t('general.labels.note')} name="note" />
                </Grid>
              </PlatformItemContent>
            )}
          </Card>
        </Box>
      )}
    />
  );
};

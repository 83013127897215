import {isFeatureEnabled} from 'feature-flags';
import {Checkbox, IconButton, TableRow} from 'platform/components';
import {Box, HStack, Integer, Show, Text, ThemeColorPath} from 'platform/foundation';

import {MouseEvent} from 'react';

import {isNil} from 'ramda';
import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useGetRoleQuery,
  useGrantResourcePermissionMutation,
  useRevokeResourcePermissionMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps, useRequiredParams, useToggle, useQueryState} from 'shared';

import {featureFlagsByAcl} from '../hooks/featureFlagsByAcl';
import {TableDataType} from '../hooks/useGetACLData';
import {NEXT_LINE_OFFSET} from '../utils/consts';
import {getResourceCheckboxValues} from '../utils/getResourceCheckboxValues';
import {ActionItem} from './ActionItem';

interface ResourceItemProps extends TestIdProps {
  data: TableDataType;
  level?: number;
}

export function ResourceItem(props: ResourceItemProps) {
  const {roleId} = useRequiredParams();
  const [mode] = useQueryState('mode');

  const {data: role} = useGetRoleQuery({roleId});
  const isSystem = role?.system ?? false;

  const {isChecked, isIndeterminate} = getResourceCheckboxValues(props.data);
  const [isOpen, toggleOpen] = useToggle(isNil(mode));

  const [grantResource] = useGrantResourcePermissionMutation();
  const [revokeResource] = useRevokeResourcePermissionMutation();

  const level = props.level ?? 0;
  const nextLevel = level + 1;
  const hasChildren = isNotNilOrEmpty(props.data.children);
  const hasProtectedUnits = isNotNilOrEmpty(props.data.protectedUnits);

  const handleToggleButton = (e: MouseEvent) => {
    e.stopPropagation();
    toggleOpen();
  };

  const handleGrantGroup = (isSelected: boolean) => {
    const submitAction = isSelected ? grantResource : revokeResource;
    submitAction({resourceId: props.data.id, roleId}).unwrap().catch(handleApiError);
  };

  const rowColor: ThemeColorPath | undefined =
    isChecked || isIndeterminate ? undefined : 'palettes.red.10.100';

  return (
    <Show when={isResourceVisible(props.data)}>
      <TableRow
        actions={{primary: []}}
        data-testid={suffixTestId('resourceRow', props)}
        color={rowColor}
      >
        <Box paddingHorizontal={2} paddingLeft={(1 + level * NEXT_LINE_OFFSET) as Integer}>
          <HStack align="center" spacing={8}>
            <Show when={hasChildren || hasProtectedUnits}>
              <IconButton
                onClick={handleToggleButton}
                icon={isOpen ? 'hardware/keyboard_arrow_up' : 'hardware/keyboard_arrow_down'}
                data-testid={suffixTestId('resourceToggle', props)}
              />
              <Checkbox
                isDisabled={isSystem}
                value={isChecked}
                isIndeterminate={isIndeterminate}
                onChange={handleGrantGroup}
                data-testid={suffixTestId('grant', props)}
              />
            </Show>
            <Text size="small" alternative>
              {props.data.name}
            </Text>
          </HStack>
        </Box>
        <Box>
          <Text size="small">{i18n.t(`acl.resource.${props.data.id.toLowerCase()}`)}</Text>
        </Box>
        <Box />
      </TableRow>

      <Show when={isOpen}>
        <Show when={hasChildren}>
          {props.data.children.map((children) => (
            <ResourceItem
              data={children}
              level={nextLevel}
              data-testid={testIds.settings.roleManagementDetail('aclTable')}
              key={children.id}
            />
          ))}
        </Show>

        <Show when={hasProtectedUnits}>
          {props.data.protectedUnits.map((protectedUnit) => (
            <ActionItem
              protectedUnit={protectedUnit}
              resource={props.data}
              level={nextLevel}
              data-testid={testIds.settings.roleManagementDetail('aclTable')}
              key={`${props.data.id}-${protectedUnit.id}`}
            />
          ))}
        </Show>
      </Show>
    </Show>
  );
}

const isResourceVisible = (val: TableDataType) => {
  const featureFlag = featureFlagsByAcl[val.id];

  if (isNilOrEmpty(featureFlag)) {
    return true;
  }

  return isFeatureEnabled(featureFlag);
};

import {closeDialog, Actions} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {usePostIntegrationsForXentryUnlinkMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

interface ConfirmUnlinkDialogProps extends RequiredTestIdProps {
  dialogId: string;
  serviceCaseId: string;
  xentryId: string | Nullish;
}

export function ConfirmUnlinkDialog(props: ConfirmUnlinkDialogProps) {
  const [postIntegrationsForXentryUnlink, {isLoading: isPostIntegrationsForXentryUnlinkLoading}] =
    usePostIntegrationsForXentryUnlinkMutation();

  return (
    <VStack spacing={4}>
      <Heading size={4}>
        {i18n.t('entity.integration.labels.unlinkXentryOrder', {xentryId: props.xentryId})}
      </Heading>
      <Actions
        align="right"
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            onClick: () => closeDialog(props.dialogId),
            title: i18n.t('general.actions.discard'),
          },
          {
            type: 'button',
            onClick: () =>
              postIntegrationsForXentryUnlink({serviceCaseId: props.serviceCaseId})
                .unwrap()
                .then(() => closeDialog(props.dialogId))
                .catch(handleApiError),
            isLoading: isPostIntegrationsForXentryUnlinkLoading,
            variant: 'danger',
            title: i18n.t('entity.integration.actions.unlink'),
          },
        ]}
        data-testid={suffixTestId('confirm', props)}
      />
    </VStack>
  );
}

import {Module} from '@omnetic-dms/api';
import {moduleKeys} from '@omnetic-dms/config';
import {featureFlags} from '@omnetic-dms/feature-flags';
import {settingsRoutes} from '@omnetic-dms/routes';
import {permissions} from '@omnetic-dms/shared';

import {AccountingActivityLog} from './pages/AccountingActivityLog/AccountingActivityLog';
import {AccountingErrorLog} from './pages/AccountingErrorLog/AccountingErrorLog';
import {AccountingErrorLogDetail} from './pages/AccountingErrorLogDetail/AccountingErrorLogDetail';
import {AddNewGdprConsentType} from './pages/AddNewGdprConsentType/AddNewGdprConsentType';
import {AddUser} from './pages/AddUser/AddUser';
import {Advertising} from './pages/Advertising/Advertising';
import {AdvertisingPlatformDetail} from './pages/AdvertisingPlatformDetail/AdvertisingPlatformDetail';
import {AdvertisingPlatforms} from './pages/AdvertisingPlatforms/AdvertisingPlatforms';
import {AutostacjaSettings} from './pages/AutostacjaSettings/AutostacjaSettings';
import {AutostacjaSettingsSubscriptionKey} from './pages/AutostacjaSettingsSubscriptionKey/AutostacjaSettingsSubscriptionKey';
import {BackgroundRemoval} from './pages/BackgroundRemoval/BackgroundRemoval';
import {BackgroundRemovalOld} from './pages/BackgroundRemoval/BackgroundRemovalOld';
import {BackgroundRemovalDetail} from './pages/BackgroundRemovalDetail/BackgroundRemovalDetail';
import {BillingInfo} from './pages/BillingInfo/BillingInfo';
import {BillingInfoEdit} from './pages/BillingInfoDetail/BillingInfoEdit';
import {BillingInfoNew} from './pages/BillingInfoDetail/BillingInfoNew';
import {BranchDetail} from './pages/BranchDetail/BranchDetail';
import {Branches} from './pages/Branches/Branches';
import {BusinessCaseCentralizedPricing} from './pages/BusinessCaseCentralizedPricing/BusinessCaseCentralizedPricing';
import {BusinessCaseDefinition} from './pages/BusinessCaseDefinition/BusinessCaseDefinition';
import {BusinessCaseDueDatesDetail} from './pages/BusinessCaseDueDatesDetail.tsx/BusinessCaseDueDatesDetail';
import {BusinessCaseDueDatesOverview} from './pages/BusinessCaseDueDatesOverview/BusinessCaseDueDatesOverview';
import {BusinessCases} from './pages/BusinessCases/BusinessCases';
import {BussinessCaseCommissionFeeDetail} from './pages/BussinessCaseCommissionFeeDetail/BussinessCaseCommissionFeeDetail';
import {CashLimits} from './pages/CashLimits/CashLimits';
import {CashLimitsAdd} from './pages/CashLimitsDetail/CashLimitsAdd';
import {CashLimitsEdit} from './pages/CashLimitsDetail/CashLimitsEdit';
import {CashRegisters} from './pages/CashRegisters/CashRegisters';
import {Cebia} from './pages/Cebia/Cebia';
import {CebiaRokvy} from './pages/CebiaRokvy/CebiaRokvy';
import {CheckinInspection} from './pages/CheckinInspection/CheckinInspection';
import {CodelistDetail} from './pages/CodelistDetail/CodelistDetail';
import {Codelists} from './pages/Codelists/Codelists';
import {ComissionFee} from './pages/ComissionFee/ComissionFee';
import {CreateOrEditInvoiceCanceling} from './pages/CreateOrEditInvoiceCanceling/CreateOrEditInvoiceCanceling';
import {CustomerCodeDefinition} from './pages/CustomerCodeDefinition/CustomerCodeDefinition';
import {CustomerCodeDefinitionDetail} from './pages/CustomerCodeDefinitionDetail/CustomerCodeDefinitionDetail';
import {CustomerContracts} from './pages/CustomerContracts/CustomerContracts';
import {CustomerContractsDetail} from './pages/CustomerContractsDetail/CustomerContractsDetail';
import {CustomerVerification} from './pages/CustomerVerification/CustomerVerification';
import {CustomerVerificationDistraints} from './pages/CustomerVerificationDistraints/CustomerVerificationDistraints';
import {CustomFieldsDefinitions} from './pages/CustomFieldsDefinitions/CustomFieldsDefinitions';
import {DocumentRoundingDefinitions} from './pages/DocumentRoundingDefinitions/DocumentRoundingDefinitions';
import {DocumentRoundingDefinitionsDetail} from './pages/DocumentRoundingDefinitionsDetail/DocumentRoundingDefinitionsDetail';
import {DocumentRoundingDefinitionsEdit} from './pages/DocumentRoundingDefinitionsDetail/DocumentRoundingDefinitionsEdit';
import {DocumentRoundingDefinitionsNew} from './pages/DocumentRoundingDefinitionsDetail/DocumentRoundingDefinitionsNew';
import {DocumentTemplates} from './pages/DocumentTemplates/DocumentTemplates';
import {EditCashRegister} from './pages/EditCashRegister/EditCashRegister';
import {EditGdprConsentType} from './pages/EditGdprConsentType/EditGdprConsentType';
import {EmailServicesPage} from './pages/EmailServices/EmailServicesPage';
import {EmailServicesSMTPPage} from './pages/EmailServicesSMTP/EmailServicesSMTPPage';
import {EmployeeCompetencesDetail} from './pages/EmployeeCompetencesDetail/EmployeeCompetencesDetail';
import {EmployeeCompetencesOverview} from './pages/EmployeeCompetencesOverview/EmployeeCompetencesOverview';
import {EmployeeCostCentersDetail} from './pages/EmployeeCostCentersDetail/EmployeeCostCentersDetail';
import {EmployeeCostCentersOverview} from './pages/EmployeeCostCentersOverview/EmployeeCostCentersOverview';
import {EmployeeProfessionsDetail} from './pages/EmployeeProfessionsDetail/EmployeeProfessionsDetail';
import {EmployeeProfessionsOverview} from './pages/EmployeeProfessionsOverview/EmployeeProfessionsOverview';
import {EmployeeWorkTeamsDetail} from './pages/EmployeeWorkTeamsDetail/EmployeeWorkTeamsDetail';
import {EmployeeWorkTeamsOverview} from './pages/EmployeeWorkTeamsOverview/EmployeeWorkTeamsOverview';
import {EsignoService} from './pages/EsignoService/EsignoService';
import {ExportParametersDetail} from './pages/ExportParameters/ExportParametersDetail';
import {Features} from './pages/Features/Features';
import {GdprConsentTypes} from './pages/GdprConsentTypes/GdprConsentTypes';
import {GeneratedDocuments} from './pages/GeneratedDocuments/GeneratedDocuments';
import {HandoverInspection} from './pages/HandoverInspection/HandoverInspection';
import {Inspections} from './pages/Inspections/Inspections';
import {InspectionsDetail} from './pages/InspectionsDetail/InspectionsDetail';
import {Interest} from './pages/Interest/Interest';
import {InterestDefinition} from './pages/InterestDefinition/InterestDefinition';
import {InterestDefinitionDetail} from './pages/InterestDefinitionDetail/InterestDefinitionDetail';
import {InvoiceCanceling} from './pages/InvoiceCanceling/InvoiceCanceling';
import {InvoiceCostDetail} from './pages/InvoiceCostDetail/InvoiceCostDetail';
import {InvoiceCostOverview} from './pages/InvoiceCostOverview/InvoiceCostOverview';
import {InvoiceDefinition} from './pages/InvoiceDefinition/InvoiceDefinition';
import {InvoiceMarginOverview} from './pages/InvoiceMargin/InvoiceMarginOverview';
import {InvoiceMarginDetail} from './pages/InvoiceMarginDetail/InvoiceMarginDetail';
import {Jbr} from './pages/JbrSettings/Jbr';
import {JbrSettingsCredentials} from './pages/JbrSettingsCredentials/JbrSettingsCredentials';
import {LabourCatalogues} from './pages/LabourCatalogues/LabourCatalogues';
import {LabourCataloguesDetail} from './pages/LabourCataloguesDetail/LabourCataloguesDetail';
import {LabourRates} from './pages/LabourRates/LabourRates';
import {LabourRatesDetail} from './pages/LabourRatesDetail/LabourRatesDetail';
import {Locale} from './pages/Locale/Locale';
import {MatrixManagement} from './pages/MatrixManagement/MatrixManagement';
import {NewCashRegister} from './pages/NewCashRegister/NewCashRegister';
import {PairedDevicesOverview} from './pages/PairedDevicesOverview/PairedDevicesOverview';
import {PhotosWatermarking} from './pages/PhotosWatermarking/PhotosWatermarking';
import {PhotosWatermarkingDetail} from './pages/PhotosWatermarking/PhotosWatermarkingDetail';
import {PhotosWatermarkingOld} from './pages/PhotosWatermarking/PhotosWatermarkingOld';
import {PreAccountingProcessingDetail} from './pages/PreAccountingProcessingDetail/PreAccountingProcessingDetail';
import {PreAccountingProcessingNew} from './pages/PreAccountingProcessingNew/PreAccountingProcessingNew';
import {PreAccountingProcessingOverview} from './pages/PreAccountingProcessingOverview/PreAccountingProcessingOverview';
import {ProductCatalog} from './pages/ProductCatalog/ProductCatalog';
import {ProformaCreationDetail} from './pages/ProformaCreationDetail/ProformaCreationDetail';
import {ProformaCreationOverview} from './pages/ProformaCreationOverview/ProformaCreationOverview';
import {ProformaInvoiceGenerationDetail} from './pages/ProformaInvoiceGenerationDetail/ProformaInvoiceGenerationDetail';
import {ProformaInvoiceGenerationNew} from './pages/ProformaInvoiceGenerationNew/ProformaInvoiceGenerationNew';
import {ProformaInvoiceGenerationOverview} from './pages/ProformaInvoiceGenerationOverview/ProformaInvoiceGenerationOverview';
import {PromoPhotoCreate} from './pages/PromoPhotoCreate/PromoPhotoCreate';
import {PromoPhotoDetail} from './pages/PromoPhotoDetail/PromoPhotoDetail';
import {PromoPhotos} from './pages/PromoPhotos/PromoPhotos';
import {QrCodeDefinition} from './pages/QrCodeDefinition/QrCodeDefinition';
import {Reservation} from './pages/Reservation/Reservation';
import {RoleOverview} from './pages/RoleManagement/RoleOverview';
import {SeriesDefinition} from './pages/SeriesDefinition/SeriesDefinition';
import {SeriesDefinitionEdit} from './pages/SeriesDefinitionDetail/SeriesDefinitionEdit';
import {SeriesDefinitionNew} from './pages/SeriesDefinitionDetail/SeriesDefinitionNew';
import {ServiceCaseType} from './pages/ServiceCaseType/ServiceCaseType';
import {ServiceCaseTypeDetail} from './pages/ServiceCaseTypeDetail/ServiceCaseTypeDetail';
import {ServiceOrderVariants} from './pages/ServiceOrderVariants/ServiceOrderVariants';
import {ServiceOrderVariantsDetail} from './pages/ServiceOrderVariantsDetail/ServiceOrderVariantsDetail';
import {SmsGateways} from './pages/SmsGateways/SmsGateways';
import {SmsGatewaysKonzulta} from './pages/SmsGatewaysKonzulta/SmsGatewaysKonzulta';
import {Suppliers} from './pages/Suppliers/Suppliers';
import {TaxDocumentForPaymentImportTypeDetail} from './pages/TaxDocumentForPaymentImportTypeDetail/TaxDocumentForPaymentImportTypeDetail';
import {TaxDocumentForPaymentImportTypeNew} from './pages/TaxDocumentForPaymentImportTypeNew/TaxDocumentForPaymentImportTypeNew';
import {TaxDocumentForPaymentIssuedTypeDetail} from './pages/TaxDocumentForPaymentIssuedTypeDetail/TaxDocumentForPaymentIssuedTypeDetail';
import {TaxDocumentForPaymentIssuedTypeNew} from './pages/TaxDocumentForPaymentIssuedTypeNew/TaxDocumentForPaymentIssuedTypeNew';
import {TaxDocumentImportTypeOverview} from './pages/TaxDocumentImportTypeOverview/TaxDocumentImportTypeOverview';
import {TaxDocumentIssuedTypeOverview} from './pages/TaxDocumentIssuedTypeOverview/TaxDocumentIssuedTypeOverview';
import {Tenant} from './pages/Tenant/Tenant';
import {TireInventoryLocations} from './pages/TireInventoryLocations/TireInventoryLocations';
import {TireInventoryManufacturers} from './pages/TireInventoryManufacturers/TireInventoryManufacturers';
import {TiresInventory} from './pages/TiresInventory/TiresInventory';
import {TireStorageLocations} from './pages/TireStorageLocations/TireStorageLocations';
import {TireStorageLocationsDetail} from './pages/TireStorageLocationsDetail/TireStorageLocationsDetail';
import {UserOverviewWrapper} from './pages/UserManagement/UserOverviewWrapper';
import {RoleEdit} from './pages/UserManagementDetail/RoleEdit';
import {UserEdit} from './pages/UserManagementDetail/UserEdit';
import {VatTotalAndCalculationDetail} from './pages/VatTotalAndCalculationDetail/VatTotalAndCalculationDetail';
import {VatTotalAndCalculationOverview} from './pages/VatTotalAndCalculationOverview/VatTotalAndCalculationOverview';
import {VehicleCondition} from './pages/VehicleCondition/VehicleCondition';
import {VehicleGroups} from './pages/VehicleGroups/VehicleGroups';
import {VehicleGroupsDetail} from './pages/VehicleGroupsDetail/VehicleGroupsDetail';
import {VehicleInspections} from './pages/VehicleInspections/VehicleInspections';
import {VehicleInspectionsDetail} from './pages/VehicleInspectionsDetail/VehicleInspectionsDetail';
import {VehicleInspectionsNew} from './pages/VehicleInspectionsDetail/VehicleInspectionsNew';
import {VehicleParking} from './pages/VehicleParking/VehicleParking';
import {VehicleParkingAdd} from './pages/VehicleParkingDetail/VehicleParkingAdd';
import {VehicleParkingEdit} from './pages/VehicleParkingDetail/VehicleParkingEdit';
import {VehicleSeriesDefinition} from './pages/VehicleSeriesDefinition/VehicleSeriesDefinition';
import {VehicleEditSeriesDefinition} from './pages/VehicleSeriesDefinitionDetail/VehicleEditSeriesDefinition';
import {VehicleNewSeriesDefinition} from './pages/VehicleSeriesDefinitionDetail/VehicleNewSeriesDefinition';
import {VehicleServices} from './pages/VehicleServices/VehicleServices';
import {VehicleTopMakes} from './pages/VehicleTopMakes/VehicleTopMakes';
import {VehicleWarehouseDetail} from './pages/VehicleWarehouseDetail/VehicleWarehouseDetail';
import {VehicleWarehouseManagement} from './pages/VehicleWarehouseManagement/VehicleWarehouseManagement';
import {WarehouseAccount} from './pages/WarehouseAccount/WarehouseAccount';
import {WarehouseAccountDetail} from './pages/WarehouseAccountDetail/WarehouseAccountDetail';
import {WarehouseArticleCategory} from './pages/WarehouseArticleCategory/WarehouseArticleCategory';
import {WarehouseArticleCategoryDetail} from './pages/WarehouseArticleCategoryDetail/WarehouseArticleCategoryDetail';
import {WarehouseDetail} from './pages/WarehouseDetail/WarehouseDetail';
import {WarehouseDirectSaleVariants} from './pages/WarehouseDirectSaleVariants/WarehouseDirectSaleVariants';
import {WarehouseDirectSaleVariantsDetail} from './pages/WarehouseDirectSaleVariantsDetail/WarehouseDirectSaleVariantsDetail';
import {WarehouseDiscountGroup} from './pages/WarehouseDiscountGroup/WarehouseDiscountGroup';
import {WarehouseDiscountGroupDetail} from './pages/WarehouseDiscountGroupDetail/WarehouseDiscountGroupDetail';
import {WarehouseMarketingCode} from './pages/WarehouseMarketingCode/WarehouseMarketingCode';
import {WarehouseMarketingCodeDetail} from './pages/WarehouseMarketingCodeDetail/WarehouseMarketingCodeDetail';
import {Warehouses} from './pages/Warehouses/Warehouses';
import {WarehouseServiceOrderIssueVariants} from './pages/WarehouseServiceOrderIssueVariants/WarehouseServiceOrderIssueVariants';
import {WarehouseServiceOrderIssueVariantsDetail} from './pages/WarehouseServiceOrderIssueVariantsDetail/WarehouseServiceOrderIssueVariantsDetail';
import {WarehousesManufacturers} from './pages/WarehousesManufacturers/WarehousesManufacturers';
import {WarehousesManufacturersDetail} from './pages/WarehousesManufacturersDetail/WarehousesManufacturersDetail';
import {WarehousesMeasurementUnits} from './pages/WarehousesMeasurementUnits/WarehousesMeasurementUnits';
import {WarehousesMeasurementUnitsDetail} from './pages/WarehousesMeasurementUnitsDetail/WarehousesMeasurementUnitsDetail';
import {WarehousesSupplierOrderVariantsDetail} from './pages/WarehousesSupplierOrderVariantsDetail/WarehousesSupplierOrderVariantsDetail';
import {WarehouseSupplierOrderVariantsList} from './pages/WarehouseSupplierOrderVariantsList/WarehouseSupplierOrderVariantsList';
import {WorkshopCustomerGroupFormPage} from './pages/WorkshopCustomerGroupFormPage/WorkshopCustomerGroupFormPage';
import {WorkshopCustomerGroups} from './pages/WorkshopCustomerGroups/WorkshopCustomerGroups';
import {WorkshopMeasurementUnitDetail} from './pages/WorkshopMeasurementUnitDetail/WorkshopMeasurementUnitDetail';
import {WorkshopMeasurementUnits} from './pages/WorkshopMeasurementUnits/WorkshopMeasurementUnits';
import {WorkshopServicePackageDetail} from './pages/WorkshopServicePackageDetail/WorkshopServicePackageDetail';
import {WorkshopServicePackages} from './pages/WorkshopServicePackages/WorkshopServicePackages';
import {WorkshopSmallUnitMaterialDetail} from './pages/WorkshopSmallUnitMaterialDetail/WorkshopSmallUnitMaterialDetail';
import {WorkshopSmallUnitMaterials} from './pages/WorkshopSmallUnitMaterials/WorkshopSmallUnitMaterials';
import {WorkType} from './pages/WorkType/WorkType';
import {WorkTypeDetail} from './pages/WorkTypeDetail/WorkTypeDetail';

export const SettingsModule: Module = {
  id: moduleKeys.settings,
  requiredPermission: permissions.readSettings,
  layoutProps: {
    title: 'module.settings.title',
    icon: 'action/settings',
    iconV2: 'sidebar/settings',
    isLastMenuItem: true,
  },
  routerProps: {
    path: settingsRoutes.userManagement,
    element: <UserOverviewWrapper />,
  },
  content: [
    {
      id: 'settings-home',
      routerProps: {
        path: settingsRoutes.userManagement,
        element: <UserOverviewWrapper />,
      },
      layoutProps: {
        title: 'module.settings.title',
        icon: 'sidebar/settings',
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising',
      routerProps: {
        path: settingsRoutes.advertising,
        element: <Advertising />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-platforms',
      routerProps: {
        path: settingsRoutes.advertisingPlatforms,
        element: <AdvertisingPlatforms />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-platforms-detail',
      routerProps: {
        path: settingsRoutes.advertisingPlatformsDetail,
        element: <AdvertisingPlatformDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-promoPhotos',
      routerProps: {
        path: settingsRoutes.advertisingPromoPhotos,
        element: <PromoPhotos />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-promoPhotos-create',
      routerProps: {
        path: settingsRoutes.advertisingPromoPhotosCreate,
        element: <PromoPhotoCreate />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-promoPhotos-detail',
      routerProps: {
        path: settingsRoutes.advertisingPromoPhotosDetail,
        element: <PromoPhotoDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-watermarking',
      routerProps: {
        path: settingsRoutes.advertisingWatermarking,
        element: <PhotosWatermarking />,
      },
      requiredPermission: permissions.readSettings,
      requiredFeatureFlag: featureFlags.SETTINGS_WATERMARKS,
    },
    {
      id: 'advertising-watermarking-old',
      routerProps: {
        path: settingsRoutes.advertisingWatermarkingOld,
        element: <PhotosWatermarkingOld />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'esignoService',
      routerProps: {
        path: settingsRoutes.esignoDetail,
        element: <EsignoService />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-watermarking-detail',
      routerProps: {
        path: settingsRoutes.advertisingWatermarkingDetail,
        element: <PhotosWatermarkingDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'PairedDevicesOverview',
      routerProps: {
        path: settingsRoutes.signatures,
        element: <PairedDevicesOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-watermarking-create',
      routerProps: {
        path: settingsRoutes.advertisingWatermarkingCreate,
        element: <PhotosWatermarkingDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-qrCodeDefinition',
      routerProps: {
        path: settingsRoutes.advertisingQrCodeDefinition,
        element: <QrCodeDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'advertising-backgroundRemovalOld',
      routerProps: {
        path: settingsRoutes.backgroundRemovalOld,
        element: <BackgroundRemovalOld />,
      },
      requiredPermission: permissions.configureBackgroundRemoval,
    },
    {
      id: 'advertising-backgroundRemoval',
      routerProps: {
        path: settingsRoutes.backgroundRemoval,
        element: <BackgroundRemoval />,
      },
      requiredPermission: permissions.configureBackgroundRemoval,
    },
    {
      id: 'advertising-backgroundRemovalDetail',
      routerProps: {
        path: settingsRoutes.backgroundRemovalDetail,
        element: <BackgroundRemovalDetail />,
      },
      requiredPermission: permissions.configureBackgroundRemoval,
    },
    {
      id: 'advertising-backgroundRemovalCreate',
      routerProps: {
        path: settingsRoutes.backgroundRemovalCreate,
        element: <BackgroundRemovalDetail />,
      },
      requiredPermission: permissions.configureBackgroundRemoval,
    },
    {
      id: 'businessCases',
      routerProps: {
        path: settingsRoutes.businessCase,
        element: <BusinessCases />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'businessCase-dueDates',
      routerProps: {
        path: settingsRoutes.businessCaseDueDates,
        element: <BusinessCaseDueDatesOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'businessCase-dueDatesDetail',
      routerProps: {
        path: settingsRoutes.businessCaseDueDatesDetail,
        element: <BusinessCaseDueDatesDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'businessCase-dueDatesNew',
      routerProps: {
        path: settingsRoutes.businessCaseDueDatesNew,
        element: <BusinessCaseDueDatesDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'businessCases-definition',
      routerProps: {
        path: settingsRoutes.businessCaseDefinition,
        element: <BusinessCaseDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'businessCases-commissionFee',
      routerProps: {
        path: settingsRoutes.businessCaseCommissionFee,
        element: <ComissionFee />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'businessCases-editCommissionFee',
      routerProps: {
        path: settingsRoutes.businessCaseEditCommissionFee,
        element: <BussinessCaseCommissionFeeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'businessCases-createCommissionFee',
      routerProps: {
        path: settingsRoutes.businessCaseCreateCommissionFee,
        element: <BussinessCaseCommissionFeeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'businessCases-centralizedPricing',
      routerProps: {
        path: settingsRoutes.businessCaseCentralizedPricing,
        element: <BusinessCaseCentralizedPricing />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleWarehouseManagement',
      routerProps: {
        path: settingsRoutes.vehicleWarehouseManagement,
        element: <VehicleWarehouseManagement />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleWarehouseCreate',
      routerProps: {
        path: settingsRoutes.vehicleWarehouseCreate,
        element: <VehicleWarehouseDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleWarehouseEdit',
      routerProps: {
        path: settingsRoutes.vehicleWarehouseEdit,
        element: <VehicleWarehouseDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'interest',
      routerProps: {
        path: settingsRoutes.interest,
        element: <Interest />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'interestDefinition',
      routerProps: {
        path: settingsRoutes.interestDefinition,
        element: <InterestDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'interestDefinitionDetail',
      routerProps: {
        path: settingsRoutes.interestDefinitionDetail,
        element: <InterestDefinitionDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'productCatalog',
      routerProps: {
        path: settingsRoutes.productCatalog,
        element: <ProductCatalog />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'codelists',
      routerProps: {
        path: settingsRoutes.codelists,
        element: <Codelists />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'codelistDetail',
      routerProps: {
        path: settingsRoutes.codelistDetail,
        element: <CodelistDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleTopMakes',
      routerProps: {
        path: settingsRoutes.vehicleTopMakes,
        element: <VehicleTopMakes />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleSeriesDefinition',
      routerProps: {
        path: settingsRoutes.vehicleSeriesDefinition,
        element: <VehicleSeriesDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleNewSeriesDefinition',
      routerProps: {
        path: settingsRoutes.vehicleNewSeriesDefinition,
        element: <VehicleNewSeriesDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleEditSeriesDefinition',
      routerProps: {
        path: settingsRoutes.vehicleEditSeriesDefinition,
        element: <VehicleEditSeriesDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleInspections',
      routerProps: {
        path: settingsRoutes.vehicleInspections,
        element: <VehicleInspections />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleInspectionsNew',
      routerProps: {
        path: settingsRoutes.vehicleInspectionsNew,
        element: <VehicleInspectionsNew />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleInspectionsEdit',
      routerProps: {
        path: settingsRoutes.vehicleInspectionDetail,
        element: <VehicleInspectionsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleCondition',
      routerProps: {
        path: settingsRoutes.vehicleCondition,
        element: <VehicleCondition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleParking',
      routerProps: {
        path: settingsRoutes.vehicleParking,
        element: <VehicleParking />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleParkingAdd',
      routerProps: {
        path: settingsRoutes.vehicleParkingAdd,
        element: <VehicleParkingAdd />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleParkingEdit',
      routerProps: {
        path: settingsRoutes.vehicleParkingEdit,
        element: <VehicleParkingEdit />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'customerDefinition',
      routerProps: {
        path: settingsRoutes.customerCodeDefinition,
        element: <CustomerCodeDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'customerCodeDetail',
      routerProps: {
        path: settingsRoutes.customerCodeDefinitionDetail,
        element: <CustomerCodeDefinitionDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'userManagement',
      routerProps: {
        path: settingsRoutes.userManagement,
        element: <UserOverviewWrapper />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'roleManagement',
      routerProps: {
        path: settingsRoutes.roleManagement,
        element: <RoleOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'userManagementCreate',
      routerProps: {
        path: settingsRoutes.userManagementAddUser,
        element: <AddUser />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'userManagementDetail',
      routerProps: {
        path: settingsRoutes.userManagementDetail,
        element: <UserEdit />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'roleManagementDetail',
      routerProps: {
        path: settingsRoutes.roleManagementDetail,
        element: <RoleEdit />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'documentTemplates',
      routerProps: {
        path: settingsRoutes.documentTemplates,
        element: <DocumentTemplates />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouses',
      routerProps: {
        path: settingsRoutes.warehousesList,
        element: <Warehouses />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseDetail',
      routerProps: {
        paths: [settingsRoutes.warehousesDetail, settingsRoutes.warehousesCreate],
        element: <WarehouseDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseMarketingCode',
      routerProps: {
        path: settingsRoutes.warehousesMarketingCode,
        element: <WarehouseMarketingCode />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseMarketingCodeAdd',
      routerProps: {
        path: settingsRoutes.warehousesMarketingCodeCreate,
        element: <WarehouseMarketingCodeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseMarketingCodeDetail',
      routerProps: {
        path: settingsRoutes.warehousesMarketingCodeDetail,
        element: <WarehouseMarketingCodeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseDirectSaleVariants',
      routerProps: {
        path: settingsRoutes.warehousesDirectSaleVariants,
        element: <WarehouseDirectSaleVariants />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseDirectSaleVariantsAdd',
      routerProps: {
        path: settingsRoutes.warehousesDirectSaleVariantsCreate,
        element: <WarehouseDirectSaleVariantsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseDirectSaleVariantsDetail',
      routerProps: {
        path: settingsRoutes.warehousesDirectSaleVariantsDetail,
        element: <WarehouseDirectSaleVariantsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseServiceOrderIssueVariants',
      routerProps: {
        path: settingsRoutes.warehousesServiceOrderIssueVariants,
        element: <WarehouseServiceOrderIssueVariants />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseServiceOrderIssueVariantsAdd',
      routerProps: {
        path: settingsRoutes.warehousesServiceOrderIssueVariantsCreate,
        element: <WarehouseServiceOrderIssueVariantsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseServiceOrderIssueVariantsDetail',
      routerProps: {
        path: settingsRoutes.warehousesServiceOrderIssueVariantsDetail,
        element: <WarehouseServiceOrderIssueVariantsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseDiscountGroup',
      routerProps: {
        path: settingsRoutes.warehousesDiscountGroup,
        element: <WarehouseDiscountGroup />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseDiscountGroupAdd',
      routerProps: {
        path: settingsRoutes.warehousesDiscountGroupCreate,
        element: <WarehouseDiscountGroupDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseDiscountGroupDetail',
      routerProps: {
        path: settingsRoutes.warehousesDiscountGroupDetail,
        element: <WarehouseDiscountGroupDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseAccount',
      routerProps: {
        path: settingsRoutes.warehousesAccount,
        element: <WarehouseAccount />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseAccountAdd',
      routerProps: {
        path: settingsRoutes.warehousesAccountCreate,
        element: <WarehouseAccountDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseAccountDetail',
      routerProps: {
        path: settingsRoutes.warehousesAccountDetail,
        element: <WarehouseAccountDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseSupplierOrderVariantsList',
      routerProps: {
        path: settingsRoutes.warehousesSupplierOrderVariants,
        element: <WarehouseSupplierOrderVariantsList />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseSupplierOrderVariantsAdd',
      routerProps: {
        path: settingsRoutes.warehousesSupplierOrderVariantsCreate,
        element: <WarehousesSupplierOrderVariantsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseSupplierOrderVariantsDetail',
      routerProps: {
        path: settingsRoutes.warehousesSupplierOrderVariantsDetail,
        element: <WarehousesSupplierOrderVariantsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseArticleCategory',
      routerProps: {
        path: settingsRoutes.warehousesArticleCategory,
        element: <WarehouseArticleCategory />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseArticleCategoryAdd',
      routerProps: {
        path: settingsRoutes.warehousesArticleCategoryCreate,
        element: <WarehouseArticleCategoryDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseArticleCategoryDetail',
      routerProps: {
        path: settingsRoutes.warehousesArticleCategoryDetail,
        element: <WarehouseArticleCategoryDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseManufacturers',
      routerProps: {
        path: settingsRoutes.warehousesManufacturers,
        element: <WarehousesManufacturers />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseManufacturersAdd',
      routerProps: {
        path: settingsRoutes.warehousesManufacturersCreate,
        element: <WarehousesManufacturersDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseManufacturersDetail',
      routerProps: {
        path: settingsRoutes.warehousesManufacturersDetail,
        element: <WarehousesManufacturersDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseMeasurementUnits',
      routerProps: {
        path: settingsRoutes.warehousesMeasurementUnits,
        element: <WarehousesMeasurementUnits />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseMeasurementUnitsAdd',
      routerProps: {
        path: settingsRoutes.warehousesMeasurementUnitsCreate,
        element: <WarehousesMeasurementUnitsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'warehouseMeasurementUnitsDetail',
      routerProps: {
        path: settingsRoutes.warehousesMeasurementUnitsDetail,
        element: <WarehousesMeasurementUnitsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'suppliers',
      routerProps: {
        path: settingsRoutes.suppliers,
        element: <Suppliers />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'gdprConsent',
      routerProps: {
        path: settingsRoutes.gdprConsentTypes,
        element: <GdprConsentTypes />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'gdprConsentEdit',
      routerProps: {
        path: settingsRoutes.gdprConsentTypesEdit,
        element: <EditGdprConsentType />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'gdprConsentAdd',
      routerProps: {
        path: settingsRoutes.gdprConsentTypesNew,
        element: <AddNewGdprConsentType />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'tenant',
      routerProps: {
        path: settingsRoutes.tenant,
        element: <Tenant />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'billingInfo',
      routerProps: {
        path: settingsRoutes.billingInformation,
        element: <BillingInfo />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'billingInfoEdit',
      routerProps: {
        path: settingsRoutes.billingInformationEdit,
        element: <BillingInfoEdit />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'billingInfoNew',
      routerProps: {
        path: settingsRoutes.billingInformationNew,
        element: <BillingInfoNew />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'customFields',
      routerProps: {
        path: settingsRoutes.customFields,
        element: <CustomFieldsDefinitions />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'seriesDefinition',
      routerProps: {
        path: settingsRoutes.seriesDefinition,
        element: <SeriesDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'seriesDefinitionEdit',
      routerProps: {
        path: settingsRoutes.seriesDefinitionEdit,
        element: <SeriesDefinitionEdit />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'seriesDefinitionNew',
      routerProps: {
        path: settingsRoutes.seriesDefinitionNew,
        element: <SeriesDefinitionNew />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'branches',
      routerProps: {
        path: settingsRoutes.branches,
        element: <Branches />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'branchEdit',
      routerProps: {
        path: settingsRoutes.branchesEdit,
        element: <BranchDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'branchNew',
      routerProps: {
        path: settingsRoutes.branchesNew,
        element: <BranchDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'cashRegisters',
      routerProps: {
        path: settingsRoutes.cashRegisters,
        element: <CashRegisters />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'cashRegisterEdit',
      routerProps: {
        path: settingsRoutes.cashRegistersDetail,
        element: <EditCashRegister />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'newCashRegister',
      routerProps: {
        path: settingsRoutes.newCashRegister,
        element: <NewCashRegister />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'cashLimits',
      routerProps: {
        path: settingsRoutes.cashLimits,
        element: <CashLimits />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'cashLimitEdit',
      routerProps: {
        path: settingsRoutes.cashLimitsEdit,
        element: <CashLimitsEdit />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'cashLimitAdd',
      routerProps: {
        path: settingsRoutes.cashLimitsAdd,
        element: <CashLimitsAdd />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'invoiceDefinition',
      routerProps: {
        path: settingsRoutes.invoiceDefinition,
        element: <InvoiceDefinition />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'documentRoundingDefinitions',
      routerProps: {
        path: settingsRoutes.documentRoundingDefinitions,
        element: <DocumentRoundingDefinitions />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'documentRoundingDefinitionEdit',
      routerProps: {
        path: settingsRoutes.documentRoundingDefinitionsEdit,
        element: <DocumentRoundingDefinitionsEdit />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'invoiceCanceling',
      routerProps: {
        path: settingsRoutes.invoiceCanceling,
        element: <InvoiceCanceling />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'newInvoiceCanceling',
      routerProps: {
        path: settingsRoutes.newInvoiceCanceling,
        element: <CreateOrEditInvoiceCanceling />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingProformaCreationOverview',
      routerProps: {
        path: settingsRoutes.ProformaCreationOverview,
        element: <ProformaCreationOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vatTotalAndCalculationOverview',
      routerProps: {
        path: settingsRoutes.vatTotalAndCalculationOverview,
        element: <VatTotalAndCalculationOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vatTotalAndCalculationDetail',
      routerProps: {
        path: settingsRoutes.vatTotalAndCalculationDetail,
        element: <VatTotalAndCalculationDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vatTotalAndCalculationNew',
      routerProps: {
        path: settingsRoutes.vatTotalAndCalculationNew,
        element: <VatTotalAndCalculationDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingTaxDocumentIssuedTypeOverview',
      routerProps: {
        path: settingsRoutes.taxDocumentIssuedType,
        element: <TaxDocumentIssuedTypeOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingTaxDocumentImportTypeOverview',
      routerProps: {
        path: settingsRoutes.taxDocumentImportType,
        element: <TaxDocumentImportTypeOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingTaxDocumentImportTypeNew',
      routerProps: {
        path: settingsRoutes.taxDocumentImportTypeNew,
        element: <TaxDocumentForPaymentImportTypeNew />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingTaxDocumentImportTypeDetail',
      routerProps: {
        path: settingsRoutes.taxDocumentImportTypeDetail,
        element: <TaxDocumentForPaymentImportTypeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingTaxDocumentIssuedTypeNew',
      routerProps: {
        path: settingsRoutes.taxDocumentIssuedTypeNew,
        element: <TaxDocumentForPaymentIssuedTypeNew />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingTaxDocumentIssuedTypeDetail',
      routerProps: {
        path: settingsRoutes.taxDocumentIssuedTypeDetail,
        element: <TaxDocumentForPaymentIssuedTypeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingInvoiceCostOverview',
      routerProps: {
        path: settingsRoutes.invoiceCostOverview,
        element: <InvoiceCostOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'generatedDocumentsSettings',
      routerProps: {
        path: settingsRoutes.generatedDocuments,
        element: <GeneratedDocuments />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingInvoiceCostNew',
      routerProps: {
        path: settingsRoutes.invoiceCostNew,
        element: <InvoiceCostDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingInvoiceCostDetail',
      routerProps: {
        path: settingsRoutes.invoiceCostDetail,
        element: <InvoiceCostDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingProformaCreationDetail',
      routerProps: {
        path: settingsRoutes.ProformaCreationEdit,
        element: <ProformaCreationDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingProformaCreationCreate',
      routerProps: {
        path: settingsRoutes.ProformaCreationCreate,
        element: <ProformaCreationDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingMatrixManagement',
      routerProps: {
        path: settingsRoutes.accountingMatrixManagement,
        element: <MatrixManagement />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'exportParameters',
      routerProps: {
        path: settingsRoutes.exportParameters,
        element: <ExportParametersDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingActivityLog',
      routerProps: {
        path: settingsRoutes.accountingActivityLog,
        element: <AccountingActivityLog />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingErrorLog',
      routerProps: {
        path: settingsRoutes.accountingErrorLog,
        element: <AccountingErrorLog />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'accountingErrorLogDetail',
      routerProps: {
        path: settingsRoutes.accountingErrorLogDetail,
        element: <AccountingErrorLogDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'preAccountingProcessingOverview',
      routerProps: {
        path: settingsRoutes.preAccountingProcessingOverview,
        element: <PreAccountingProcessingOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'preAccountingProcessingNew',
      routerProps: {
        path: settingsRoutes.preAccountingProcessingNew,
        element: <PreAccountingProcessingNew />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'preAccountingProcessingDetail',
      routerProps: {
        path: settingsRoutes.preAccountingProcessingDetail,
        element: <PreAccountingProcessingDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'invoiceCancelingDetail',
      routerProps: {
        path: settingsRoutes.invoiceCancelingDetail,
        element: <CreateOrEditInvoiceCanceling />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'documentRoundingDefinitionAdd',
      routerProps: {
        path: settingsRoutes.documentRoundingDefinitionsAdd,
        element: <DocumentRoundingDefinitionsNew />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'documentRoundingDefinitionDetail',
      routerProps: {
        path: settingsRoutes.documentRoundingDefinitionsDetail,
        element: <DocumentRoundingDefinitionsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleServices',
      routerProps: {
        path: settingsRoutes.vehicleServices,
        element: <VehicleServices />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'autostacjaSettings',
      routerProps: {
        path: settingsRoutes.autostacjaSettings,
        element: <AutostacjaSettings />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'autostacjaSettingsSubscriptionKey',
      routerProps: {
        path: settingsRoutes.autostacjaSettingsSubscriptionKey,
        element: <AutostacjaSettingsSubscriptionKey />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'jbr',
      routerProps: {
        path: settingsRoutes.jbr,
        element: <Jbr />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'jbrSettings',
      routerProps: {
        path: settingsRoutes.jbrSettings,
        element: <JbrSettingsCredentials />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleServicesCebia',
      routerProps: {
        path: settingsRoutes.vehicleServicesCebia,
        element: <Cebia />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleServicesFeatures',
      routerProps: {
        path: settingsRoutes.vehicleServicesFeatures,
        element: <Features />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleServicesCebiaRokvy',
      routerProps: {
        path: settingsRoutes.vehicleServicesRokVy,
        element: <CebiaRokvy />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'smsGateways',
      routerProps: {
        path: settingsRoutes.smsGateways,
        element: <SmsGateways />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'smsGatewaysKonzulta',
      routerProps: {
        path: settingsRoutes.smsGatewaysKonzulta,
        element: <SmsGatewaysKonzulta />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'proformaGeneration',
      routerProps: {
        path: settingsRoutes.proformaGeneration,
        element: <ProformaInvoiceGenerationOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'proformaGenerationDetail',
      routerProps: {
        path: settingsRoutes.proformaGenerationDetail,
        element: <ProformaInvoiceGenerationDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'proformaGenerationNew',
      routerProps: {
        path: settingsRoutes.proformaGenerationNew,
        element: <ProformaInvoiceGenerationNew />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'invoiceMargin',
      routerProps: {
        path: settingsRoutes.invoiceMarginOverview,
        element: <InvoiceMarginOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'invoiceMargin',
      routerProps: {
        path: settingsRoutes.newInvoiceMargin,
        element: <InvoiceMarginDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'invoiceMarginDetail',
      routerProps: {
        path: settingsRoutes.invoiceMarginDetail,
        element: <InvoiceMarginDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'emailServices',
      routerProps: {
        path: settingsRoutes.emailServices,
        element: <EmailServicesPage />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'emailServicesSmtp',
      routerProps: {
        path: settingsRoutes.emailServicesSmtp,
        element: <EmailServicesSMTPPage />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'customerVerification',
      routerProps: {
        path: settingsRoutes.customerVerificationServices,
        element: <CustomerVerification />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'customerVerificationDistraints',
      routerProps: {
        path: settingsRoutes.customerVerificationDistraints,
        element: <CustomerVerificationDistraints />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'reservation',
      routerProps: {
        path: settingsRoutes.reservation,
        element: <Reservation />,
      },
    },
    {
      id: 'locale',
      routerProps: {
        path: settingsRoutes.locale,
        element: <Locale />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'inspections',
      routerProps: {
        path: settingsRoutes.inspections,
        element: <Inspections />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'inspectionsDetail',
      routerProps: {
        path: settingsRoutes.inspectionDetail,
        element: <InspectionsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'checkinInspection',
      routerProps: {
        path: settingsRoutes.checkinInspection,
        element: <CheckinInspection />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'handoverInspection',
      routerProps: {
        path: settingsRoutes.handoverInspection,
        element: <HandoverInspection />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workType',
      routerProps: {
        path: settingsRoutes.workType,
        element: <WorkType />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workTypeCreate',
      routerProps: {
        path: settingsRoutes.workTypeCreate,
        element: <WorkTypeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workTypeDetail',
      routerProps: {
        path: settingsRoutes.workTypeDetail,
        element: <WorkTypeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeCompetences',
      routerProps: {
        path: settingsRoutes.employeeCompetences,
        element: <EmployeeCompetencesOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeCompetencesCreate',
      routerProps: {
        path: settingsRoutes.employeeCompetencesCreate,
        element: <EmployeeCompetencesDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeCompetencesDetail',
      routerProps: {
        path: settingsRoutes.employeeCompetencesDetail,
        element: <EmployeeCompetencesDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleGroups',
      routerProps: {
        path: settingsRoutes.vehicleGroups,
        element: <VehicleGroups />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleGroupsCreate',
      routerProps: {
        path: settingsRoutes.vehicleGroupsCreate,
        element: <VehicleGroupsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'vehicleGroupsDetail',
      routerProps: {
        path: settingsRoutes.vehicleGroupsDetail,
        element: <VehicleGroupsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeCostCenters',
      routerProps: {
        path: settingsRoutes.employeeCostCenters,
        element: <EmployeeCostCentersOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeCostCentersCreate',
      routerProps: {
        path: settingsRoutes.employeeCostCentersCreate,
        element: <EmployeeCostCentersDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeCostCentersDetail',
      routerProps: {
        path: settingsRoutes.employeeCostCentersDetail,
        element: <EmployeeCostCentersDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeWorkTeams',
      routerProps: {
        path: settingsRoutes.employeeWorkTeams,
        element: <EmployeeWorkTeamsOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeWorkTeamsCreate',
      routerProps: {
        path: settingsRoutes.employeeWorkTeamsCreate,
        element: <EmployeeWorkTeamsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeWorkTeamsDetail',
      routerProps: {
        path: settingsRoutes.employeeWorkTeamsDetail,
        element: <EmployeeWorkTeamsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'labourRates',
      routerProps: {
        path: settingsRoutes.labourRates,
        element: <LabourRates />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'labourRatesCreate',
      routerProps: {
        path: settingsRoutes.labourRatesCreate,
        element: <LabourRatesDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'labourRatesDetail',
      routerProps: {
        path: settingsRoutes.labourRatesDetail,
        element: <LabourRatesDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeProfessions',
      routerProps: {
        path: settingsRoutes.employeeProfessions,
        element: <EmployeeProfessionsOverview />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeProfessionsCreate',
      routerProps: {
        path: settingsRoutes.employeeProfessionsCreate,
        element: <EmployeeProfessionsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'employeeProfessionsDetail',
      routerProps: {
        path: settingsRoutes.employeeProfessionsDetail,
        element: <EmployeeProfessionsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'serviceCaseType',
      routerProps: {
        path: settingsRoutes.serviceCaseType,
        element: <ServiceCaseType />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'serviceCaseTypeDetail',
      routerProps: {
        path: settingsRoutes.serviceCaseTypeDetail,
        element: <ServiceCaseTypeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'serviceCaseTypeCreate',
      routerProps: {
        path: settingsRoutes.serviceCaseTypeCreate,
        element: <ServiceCaseTypeDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'serviceOrderVariants',
      routerProps: {
        path: settingsRoutes.serviceOrderVariants,
        element: <ServiceOrderVariants />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'serviceOrderVariantsCreate',
      routerProps: {
        path: settingsRoutes.serviceOrderVariantsCreate,
        element: <ServiceOrderVariantsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'serviceOrderVariantsDetail',
      routerProps: {
        path: settingsRoutes.serviceOrderVariantsDetail,
        element: <ServiceOrderVariantsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'labourCatalogues',
      routerProps: {
        path: settingsRoutes.labourCatalogues,
        element: <LabourCatalogues />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'labourCataloguesCreate',
      routerProps: {
        path: settingsRoutes.labourCataloguesCreate,
        element: <LabourCataloguesDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'labourCataloguesDetail',
      routerProps: {
        path: settingsRoutes.labourCataloguesDetail,
        element: <LabourCataloguesDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopCustomerGroups',
      routerProps: {
        path: settingsRoutes.workshopCustomerGroups,
        element: <WorkshopCustomerGroups />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopCustomerGroupCreate',
      routerProps: {
        path: settingsRoutes.workshopCustomerGroupCreate,
        element: <WorkshopCustomerGroupFormPage />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopCustomerGroupDetail',
      routerProps: {
        path: settingsRoutes.workshopCustomerGroupDetail,
        element: <WorkshopCustomerGroupFormPage />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopMinorUnitMaterial',
      routerProps: {
        path: settingsRoutes.workshopSmallUnitMaterial,
        element: <WorkshopSmallUnitMaterials />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopMinorUnitMaterialDetail',
      routerProps: {
        path: settingsRoutes.workshopSmallUnitMaterialDetail,
        element: <WorkshopSmallUnitMaterialDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopMinorUnitMaterialCreate',
      routerProps: {
        path: settingsRoutes.workshopSmallUnitMaterialCreate,
        element: <WorkshopSmallUnitMaterialDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'customerContracts',
      routerProps: {
        path: settingsRoutes.customerContracts,
        element: <CustomerContracts />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'customerContractsCreate',
      routerProps: {
        path: settingsRoutes.customerContractsCreate,
        element: <CustomerContractsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'customerContractsDetail',
      routerProps: {
        path: settingsRoutes.customerContractsDetail,
        element: <CustomerContractsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'tiresInventory',
      routerProps: {
        path: settingsRoutes.tiresInventory,
        element: <TiresInventory />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'tireManufacturers',
      routerProps: {
        path: settingsRoutes.tireManufacturers,
        element: <TireInventoryManufacturers />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'tireLocations',
      routerProps: {
        path: settingsRoutes.tireLocations,
        element: <TireInventoryLocations />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'tireStorageLocation',
      routerProps: {
        path: settingsRoutes.tireStorageLocation,
        element: <TireStorageLocations />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'tireCreateStorageLocation',
      routerProps: {
        path: settingsRoutes.tireCreateStorageLocation,
        element: <TireStorageLocationsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'tireStorageLocationDetail',
      routerProps: {
        path: settingsRoutes.tireStorageLocationDetail,
        element: <TireStorageLocationsDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopServicePackages',
      routerProps: {
        path: settingsRoutes.workshopServicePackages,
        element: <WorkshopServicePackages />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopCreateServicePackages',
      routerProps: {
        path: settingsRoutes.workshopCreateServicePackages,
        element: <WorkshopServicePackageDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopEditServicePackages',
      routerProps: {
        path: settingsRoutes.workshopEditServicePackages,
        element: <WorkshopServicePackageDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopMeasurementUnits',
      routerProps: {
        path: settingsRoutes.workshopMeasurementUnits,
        element: <WorkshopMeasurementUnits />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopMeasurementUnitCreate',
      routerProps: {
        path: settingsRoutes.workshopMeasurementUnitCreate,
        element: <WorkshopMeasurementUnitDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
    {
      id: 'workshopMeasurementUnitDetail',
      routerProps: {
        path: settingsRoutes.workshopMeasurementUnitDetail,
        element: <WorkshopMeasurementUnitDetail />,
      },
      requiredPermission: permissions.readSettings,
    },
  ],
};

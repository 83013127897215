import {Money} from '../types/common/Money';
import {PhoneNumber} from '../types/common/PhoneNumber';
import {BigDecimal} from '../types/common/Price';

export type UpdateVehicleIsInSaleApiArg = {
  vehicleId: string;
  publish: boolean;
};

export type UpdateVehicleIsInSaleApiRes = unknown;

export type GetSalesPlatformsApiArg = {vehicleId: string};

export enum AdvertisementStatusEnum {
  NOT_PUBLISHED = 'not_published',
  PUBLISHING = 'publishing',
  PUBLISH_FAILED = 'publish_failed',
  PUBLISHED = 'published',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update_failed',
  UNPUBLISHING = 'unpublishing',
  UNPUBLISH_FAILED = 'unpublish_failed',
  UNKNOWN = 'unknown',
}

export type SalesPlatformsResBody = {
  enabled: boolean;
  platform: {
    code: AdvertisingPlatformCodeEnum;
    title: string;
    enabled: boolean;
    branchId: string | null;
  };
  status: AdvertisementStatusEnum;

  statusDescription: string | null;
  statusTriggeredAt: string | null;
  statusTriggeredBy: string | null;
  priceWithVat: {
    amount: string;
    currency: string;
  } | null;
  publishingBranchId: string | null;
  publishingBranchName: string | null;
  hasOverrides: boolean;
};
export type GetSalesPlatformsApiRes = Array<SalesPlatformsResBody>;

export type UpdateVehicleOnAllPlatformsApiResponse = unknown;
export type UpdateVehicleOnAllPlatformsApiArg = {
  vehicleId: string;
};
export type UpdateVehicleOnPlatformApiResponse = unknown;
export type UpdateVehicleOnPlatformApiArg = {
  vehicleId: string;
  platformCode: AdvertisingPlatformCodeEnum;
};
export type EditGeneralSettingsApiResponse =
  /** status 200  */ VehicleAdvertisingSettingsResponseBody;
export type EditGeneralSettingsApiArg = {
  vehicleId: string;
  patchVehicleAdvertisingSettingsRequestBody: PatchVehicleAdvertisingSettingsRequestBody;
};
export type GetGeneralSettingsApiResponse =
  /** status 200  */ VehicleAdvertisingSettingsResponseBody;
export type GetGeneralSettingsApiArg = {
  vehicleId: string;
};
export type EditWindshieldSettingsApiResponse =
  /** status 200  */ VehicleWindshieldAdvertisingSettingsResponseBody;
export type EditWindshieldSettingsApiArg = {
  vehicleId: string;
  patchVehicleWindshieldAdvertisingSettingsRequestBody: PatchVehicleWindshieldAdvertisingSettingsRequestBody;
};
export type GetWindshieldSettingsApiResponse =
  /** status 200  */ VehicleWindshieldAdvertisingSettingsResponseBody;
export type GetWindshieldSettingsApiArg = {
  vehicleId: string;
};
export type AdvertisingPlatformCodeEnum =
  | 'sauto'
  | 'tipcars'
  | 'mobile.de'
  | 'myweb'
  | 'facebook'
  | 'autocaris'
  | 'otomoto'
  | 'olx'
  | 'skoda_plus'
  | 'das_weltauto'
  | 'mercedes_na_sklade'
  | 'hyundai_promise'
  | 'ford_uzywane'
  | 'autoplac_pl'
  | 'vehis'
  | 'mamgo'
  | 'ford_geronimo';
export type AdvertisementPlatformResponseBody = {
  code: AdvertisingPlatformCodeEnum;
  title: string;
  enabled: boolean;
};
export type AdvertisementStatus =
  | 'not_published'
  | 'publishing'
  | 'publish_failed'
  | 'published'
  | 'updating'
  | 'update_failed'
  | 'unpublishing'
  | 'unpublish_failed';
export type AdvertisementResponseBody = {
  enabled: boolean;
  platform: AdvertisementPlatformResponseBody;
  status: AdvertisementStatus;
  statusDescription: string | null;
  statusTriggeredAt: string | null;
  statusTriggeredBy: string | null;
  priceWithVat: Money | null;
  hasOverrides: boolean;
};
export type VehicleAdvertisingSettingsCodeEnum =
  | 'title_extension'
  | 'model_info'
  | 'vin'
  | 'mileage'
  | 'price'
  | 'description'
  | 'price_note'
  | 'financing_price'
  | 'contact_email'
  | 'contact_phone'
  | 'url'
  | 'condition'
  | 'version'
  | 'generation'
  | 'digital_certificate'
  | 'video_url';
export type VehicleAdvertisingSettingsTypeEnum =
  | 'string'
  | 'text'
  | 'mileage'
  | 'price'
  | 'financing_price'
  | 'email'
  | 'phone'
  | 'condition'
  | 'digital_certificate';
export type CommonTitleExtensionAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonVinAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: true;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type MileageValue = {
  value: number;
  unit: 'km';
};
export type CommonMileageAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: MileageValue | null;
  defaultValue?: MileageValue | null;
  displayValue?: MileageValue | null;
  extra?: object | null;
  required?: boolean;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type PriceValue = {
  vatDeductible?: boolean;
  vatRate?: BigDecimal | null;
  value: Money;
  withVat: boolean;
  vatCode?: string | null;
  countryCode?: string;
};
export type PriceExtra = {
  vatDeductible: boolean;
  vatRate: BigDecimal | null;
  vatCode: string;
  countryCode: string;
};
export type CommonPriceAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: PriceValue | null;
  defaultValue?: PriceValue | null;
  displayValue?: PriceValue | null;
  extra?: PriceExtra;
  required?: boolean;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonFinancingPriceAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: PriceValue | null;
  defaultValue?: PriceValue | null;
  displayValue?: PriceValue | null;
  extra?: PriceExtra;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonPriceNoteAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonDescriptionAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonVersionAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonGenerationAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonDigitalCertificateAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonVideoUrlAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type CommonConditionAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: number | null;
  defaultValue?: number | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type VehicleGeneralAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute
  | CommonVersionAttribute
  | CommonGenerationAttribute
  | CommonDigitalCertificateAttribute
  | CommonVideoUrlAttribute
  | CommonConditionAttribute;
export type VehicleGeneralAdvertisingSettingsResponseBody = {
  attributes: VehicleGeneralAdvertisingAttributeResponseBody[];
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleSAutoPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleSAutoPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleSAutoPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type TipCarsPriceNoteAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type VehicleTipCarsPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | TipCarsPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleTipCarsPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleTipCarsPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleMobileDePlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleMobileDePlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleMobileDePlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleMyWebPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleMyWebPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleMyWebPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleFacebookPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleFacebookPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleFacebookPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleAutocarisPlatformAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehicleAutocarisPlatformAdvertisingSettingsResponseBody = {
  platformCode: AdvertisingPlatformCodeEnum;
  enabled: boolean;
  attributes: VehicleAutocarisPlatformAdvertisingAttributeResponseBody[];
  hasOverrides: boolean;
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehiclePlatformAdvertisingSettingsResponseBody =
  | VehicleSAutoPlatformAdvertisingSettingsResponseBody
  | VehicleTipCarsPlatformAdvertisingSettingsResponseBody
  | VehicleMobileDePlatformAdvertisingSettingsResponseBody
  | VehicleMyWebPlatformAdvertisingSettingsResponseBody
  | VehicleFacebookPlatformAdvertisingSettingsResponseBody
  | VehicleAutocarisPlatformAdvertisingSettingsResponseBody;
export type VehicleAdvertisingSettingsResponseBody = {
  vehicleId: string;
  inSale: boolean;
  general: VehicleGeneralAdvertisingSettingsResponseBody;
  platforms?: VehiclePlatformAdvertisingSettingsResponseBody[];
};
export type VehicleGeneralAdvertisingAttributeRequestBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | CommonDescriptionAttribute
  | CommonVersionAttribute
  | CommonGenerationAttribute
  | CommonConditionAttribute;
export type VehicleGeneralAdvertisingSettingsRequestBody = {
  attributes: VehicleGeneralAdvertisingAttributeRequestBody[];
};
export type PatchVehicleAdvertisingSettingsRequestBody = {
  general?: VehicleGeneralAdvertisingSettingsRequestBody | null;
};
export type VehiclePlatformAdvertisingAttributeRequestBody =
  | CommonTitleExtensionAttribute
  | CommonVinAttribute
  | CommonMileageAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | TipCarsPriceNoteAttribute
  | CommonDescriptionAttribute;
export type VehiclePlatformAdvertisingSettingsRequestBody = {
  attributes: VehiclePlatformAdvertisingAttributeRequestBody[];
};
export type GetBusinessCaseDocumentsSettingsApiResponse =
  /** status 200  */ BusinessCaseDocumentsSettingsResponseBody;
export type PutBusinessCaseDocumentsSettingsApiResponse = /** status 204  */ undefined;
export type PutBusinessCaseDocumentsSettingsApiArg = {
  businessCaseDocumentsSettingsRequestBody: BusinessCaseDocumentsSettingsRequestBody;
};
export type SellingBusinessCaseDocumentsResponseBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  saleHandoverProtocol: boolean;
  saleContract: boolean;
  consent: boolean;
};
export type BuyingBusinessCaseDocumentsResponseBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  handoverProtocol: boolean;
  purchaseContract: boolean;
  consent: boolean;
};
export type PurchaseBrokerageBusinessCaseDocumentsResponseBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  purchaseBrokerageHandoverProtocol: boolean;
  purchaseBrokerageContract: boolean;
  consent: boolean;
};
export type SellingBrokerageBusinessCaseDocumentsResponseBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  saleHandoverProtocol: boolean;
  saleBrokerageContract: boolean;
  consent: boolean;
};
export type BusinessCaseDocumentsSettingsResponseBody = {
  selling: SellingBusinessCaseDocumentsResponseBody;
  buying: BuyingBusinessCaseDocumentsResponseBody;
  purchaseBrokerage: PurchaseBrokerageBusinessCaseDocumentsResponseBody;
  sellingBrokerage: SellingBrokerageBusinessCaseDocumentsResponseBody;
};
export type SellingBusinessCaseDocumentsRequestBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  saleHandoverProtocol: boolean;
  saleContract: boolean;
  consent: boolean;
};
export type BuyingBusinessCaseDocumentsRequestBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  handoverProtocol: boolean;
  purchaseContract: boolean;
  consent: boolean;
};
export type PurchaseBrokerageBusinessCaseDocumentsRequestBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  purchaseBrokerageHandoverProtocol: boolean;
  purchaseBrokerageContract: boolean;
  consent: boolean;
};
export type SellingBrokerageBusinessCaseDocumentsRequestBody = {
  vehicleCondition: boolean;
  powerOfAttorney: boolean;
  saleHandoverProtocol: boolean;
  saleBrokerageContract: boolean;
  consent: boolean;
};
export type BusinessCaseDocumentsSettingsRequestBody = {
  selling: SellingBusinessCaseDocumentsRequestBody;
  buying: BuyingBusinessCaseDocumentsRequestBody;
  purchaseBrokerage: PurchaseBrokerageBusinessCaseDocumentsRequestBody;
  sellingBrokerage: SellingBrokerageBusinessCaseDocumentsRequestBody;
};
export type CreatePurchaseBusinessCaseApiResponse = /** status 201  */ UuidResponseBodyBusinessCase;
export type CreatePurchaseBusinessCaseApiArg = {
  createPurchaseBusinessCaseRequestBody: CreatePurchaseBusinessCaseRequestBody;
};
export type CreateSaleBusinessCaseApiResponse = /** status 201  */ UuidResponseBodyBusinessCase;
export type CreateSaleBusinessCaseApiArg = {
  createSaleBusinessCaseRequestBody: CreateSaleBusinessCaseRequestBody;
};
export type UpdateKeyLocationApiResponse = unknown;
export type UpdateKeyLocationApiArg = {
  vehicleId: string;
  updateVehicleKeyLocationRequestBody: UpdateVehicleKeyLocationRequestBody;
};
export type UuidResponseBodyBusinessCase = {
  id: string;
};
export type PurchaseBusinessCaseType = 'IN_STOCK' | 'BROKERAGE';
export type CreatePurchaseBusinessCaseRequestBody = {
  customerId: string;
  vehicleToPurchaseId: string;
  purchaseType: PurchaseBusinessCaseType;
  createdAt?: string | null;
};
export type CreateSaleBusinessCaseRequestBody = {
  customerId: string;
  vehicleToSaleId: string;
  createdAt?: string | null;
};
export type UpdateVehicleKeyLocationRequestBody = {
  keyLocation: string | null;
};
export type WindshieldContactEmailAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type WindshieldContactPhoneAttribute = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: PhoneNumber | null;
  defaultValue?: PhoneNumber | null;
  displayValue?: PhoneNumber | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type WindshieldUrl = {
  code: VehicleAdvertisingSettingsCodeEnum;
  type?: VehicleAdvertisingSettingsTypeEnum;
  title?: string;
  value: string | null;
  defaultValue?: string | null;
  displayValue?: string | null;
  extra?: object | null;
  required?: false;
  override: boolean;
  hasPublishControl?: boolean;
  publish: boolean;
};
export type VehicleWindshieldAdvertisingAttributeResponseBody =
  | CommonTitleExtensionAttribute
  | CommonMileageAttribute
  | WindshieldContactEmailAttribute
  | WindshieldContactPhoneAttribute
  | CommonDescriptionAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | WindshieldUrl;
export type VehicleWindshieldAdvertisingSettingsResponseBody = {
  attributes: VehicleWindshieldAdvertisingAttributeResponseBody[];
  updatedAt: string | null;
  updatedBy: string | null;
};
export type VehicleWindshieldAdvertisingAttributeRequestBody =
  | CommonTitleExtensionAttribute
  | CommonMileageAttribute
  | WindshieldContactEmailAttribute
  | WindshieldContactPhoneAttribute
  | CommonDescriptionAttribute
  | CommonPriceAttribute
  | CommonFinancingPriceAttribute
  | CommonPriceNoteAttribute
  | WindshieldUrl;
export type PatchVehicleWindshieldAdvertisingSettingsRequestBody = {
  attributes?: VehicleWindshieldAdvertisingAttributeRequestBody[] | null;
};
export type EditPlatformSettingsApiResponse =
  /** status 200  */ VehiclePlatformAdvertisingSettingsResponseBody;
export type EditPlatformSettingsApiArg = {
  vehicleId: string;
  platformCode: AdvertisingPlatformCodeEnum;
  vehiclePlatformAdvertisingSettingsRequestBody: VehiclePlatformAdvertisingSettingsRequestBody;
};
export type GetPlatformSettingsApiResponse =
  /** status 200  */ VehiclePlatformAdvertisingSettingsResponseBody;
export type GetPlatformSettingsApiArg = {
  vehicleId: string;
  platformCode: AdvertisingPlatformCodeEnum;
};

export type DisablePublishCebiaReportApiResponse = unknown;
export type DisablePublishCebiaReportApiArg = {
  vehicleId: string;
};

export type EnablePublishCebiaReportApiResponse = unknown;
export type EnablePublishCebiaReportApiArg = {
  vehicleId: string;
};

export type PublishCebiaReportResponseBody = {
  publishCebiaReport: boolean;
};
export type GetCebiaReportConfigApiResponse = /** status 200  */ PublishCebiaReportResponseBody;
export type GetCebiaReportConfigApiArg = {
  vehicleId: string;
};

export type DisablePublishCebiaSmartCodeUrlApiResponse = unknown;
export type DisablePublishCebiaSmartCodeUrlApiArg = {
  vehicleId: string;
};

export type EnablePublishCebiaSmartCodeUrlApiResponse = unknown;
export type EnablePublishCebiaSmartCodeUrlApiArg = {
  vehicleId: string;
};

export type PublishCebiaSmartCodeUrlResponseBody = {
  publishCebiaSmartCodeUrl: boolean;
};
export type GetCebiaSmartCodeUrlConfigApiResponse =
  /** status 200  */ PublishCebiaSmartCodeUrlResponseBody;
export type GetCebiaSmartCodeUrlConfigApiArg = {
  vehicleId: string;
};

export type GetGenerationsApiResponse = /** status 200  */ GenerationResponseBody[];
export type GetGenerationsApiArg = {
  vehicleId: string;
};
export type GetVersionsApiResponse = /** status 200  */ VersionResponseBody[];
export type GetVersionsApiArg = {
  vehicleId: string;
};
export type GenerationResponseBody = {
  code: string;
  name: string;
};
export type VersionResponseBody = {
  code: string;
  name: string;
};

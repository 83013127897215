import {z} from 'zod';

export const PutServiceCaseActionApiResponseSchema = z.unknown();

export type PutServiceCaseActionApiResponse = z.infer<typeof PutServiceCaseActionApiResponseSchema>;

export const PutServiceCaseActionApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: z
    .object({
      actionKey: z.enum(['cancel', 'reOpen']).nullable(),
    })
    .nullable(),
});

export type PutServiceCaseActionApiArg = z.infer<typeof PutServiceCaseActionApiArgSchema>;

export const GetServiceCaseActionsApiResponseSchema = z
  .object({
    caseActions: z
      .object({
        optionClick: z.string().nullable().optional(),
        options: z
          .array(
            z
              .object({
                type: z.enum(['list-item', 'divider']).nullable(),
                key: z.enum(['cancel', 'reOpen']).nullable(),
                style: z.enum(['hidden', 'disabled', 'enable']).nullable(),
                position: z.number().nullable(),
              })
              .nullable()
          )
          .optional(),
      })
      .nullable(),
  })
  .nullable();

export type GetServiceCaseActionsApiResponse = z.infer<
  typeof GetServiceCaseActionsApiResponseSchema
>;

export const GetServiceCaseActionsApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceCaseActionsApiArg = z.infer<typeof GetServiceCaseActionsApiArgSchema>;

import {AnimatePresence, Variants, motion} from 'framer-motion';

import {ReactNode, useEffect, useState} from 'react';

interface CollapseProps {
  isOpen: boolean;
  children: ReactNode;
}

export function Collapse(props: CollapseProps) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsMounted(true);
    });
    return () => clearTimeout(timeout);
  }, []);

  const custom = {
    startingHeight: 0,
    endingHeight: 'auto',
    animateOpacity: true,
    transition: !isMounted ? {enter: {duration: 0}} : undefined,
    transitionEnd: {
      enter: {overflow: 'initial'},
      exit: {
        display: 'none',
      },
    },
  };

  return (
    <AnimatePresence initial={false} custom={custom}>
      <motion.div
        style={motionStyle}
        variants={variants}
        animate={props.isOpen ? 'enter' : 'exit'}
        custom={custom}
        initial={false}
        exit="exit"
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
}

const variants: Variants = {
  exit: ({startingHeight, transitionEnd}) => ({
    overflow: 'hidden',
    height: startingHeight,
    transitionEnd: transitionEnd?.exit,
    transition: defaultTransitions.exit,
  }),
  enter: ({endingHeight, transitionEnd}) => ({
    height: endingHeight,
    transitionEnd: transitionEnd?.enter,
    transition: defaultTransitions.enter,
  }),
};

const motionStyle = {
  overflow: 'hidden',
  display: 'block',
};

const ease = [0.25, 0.1, 0.25, 1];
const defaultTransitions = {
  exit: {
    height: {duration: 0.2, ease},
    opacity: {duration: 0.3, ease},
  },
  enter: {
    height: {duration: 0.3, ease},
    opacity: {duration: 0.4, ease},
  },
};

import {z} from 'zod';

/*
 *  Base
 *
 * */
export const AbortSignalSchema = z.custom<AbortSignal>((value) => value instanceof AbortSignal);

// TODO: Migrate to CreatedAndUpdatedAtBy => it's used on all newer endpoints

export const CreatedAndUpdatedBySchema = z.object({
  created: z.string(),
  createdBy: z.string(),
  updated: z.string().optional(),
  updatedBy: z.string().optional(),
});

export type CreatedAndUpdatedBy = z.infer<typeof CreatedAndUpdatedBySchema>;

export const CreatedAndUpdatedAtBySchema = z.object({
  createdAt: z.string(),
  createdBy: z.string(),
  updatedAt: z.string(),
  updatedBy: z.string(),
});

export type CreatedAndUpdatedAtBy = z.infer<typeof CreatedAndUpdatedAtBySchema>;

export const BaseIdSchema = z.object({
  id: z.string(),
});

export type BaseId = z.infer<typeof BaseIdSchema>;

export const BaseVoidRequestSchema = z.void();

export type BaseVoidRequest = z.infer<typeof BaseVoidRequestSchema>;

// Metada sends null instead of void by default
export const BaseVoidResponseSchema = z.null();

export type BaseVoidResponse = z.infer<typeof BaseVoidResponseSchema>;

export const OptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const PaymentTypeSchema = z.enum([
  'bank_transfer',
  'cash',
  'payment_card',
  'free_of_charge',
]);

export type PaymentType = z.infer<typeof PaymentTypeSchema>;

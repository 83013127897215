import {isNil} from 'ramda';

import {ColumnApi} from '../types/AgGridTypes';
import {ColumnsSetting} from '../types/Api';

export const getPresetStates = (colApi: ColumnApi): ColumnsSetting[] =>
  (colApi.getColumnState() ?? []).map((state) => {
    if (isNil(state.hide)) {
      throw Error('Hide prop from column api is undefined');
    }

    if (isNil(state.width)) {
      throw Error('Width prop from column api is undefined');
    }

    let pinned: ColumnsSetting['pinned'] = null;
    if (state.pinned === 'left') {
      pinned = 'LEFT';
    }
    if (state.pinned === 'right') {
      pinned = 'RIGHT';
    }

    return {
      columnKey: state.colId,
      visible: !state.hide,
      width: state.width,
      pinned,
    };
  });

import {OptionType} from 'platform/components';

import {GetServiceOrderVariantPaymentTypeApiResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {buildArray} from 'shared';

export const getOrderPaymentOptions = (
  orderVariantPayment?: GetServiceOrderVariantPaymentTypeApiResponse
) =>
  buildArray<OptionType>()
    .when(orderVariantPayment?.bankPayment?.isAllowed ?? true, {
      label: i18n.t('entity.invoice.paymentMethod.bankTransfer'),
      value: 'BANK_TRANSFER',
    })
    .when(orderVariantPayment?.cardPayment?.isAllowed ?? true, {
      label: i18n.t('entity.invoice.paymentMethod.card'),
      value: 'CARD',
    })
    .when(orderVariantPayment?.cashPayment?.isAllowed ?? true, {
      label: i18n.t('entity.invoice.paymentMethod.cash'),
      value: 'CASH',
    });

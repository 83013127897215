import {useSearchParams} from 'react-router-dom';

import {ServiceVehicleFormTabs} from '../types/ServiceVehicleFormTabs';

const QUERY_TAB_KEY = 'tab';

export function useTabs() {
  const [queryParams, setQueryParams] = useSearchParams();
  const query = new URLSearchParams(queryParams);

  const selectedTab = query.get(QUERY_TAB_KEY) || ServiceVehicleFormTabs.BasicInformation;

  const setSelectedTab = (newTab: ServiceVehicleFormTabs) => {
    setQueryParams({[QUERY_TAB_KEY]: newTab}, {replace: true});
  };

  return {selectedTab, setSelectedTab};
}

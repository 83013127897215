import {motion} from 'framer-motion';
import styled from 'styled-components';

const WIDTH = 18;
const HEIGHT = 10;
const UNIT_HEIGHT = 4;
const UNIT_WIDTH = (UNIT_HEIGHT * WIDTH) / HEIGHT;
const VARIANT = {
  TOP: {closed: {rotate: 0, translateY: 0}, opened: {rotate: 45, translateY: 2}},
  CENTER: {closed: {opacity: 1}, opened: {opacity: 0}},
  BOTTOM: {closed: {rotate: 0, translateY: 0}, opened: {rotate: -45, translateY: -2}},
};

interface SidebarMenuTriggerProps {
  isOpen: boolean;
}

export function SidebarToggleMenuIcon({isOpen}: SidebarMenuTriggerProps) {
  const lineProps = {
    initial: 'closed',
    animate: isOpen ? 'opened' : 'closed',
    transition: {ease: 'easeOut', duration: 0.2},
  };

  return (
    <motion.svg
      viewBox={`0 0 ${UNIT_WIDTH} ${UNIT_HEIGHT}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={18}
      height={10}
    >
      <Line x1="0" x2={UNIT_WIDTH} y1="0" y2="0" variants={VARIANT.TOP} {...lineProps} />
      <Line x1="0" x2={UNIT_WIDTH} y1="2" y2="2" variants={VARIANT.CENTER} {...lineProps} />
      <Line x1="0" x2={UNIT_WIDTH} y1="4" y2="4" variants={VARIANT.BOTTOM} {...lineProps} />
    </motion.svg>
  );
}

const Line = styled(motion.line)`
  vector-effect: non-scaling-stroke;
  stroke: ${(props) => props.theme.colors.general.white};
  stroke-width: 2;
`;

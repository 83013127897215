import {Dropdown, IconButton, Tooltip} from 'platform/components';
import {Icon, Spinner} from 'platform/foundation';

import {FC, useEffect, useMemo, useState} from 'react';

import {Box} from '@chakra-ui/react';

import {suffixTestId, TestIdProps} from 'shared';

import {Preset} from '../../types/Api';
import {PresetButtonActions} from '../../types/PresetButtonActions';
import {PresetApiStateEnum} from './PresetApiStateEnum';

export interface PresetButtonProps extends TestIdProps {
  preset: Preset;
  active: boolean;
  unsaved: boolean;
  state: PresetApiStateEnum;
  rowCount: number | undefined;
  actions?: PresetButtonActions;
  onClick: () => void;
}

export const PresetButton: FC<PresetButtonProps> = (props) => {
  const {rowCount, unsaved, state, preset, onClick, actions, active} = props;
  const [hover, setHover] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [animationState, setAnimationState] = useState<'open' | 'close'>('close');

  const wrapperTheme = {
    overflow: 'hidden',
    position: 'relative',
    display: 'inline-flex',
    height: '32px',
    borderRadius: '32px',
    lineHeight: '32px',
    backgroundColor: 'gray.100',
    _hover: {
      backgroundColor: 'gray.200',
    },
    cursor: 'pointer',
    userSelect: 'none',
    maxWidth: '200px',
  };
  const buttonTheme = {
    padding: '0 12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: '32px',
    maxWidth: '200px',
  };
  const activeButtonTheme = {
    color: 'palettes.white.10.100',
  };
  const activeWrapperTheme = {
    backgroundColor: 'general.accent',
    _hover: {
      backgroundColor: 'palettes.blue.70.100',
    },
  };
  const iconTheme = {
    left: '0',
    position: 'absolute',
    transform: 'translate(-24px, -50%)',
    transition: '.65s cubic-bezier(0.4, 0.14, 0.3, 1)',
    // transitionDelay: "0.3s",
    transitionProperty: 'transform',
    top: '50%',
    width: '16px',
    height: '16px',
    paddingRight: '6px',
    boxSizing: 'content-box',
    paddingLeft: 0,
    backgroundColor: 'gray.100',
    _groupHover: {
      backgroundColor: 'gray.200',
    },
    '&>*': {
      display: 'flex',
    },
  };
  const activeIconTheme = {
    backgroundColor: 'general.accent',
    _groupHover: {
      backgroundColor: 'palettes.blue.70.100',
    },
  };
  const iconSlideTheme = {
    transform: 'translate(5px, -50%)',
  };
  const dropdownWrapperTheme = {
    position: 'absolute',
    right: '0',
    transform: 'translateX(24px)',
    transition: '.65s cubic-bezier(0.4, 0.14, 0.3, 1)',
    transitionDelay: '0.3s',
    transitionProperty: 'transform',
  };
  const dropdownWrapperSlideTheme = {
    transform: 'translateX(-5px)',
  };
  const successTheme = {
    color: 'severity.success',
  };
  const errorTheme = {
    color: 'severity.danger',
  };

  useEffect(() => {
    if (state !== PresetApiStateEnum.idle) {
      setAnimationState('open');
    }
    if (state !== PresetApiStateEnum.loading) {
      setTimeout(() => setAnimationState('close'), 800);
    }
  }, [state]);

  const [icon, color] = useMemo(() => {
    let icon;
    let color;
    if (state === PresetApiStateEnum.loading) {
      icon = <Spinner size="small" />;
    }
    if (state === PresetApiStateEnum.success) {
      icon = <Icon value="action/check_circle" />;
      color = successTheme;
    }
    if (state === PresetApiStateEnum.error) {
      icon = <Icon value="alert/error" />;
      color = errorTheme;
    }
    return [icon, color];
  }, [state, successTheme, errorTheme]);

  return (
    <Box
      data-testid={props['data-testid']}
      sx={{
        ...wrapperTheme,
        ...(active ? activeWrapperTheme : {}),
      }}
      role="group"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box
        sx={{
          ...iconTheme,
          ...color,
          ...(active ? activeIconTheme : {}),
          ...(animationState === 'open' ? iconSlideTheme : {}),
        }}
      >
        {icon}
      </Box>

      <Tooltip
        placement="top"
        label={rowCount !== undefined ? `${preset.title} (${rowCount})` : preset.title}
        description={preset.description}
      >
        <Box
          onClick={onClick}
          sx={{
            ...buttonTheme,
            ...(active ? activeButtonTheme : {}),
          }}
        >
          {unsaved && '* '}
          {preset.title}
          {rowCount !== undefined ? ` (${rowCount})` : null}
        </Box>
      </Tooltip>

      {actions && (
        <Box
          sx={{
            ...dropdownWrapperTheme,
            marginTop: '4px',
            width: '24px',
            minWidth: '24px',
            padding: '0',
            height: '24px',
            background: 'white',
            cursor: 'pointer',
            borderRadius: '50%',
            overflow: 'hidden',
            ...(hover || dropdownOpen ? dropdownWrapperSlideTheme : {}),
          }}
        >
          <Dropdown
            isLazy
            strategy="fixed"
            data-testid={suffixTestId('preset-actions-dropdown', props)}
            dropdownControl={
              <IconButton
                size="small"
                icon="navigation/more_vert"
                data-testid={suffixTestId('actions', props)}
              />
            }
            onClose={() => setDropdownOpen(false)}
            onOpen={() => setDropdownOpen(true)}
          >
            {actions}
          </Dropdown>
        </Box>
      )}
    </Box>
  );
};

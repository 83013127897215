import {showNotification} from 'platform/components';

import {GridApi} from '../types/AgGridTypes';

/**
 * Validates the keys of the external filter values to ensure that each key corresponds to a defined column in the Grid API.
 * If a key does not correspond to any defined column, an error notification is shown.
 */
export const validateExternalFiltersKeys = (
  newFilterValues: Record<string, unknown>,
  api: GridApi
) => {
  Object.keys(newFilterValues).forEach((key: string) => {
    const column = api.getColumn(key);
    if (column) {
      return;
    }

    return showNotification.error(`Column not defined ${key}`);
  });
};

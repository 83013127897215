import i18n from '@omnetic-dms/i18n';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {Main, PageTab, useGetComplexPermissions, usePermissions} from '@omnetic-dms/shared';

import {buildArray, composePath, useRequiredParams} from 'shared';

import {VehicleEarningsAndCosts} from './components/VehicleEarningsAndCosts';
import {VehiclePrices} from './components/VehiclePrices';

export function PricesAndFinances() {
  const {id} = useRequiredParams();

  const [canReadCosts, canReadEarnings] = usePermissions({
    permissionKeys: ['vehicleReadCosts', 'vehicleReadEarnings'],
  });

  const {canReadPurchasePrice, canReadSalePrice} = useGetComplexPermissions();

  return (
    <Main
      data-testid={testIds.vehicles.finances('page')}
      tabs={buildArray<PageTab>()
        .when(canReadPurchasePrice || canReadSalePrice, {
          id: 'prices',
          title: i18n.t('entity.vehicle.labels.carPrices'),
          href: composePath(vehiclesRoutes.financesPrices, {params: {id}}),
          content: <VehiclePrices />,
        })
        .when(canReadCosts || canReadEarnings, {
          id: 'earningsCosts',
          title: i18n.t('page.vehicle.labels.earningsCosts'),
          href: composePath(vehiclesRoutes.financesEarningsCosts, {params: {id}}),
          content: <VehicleEarningsAndCosts />,
        })}
    />
  );
}

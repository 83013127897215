import {Card, FormControl, Separator} from 'platform/components';
import {Box, Grid, GridItem, Show, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ServiceVehicleFormType} from '../../types/ServiceVehicleFormType';
import {ServiceVehicleFormVariant} from '../../types/ServiceVehicleFormVariant';
import {Additional} from './components/Additional';
import {Main} from './components/Main';

interface BasicInformationProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
  variant: ServiceVehicleFormVariant;
}

export function BasicInformation(props: BasicInformationProps) {
  return (
    <>
      <Show when={props.variant === ServiceVehicleFormVariant.SIMPLE}>
        <VStack spacing={4}>
          <Main
            control={props.control}
            formApi={props.formApi}
            data-testid={suffixTestId('main', props)}
          />
          <Separator spacing={0} />
          <Additional control={props.control} data-testid={suffixTestId('additional', props)} />
        </VStack>
      </Show>
      <Show when={props.variant === ServiceVehicleFormVariant.EXTENDED}>
        <Box padding={4}>
          <Grid columns={3}>
            <GridItem span={2}>
              <VStack spacing={4}>
                <Card>
                  <Main
                    control={props.control}
                    formApi={props.formApi}
                    data-testid={suffixTestId('main', props)}
                  />
                </Card>
                <Card title={i18n.t('general.labels.additionalInformation')}>
                  <Additional
                    control={props.control}
                    data-testid={suffixTestId('additional', props)}
                  />
                </Card>
              </VStack>
            </GridItem>
          </Grid>
        </Box>
      </Show>
    </>
  );
}

import {DataStatus, FormSubmitHandler, showNotification} from 'platform/components';
import {Show} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {
  useGetMercedesNaSkladePlatformQuery,
  useMercedesNaSkladeAuthenticateMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import {handleApiError, queryParams} from '@omnetic-dms/shared';
import {AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

import {useQueryState} from 'shared';

import {
  MercedesNaSkladeAuthenticationFormData,
  PlatformAuthenticationFormMercedesNaSklade,
} from './components/PlatformAuthenticationFormMercedesNaSklade';
import {PlatformPhotoSettings} from './components/PlatformPhotoSettings';

export function PlatformSettingsMercedesNaSklade() {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const params = isNotNil(branchId) ? {branchId} : undefined;
  const {data, isLoading, isError} = useGetMercedesNaSkladePlatformQuery({params});
  const [saveAuthentication] = useMercedesNaSkladeAuthenticateMutation();

  const onSubmitCredentials: FormSubmitHandler<MercedesNaSkladeAuthenticationFormData> = (values) =>
    saveAuthentication({
      body: values,
      params,
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_GENERAL_SETTINGS}>
      <DataStatus isLoading={isLoading} isError={isError}>
        {isNotNil(data) && (
          <PlatformAuthenticationFormMercedesNaSklade
            defaultValues={data.credentials}
            onSubmit={onSubmitCredentials}
          />
        )}
        <PlatformPhotoSettings platformCode={AdvertisingPlatformCodeEnum.MERCEDES_NA_SKLADE} />
      </DataStatus>
    </Show>
  );
}

import {Card, DataStatus, Table, TableRow} from 'platform/components';
import {Box, Show, Text, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {useSelector} from 'react-redux';

import {always, defaultTo, filter, head, map, pipe, sort} from 'ramda';

import {
  AdvertisementStatusEnum,
  EntityResourceIds,
  SalesPlatformsResBody,
  useGetGeneralSettingsQuery,
  useGetParticipationQuery,
  useGetSalesPlatformsQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  EMPTY_PLACEHOLDER,
  fakePlatforms,
  platformImageURLs,
  useIsActiveBrowserTab,
  usePermissions,
} from '@omnetic-dms/shared';
import {selectSalesVehicleData} from '@omnetic-dms/teas';

import {parseDate} from 'shared';

import {AdditionalInfoStatus} from './AdditionalInfoStatus';
import {PlatformActions} from './PlatformActions';
import {PlatformDate} from './PlatformDate';
import {PlatformLogo} from './PlatformLogo';
import {PlatformPrice} from './PlatformPrice';
import {PlatformSwitcher} from './PlatformSwitcher';
import {PlatformUpdatedBy} from './PlatformUpdatedBy';
import {StatusItem} from './StatusItem';

const EMPTY_COLUMN_HEADER = null;
const POLLING_INTERVAL = 2000;

const COLUMN_TITLES = [
  EMPTY_COLUMN_HEADER,
  i18n.t('page.advertisement.labels.platformName'),
  i18n.t('page.advertisement.labels.status'),
  i18n.t('page.advertisement.labels.advertisedPrice'),
  i18n.t('page.advertisement.labels.data'),
  i18n.t('page.advertisement.labels.publishingBranch'),
  i18n.t('page.advertisement.labels.updatedBy'),
  i18n.t('page.advertisement.labels.updated'),
  EMPTY_COLUMN_HEADER,
] as const;

interface AdPlatformProps {
  vehicleId: string;
  isPoolingEnabled: boolean;
}

export function AdPlatforms(props: AdPlatformProps) {
  const {data: generalSettings} = useGetGeneralSettingsQuery({vehicleId: props.vehicleId});
  const inSale = !!generalSettings?.inSale;

  const vehicleData = useSelector(selectSalesVehicleData);

  const {data: vehicleParticipation} = useGetParticipationQuery({
    resourceId: EntityResourceIds.vehicle,
    recordId: props.vehicleId,
  });

  const [hasEnablePlatformForVehiclePermission, hasDisablePlatformForVehiclePermission] =
    usePermissions({
      permissionKeys: ['enablePlatformForVehicle', 'disablePlatformForVehicle'],
      scopes: {
        enablePlatformForVehicle: vehicleParticipation,
        disablePlatformForVehicle: vehicleParticipation,
      },
    });

  const isDisabled =
    !hasEnablePlatformForVehiclePermission || !hasDisablePlatformForVehiclePermission;

  const isActiveBrowserTab = useIsActiveBrowserTab();

  const {data: platforms, isLoading} = useGetSalesPlatformsQuery(
    {vehicleId: props.vehicleId},
    {pollingInterval: props.isPoolingEnabled && isActiveBrowserTab ? POLLING_INTERVAL : undefined}
  );

  const triggeredUpdates = platforms?.map((platform) => platform.statusTriggeredAt) ?? [];
  const latestUpdate =
    head(
      sort(
        (a, b) => (parseDate(b)?.getTime() ?? 0) - (parseDate(a)?.getTime() ?? 0),
        triggeredUpdates
      )
    ) ?? '';

  const items = pipe(
    filter<SalesPlatformsResBody>(({platform, status}) =>
      match([platform.enabled, status])
        .with(
          [true, Pattern.any],
          [Pattern.any, Pattern.not(AdvertisementStatusEnum.NOT_PUBLISHED)],
          always(true)
        )
        .otherwise(always(false))
    ),
    map((platformData) => {
      const code = platformData.platform.code;
      const platformTitle = platformData.platform.title;
      const status = platformData.status;

      return (
        <TableRow key={code} data-testid={testIds.vehicles.publishing(`platform-${code}`)}>
          <Box paddingHorizontal={2}>
            <PlatformSwitcher
              vehicleSaleState={vehicleData?.saleState ?? null}
              code={code}
              currentStatus={status}
              inSale={inSale}
              value={platformData.enabled}
              isDisabled={isDisabled}
            />
          </Box>
          <PlatformLogo src={platformImageURLs[code]} name={platformData.platform.title} />
          <StatusItem
            currentStatus={platformData.status}
            statusDescription={platformData.statusDescription}
            platformCode={code}
          />
          <PlatformPrice price={platformData.priceWithVat} />
          <div>
            {platformData.hasOverrides
              ? i18n.t('general.labels.custom')
              : i18n.t('general.labels.default')}
          </div>
          <div>
            {platformData.status === AdvertisementStatusEnum.NOT_PUBLISHED
              ? EMPTY_PLACEHOLDER
              : (platformData.publishingBranchName ?? i18n.t('entity.tenant.labels.tenant'))}
          </div>
          <PlatformUpdatedBy text={platformData.statusTriggeredBy} />
          <PlatformDate platformCode={code} updatedAt={platformData.statusTriggeredAt} />
          <PlatformActions
            platformCode={code}
            vehicleId={props.vehicleId}
            currentStatus={status}
            platformTitle={platformTitle}
            inSale={inSale}
            statusTriggeredAt={platformData.statusTriggeredAt}
          />
        </TableRow>
      );
    })
  )(defaultTo([], platforms));

  // TODO T20-40939 remove
  const fakeItems = fakePlatforms.map(({code, title}) => {
    const status = AdvertisementStatusEnum.NOT_PUBLISHED;

    return (
      <TableRow key={code} data-testid={testIds.vehicles.publishing(`platform-${code}`)}>
        <Box paddingHorizontal={2}>
          <PlatformSwitcher
            vehicleSaleState={vehicleData?.saleState ?? null}
            // @ts-ignore Fake platform code
            code={code}
            currentStatus={status}
            inSale={inSale}
            value={false}
            isDisabled
          />
        </Box>
        <PlatformLogo src={platformImageURLs[code]} name={title} />
        <StatusItem
          currentStatus={AdvertisementStatusEnum.NOT_PUBLISHED}
          statusDescription={null}
          platformCode={code}
        />
        <PlatformPrice price={null} />
        <div>{i18n.t('general.labels.default')}</div>
        <div>{EMPTY_PLACEHOLDER}</div>
        <PlatformUpdatedBy />
        <PlatformDate platformCode={code} />
        <PlatformActions
          // @ts-ignore Fake platform code
          platformCode={code}
          vehicleId={props.vehicleId}
          currentStatus={status}
          platformTitle={title}
          inSale={inSale}
          statusTriggeredAt={null}
        />
      </TableRow>
    );
  });

  return (
    <Card>
      <DataStatus
        isLoading={isLoading}
        isEmpty={!items.length}
        emptyMessage={i18n.t('page.advertisement.labels.notAvailablePlatforms')}
        spacing={10}
      >
        <VStack spacing={4}>
          <AdditionalInfoStatus latestUpdate={latestUpdate} inAdvertisement={inSale} />
          <Box overflowX="auto">
            <Box minWidth={240}>
              <Table
                columns={COLUMN_TITLES.map((item) => ({
                  element: (
                    <Box paddingBottom={2}>
                      <Text key={item} size="xSmall" align="left">
                        {item}
                      </Text>
                    </Box>
                  ),
                }))}
                data-testid={testIds.vehicles.publishing(`platform-list`)}
              >
                {items}
                <Show whenFeatureEnabled={featureFlags.ADVERTISEMENT_FAKE_PLATFORMS}>
                  {fakeItems}
                </Show>
              </Table>
            </Box>
          </Box>
        </VStack>
      </DataStatus>
    </Card>
  );
}

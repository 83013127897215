import {createContext} from 'react';

import {noop} from '../../utils/someTeasUtils';
import {DragAndDropContextType} from './types';

export const DroppableDropContext = createContext<DragAndDropContextType>({
  globalDragging: false,
  isHovered: false,
  setActiveDroppable: noop,
  activeDroppable: null,
  providerId: null,
  draggedItem: {dragId: null},
  setDraggedItem: noop,
  selectedItems: [],
  multipleSelectionComponent: undefined,
  onDragStart: noop,
  onDragEnd: noop,
  register: noop,
  unregister: noop,
  setHoveredItem: noop,
  lastHoveredItem: {current: undefined},
});

import {FormControl, FormField, Separator} from 'platform/components';
import {Show, Space, Text, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import i18n from '@omnetic-dms/i18n';
import {testIds, settingsRoutes} from '@omnetic-dms/routes';

import {Nullish} from 'shared';

import {SettingsFooter} from '../../../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../../../components/SettingsSection/SettingsSection';
import {BEFORE_CLOSING, BEFORE_OPENING} from '../../conditions/mandatoryConditions';
import {FormType, MandatoryFieldType} from '../../types/ServiceOrderVariant';
import {getFieldsByOrderType} from '../../utils/getFieldsByOrderType';

interface GeneralTabProps {
  control: FormControl<FormType>;
  formApi: UseFormReturn<FormType>;
  selectedOrderTypeCode: string | Nullish;
}

export function MandatoryFieldsTab(props: GeneralTabProps) {
  const navigate = useNavigate();

  const handleCheckboxChange = (fieldType: MandatoryFieldType) => (value: boolean) => {
    if (!value) {
      return;
    }

    const conditionFieldValue = props.formApi.getValues(`mandatoryFieldConditions.${fieldType}`);

    if (isNilOrEmpty(conditionFieldValue)) {
      props.formApi.setValue(`mandatoryFieldConditions.${fieldType}`, [BEFORE_OPENING]);
    }
  };

  return (
    <>
      <SettingsSection>
        <VStack spacing={4}>
          <Text color="secondary" size="small">
            {i18n.t('entity.order.labels.mandatoryFieldsDescription')}
          </Text>
          {getFieldsByOrderType(props.selectedOrderTypeCode).map((fieldType) => (
            <VStack key={fieldType} spacing={4}>
              <FormField
                control={props.control}
                label={i18n.t(`entity.order.labels.mandatoryField-${fieldType}`)}
                name={`mandatoryFields.${fieldType}`}
                type="checkbox"
                onChange={handleCheckboxChange(fieldType)}
                data-testid={testIds.settings.serviceOrderVariantsDetail(`mandatory-${fieldType}`)}
              />
              <Show
                when={
                  props.formApi.watch(`mandatoryFields.${fieldType}`) &&
                  fieldType !== 'generatingOrderSheet'
                }
              >
                <FormField
                  control={props.control}
                  type="chips"
                  options={[
                    {label: i18n.t('entity.order.labels.beforeOpening'), value: BEFORE_OPENING},
                    {label: i18n.t('entity.order.labels.beforeClosing'), value: BEFORE_CLOSING},
                  ]}
                  name={`mandatoryFieldConditions.${fieldType}`}
                  data-testid={testIds.settings.serviceOrderVariantsDetail(
                    `mandatoryCondition-${fieldType}`
                  )}
                />
              </Show>
              <Show when={['generatingOrderSheet', 'totalPriceEstimated'].includes(fieldType)}>
                <Separator spacing={0} />
              </Show>
            </VStack>
          ))}
        </VStack>
      </SettingsSection>
      <Space vertical={20} />
      <SettingsFooter
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            title: i18n.t('general.actions.discardChanges'),
            onClick: () => navigate(settingsRoutes.serviceOrderVariants),
          },
          {
            type: 'form-button',
            control: props.control,
            buttonType: 'submit',
            variant: 'primary',
            title: i18n.t('general.actions.saveChanges'),
          },
        ]}
        data-testid={testIds.settings.serviceOrderVariantsDetail('footer')}
      />
    </>
  );
}

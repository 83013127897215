import {
  Button,
  ButtonGroup,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {
  useGetEmployeeMechanicsQuery,
  useGetServiceOrderQuery,
  useGetServiceOrderVariantQuery,
  usePutServiceOrderJobMechanicsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

interface AssignMechanicFormProps extends TestIdProps {
  onClose: () => void;
  selectedMechanics: string[];
  orderId: string;
  serviceJobId: string;
  serviceCaseId: string;
}

type FormType = {mechanics: string[]};

export function AssignMechanicForm(props: AssignMechanicFormProps) {
  const {data: order} = useGetServiceOrderQuery({
    serviceCaseId: props.serviceCaseId,
    serviceOrderId: props.orderId,
  });
  const {data: orderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: order?.serviceOrderVariantId ?? ''},
    {skip: isNil(order?.serviceOrderVariantId)}
  );
  const {data: employeeMechanics} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: orderVariant?.authorizationProfileId ?? ''},
    {skip: isNil(orderVariant?.authorizationProfileId)}
  );
  const [putServiceOrderJobMechanics] = usePutServiceOrderJobMechanicsMutation();

  const mechanicOptions = employeeMechanics?.employees?.map((mechanic) => ({
    label: mechanic?.name,
    value: mechanic?.id,
  }));

  const handleSubmit: FormSubmitHandler<FormType> = async ({mechanics}) => {
    await putServiceOrderJobMechanics({
      serviceCaseId: props.serviceCaseId,
      serviceOrderId: props.orderId,
      serviceJobId: props.serviceJobId,
      body: {
        assignMechanics: mechanics.map((mechanic, index) => ({
          id: mechanic,
          isDefault: index === 0,
          costCenterId: employeeMechanics?.employees?.find((employee) => employee?.id === mechanic)
            ?.costCenterId,
        })),
      },
    })
      .unwrap()
      .then(() => props.onClose())
      .catch(handleApiError);
  };

  return (
    <Form<FormType> onSubmit={handleSubmit} defaultValues={{mechanics: props.selectedMechanics}}>
      {(control) => (
        <VStack spacing={4}>
          <FormField
            control={control}
            name="mechanics"
            type="multiChoice"
            options={mechanicOptions}
            menuInPortal
            data-testid={suffixTestId('mechanics', props)}
            isNotClearable
          />
          <ButtonGroup align="right">
            <Button
              title={i18n.t('general.actions.discard')}
              onClick={props.onClose}
              variant="secondary"
              data-testid={suffixTestId('close', props)}
            />
            <FormButton
              control={control}
              type="submit"
              title={i18n.t('general.actions.save')}
              data-testid={suffixTestId('save', props)}
            />
          </ButtonGroup>
        </VStack>
      )}
    </Form>
  );
}

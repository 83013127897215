import {PlatformInformation, AdvertisingPlatformCodeEnum} from '@omnetic-dms/teas';

// TODO: Temporary hack to remap credentials fields
export const remapPlatform = (platform: PlatformInformation): PlatformInformation => {
  if (platform.code === AdvertisingPlatformCodeEnum.MOBILE_DE && platform.credentials) {
    return {
      ...(platform as any),
      credentials: {
        sellerId: (platform.credentials as any).sellerId, // FIXME: !!
        username: (platform.credentials as any).sellerUsername, // FIXME: !!
        password: (platform.credentials as any).sellerPassword, // FIXME: !!
      },
    } as PlatformInformation;
  }

  return platform;
};

import {
  CreateServiceVehicleApiRes,
  CreateServiceVehicleApiArg,
  LastActiveServiceCaseApiRes,
  LastActiveServiceCaseApiArg,
  GetServiceVehicleApiRes,
  GetServiceVehicleApiArg,
} from '../types/api';
import {metadaApi} from './baseApi/metadaApi';

const metadaServiceVehicleApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    createServiceVehicle: build.mutation<CreateServiceVehicleApiRes, CreateServiceVehicleApiArg>({
      query: (body) => ({
        url: `/service/v1/service-vehicle`,
        method: 'POST',
        body,
      }),
    }),
    getServiceVehicle: build.query<GetServiceVehicleApiRes, GetServiceVehicleApiArg>({
      query: (params) => ({
        url: `/service/v1/service-vehicle`,
        params,
      }),
    }),
    getLastActiveServiceCase: build.query<LastActiveServiceCaseApiRes, LastActiveServiceCaseApiArg>(
      {
        query: ({vehicleId}) => ({
          url: `/service/v1/service-case/by-vehicle/${vehicleId}/last-active`,
        }),
      }
    ),
  }),
});

export const {
  useCreateServiceVehicleMutation,
  useGetLastActiveServiceCaseQuery,
  useGetServiceVehicleQuery,
} = metadaServiceVehicleApi;

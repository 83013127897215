import {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {useSelector} from 'react-redux';

import i18n from '@omnetic-dms/i18n';
import {useFormRenderer, selectVehicleMakeModel, createVehicleTitle} from '@omnetic-dms/teas';

import {VehicleCreateFormState} from './types';

export const VehicleTitleField: FC<PropsWithChildren<any>> = () => {
  const [isMounted, setIsMounted] = useState(false);
  const {Field} = useFormRenderer<VehicleCreateFormState>();
  const {mutators} = useForm<VehicleCreateFormState>();
  const {values} = useFormState<VehicleCreateFormState>({subscription: {values: true}});

  const makeModels = useSelector(selectVehicleMakeModel(values?.make));
  const vehicleTitle = createVehicleTitle(values, makeModels);

  useEffect(() => {
    isMounted && mutators.setVehicleTitle(vehicleTitle);

    setIsMounted(true);
  }, [vehicleTitle]);

  return <Field name="title" label={i18n.t('entity.vehicle.labels.title')} />;
};

import {ButtonProps, showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {equals, isNotNil, not} from 'ramda';
import {isArray, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  DeletePartsRequestReturnItemsRequest,
  PartsRequestReturnBasketItem,
  PostPartsRequestReturnItemRequest,
  RequestItem,
  useDeletePartsRequestReturnBasketMutation,
  useDeletePartsRequestReturnItemsMutation,
  useGetPartsRequestReturnBasketQuery,
  usePatchPartsRequestReturnBasketItemQuantityMutation,
  usePostPartsRequestReturnBasketCheckoutMutation,
  usePostPartsRequestReturnItemMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  AfterSalesMaterialReturnBasket,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  useInvalidBasketItemsIds,
  useBasketMechanic,
} from '@omnetic-dms/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useRefreshDataGrid} from 'features/datagrid';

import {DirectSaleMaterialReturnList} from './DirectSaleMaterialReturnList';

interface DirectSaleMaterialReturnModalProps extends RequiredTestIdProps {
  directSaleId: string;
  authorizationProfileId: string | Nullish;
  onClose: VoidFunction;
}

export function DirectSaleMaterialReturnModal(props: DirectSaleMaterialReturnModalProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: hasBasketError,
  } = useGetPartsRequestReturnBasketQuery({originEntityHeaderId: props.directSaleId});

  const [checkoutBasket, {isLoading: isCheckingOutBasket}] =
    usePostPartsRequestReturnBasketCheckoutMutation();

  const [discardBasket, {isLoading: isDiscardingBasket}] =
    useDeletePartsRequestReturnBasketMutation();

  const [addItemsToBasket] = usePostPartsRequestReturnItemMutation();

  const [patchBasketItemQuantity] = usePatchPartsRequestReturnBasketItemQuantityMutation();

  const [deleteBasketItems, {isLoading: isDeletingBasketItems}] =
    useDeletePartsRequestReturnItemsMutation();

  const hasEmptyBasket = isNilOrEmpty(basket?.requestReturnBasketItem);
  const basketItems = basket?.requestReturnBasketItem ?? [];
  const basketTotalPrice = basket?.requestReturnBasketTotalPrice;
  const basketMechanicId = basket?.assignMechanicId;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const {mechanics, areMechanicsLoading, mechanicPayload, inputMechanicId, handleMechanicChange} =
    useBasketMechanic({
      authorizationProfileId: props.authorizationProfileId,
      assignedMechanicId: basketMechanicId,
    });

  const handleComplete = async () => {
    if (isNotNil(mechanicPayload)) {
      return await checkoutBasket({
        originEntityHeaderId: props.directSaleId,
        body: {
          assignMechanic: mechanicPayload,
        },
      })
        .unwrap()
        .then(() =>
          showNotification.success(i18n.t('entity.warehouse.notifications.returnCompleted'))
        )
        .then(props.onClose)
        .catch(handleApiError);
    }

    await checkoutBasket({
      originEntityHeaderId: props.directSaleId,
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.materialAdded')))
      .then(props.onClose)
      .catch(handleApiError);
  };

  const handleDiscard = async () => {
    await discardBasket({
      originEntityHeaderId: props.directSaleId,
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);
  };

  const handleAddToBasket = async (selectedItems: RequestItem[]) => {
    const requestBody: PostPartsRequestReturnItemRequest['body'] = {
      originEntityHeaderId: props.directSaleId,
      originEntityType: 'wrh_sale_item',
      requestItems: selectedItems,
    };

    await addItemsToBasket({
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchBasketItemQuantity({
      originEntityHeaderId: props.directSaleId,
      requestItemId: itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody: DeletePartsRequestReturnItemsRequest['body'] = {
      requestItemId: ids,
    };

    await deleteBasketItems({
      originEntityHeaderId: props.directSaleId,
      body: requestBody,
    })
      .unwrap()
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const actions: ButtonProps[] = [
    {
      title: i18n.t('general.actions.discard'),
      variant: 'secondary',
      onClick: handleDiscard,
      isLoading: isDiscardingBasket,
      'data-testid': suffixTestId('headerActions.discard', props),
    },
    {
      title: i18n.t('general.actions.complete'),
      variant: 'primary',
      onClick: handleComplete,
      isLoading: isCheckingOutBasket,
      isDisabled: hasEmptyBasket || isNotNilOrEmpty(invalidBasketItemsIds),
      'data-testid': suffixTestId('headerActions.complete', props),
    },
  ];

  return (
    <FullScreenModal headline={i18n.t('general.actions.returnMaterial')} actions={actions}>
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <DirectSaleMaterialReturnList
              directSaleId={props.directSaleId}
              dataGridRef={dataGridRef}
              onAddToBasket={handleAddToBasket}
              data-testid={suffixTestId('list', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesMaterialReturnBasket<PartsRequestReturnBasketItem>
              basket={{
                items: basketItems,
                totalPrice: basketTotalPrice,
                isLoading: isBasketLoading,
                hasError: hasBasketError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingBasketItems,
              }}
              mechanic={{
                mechanics,
                areMechanicsLoading,
                selectedMechanicId: inputMechanicId,
                onMechanicChange: handleMechanicChange,
              }}
              onQuantityChange={handleQuantityChange}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}

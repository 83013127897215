import {Action, Card, useDialog} from 'platform/components';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {Section, UploadDocumentsDialog, useGetDocumentDatagridActions} from '@omnetic-dms/shared';

import {TestIdProps, buildArray, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface DocumentsProps extends TestIdProps {}

export function Documents(props: DocumentsProps) {
  const {receiveNoteId} = useWarehouseParams();

  const [rowActions] = useGetDocumentDatagridActions();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {warehouseReceiptNoteId: receiveNoteId}]),
    [receiveNoteId]
  );

  const mainActions = buildArray<Action>().add({
    title: i18n.t('entity.warehouse.actions.addDocument'),
    type: 'button',
    leftIcon: 'content/add_circle',
    variant: 'link',
    onClick: openUploadDialog,
    'data-testid': suffixTestId('openAddDocument-button', props),
  });

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <Card title={i18n.t('page.warehouse.labels.documents')} actions={mainActions}>
        <DataGrid
          gridCode="document-context-list-warehouse-receipt-note"
          ref={dataGridRef}
          emptyState={{headline: i18n.t('general.labels.noData')}}
          actionCallback={rowActions}
          queryModifier={queryModifier}
          autoHeight
          data-testid={suffixTestId('documents', props)}
        />
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={refreshDataGrid}
        contextTarget="warehouse-receipt-note"
        contextId={receiveNoteId}
        bulkContext={[{target: 'warehouse-receipt-note', targetId: receiveNoteId}]}
        data-testid={suffixTestId('upload-document', props)}
      />
    </Section>
  );
}

import {Grid, Show} from 'platform/foundation';
import {css} from 'styled-components';

import {FC, PropsWithChildren} from 'react';

import i18n from '@omnetic-dms/i18n';

import {useGetVehicleEnums} from '../../../hooks/useGetVehicleEnums';
import {VehicleType} from '../../../types/VehicleType';
import {useFormRenderer} from '../../FinalForm/hooks/useFormRenderer';
import {VehicleCreateFormState} from '../types/VehicleCreateFormState';

export const Dimensions: FC<PropsWithChildren<any>> = () => {
  const {Field, Subscribe, form} = useFormRenderer<VehicleCreateFormState>();

  const {dimensions_height, dimensions_length} = useGetVehicleEnums(
    form.getFieldState('type')?.value
  );

  const dimensionsHeightOptions = Object.values(dimensions_height ?? {});
  const dimensionsLengthOptions = Object.values(dimensions_length ?? {});

  return (
    <div
      css={css`
        margin-bottom: -16px;
      `}
    >
      <Subscribe<VehicleType>
        name="type"
        component={({input: {value: vehicleType}}) => (
          <Show when={vehicleType === 'VEHICLETYPE_VAN' || vehicleType === 'VEHICLETYPE_TRUCK'}>
            <Grid columns={4}>
              <Field
                as="select"
                name="dimensions.lengthCategory"
                label={i18n.t('entity.vehicle.labels.vehicleLength')}
                options={dimensionsLengthOptions}
              />
              <Field
                as="select"
                name="dimensions.heightCategory"
                label={i18n.t('entity.vehicle.labels.vehicleHeight')}
                options={dimensionsHeightOptions}
              />
            </Grid>
          </Show>
        )}
      />
      <Grid columns={4}>
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.dimensions.length"
          label={i18n.t('general.labels.length')}
          suffix={i18n.t('general.metric.mm')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.dimensions.width"
          label={i18n.t('general.labels.width')}
          suffix={i18n.t('general.metric.mm')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.dimensions.height"
          label={i18n.t('general.labels.height')}
          suffix={i18n.t('general.metric.mm')}
        />
      </Grid>
      <Subscribe<VehicleType>
        name="type"
        component={({input: {value: vehicleType}}) => (
          <Show when={vehicleType === 'VEHICLETYPE_VAN' || vehicleType === 'VEHICLETYPE_TRUCK'}>
            <Grid columns={4}>
              <Field
                as="number"
                name="dimensions.dimensionsLoadingSpace.length"
                label={i18n.t('entity.vehicle.labels.loadSpaceLength')}
                suffix={i18n.t('general.metric.m')}
              />
              <Field
                as="number"
                name="dimensions.dimensionsLoadingSpace.width"
                label={i18n.t('entity.vehicle.labels.loadSpaceWidth')}
                suffix={i18n.t('general.metric.m')}
              />
              <Field
                as="number"
                name="dimensions.dimensionsLoadingSpace.height"
                label={i18n.t('entity.vehicle.labels.loadSpaceHeight')}
                suffix={i18n.t('general.metric.m')}
              />
              <Field
                as="number"
                name="dimensions.loadingArea"
                label={i18n.t('entity.vehicle.labels.loadArea')}
                suffix={i18n.t('general.metric.m2')}
              />
            </Grid>
          </Show>
        )}
      />
      <Grid columns={4}>
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.operatingWeight"
          label={i18n.t('entity.vehicle.labels.operatingWeight')}
          suffix={i18n.t('general.metric.kg')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.weight"
          label={i18n.t('entity.vehicle.labels.maxWeight')}
          suffix={i18n.t('general.metric.kg')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.maximalLoadCapacity"
          label={i18n.t('entity.vehicle.labels.maxLoadCapacity')}
          suffix={i18n.t('general.metric.kg')}
        />
      </Grid>
      <Grid columns={4}>
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.trunkSpace"
          label={i18n.t('entity.vehicle.labels.trunkSpace')}
          suffix={i18n.t('general.metric.l')}
        />
        <Field
          as="integer"
          maxLength={19}
          name="dimensions.wheelBase"
          label={i18n.t('entity.vehicle.labels.wheelBase')}
          suffix={i18n.t('general.metric.mm')}
        />
      </Grid>
    </div>
  );
};

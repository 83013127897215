import {
  Button,
  ButtonGroup,
  Dialog,
  DialogProps,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {HStack, Space, Box} from 'platform/foundation';
import {object, string} from 'yup';

import {DefaultValues} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {yupNumber} from 'shared';

export type CebiaReportFormType = {
  registrationYear?: string | null;
  manufactureYear?: string | null;
  mileage?: number | null;
};

interface ComponentProps extends Omit<DialogProps, 'children'> {
  onSubmit: FormSubmitHandler<CebiaReportFormType>;
  defaultValues: DefaultValues<CebiaReportFormType>;
}

export function CebiaReportDialog({onSubmit, defaultValues, ...props}: ComponentProps) {
  return (
    <Dialog {...props} title={i18n.t('page.integrations.labels.cebiaReportTitle')}>
      <Form<CebiaReportFormType> defaultValues={defaultValues} schema={schema} onSubmit={onSubmit}>
        {(control, formApi) => (
          <>
            <HStack spacing={3}>
              <Box flex={1}>
                <FormField
                  type="text"
                  name="registrationYear"
                  label={i18n.t('entity.vehicle.labels.firstRegistrationYear')}
                  control={control}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  type="text"
                  name="manufactureYear"
                  label={i18n.t('entity.vehicle.labels.manufactureYear')}
                  control={control}
                />
              </Box>
              <Box flex={1}>
                <FormField
                  type="number"
                  name="mileage"
                  label={i18n.t('entity.vehicle.labels.mileage')}
                  control={control}
                />
              </Box>
            </HStack>
            <Space vertical={4} />
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onClose}
                title={i18n.t('general.actions.cancel')}
                isDisabled={formApi.formState.isSubmitting}
              />
              <FormButton
                control={control}
                type="submit"
                variant="primary"
                title={i18n.t('general.actions.confirm')}
              />
            </ButtonGroup>
          </>
        )}
      </Form>
    </Dialog>
  );
}

const schema = object({
  registrationYear: string()
    .nullable()
    .matches(/^\d*$/, i18n.t('general.notifications.numberValidationMessage'))
    .required(),
  manufactureYear: string()
    .nullable()
    .matches(/^\d*$/, i18n.t('general.notifications.numberValidationMessage'))
    .required(),
  mileage: yupNumber.required(),
});

import {
  closeCurrentDialog,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {match} from 'ts-pattern';

import {forwardRef, useImperativeHandle} from 'react';

import {isArray} from 'ramda-adjunct';

import {
  BulkPatchDeliveryNoteRequest,
  useBulkDeleteDeliveryNoteMutation,
  useBulkPatchDeliveryNoteMutation,
  useBulkPostReceiveNoteMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {catchUnhandledDataGridActions, handleApiError} from '@omnetic-dms/shared';

import {composePath, useNavigate} from 'shared';

import {ActionCallback, DataGrid, useRefreshDataGrid} from 'features/datagrid';

import {FullscreenDataGridWrapper} from '../../components/FullscreenDataGridWrapper';
import {DeliveryNoteListBulkEdit} from './components/DeliveryNoteListBulkEdit';

export type DeliveryNoteListHandle = {
  refreshDataGrid: () => void;
};

export const DeliveryNoteList = forwardRef<DeliveryNoteListHandle>((_, ref) => {
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [deleteDeliveryNote] = useBulkDeleteDeliveryNoteMutation();
  const [postReceiveNote] = useBulkPostReceiveNoteMutation();
  const [bulkPatchDeliveryNote] = useBulkPatchDeliveryNoteMutation();

  useImperativeHandle(
    ref,
    () => ({
      refreshDataGrid,
    }),
    []
  );

  const handleBulkEditDeliveryNote = async (requestBody: BulkPatchDeliveryNoteRequest) => {
    await bulkPatchDeliveryNote(requestBody)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(closeCurrentDialog)
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const handleDelete = (rowId: string | string[], deselectAll: () => void) => {
    openDeleteDialog({
      onConfirm: () =>
        deleteDeliveryNote({deliveryNoteId: isArray(rowId) ? rowId : [rowId]})
          .unwrap()
          .then(() => {
            deselectAll();
            refreshDataGrid();
          })
          .catch(handleApiError),
    });
  };

  const handleCreateReceiveNote = (rowId: string | string[]) => {
    postReceiveNote({deliveryNoteId: isArray(rowId) ? rowId : [rowId]})
      .unwrap()
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const actionCallback: ActionCallback = ({actionKey, rowId, deselectAll, sourceSearchParams}) => {
    match(actionKey)
      .with('redirectDetail', 'edit', () => {
        const id = isArray(rowId) ? rowId[0] : rowId;
        navigate(
          composePath(warehouseRoutes.deliveryNoteDetailOverview, {
            queryParams: sourceSearchParams,
            params: {id},
            isSearchParamsPreserved: false,
          })
        );
      })
      .with('bulkEdit', () => {
        openDialog(
          <DeliveryNoteListBulkEdit
            rowId={rowId}
            onSubmit={handleBulkEditDeliveryNote}
            data-testid={testIds.warehouse.deliveryNoteList('dialogs.bulkEdit')}
          />,
          {
            size: 'small',
            title: i18n.t('entity.warehouse.labels.bulkEdit'),
          }
        );
      })
      .with('delete', () => {
        handleDelete(rowId, deselectAll);
      })
      .with('createReceiveNote', () => {
        handleCreateReceiveNote(rowId);
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <FullscreenDataGridWrapper hideCardWrapper>
      <DataGrid
        ref={dataGridRef}
        gridCode="warehouse-delivery-notes"
        actionCallback={actionCallback}
        data-testid="warehouseDeliveryNotesDatagrid"
      />
    </FullscreenDataGridWrapper>
  );
});

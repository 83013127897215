import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Grid, Text, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useState} from 'react';

import {find} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  FileResponseBody,
  TemplateListItemResponseBody,
  useGetTemplatesQuery,
  useIssueVehicleWarehouseMovementMutation,
  useLazyGetVehicleWarehouseMovementQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {getApiDateString, yupString} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {DocumentTemplateBox} from '../../DocumentTemplateBox/DocumentTemplateBox';

interface VehicleWarehouseStockOutProps {
  vehicleId: string;
  vehicleWarehouseId: string;
  onDocumentCreation: (document: FileResponseBody) => void;
  onMovementComplete?: () => void;
}

interface VehicleWarehouseStockOutFormFields {
  movementAt: string;
  note: string | null;
}

const STOCK_OUT_DOCUMENT_TEMPLATES_CODE = 'issue-vehicle-warehouse-movement';

export function VehicleWarehouseStockOut(props: VehicleWarehouseStockOutProps) {
  const [isDocumentSelected, setIsDocumentSelected] = useState(true);
  const [documentTemplate, setDocumentTemplate] = useState<TemplateListItemResponseBody>();

  const {
    data: stockOutTemplates,
    isLoading: isTemplatesLoading,
    isError: isTemplatesError,
  } = useGetTemplatesQuery({
    documentKindCode: STOCK_OUT_DOCUMENT_TEMPLATES_CODE,
  });

  const [stockOutVehicle] = useIssueVehicleWarehouseMovementMutation();
  const [getMovement] = useLazyGetVehicleWarehouseMovementQuery();

  if (!documentTemplate && isNotNilOrEmpty(stockOutTemplates)) {
    setDocumentTemplate(find((template) => template.primary, stockOutTemplates ?? []));
  }

  const onSubmit: FormSubmitHandler<VehicleWarehouseStockOutFormFields> = (values) =>
    stockOutVehicle({
      vehicleWarehouseId: props.vehicleWarehouseId,
      body: {
        vehicleId: props.vehicleId,
        movementAt: values.movementAt,
        issueTemplateId: isDocumentSelected ? documentTemplate?.id : undefined,
        note: values.note,
      },
    })
      .unwrap()
      .then((response) => {
        if (!isDocumentSelected) {
          showNotification.success(i18n.t('entity.vehicle.labels.vehicleStockedOut'));
          closeCurrentDialog();

          return;
        }

        return response;
      })
      .then((response) => {
        if (!response) {
          return;
        }

        return getMovement({
          vehicleWarehouseMovementId: response?.id,
        })
          .unwrap()
          .then((response) => {
            response.document && props.onDocumentCreation(response.document);
          });
      })
      .then(props.onMovementComplete)
      .catch(handleApiError);

  const defaultValues: Partial<VehicleWarehouseStockOutFormFields> = {
    movementAt: getApiDateString(new Date()),
  };

  return (
    <VStack spacing={4}>
      <Text size="small" color="secondary">
        {i18n.t('entity.vehicleWarehouse.modal.stockOutDescription')}
      </Text>
      <DataStatus isLoading={isTemplatesLoading} isError={isTemplatesError}>
        <Form<VehicleWarehouseStockOutFormFields>
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          schema={schema}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <Grid columns={2}>
                <FormField
                  label={i18n.t('entity.vehicleWarehouse.labels.dateOfStockOut')}
                  name="movementAt"
                  type="apiDate"
                  control={control}
                  isRequired
                />
              </Grid>
              <DocumentTemplateBox
                title={i18n.t('entity.document.labels.vehicleWarehouseStockOutDocument')}
                templates={stockOutTemplates}
                selectedTemplate={documentTemplate}
                isSelected={isDocumentSelected}
                onSelect={setIsDocumentSelected}
                onTemplateChange={setDocumentTemplate}
                onNoteChange={(value) => formApi.setValue('note', value)}
                noteLabel={i18n.t('entity.document.labels.vehicleWarehouseStockOutNote')}
                isWithNote
                isDisabled
              />
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.cancel')}
                  variant="secondary"
                  onClick={closeCurrentDialog}
                />
                <FormButton
                  title={i18n.t('general.actions.confirm')}
                  type="submit"
                  control={control}
                />
              </ButtonGroup>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </VStack>
  );
}

const schema = object({
  movementAt: yupString.required(),
  note: yupString,
});

import {
  BreadcrumbType,
  Form,
  FormField,
  FormSubmitHandler,
  OptionType,
  showNotification,
} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';
import {Pattern, match} from 'ts-pattern';
import {object} from 'yup';

import {useParams} from 'react-router-dom';

import {always, isNil} from 'ramda';
import {isFalse, isTrue} from 'ramda-adjunct';

import {
  VatCalculationType,
  VatCheckTotalsDocumentTypeV2,
  useCreateVatTotalAndCalculationSettingMutation,
  useGetVatTotalAndCalculationSettingQuery,
  useUpdateVatTotalAndCalculationSettingMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, useBranches} from '@omnetic-dms/shared';

import {NullishBoolean, useNavigate, yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';

type FormValues = {
  branchId: string;
  calculationType: VatCalculationType;
  documentType: VatCheckTotalsDocumentTypeV2 | null;
};

const breadcrumbs: BreadcrumbType[] = [
  {
    label: i18n.t('page.accountingSettings.labels.vatTotalAndCalculation'),
    href: settingsRoutes.vatTotalAndCalculationOverview,
  },
];

export function VatTotalAndCalculationDetail() {
  const {id: configurationId} = useParams();
  const isCreating = isNil(configurationId);
  const navigate = useNavigate();

  const {
    data: definition,
    isLoading: isLoadingDefinition,
    isError: isDefinitionError,
  } = useGetVatTotalAndCalculationSettingQuery(
    {vatTotalAndCalculationId: configurationId ?? ''},
    {skip: isCreating}
  );
  const [updateInvoiceCostDefinition] = useUpdateVatTotalAndCalculationSettingMutation();
  const [createInvoiceCostDefinition] = useCreateVatTotalAndCalculationSettingMutation();

  const isSystem = isTrue(definition?.isSystem);

  const {
    data: branches,
    branchOptions,
    isLoading: isLoadingBranches,
    isError: isBranchesError,
  } = useBranches();

  const handleSubmit: FormSubmitHandler<FormValues> = async (data) => {
    const submitData: FormValues = {
      ...data,
      documentType: data.calculationType === 'sum' ? data.documentType : null,
    };

    if (isCreating) {
      await createInvoiceCostDefinition(submitData)
        .unwrap()
        .then(() => showNotification.success())
        .then(() => navigate(settingsRoutes.vatTotalAndCalculationOverview))
        .catch(handleApiError);
    } else {
      await updateInvoiceCostDefinition({
        vatTotalAndCalculationId: configurationId ?? '',
        ...submitData,
      })
        .unwrap()
        .then(() => showNotification.success())
        .then(() => navigate(settingsRoutes.vatTotalAndCalculationOverview))
        .catch(handleApiError);
    }
  };

  const isLoading = isLoadingBranches || isLoadingDefinition;
  const isError = isBranchesError || isDefinitionError;

  const branchName = branches?.branchListItems.find(
    (branch) => branch.id === definition?.branchId
  )?.marketingName;

  const title = match([isCreating, branchName, isSystem])
    .with(
      [true, Pattern.any, false],
      always(i18n.t('page.accountingSettings.labels.newDefinition'))
    )
    .with(
      [false, Pattern.string, false],
      always(
        `${branchName} - ${i18n.t(`page.accountingSettings.labels.calculationType.${definition?.calculationType}`)}`
      )
    )
    .with([false, Pattern.any, true], always(i18n.t('page.accounting.labels.tenantDefinition')))
    .otherwise(always(i18n.t('page.accounting.labels.definition')));

  const defaultValues = {
    branchId: definition?.branchId ?? undefined,
    calculationType: definition?.calculationType ?? 'sum',
    documentType: definition?.documentType ?? undefined,
  };

  return (
    <SettingsTemplate
      header={{
        title,
        breadcrumbs,
      }}
      data-testid={testIds.settings.taxDocumentIssuedTypeDetail('page')}
      isLoading={isLoading}
      isError={isError}
      isCreating={isCreating}
    >
      <Form<FormValues>
        onSubmit={handleSubmit}
        schema={getSchema(isSystem)}
        defaultValues={defaultValues}
      >
        {(control, formApi) => {
          const isCalculationTypeSum = formApi.watch('calculationType') === 'sum';
          return (
            <VStack spacing={4}>
              <SettingsSection>
                <Grid columns={2} align="flex-end">
                  <FormField
                    control={control}
                    type="choice"
                    name="branchId"
                    isNotClearable
                    options={branchOptions}
                    label={i18n.t('entity.cashRegister.parameters.branch')}
                    isRequired
                    data-testid={testIds.settings.vatTotalAndCalculationDetail('branchId')}
                    isDisabled={isSystem}
                    placeholder={
                      isSystem ? i18n.t('page.accounting.labels.tenantDefinition') : undefined
                    }
                  />

                  <FormField
                    control={control}
                    type="choice"
                    name="calculationType"
                    isDisabled={isCalculationTypeSum}
                    isNotClearable
                    options={calculationTypeOptions}
                    label={i18n.t('page.accountingSettings.labels.calculationType.title')}
                    isRequired
                    data-testid={testIds.settings.vatTotalAndCalculationDetail('calculationType')}
                  />

                  <Show when={isCalculationTypeSum}>
                    <FormField
                      control={control}
                      type="choice"
                      name="documentType"
                      isNotClearable
                      options={documentTypeOptions}
                      label={i18n.t('page.accountingSettings.labels.documentType.title')}
                      isRequired
                      data-testid={testIds.settings.vatTotalAndCalculationDetail('documentType')}
                    />
                  </Show>
                </Grid>
              </SettingsSection>
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    title: i18n.t('general.actions.discardChanges'),
                    onClick: () => navigate(settingsRoutes.vatTotalAndCalculationOverview),
                    variant: 'secondary',
                  },
                  {
                    type: 'form-button',
                    control,
                    buttonType: 'submit',
                    title: i18n.t('general.actions.saveChanges'),
                  },
                ]}
              />
            </VStack>
          );
        }}
      </Form>
    </SettingsTemplate>
  );
}

const getSchema = (isSystem: NullishBoolean) =>
  object({
    branchId: yupString.when([], {
      is: () => isFalse(isSystem),
      then: (schema) => schema.required(),
    }),
    calculationType: yupString.oneOf(['from-above', 'from-below', 'sum']).required(),
    documentType: yupString.when('calculationType', {
      is: 'sum',
      then: yupString.oneOf(['off', 'invoices', 'cash-receipts', 'invoicing-documents']).required(),
    }),
  });

const calculationTypeOptions: OptionType<VatCalculationType>[] = [
  {value: 'from-above', label: i18n.t('page.accountingSettings.labels.calculationType.from-above')},
  {value: 'from-below', label: i18n.t('page.accountingSettings.labels.calculationType.from-below')},
  {value: 'sum', label: i18n.t('page.accountingSettings.labels.calculationType.sum')},
];

const documentTypeOptions: OptionType<VatCheckTotalsDocumentTypeV2>[] = [
  {value: 'off', label: i18n.t('page.accountingSettings.labels.documentType.off')},
  {value: 'invoices', label: i18n.t('page.accountingSettings.labels.documentType.invoices')},
  {
    value: 'cash-receipts',
    label: i18n.t('page.accountingSettings.labels.documentType.cash-receipts'),
  },
  {
    value: 'invoicing-documents',
    label: i18n.t('page.accountingSettings.labels.documentType.invoicing-documents'),
  },
];

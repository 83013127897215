import {Card, closeCurrentDialog, DataStatus, openDialog} from 'platform/components';
import {Scroll, VStack} from 'platform/foundation';

import {isNil, reject} from 'ramda';

import {MaterialBasketItemType, useGetBasketMaterialItemsApiQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {EditMaterialItem} from './EditMaterialItem';
import {MaterialBasketItem} from './MaterialBasketItem';

interface MaterialBasketCardProps extends TestIdProps {
  packageId: string;
}

export function MaterialBasketCard(props: MaterialBasketCardProps) {
  const {data, isLoading, isError} = useGetBasketMaterialItemsApiQuery({
    packageId: props.packageId,
  });

  const handleEditItem = (item: MaterialBasketItemType) =>
    openDialog(
      <EditMaterialItem
        itemId={item.basketItemId ?? ''}
        context="basket"
        onClose={closeCurrentDialog}
      />,
      {
        title: `${item.name} (${item.number})`,
      }
    );

  const count = data?.materialBasketItem?.length ?? 0;
  const basketItems = reject(isNil, data?.materialBasketItem ?? []);

  return (
    <Card
      isFullHeight
      title={`${i18n.t('general.labels.selected')} (${count})`}
      data-testid={suffixTestId('header', props)}
    >
      <VStack height="100%">
        <Scroll flex={1} auto>
          <VStack height="100%" spacing={4}>
            <DataStatus
              isLoading={isLoading}
              isError={isError}
              minHeight="100%"
              isEmpty={count === 0}
            >
              {basketItems.map((item, index) => (
                <MaterialBasketItem
                  key={item?.basketItemId}
                  id={item.basketItemId ?? ''}
                  name={item.number ?? ''}
                  quantity={item.quantity ?? 0}
                  subtitle={item.name ?? ''}
                  warehouseId={item.warehouseId}
                  articleId={item.warehouseArticleId}
                  onEditClick={() => handleEditItem(item)}
                  data-testid={suffixTestId(`basketItem-[${index}]`, props)}
                />
              ))}
            </DataStatus>
          </VStack>
        </Scroll>
      </VStack>
    </Card>
  );
}

import {CreateVehicleV2ApiArg} from '@omnetic-dms/api';
import {
  $VehicleBatterySection,
  FuelTypeEnum,
  VehicleCatalogueMakeModelItem,
  vehicleTitle,
} from '@omnetic-dms/teas';

import {MAKE_OTHER, MODEL_FAMILY_OTHER} from '../../../../../constants/makes';
import {getManufacturedOn} from '../../../../../utils/getManufacturedOn';
import {VehicleCreateFormState} from '../components/types';

export type VehicleCreateFormStateWithNote = VehicleCreateFormState & {note?: string};

export const getRequestBody =
  (makeModels: Record<string, VehicleCatalogueMakeModelItem>) =>
  (values: VehicleCreateFormState): CreateVehicleV2ApiArg => {
    const makeModel = makeModels[values.make];
    const modelFamily = makeModel?.models.find((model) => model.value === values.modelFamily);

    const manufacturedOn = getManufacturedOn(values.manufacturedOnYear, values.manufacturedOnMonth);

    const _title = vehicleTitle({
      make: makeModel,
      modelFamily: values.modelFamily,
      trim: values.trim,
      variant: values.variant,
    });
    const isTitleCustom = _title !== values.title;

    return {
      ...values,
      ...(values.state ?? {}),
      ...(values.engine ?? {}),
      ...(values.modelSpecification ?? {}),
      ...(values.battery &&
      (values.fuelType === FuelTypeEnum.FUELTYPE_ELECTRIC ||
        values.fuelType === FuelTypeEnum.FUELTYPE_HYBRID)
        ? {
            ...$VehicleBatterySection.getDefault(),
            ...values.battery,
          }
        : {}),

      make: makeModel?.isCustom ? MAKE_OTHER : makeModel?.value,
      title: isTitleCustom ? values.title || null : null,
      modelFamily: modelFamily?.isCustom ? MODEL_FAMILY_OTHER : (modelFamily?.value as string),
      fuelConsumptionCombined: values.engine?.fuelConsumptionCombined?.toString() ?? null,
      fuelConsumptionExtraUrban: values.engine?.fuelConsumptionExtraUrban?.toString() ?? null,
      fuelConsumptionUrban: values.engine?.fuelConsumptionUrban?.toString() ?? null,
      customMake: makeModel?.isCustom ? makeModel?.value : null,
      customModelFamily: modelFamily?.isCustom ? (modelFamily?.value as string) : null,
      firstRegistrationOn: values.firstRegistrationDate ?? null,
      manufacturedOn,
      carbonDioxideEmission: values.engine.carbonDioxideEmission?.toString(),
      powerConsumptionCombined: values.engine.powerConsumptionCombined?.toString(),
      powerCombustionEngine: values.engine.powerCombustionEngine?.toString(),
      powerElectricEngine: values.engine.powerElectricEngine?.toString(),
      features: [],
    };
  };

import {Card} from 'platform/components';
import {VStack} from 'platform/foundation';

import {RequiredTestIdProps} from 'shared';

import {EitherQuantityOrError} from '../../../types/basket/EitherQuantityOrError';
import {MappedBasketItem} from '../../../types/basket/MappedBasketItem';
import {LabourBasketItem} from '../types/LabourBasketItem';
import {LabourBasketItemEditingDetails} from '../types/LabourBasketItemEditingDetails';
import {AfterSalesLabourBasketItemQuantityWithPrice} from './AfterSalesLabourBasketItemQuantityWithPrice';
import {AfterSalesLabourBasketItemTitleWithActions} from './AfterSalesLabourBasketItemTitleWithActions';

interface AfterSalesLabourBasketItemProps<T extends LabourBasketItem> extends RequiredTestIdProps {
  item: MappedBasketItem<T>;
  onSelect: (itemId: string, isSelected: boolean) => void;
  onQuantityChange: (itemId: string, quantity: EitherQuantityOrError) => Promise<void>;
  onEdit: (editingDetails: LabourBasketItemEditingDetails) => void;
  onDelete: (itemId: string) => Promise<void>;
}

export function AfterSalesLabourBasketItem<T extends LabourBasketItem>(
  props: AfterSalesLabourBasketItemProps<T>
) {
  return (
    <Card variant="inlineGrey">
      <VStack spacing={1}>
        <AfterSalesLabourBasketItemTitleWithActions
          item={props.item}
          onSelect={props.onSelect}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          data-testid={props['data-testid']}
        />
        <AfterSalesLabourBasketItemQuantityWithPrice
          item={props.item}
          onQuantityChange={props.onQuantityChange}
          data-testid={props['data-testid']}
        />
      </VStack>
    </Card>
  );
}

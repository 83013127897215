import {AutoLinkNode, LinkNode} from '@lexical/link';
import {InitialConfigType} from '@lexical/react/LexicalComposer';
import {captureException} from '@sentry/browser';
import {
  type Klass,
  LexicalEditor,
  LexicalNode,
  LineBreakNode,
  ParagraphNode,
  TextNode,
} from 'lexical';
import {BeautifulMentionNode, BeautifulMentionsTheme} from 'lexical-beautiful-mentions';
import {DOMExportOutput} from 'lexical/LexicalNode';

import {identity} from 'ramda';

import {OptionType} from '../../../types/FormControlProps';
import {MentionsInputProps} from '../types/MentionsInputProps';

const linkFormatter = (_editor: LexicalEditor, node: LexicalNode) => {
  const convertedNode = node as AutoLinkNode;
  const element = document.createTextNode(
    `${convertedNode.getURL().startsWith('mailto') ? 'email' : 'url'}[${convertedNode.getURL().replace('mailto:', '')}]`
  );
  return {element};
};

const beautifulMentionsTheme: BeautifulMentionsTheme = {
  '@': 'mentions',
};

const theme = {
  link: 'editor-link',
  beautifulMentions: beautifulMentionsTheme,
};

const onError: InitialConfigType['onError'] = (error) => {
  captureException(error);
};

export const getEditorConfig = <T extends OptionType>(
  props: Pick<MentionsInputProps<T>, 'exportHtmlNodeTransforms' | 'isDisabled'>
): InitialConfigType => {
  return {
    editable: !props.isDisabled,
    namespace: 'editor',
    theme,
    nodes: [AutoLinkNode, LinkNode, BeautifulMentionNode],
    html: {
      export: (props.exportHtmlNodeTransforms || identity)(
        new Map<
          Klass<LexicalNode>,
          (editor: LexicalEditor, target: LexicalNode) => DOMExportOutput
        >([
          [
            ParagraphNode,
            () => {
              const element = document.createElement('div');
              return {element};
            },
          ],
          [
            BeautifulMentionNode,
            (_editor, node) => {
              const convertedNode = node as BeautifulMentionNode;
              const element = document.createTextNode(
                `@[${convertedNode.getValue()}](${convertedNode.getData?.()?.id})`
              );
              return {element};
            },
          ],
          [AutoLinkNode, linkFormatter],
          [LinkNode, linkFormatter],
          [
            LineBreakNode,
            () => {
              const element = document.createTextNode('\n');
              return {element};
            },
          ],
          [
            TextNode,
            (_editor, node) => {
              const element = document.createTextNode(node.getTextContent());
              return {element};
            },
          ],
        ])
      ),
    },
    onError,
  };
};

import type {PermissionsRecordType} from '../permissions';

export const interestPermissions = {
  deleteInterest: {
    actionId: 'DELETE',
    resourceId: 'INTEREST',
    scopes: [],
  },
  interestTagRead: {
    actionId: 'RECORD_TAG_VALUES_READ_RECORD_TAG_VALUES',
    resourceId: 'INTEREST',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  interestTagAddToRecord: {
    actionId: 'RECORD_TAG_VALUES_ADD_TAG_VALUE_TO_RECORD',
    resourceId: 'INTEREST',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  interestTagRemoveFromRecord: {
    actionId: 'RECORD_TAG_VALUES_REMOVE_TAG_VALUE_FROM_RECORD',
    resourceId: 'INTEREST',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  interestTagAdd: {
    actionId: 'ADD_TAG_VALUE',
    resourceId: 'INTEREST_TAG',
    scopes: [],
  },
  interestTagUpdate: {
    actionId: 'UPDATE_TAG_VALUE',
    resourceId: 'INTEREST_TAG',
    scopes: [],
  },
  interestTagRemove: {
    actionId: 'REMOVE_TAG_VALUE',
    resourceId: 'INTEREST_TAG',
    scopes: [],
  },
  interestTagClearExpiration: {
    actionId: 'CLEAR_TAG_VALUE_EXPIRATION',
    resourceId: 'INTEREST_TAG',
    scopes: [],
  },
  interestTagSetExpiration: {
    actionId: 'SET_TAG_VALUE_EXPIRATION',
    resourceId: 'INTEREST_TAG',
    scopes: [],
  },
} satisfies PermissionsRecordType;

import {Form, FormField, FormSubmitHandler, Separator} from 'platform/components';
import {Box, HStack, Space, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useNavigate, useParams} from 'react-router-dom';

import {filter, isNil, pipe, propEq} from 'ramda';

import {
  BaseServiceOrderVariant,
  useGetAuthorizationProfilesQuery,
  useGetSeriesListQuery,
  useGetServiceCaseTypeQuery,
  useGetServiceOrderVariantsQuery,
  useGetTenantQuery,
  usePatchServiceCaseTypeMutation,
  usePostServiceCaseTypeMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {yupString} from 'shared';

import {SettingsFooter} from '../../components/SettingsFooter/SettingsFooter';
import {SettingsSection} from '../../components/SettingsSection/SettingsSection';
import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {getOptionsFromServiceOrderVariants} from '../../utils/getOptionsFromServiceOrderVariants';

type ServiceCaseType = {
  authorizationProfileId: string;
  docSeriesServiceCaseId: string;
  docSeriesServiceOrderId: string;
  name: string;
  isDefaultServiceOrderVariant: boolean;
  serviceOrderVariantId: string | null;
};

export function ServiceCaseTypeDetail() {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: tenant} = useGetTenantQuery();
  const {
    data: serviceCaseType,
    isLoading: isServiceCaseTypeLoading,
    isError: isServiceCaseTypeError,
  } = useGetServiceCaseTypeQuery({serviceCaseTypeId: id ?? ''}, {skip: isNil(id)});
  const {data: authorizationProfiles, isLoading: isAuthorizationProfilesLoading} =
    useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id ?? ''}, {skip: isNil(tenant)});
  const {data: series, isLoading: isSeriesLoading} = useGetSeriesListQuery(
    {
      type: ['workshop/service_case', 'workshop/service_order'],
    },
    {
      selectFromResult: (result) => ({
        ...result,
        data: {
          serviceCase: result?.data?.filter((item) => item.type === 'workshop/service_case'),
          serviceOrder: result?.data?.filter((item) => item.type === 'workshop/service_order'),
        },
      }),
    }
  );
  const {data: serviceOrderVariants, isLoading: isServiceOrderVariantsLoading} =
    useGetServiceOrderVariantsQuery();

  const [postServiceCaseType] = usePostServiceCaseTypeMutation();
  const [patchServiceCaseType] = usePatchServiceCaseTypeMutation();

  const handleSubmit: FormSubmitHandler<ServiceCaseType> = async (data) => {
    const body = {
      ...data,
      serviceOrderVariantId: data.serviceOrderVariantId,
      isDefaultServiceOrderVariant: true,
    };

    if (isNil(id)) {
      return await postServiceCaseType({body})
        .unwrap()
        .then(() => navigate(settingsRoutes.serviceCaseType))
        .catch(handleApiError);
    }

    return await patchServiceCaseType({serviceCaseTypeId: id, body})
      .unwrap()
      .then(() => navigate(settingsRoutes.serviceCaseType))
      .catch(handleApiError);
  };

  const defaultValues = {
    name: serviceCaseType?.name ?? undefined,
    authorizationProfileId: serviceCaseType?.authorizationProfileId ?? undefined,
    docSeriesServiceCaseId: serviceCaseType?.docSeriesServiceCaseId ?? undefined,
    docSeriesServiceOrderId: serviceCaseType?.docSeriesServiceOrderId ?? undefined,
    serviceOrderVariantId: serviceCaseType?.serviceOrderVariantId ?? null,
  };

  return (
    <SettingsTemplate
      header={{
        title: serviceCaseType?.name ?? i18n.t('entity.serviceCase.actions.newServiceCaseType'),
        breadcrumbs: [
          {
            label: i18n.t('entity.workshopPresetsCategory.labels.serviceCaseType'),
            href: settingsRoutes.serviceCaseType,
          },
        ],
      }}
      isCreating={isNil(id)}
      isLoading={isServiceCaseTypeLoading}
      isError={isServiceCaseTypeError}
      data-testid={testIds.settings.serviceCaseTypeDetail('template')}
    >
      <SettingsSection>
        <Form<ServiceCaseType>
          defaultValues={defaultValues}
          schema={FormSchema}
          onSubmit={handleSubmit}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <FormField
                control={control}
                type="text"
                name="name"
                label={i18n.t('entity.serviceCase.labels.serviceCaseTypeName')}
                isRequired
                data-testid={testIds.settings.serviceCaseTypeDetail('name')}
              />
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    type="choice"
                    name="authorizationProfileId"
                    label={i18n.t('entity.settings.labels.authorizationProfile')}
                    options={authorizationProfiles?.map((profile) => ({
                      label: profile?.name,
                      value: profile?.id,
                    }))}
                    isLoading={isAuthorizationProfilesLoading}
                    isRequired
                    data-testid={testIds.settings.serviceCaseTypeDetail('authorizationProfile')}
                  />
                </Box>
                <Space fillAvailable />
              </HStack>
              <Separator spacing={0} />
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    type="choice"
                    name="docSeriesServiceCaseId"
                    label={i18n.t('entity.serviceCase.labels.serviceCaseSeries')}
                    options={series?.serviceCase?.map((series) => ({
                      label: series.name,
                      value: series.id,
                    }))}
                    isLoading={isSeriesLoading}
                    isRequired
                    data-testid={testIds.settings.serviceCaseTypeDetail('SCSeries')}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    type="choice"
                    name="docSeriesServiceOrderId"
                    label={i18n.t('entity.serviceOrder.labels.serviceOrderSeries')}
                    options={series?.serviceOrder?.map((series) => ({
                      label: series.name,
                      value: series.id,
                    }))}
                    isLoading={isSeriesLoading}
                    isRequired
                    data-testid={testIds.settings.serviceCaseTypeDetail('orderSeries')}
                  />
                </Box>
              </HStack>
              <Separator spacing={0} />
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    type="choice"
                    name="serviceOrderVariantId"
                    options={getOrderVariantOptionsForAuthProfile(
                      serviceOrderVariants,
                      formApi.watch('authorizationProfileId')
                    )}
                    label={i18n.t('entity.serviceCaseType.labels.serviceOrderVariant')}
                    isLoading={isServiceOrderVariantsLoading}
                    isRequired
                    data-testid={testIds.settings.serviceCaseTypeDetail('serviceOrderVariantId')}
                  />
                </Box>
                <Space fillAvailable />
              </HStack>
              <SettingsFooter
                actions={[
                  {
                    type: 'button',
                    title: i18n.t('general.actions.discardChanges'),
                    variant: 'secondary',
                    onClick: () => navigate(settingsRoutes.serviceCaseType),
                  },
                  {
                    control,
                    type: 'form-button',
                    buttonType: 'submit',
                    title: i18n.t('general.actions.saveChanges'),
                  },
                ]}
                data-testid={testIds.settings.serviceCaseTypeDetail('footer')}
              />
            </VStack>
          )}
        </Form>
      </SettingsSection>
    </SettingsTemplate>
  );
}

const FormSchema = object({
  authorizationProfileId: yupString.required(),
  docSeriesServiceCaseId: yupString.required(),
  docSeriesServiceOrderId: yupString.required(),
  name: yupString.required(),
  serviceOrderVariantId: yupString.required(),
});

const getOrderVariantOptionsForAuthProfile = (
  serviceOrderVariants?: BaseServiceOrderVariant[],
  authorizationProfileId?: string
) => {
  if (isNil(serviceOrderVariants) || isNil(authorizationProfileId)) {
    return;
  }

  return pipe(
    filter(propEq(authorizationProfileId, 'authorizationProfileId')),
    getOptionsFromServiceOrderVariants
  )(serviceOrderVariants);
};

import {Card} from 'platform/components';
import {Icon, Link, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import {isNil} from 'ramda';

import {salesPublishingApi} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {queryParams} from '@omnetic-dms/shared';
import {
  AdvertisingPlatformCodeEnum,
  changeCredentialsRequest,
  Form,
  MyWebLink,
  PlatformInformation,
  PublishingService,
  Tag,
  useCallApi,
} from '@omnetic-dms/teas';

import {useQueryState} from 'shared';

import {remapPlatform} from '../../utils/remapPlatform';
import {PlatformAuthField} from './PlatformAuthField';

const TextContent = styled.div`
  display: flex;
  padding: 0 0 20px;

  h5 {
    width: 220px;
    text-align: right;
    margin: 0;
    margin-right: 20px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.palettes.neutral[300][100]};
    font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  }

  h4 {
    font-weight: 400;
    color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
    font-size: ${({theme}) => theme.fontSizes.text.small};
  }
`;

const PlatformTag = styled(Tag)`
  color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  margin-bottom: 20px;

  svg {
    margin-right: 9px;
  }
`;

const Item = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
  display: flex;
  padding: 16px;

  &:first-child {
    border-top: none;
  }
`;

const PlatformItemContent = styled(Item)`
  display: flex;
  border-top: 0;
  flex-direction: column;
`;

export interface PlatformAuthenticationProps {
  platform?: PlatformInformation;
}

export const PlatformAuthentication = ({platform}: PlatformAuthenticationProps) => {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const dispatch = useDispatch();
  const {code} = platform as PlatformInformation;
  const getMyWebLink = useCallApi(PublishingService.getMyWebLink);
  const getFacebookLink = useCallApi(PublishingService.getFacebookLink);
  const getAutosoftLink = useCallApi(PublishingService.getAutosoftLink);
  const [extraLink, setLink] = useState<MyWebLink | null>(null);

  const saveCredentials = (credentials: Record<string, string>) => {
    dispatch(
      changeCredentialsRequest({
        platformCode: code as AdvertisingPlatformCodeEnum,
        credentials,
        branchId: branchId ?? undefined,
      })
    );
    dispatch(salesPublishingApi.util.invalidateTags(['PlatformList']));
  };

  useEffect(() => {
    if (code === 'myweb') {
      getMyWebLink()
        .then((result) => setLink(result))
        .catch(() => setLink(null));
    }
    if (code === 'facebook') {
      getFacebookLink()
        .then((result) => setLink(result))
        .catch(() => setLink(null));
    }
    if (code === 'autosoft') {
      getAutosoftLink()
        .then((result) => setLink(result))
        .catch(() => setLink(null));
    }
  }, [code, getAutosoftLink, getFacebookLink, getMyWebLink]);

  const remappedPlatform = useMemo(
    () => remapPlatform(platform as PlatformInformation),
    [platform]
  );

  return (
    <VStack spacing={4}>
      <Form
        onSubmit={saveCredentials}
        initialValues={remappedPlatform.credentials as any} // FIXME: !!
        isFormSaverDisabled
        render={(
          {handleSubmit}: any // FIXME: !!
        ) => (
          <Card
            title={`${i18n.t('general.labels.authentication')} - ${code}`}
            actions={[
              {
                type: 'button',
                variant: 'link',
                onClick: handleSubmit,
                'data-testid': testIds.settings.advertisingPlatforms(`saveCredentials-${code}`),
                title: i18n.t('general.actions.save'),
              },
            ]}
            data-testid={testIds.settings.advertisingPlatforms(`credentials-${code}`)}
          >
            <PlatformItemContent>
              {!isNil(platform?.error) && (
                <PlatformTag
                  orange
                  data-testid={testIds.settings.advertisingPlatforms(`errorTag-${code}`)}
                >
                  <Icon value="alert/error_outline" color="severity.danger" />
                  {platform?.error.message ??
                    i18n.t('page.advertisingPlatformDetail.notifications.changeCredentialsError')}
                </PlatformTag>
              )}
              {!isNil(platform?.success) && (
                <PlatformTag
                  teal
                  data-testid={testIds.settings.advertisingPlatforms(`successTag-${code}`)}
                >
                  <Icon value="action/done" color="severity.success" />
                  {i18n.t('general.notifications.success')}
                </PlatformTag>
              )}
              <PlatformAuthField platform={remappedPlatform} />
              {extraLink && (
                <>
                  <TextContent key="URL_property">
                    <Text size="xSmall">{i18n.t('general.labels.URL')}</Text>
                    <div>
                      <Link
                        size="small"
                        data-testid={testIds.settings.advertisingPlatforms(`externalLink-${code}`)}
                        href={extraLink?.link}
                        title={i18n.t('page.advertisement.labels.extraLinkText')}
                      />
                    </div>
                  </TextContent>
                  <TextContent key="URL_property_text">
                    <span>{i18n.t('page.advertisement.labels.extraLinkCredential')}</span>
                  </TextContent>
                </>
              )}
            </PlatformItemContent>
            <Text color="tertiary" size="small">
              {i18n.t('page.settings.labels.authenticationDescription')}
            </Text>
          </Card>
        )}
      />
    </VStack>
  );
};

import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  openDialog,
  Separator,
  showNotification,
  UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField,
} from 'platform/components';
import {Box, HStack, Text, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useParams} from 'react-router-dom';

import {equals, omit} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useCreateWatermarkMutation,
  useGetWatermarkDetailQuery,
  useGetWatermarkListQuery,
  useUpdateWatermarkDetailMutation,
  useUploadFileMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {useNavigate, yupString} from 'shared';

import {WatermarkFormType} from '../types/watermarkForm';
import {getDefaultValue} from '../utils/getDefaultValue';
import {getRequestData} from '../utils/getRequestData';
import {addToOptions, getPositionOptions} from '../utils/radioOptions';
import {WatermarkDetailPreview} from './WatermarkDetailPreview';
import {WatermarkRepublishAdsDialog} from './WatermarkRepublishAdsDialog';

export function WatermarkForm() {
  const {id} = useParams();
  const navigate = useNavigate();

  const [uploadFile] = useUploadFileMutation();
  const [updateWatermarkMutation] = useUpdateWatermarkDetailMutation();
  const [createWatermarkMutation, {isLoading: isCreatingWatermark}] = useCreateWatermarkMutation();
  const {data: watermarkData} = useGetWatermarkDetailQuery(
    {watermarkId: id ?? ''},
    {skip: isNilOrEmpty(id)}
  );
  const {data: watermarkList, isLoading: isLoadingWatermarkList} = useGetWatermarkListQuery();

  const handleUpload = async (file: File) => {
    const data = await uploadFile({file}).unwrap();

    return {id: data.fileId, url: data.fileUri};
  };

  const isLoading = isCreatingWatermark || isLoadingWatermarkList;

  const handleSubmit: FormSubmitHandler<WatermarkFormType> = async (data) => {
    const isBodyEqual = watermarkData
      ? equals(
          {
            ...omit(['fileId', 'name'], data),
            fileId: data.fileId?.id,
          },
          {
            ...omit(['id', 'imageUri', 'name', 'addOnlyForFirstPhoto'], watermarkData),
            addOnlyForFirstPhoto: watermarkData?.addOnlyForFirstPhoto?.toString(),
          }
        )
      : true;

    const createWatermark = ({
      setToAllVehicles,
      republishAds,
    }: {
      setToAllVehicles: boolean;
      republishAds: boolean;
    }) => {
      if (id) {
        return updateWatermarkMutation({
          watermarkId: id,
          updateWatermarkRequestBody: {
            ...getRequestData(data),
            republishAds,
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success();
            navigate(
              isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
                ? settingsRoutes.advertisingWatermarking
                : settingsRoutes.advertisingWatermarkingOld
            );
          })
          .catch(handleApiError);
      } else {
        return createWatermarkMutation({
          createWatermarkRequestBody: {
            ...getRequestData(data),
            setToAllVehicles,
            republishAds,
          },
        })
          .unwrap()
          .then(() => {
            showNotification.success();
            navigate(
              isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
                ? settingsRoutes.advertisingWatermarking
                : settingsRoutes.advertisingWatermarkingOld
            );
          })
          .catch(handleApiError);
      }
    };

    if (watermarkList && watermarkList.length > 0 && !id) {
      openDialog(
        <VStack spacing={1}>
          <Text>{i18n.t('entity.watermark.setVehicleDialog.description')}</Text>
          <ButtonGroup align="right">
            <Button
              data-testid="setVehicles-watermark-dialog-cancel"
              variant="secondary"
              onClick={() => createWatermark({setToAllVehicles: false, republishAds: false})}
              isDisabled={isLoading}
              title={i18n.t('general.actions.cancel')}
            />
            <Button
              data-testid="setVehicles-watermark-dialog-confirm"
              variant="primary"
              onClick={() => createWatermark({setToAllVehicles: true, republishAds: true})}
              isLoading={isLoading}
              title={i18n.t('general.actions.confirm')}
            />
          </ButtonGroup>
        </VStack>,
        {
          'data-testid': 'setVehicles-watermark-dialog',
          id: 'watermark-setVehiclesDialog',
          title: i18n.t('entity.watermark.setVehicleDialog.title'),
        }
      );
    }
    if (watermarkList && watermarkList.length < 1 && !id) {
      openDialog(
        <VStack spacing={1}>
          <Text>{i18n.t('entity.watermark.setNewVehicleDialog.description')}</Text>
          <ButtonGroup align="right">
            <Button
              data-testid="setVehicles-newWatermark-dialog-cancel"
              variant="secondary"
              onClick={() => createWatermark({setToAllVehicles: false, republishAds: false})}
              isDisabled={isLoading}
              title={i18n.t('general.actions.cancel')}
            />
            <Button
              data-testid="setVehicles-newWatermark-dialog-confirm"
              variant="primary"
              onClick={() => {
                closeCurrentDialog();
                openDialog(
                  <WatermarkRepublishAdsDialog
                    createWatermark={createWatermark}
                    isLoading={isLoading}
                  />,
                  {
                    'data-testid': 'watermark-republichAds-dialog',
                    id: 'watermark-republichAds-new',
                    title: i18n.t('entity.watermark.republishAdsDialog.title'),
                  }
                );
              }}
              isLoading={isLoading}
              title={i18n.t('general.actions.confirm')}
            />
          </ButtonGroup>
        </VStack>,
        {
          'data-testid': 'setVehicles-newWatermark-dialog',
          id: 'watermark-setVehiclesDialog-new',
          title: i18n.t('entity.watermark.setNewVehicleDialog.title'),
        }
      );
    }
    if (id && !isBodyEqual) {
      openDialog(
        <WatermarkRepublishAdsDialog createWatermark={createWatermark} isLoading={isLoading} />,
        {
          'data-testid': 'watermark-republichAds-dialog',
          id: 'watermark-republichAds-new',
          title: i18n.t('entity.watermark.republishAdsDialog.title'),
        }
      );
    }
    if (id && isBodyEqual) {
      await createWatermark({republishAds: false, setToAllVehicles: false});
    }
  };

  const handleDiscard = () =>
    navigate(
      isFeatureEnabled(featureFlags.SETTINGS_WATERMARKS)
        ? settingsRoutes.advertisingWatermarking
        : settingsRoutes.advertisingWatermarkingOld
    );

  const handleUploadError = (error: Error) => {
    if (error instanceof UploadedFileFormatIsNotAllowedBySpecifiedAcceptedField) {
      showNotification.error(i18n.t('entity.watermark.notifications.imageFormatIsNotAllowed'));
    }
  };

  return (
    <Form<WatermarkFormType>
      onSubmit={handleSubmit}
      schema={WatermarkFormSchema}
      defaultValues={getDefaultValue(watermarkData)}
    >
      {(control, formApi) => (
        <VStack spacing={3}>
          <Box maxWidth="50%">
            <FormField
              label={i18n.t('page.salesSettings.backgroundRemoval.configurationName')}
              control={control}
              name="name"
              type="text"
              isRequired
              data-testid="watermarks-configurationName"
            />
          </Box>

          <Separator spacing={2} />

          <FormField
            type="uploadImage"
            control={control}
            name="fileId"
            uploadFileRequest={handleUpload}
            onUploadError={handleUploadError}
            helperText={i18n.t('entity.photo.labels.uploadOrDragAndDropImage')}
            uploadingText={i18n.t('general.labels.uploadingWithDots')}
            uploadIcon="image/photo_camera"
            linkValueFormatter={(url) => url.replace('resize', 'get')}
            srcValueFormatter={(url) => `${url}${url.includes('resize') ? '&height=108' : ''}`}
            onPreviewClick={(url) =>
              window.open(`${url.replace('resize', 'get')}&type=inline`, '_blank')
            }
            isRequired
            data-testid="settings-advertising-watermarking-uploadImage"
          />
          <Separator />
          <HStack align="center" spacing={4}>
            <VStack spacing={2} width={80}>
              <FormField
                type="slider"
                name="size"
                control={control}
                min={0}
                max={100}
                step={1}
                ticks={2}
                label={i18n.t('entity.watermark.labels.size')}
                isDisabled={formApi.watch('fitToImage')}
                data-testid="settings-advertising-watermarking-form-size"
              />
            </VStack>
            <FormField
              type="checkbox"
              name="fitToImage"
              control={control}
              label={i18n.t('entity.watermark.labels.fitToImage')}
              data-testid="settings-advertising-watermarking-form-fitToImage"
            />
          </HStack>
          <Separator />
          <VStack spacing={4}>
            <FormField
              label={i18n.t('entity.watermark.labels.position')}
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('top')}
              data-testid="settings-advertising-watermarking-form-position-top"
            />
            <FormField
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('center')}
              data-testid="settings-advertising-watermarking-form-position-center"
            />
            <FormField
              type="radio"
              name="position"
              control={control}
              options={getPositionOptions('bottom')}
              data-testid="settings-advertising-watermarking-form-position-bottom"
            />
          </VStack>
          <Separator />
          <FormField
            type="radio"
            name="addOnlyForFirstPhoto"
            control={control}
            options={addToOptions}
            data-testid="settings-advertising-watermarking-form-addOnlyForFirstPhoto"
          />
          <Separator />
          <VStack spacing={4}>
            <WatermarkDetailPreview watermarkData={getRequestData(formApi.watch())} />
          </VStack>
          <Separator />
          <VStack justify="flex-end">
            <ButtonGroup>
              <Button
                variant="secondary"
                onClick={handleDiscard}
                title={i18n.t('general.actions.discard')}
                data-testid="settings-advertising-watermarking-form-discard-btn"
              />
              <FormButton
                type="submit"
                title={i18n.t('general.actions.save')}
                control={control}
                data-testid="settings-advertising-watermarking-form-save-btn"
              />
            </ButtonGroup>
          </VStack>
        </VStack>
      )}
    </Form>
  );
}

const WatermarkFormSchema = Yup.object({
  name: yupString.required(),
  fileId: Yup.mixed()
    .nullable()
    .test(
      'fileId-required',
      i18n.t('general.errors.mixed.required'),
      (value) => value !== null && value !== undefined
    ),
  size: Yup.number().required(),
  position: Yup.string().required(),
  fitToImage: Yup.boolean(),
  addOnlyForFirstPhoto: Yup.string().required(),
});

import {
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Switch,
} from 'platform/components';
import {Grid, HStack, Link, Right, Text, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import {object, string} from 'yup';

import {always, isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {useGetMyWebExtraLinkQuery, useMyWebPublishingOnTenantMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {queryParams} from '@omnetic-dms/shared';

import {Nullish, useQueryState} from 'shared';

import {PlatformAuthenticationFormData} from './PlatformAuthenticationForm';

type PlatformAuthenticationFormMyWebProps = {
  defaultValues: PlatformAuthenticationFormData | Nullish;
  isPublishingOnTenant: boolean;
  onSubmit: FormSubmitHandler<PlatformAuthenticationFormData>;
};

export function PlatformAuthenticationFormMyWeb(props: PlatformAuthenticationFormMyWebProps) {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const {data: extraLink} = useGetMyWebExtraLinkQuery({
    branchId: isNil(branchId) || props.isPublishingOnTenant ? undefined : branchId,
  });
  const [setPublishOnTenant, {isLoading}] = useMyWebPublishingOnTenantMutation();

  const mode = match([isNil(branchId), props.isPublishingOnTenant])
    .with([false, true], always('Tenant'))
    .with([false, false], always('Branch'))
    .otherwise(always(''));

  return (
    <Card title={`${i18n.t('general.labels.authentication')} – My Web`}>
      <Form<PlatformAuthenticationFormData>
        defaultValues={props.defaultValues}
        schema={schema}
        onSubmit={props.onSubmit}
        key={mode}
      >
        {(control) => (
          <VStack spacing={4}>
            {isNotNil(branchId) && (
              <DataStatus isLoading={isLoading}>
                <Switch
                  label={i18n.t('page.settings.labels.publishOnTenant')}
                  value={props.isPublishingOnTenant}
                  onChange={(isEnabled) => setPublishOnTenant({isEnabled, branchId})}
                />
              </DataStatus>
            )}
            <Grid columns={2}>
              <FormField
                control={control}
                name="username"
                type="text"
                label={i18n.t(`page.advertisement.labels.username${mode}`)}
              />
              <FormField
                control={control}
                name="password"
                type="password"
                label={i18n.t(`page.advertisement.labels.password${mode}`)}
              />
            </Grid>
            {extraLink && (
              <>
                <HStack spacing={1} align="center">
                  <Text size="xSmall">{i18n.t('general.labels.URL')}</Text>
                  <Link
                    size="small"
                    data-testid={testIds.settings.advertisingPlatforms('externalLink-myweb')}
                    href={extraLink.link}
                    title={i18n.t('page.advertisement.labels.extraLinkText')}
                  />
                </HStack>
                <Text size="small">{i18n.t('page.advertisement.labels.extraLinkCredential')}</Text>
              </>
            )}
            <Text color="tertiary" size="small">
              {i18n.t('page.settings.labels.authenticationDescription')}
            </Text>
            <Right>
              <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
            </Right>
          </VStack>
        )}
      </Form>
    </Card>
  );
}

const schema = object({
  username: string().nullable().required(),
  password: string().nullable().required(),
});

import {useState} from 'react';

import {Nullish, TestIdProps} from 'shared';

import {Tab, TabProps, TabVariants} from '../Tabs/Tab';
import {StyledTabList} from '../Tabs/Tabs';

type HeaderTabs = Omit<TabProps, 'content' | 'actions'>;

export type TabsHeaderProps = TestIdProps & {
  variant?: TabVariants;
  tabs: HeaderTabs[];
  defaultTabId?: string | Nullish;
  activeTabId?: string | Nullish;
  isFullWidth?: boolean;
  onChange?: (id: string) => void;
};

export function TabsHeader(props: TabsHeaderProps) {
  const [innerActiveTabId, setInnerActiveTabId] = useState<string>();

  const activeTabId =
    props.activeTabId ?? innerActiveTabId ?? props.defaultTabId ?? props.tabs[0]?.id;
  const setActiveTabId = props.onChange ?? setInnerActiveTabId;

  const handleChangeTab = (tabId: string) => {
    setActiveTabId(tabId);
  };

  return (
    <StyledTabList $variant={props.variant} $isFullWidth={props.isFullWidth}>
      {props.tabs.map((tab) => (
        <Tab
          key={tab.id}
          id={tab.id}
          title={tab.title}
          icon={tab.icon}
          isDisabled={tab.isDisabled}
          variant={props.variant}
          isSelected={tab.id === activeTabId}
          onClick={handleChangeTab}
          isFullWidth={props.isFullWidth}
          data-testid={tab['data-testid']}
        />
      ))}
    </StyledTabList>
  );
}

import {ReactNode, useMemo, useState} from 'react';

import {DataQueryIdContext} from './DataQueryIdContext';

type DataQueryIdProviderProps = {
  children: ReactNode;
  dataQueryId?: string;
};

export const DataQueryIdProvider = (props: DataQueryIdProviderProps) => {
  const [dataQueryId, setDataQueryId] = useState<string | undefined>(props.dataQueryId);

  const value = useMemo(() => {
    const changeDataQueryId = (dataQueryId: string) => {
      setDataQueryId(dataQueryId);
    };

    return {
      dataQueryId,
      changeDataQueryId,
    };
  }, [dataQueryId]);

  return <DataQueryIdContext.Provider value={value}>{props.children}</DataQueryIdContext.Provider>;
};

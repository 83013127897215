import {isFeatureEnabled} from 'feature-flags';
import {openConfirmDialog, openDialog, Parameter} from 'platform/components';
import {formatPhoneNumber, useDateTimeFormatter, useFormatNumber} from 'platform/locale';
import {match} from 'ts-pattern';

import {useSelector} from 'react-redux';
import {createSearchParams, useNavigate} from 'react-router-dom';

import {defaultTo, isNil, reject} from 'ramda';
import {isFalse, isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  selectSaleVehicle,
  useGetBusinessCaseByBusinessCaseQuery,
  useGetCustomerV2Query,
  useGetLastActiveBuyingBusinessCaseQuery,
  useGetLastActiveSellingBusinessCaseQuery,
  useGetLastActiveServiceCaseQuery,
  useGetParticipationQuery,
  useGetServiceVehicleQuery,
  useGetUserQuery,
  useGetVehicleCustomerQuery,
  useGetVehicleQuery,
  useGetVehicleV2Query,
  useListHighlightsQuery,
  useListShortcomingsQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, customerRoutes, testIds, workshopRoutes} from '@omnetic-dms/routes';
import {
  EMPTY_PLACEHOLDER,
  getCustomerName,
  getUserName,
  SnippetProps,
  StateType,
  usePermissions,
  useStateFlag,
  useVehicleCatalogue,
} from '@omnetic-dms/shared';
import {useGetVehicleEnums} from '@omnetic-dms/teas';

import {buildArray, composePath, DOT_CHARACTER, parseDate} from 'shared';

import {VehicleBuyForm} from '../(sections)/VehicleDetails/components/VehicleBuyForm';
import {getBusinessCaseSnippet} from '../../../utils/getBusinessCaseSnippet';
import {useGetManufacturedOn} from './useGetManufacturedOn';

export const useGetVehicleSnippets = (vehicleId: string) => {
  const formatNumber = useFormatNumber();
  const {data: saleVehicle} = useSelector(selectSaleVehicle(vehicleId));
  const {data: vehicleDetail} = useGetVehicleQuery({vehicleId});

  const {data: vehicleParticipation} = useGetParticipationQuery(
    {
      resourceId: EntityResourceIds.vehicle,
      recordId: saleVehicle?.vehicleId ?? '',
    },
    {skip: isNil(saleVehicle?.vehicleId)}
  );

  const [
    canReadPurchaseVehicle,
    hasCreateBusinessCasePurchasePermission,
    hasCreateBusinessCaseSellingPermission,
    hasAddVehicleToBuyPermission,
    hasAddVehicleToSellPermission,
    canReadSellVehicle,
    canReadServiceCase,
    canReadAdvertisingSettings,
  ] = usePermissions({
    permissionKeys: [
      'viewBusinessCasePurchase',
      'createBusinessCasePurchase',
      'createBusinessCaseSelling',
      'addVehicleToBuy',
      'addVehicleToSell',
      'viewBusinessCaseSelling',
      'serviceCaseRead',
      'readAdvertisingSettings',
    ],
    scopes: {
      readAdvertisingSettings: vehicleParticipation,
    },
  });

  const canReadWorkshop = isFeatureEnabled(featureFlags.ACL_WORKSHOP) && canReadServiceCase;

  const {data: serviceVehicle, isLoading} = useGetServiceVehicleQuery(
    {vehicleId},
    {skip: !canReadWorkshop}
  );

  const isSaleVehicle = isNotNil(saleVehicle);
  const isServiceVehicle = isNotNil(serviceVehicle);
  const isNotServiceNorSaleVehicle = !isSaleVehicle && !isServiceVehicle;
  const isSaleVehicleDisabledForSale =
    saleVehicle?.buyingState === 'not-bought' ||
    saleVehicle?.buyingState === 'returned-to-customer' ||
    (saleVehicle?.buyingState === 'buying' && saleVehicle?.type === 'BROKERAGE') ||
    (saleVehicle?.buyingState === 'priced' && saleVehicle?.type === 'BROKERAGE');

  const canCreatePurchase = hasCreateBusinessCasePurchasePermission && hasAddVehicleToBuyPermission;

  const canCreateSell =
    hasCreateBusinessCaseSellingPermission &&
    hasAddVehicleToSellPermission &&
    isFalse(isSaleVehicleDisabledForSale);

  const {data: vehicle} = useGetVehicleV2Query({vehicleId});
  const {data: lastActiveSC} = useGetLastActiveServiceCaseQuery(
    {vehicleId},
    {skip: !canReadWorkshop}
  );
  const {data: serviceCaseCustomer} = useGetCustomerV2Query(
    {customerId: lastActiveSC?.customerId ?? ''},
    {skip: isNil(lastActiveSC?.customerId)}
  );
  const {data: serviceCaseCreator} = useGetUserQuery(
    {id: lastActiveSC?.createdBy ?? ''},
    {skip: isNil(lastActiveSC?.createdBy)}
  );
  const {data: lastActiveBuyingBC, isError: isLastActiveBuyingBCError} =
    useGetLastActiveBuyingBusinessCaseQuery({vehicleId}, {skip: !isSaleVehicle});
  const {data: lastActiveSellingBC, isError: isLastActiveSellingBCError} =
    useGetLastActiveSellingBusinessCaseQuery({vehicleId}, {skip: !isSaleVehicle});
  const {data: saleVehicleBC} = useGetBusinessCaseByBusinessCaseQuery(
    {businessCaseId: saleVehicle?.saleBusinessCaseId ?? ''},
    {skip: isNil(saleVehicle?.saleBusinessCaseId)}
  );

  const sellingVehicleBC =
    (saleVehicleBC ?? !isLastActiveSellingBCError) ? lastActiveSellingBC : undefined;

  const {data: vehicleHighlights} = useListHighlightsQuery({vehicleId});
  const {data: vehicleShortcoming} = useListShortcomingsQuery({vehicleId});

  const navigate = useNavigate();
  const {fuel_type, transmission, drive} = useGetVehicleEnums(vehicle?.type);

  const firstRegistrationYear = isNotNil(vehicle?.firstRegistrationOn)
    ? parseDate(vehicle?.firstRegistrationOn ?? '').getFullYear()
    : null;

  const mileage = isNotNil(vehicle?.mileage)
    ? `${formatNumber(vehicle?.mileage)} ${i18n.t('general.metric.km')}`
    : null;

  const numberOfPreviousOwners = isNotNil(vehicleDetail?.state?.ownerCount)
    ? `${vehicleDetail?.state?.ownerCount} ${i18n.t('entity.vehicle.labels.previousOwner')}`
    : null;

  const originCountry = isNotNil(vehicleDetail?.modelSpecification?.originCountry)
    ? `${vehicleDetail?.modelSpecification?.originCountry}`
    : null;

  const specificationsTitle =
    reject(isNil, [firstRegistrationYear, mileage, numberOfPreviousOwners, originCountry]).join(
      ` ${DOT_CHARACTER} `
    ) || EMPTY_PLACEHOLDER;

  const specificationParameters: Parameter[] = reject(isNil, [
    fuel_type?.[vehicle?.fuelType ?? '']?.label,
    transmission?.[vehicle?.transmission ?? '']?.label,
    isNotNil(vehicle?.power) ? `${vehicle?.power} ${i18n.t('general.metric.kW')}` : undefined,
    isNotNil(vehicle?.engineVolume)
      ? `${vehicle?.engineVolume} ${i18n.t('general.metric.ccm')}`
      : undefined,
    drive?.[vehicle?.drive ?? '']?.label,
  ]);

  const highlightedFeaturesFlagProps = (vehicle?.features ?? [])
    .filter((feature) => feature.isHighlighted)
    .map(
      (feature) =>
        ({
          label: feature.translation,
          isSubtle: true,
          colorScheme: 'blue',
          size: 'small',
        }) as const
    );

  const premiumFeaturesFlagProps = (vehicle?.features ?? [])
    .filter((feature) => feature.isPremium)
    .map(
      (feature) =>
        ({
          label: feature.translation,
          colorScheme: 'blue',
          size: 'small',
        }) as const
    );

  const vehicleHighlightsFlagProps = (vehicleHighlights ?? []).map(
    (item) =>
      ({
        label: item.title,
        size: 'small',
      }) as const
  );

  const vehicleShortcomingFlagProps = (vehicleShortcoming ?? []).map(
    (item) =>
      ({
        label: item.title,
        isSubtle: true,
        colorScheme: 'red',
        size: 'small',
      }) as const
  );

  const onClickBuy = () => {
    openDialog(<VehicleBuyForm vehicleId={vehicleId} />, {
      id: 'vehicleBuy-dialog',
      title: i18n.t('entity.vehicle.actions.addVehicleToBuy'),
      'data-testid': 'vehicleBuy-dialog',
    });
  };
  const onClickSell = () => {
    if (saleVehicle?.activeReservation?.paidAdvanceDeposit) {
      openConfirmDialog({
        text: i18n.t('entity.vehicle.labels.reservationWithAdvanceDepositExistsConfirmation'),
        onConfirm: () =>
          navigate({
            pathname: businessCaseRoutes.concept,
            search: createSearchParams({
              vehicleId,
            }).toString(),
          }),
      });
    } else {
      navigate({
        pathname: businessCaseRoutes.concept,
        search: createSearchParams({
          vehicleId,
        }).toString(),
      });
    }
  };

  const sellingBCWidget = getBusinessCaseSnippet(
    saleVehicle,
    !isLastActiveSellingBCError ? sellingVehicleBC : undefined
  );
  const buyingBCWidget = getBusinessCaseSnippet(
    saleVehicle,
    !isLastActiveBuyingBCError ? lastActiveBuyingBC : undefined,
    isNotNil(sellingBCWidget)
  );

  const highlights = [
    ...vehicleHighlightsFlagProps,
    ...vehicleShortcomingFlagProps,
    ...premiumFeaturesFlagProps,
    ...highlightedFeaturesFlagProps,
  ];

  const serviceCaseState = useStateFlag(lastActiveSC?.state as StateType);
  const serviceCaseOrderState = useStateFlag(lastActiveSC?.ordersState as StateType);

  const [vehicleUtils] = useVehicleCatalogue(vehicle?.type);

  const bodyStyle = vehicleUtils.getBodyStyle(vehicle?.bodyStyle);
  const vehicleType = vehicleUtils.getVehicleType(vehicle?.type);

  const manufacturedOn = useGetManufacturedOn(vehicle?.manufacturedOn);

  const formatDateTime = useDateTimeFormatter();

  const {selection} = useGetVehicleCustomerQuery(
    {vehicleId},
    {
      skip:
        !vehicleId ||
        !isServiceVehicle ||
        !isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2),
      selectFromResult: (result) => ({
        ...result,
        selection: {
          vehicleOperator: result.data?.find(
            (item) => item?.customerType === 'CORVEHICLECUSTOMERTYPE_OPERATOR'
          ),
        },
      }),
    }
  );

  const customerQuery = useGetCustomerV2Query(
    {customerId: defaultTo('', selection.vehicleOperator?.customerId)},
    {skip: !selection.vehicleOperator}
  );

  const getVehicleOperatorParameters = () => {
    if (!customerQuery.data) {
      return [];
    }

    const email = match(customerQuery.data.discriminator)
      .with('NATURAL_PERSON', () =>
        defaultTo(EMPTY_PLACEHOLDER, customerQuery.data?.foundingPerson?.emails[0]?.email)
      )
      .with('BUSINESS', () =>
        defaultTo(EMPTY_PLACEHOLDER, customerQuery.data?.contacts[0].emails[0]?.email)
      )
      .otherwise(() => EMPTY_PLACEHOLDER);

    const phoneNumber = match(customerQuery.data.discriminator)
      .with('NATURAL_PERSON', () => {
        const phoneNumberRaw = customerQuery.data?.foundingPerson?.phoneNumbers[0];

        return phoneNumberRaw
          ? formatPhoneNumber(`${phoneNumberRaw.prefix}${phoneNumberRaw.number}`)
          : EMPTY_PLACEHOLDER;
      })
      .with('BUSINESS', () => {
        const phoneNumberRaw = customerQuery.data?.contacts[0].phoneNumbers[0];

        return phoneNumberRaw
          ? formatPhoneNumber(`${phoneNumberRaw.prefix}${phoneNumberRaw.number}`)
          : EMPTY_PLACEHOLDER;
      })
      .otherwise(() => EMPTY_PLACEHOLDER);

    return [phoneNumber, email];
  };

  const getSnippets = (): SnippetProps[] => {
    if (isServiceVehicle && isFeatureEnabled(featureFlags.AFTS_SERVICE_VEHICLE_ENTITY_V2)) {
      return [
        {
          label: i18n.t('entity.vehicle.labels.specification'),
          title: [vehicleType, bodyStyle, manufacturedOn]
            .filter(Boolean)
            .join(` ${DOT_CHARACTER} `),
          parameters: isNotNilOrEmpty(specificationParameters)
            ? specificationParameters
            : [EMPTY_PLACEHOLDER],
          'data-testid': testIds.vehicles.detail('headerSnippet-specification'),
        },
        {
          label: i18n.t('entity.vehicle.labels.highlights'),
          flags: highlights,
          placeholder: {title: i18n.t('entity.vehicle.labels.noHighlights')},
          isDisabled: isNilOrEmpty(highlights),
          'data-testid': testIds.vehicles.detail('headerSnippet-highlights'),
        },
        {
          label: i18n.t('entity.ownership.label.vehicleOperator'),
          title: getCustomerName(customerQuery.data),
          href: customerQuery.data
            ? composePath(customerRoutes.detail, {
                params: {id: customerQuery.data.id},
                isSearchParamsPreserved: false,
              })
            : undefined,
          target: '_blank',
          parameters: reject(isNil, getVehicleOperatorParameters()),
          isDisabled: isNilOrEmpty(customerQuery.data),
          'data-testid': testIds.vehicles.detail('headerSnippet-operator'),
        },
        {
          label: i18n.t('page.vehicle.labels.lastActiveServiceCase'),
          placeholder: {
            title: i18n.t('page.workshop.labels.newServiceCase'),
            onClick: () =>
              navigate(
                composePath(workshopRoutes.createServiceCase, {
                  queryParams: {vehicleId},
                  isSearchParamsPreserved: false,
                })
              ),
          },
          href: lastActiveSC
            ? composePath(workshopRoutes.serviceCaseDetail, {
                params: {id: lastActiveSC?.id},
                isSearchParamsPreserved: false,
              })
            : undefined,
          target: '_blank',
          title: lastActiveSC?.number || '',
          flags: reject(isNil, [serviceCaseState, serviceCaseOrderState]),
          parameters: reject(isNil, [
            lastActiveSC?.createdAt
              ? formatDateTime('dateShort', parseDate(lastActiveSC?.createdAt))
              : undefined,
            `${i18n.t('general.labels.createdBy')}: ${getUserName(serviceCaseCreator)}`,
          ]),
          'data-testid': testIds.vehicles.detail('headerSnippet-lastActiveService'),
        },
      ];
    }

    // display logic
    // https://carvago.atlassian.net/browse/T20-34094
    return buildArray<SnippetProps>([
      {
        label: i18n.t('entity.vehicle.labels.specification'),
        parameters: isNotNilOrEmpty(specificationParameters)
          ? specificationParameters
          : [EMPTY_PLACEHOLDER],
        title: specificationsTitle,
        'data-testid': testIds.vehicles.detail('headerSnippet-specification'),
      },
    ])
      .when(canReadAdvertisingSettings && !isNotServiceNorSaleVehicle, {
        label: i18n.t('entity.vehicle.labels.highlights'),
        flags: highlights,
        placeholder: {title: i18n.t('entity.vehicle.labels.noHighlights')},
        isDisabled: isNilOrEmpty(highlights),
        'data-testid': testIds.vehicles.detail('headerSnippet-highlights'),
      })
      .when((isSaleVehicle || isNotServiceNorSaleVehicle) && canReadPurchaseVehicle, {
        label: i18n.t('page.businessCase.labels.activeSellingBusinessCase'),
        placeholder: {
          title: i18n.t('entity.vehicle.labels.buyVehicle'),
          onClick: onClickBuy,
          isDisabled: !canCreatePurchase,
        },
        'data-testid': testIds.vehicles.detail('headerSnippet-sellingBc'),
        ...buyingBCWidget,
      })
      .when((isSaleVehicle || isNotServiceNorSaleVehicle) && canReadSellVehicle, {
        label: i18n.t('page.businessCase.labels.activeBuyingBusinessCase'),
        placeholder: {
          title: i18n.t('entity.vehicle.labels.sellVehicle'),
          onClick: onClickSell,
          isDisabled: !canCreateSell,
        },
        'data-testid': testIds.vehicles.detail('headerSnippet-buyingBc'),
        ...sellingBCWidget,
      })
      .when(isServiceVehicle || isNotServiceNorSaleVehicle, {
        label: i18n.t('page.vehicle.labels.lastActiveServiceCase'),
        placeholder: {
          title: i18n.t('page.workshop.labels.newServiceCase'),
          onClick: () =>
            navigate(
              composePath(workshopRoutes.createServiceCase, {
                queryParams: {vehicleId},
                isSearchParamsPreserved: false,
              })
            ),
        },
        href: lastActiveSC
          ? composePath(workshopRoutes.serviceCaseDetail, {
              params: {id: lastActiveSC?.id},
              isSearchParamsPreserved: false,
            })
          : undefined,
        title: getCustomerName(serviceCaseCustomer),
        icon: 'action/build',
        flags: reject(isNil, [serviceCaseState, serviceCaseOrderState]),
        onClick: () =>
          navigate(
            composePath(workshopRoutes.serviceCaseDetail, {
              params: {id: lastActiveSC?.id},
              isSearchParamsPreserved: false,
            })
          ),
        parameters: reject(isNil, [
          lastActiveSC?.number,
          `${i18n.t('general.labels.createdBy')}: ${getUserName(serviceCaseCreator)}`,
        ]),
      });
  };

  const snippets = getSnippets();

  return {snippets, isLoading};
};

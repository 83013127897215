import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';

import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {Main, PageTab} from '@omnetic-dms/shared';

import {IssueNotes} from './components/IssueNotes';
import {ServiceOrderIssues} from './components/ServiceOrderIssues';

export function ServiceOrderIssueList() {
  const navigate = useNavigate();

  const tabs: PageTab[] = [
    {
      id: 'serviceOrderIssues',
      title: i18n.t('page.warehouse.labels.serviceOrderIssues'),
      content: <ServiceOrderIssues />,
      href: warehouseRoutes.serviceOrderIssues,
    },
    {
      id: 'issueNotes',
      title: i18n.t('page.warehouse.labels.issueNotes'),
      content: <IssueNotes />,
      href: warehouseRoutes.issueNotes,
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('module.warehouse.title')} />
      <Main
        isFullHeight
        tabs={tabs}
        actions={[
          {
            type: 'button',
            title: i18n.t('entity.warehouse.labels.newServiceOrderIssue'),
            onClick: () => navigate(warehouseRoutes.serviceOrderIssueCreate),
            'data-testid': testIds.warehouse.serviceOrderIssues('newServiceOrderIssue'),
          },
        ]}
      />
    </>
  );
}

import {Button, TextInput} from 'platform/components';
import {GridItem, Grid, Right, VStack} from 'platform/foundation';

import {useState} from 'react';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {MatchingVehicles} from './MatchingVehicles';
import {CreateVehicleInitValues} from './types';

interface VehicleFinderProps extends TestIdProps {
  onVehicleSelect?: (vehicleId: string) => void;
  onCreateClick: (data: CreateVehicleInitValues) => void;
}

export function VehicleFinder(props: VehicleFinderProps) {
  const [licencePlate, setLicencePlate] = useState<string | null>(null);
  const [vinCode, setVinCode] = useState<string | null>(null);

  const vin = vinCode?.length === 17 ? parseVin(vinCode) : undefined;
  const registrationPlateContains = parsePlate(licencePlate);

  return (
    <VStack spacing={4}>
      <Grid columns={12}>
        <GridItem span={4}>
          <TextInput
            name="registrationPlate"
            onChange={setLicencePlate}
            value={licencePlate}
            label={i18n.t('entity.vehicle.labels.licensePlate')}
            data-testid={suffixTestId('registrationPlate', props)}
          />
        </GridItem>
        <GridItem span={8}>
          <TextInput
            name="vin"
            onChange={setVinCode}
            value={vinCode}
            maxLength={17}
            isCounterVisible
            label={i18n.t('entity.vehicle.labels.vinCode')}
            data-testid={suffixTestId('vin', props)}
          />
        </GridItem>
      </Grid>
      <MatchingVehicles
        data-testid={suffixTestId('matchingVehicles', props)}
        vin={vin}
        registrationPlateContains={registrationPlateContains}
        onVehicleSelect={(vehicle) => props.onVehicleSelect?.(vehicle.id)}
      />
      <Right>
        <Button
          data-testid={suffixTestId('createNewVehicle', props)}
          title={i18n.t('entity.vehicle.actions.createNewVehicle')}
          variant="primary"
          onClick={() => props.onCreateClick({licencePlate, vinCode})}
        />
      </Right>
    </VStack>
  );
}

const vinCharRegex = /[(A-H|J-N|P|R-Z|0-9)]{1,17}/;
const registrationPlateCharRegex = /[A-Z0-9]/g;

function parseVin(value?: string | null) {
  if (!value) {
    return undefined;
  }
  return value.trim().toUpperCase().match(vinCharRegex)?.slice(0, 17).join('');
}

function parsePlate(value?: string | null) {
  if (!value) {
    return undefined;
  }
  return value.trim().toUpperCase().match(registrationPlateCharRegex)?.slice(0, 17).join('');
}

import {useSubscription} from '@apollo/client';
import {ProgressBar, showNotification, Upload, UploadState} from 'platform/components';
import {Box, Center, Grid} from 'platform/foundation';
import {Lightbox, LightboxVideo, useLightbox} from 'platform/lightbox';
import {RcFile} from 'rc-upload/lib/interface';

import {CSSProperties, useState} from 'react';
import {useSelector} from 'react-redux';

import {isNil, isNotNil} from 'ramda';

import {
  AuditDataVideoAssetsFilesBody,
  SEND_NOTIFICATION_SUBSCRIPTION,
  useDeleteVideoAssetMutation,
  useLazyGetSentBackgroundNotificationQuery,
  useLazyGetVideoAssetQuery,
  useUploadVideoAssetMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, notificationTypes} from '@omnetic-dms/shared';

import {Nullish, parseDate, randomLowerCaseUUID, suffixTestId, TestIdProps} from 'shared';

import {
  QueueVideoTask,
  RawFile,
  useVideoUploadQueueAction,
  useVideoUploadQueueState,
} from 'features/video-upload-queue';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {
  addVideoAssets,
  deleteVideoAssets,
  updateAuditState,
  updateVideoAssetData,
} from '../../../store/carAudit/reducer';
import {selectActiveAuditId} from '../../../store/carAudit/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {useAuditVideos} from '../hooks/useAuditVideos';
import {useConditionContext} from '../hooks/useConditionContext';
import {isFileVideo} from '../utils/isFileVideo';
import {AuditVideo} from './AuditVideo';

interface AuditVideosProps extends TestIdProps {
  paramDefinitionId: string;
  categoryId: string;
  ratio?: CSSProperties['aspectRatio'];
  isMandatory?: boolean;
}

const isVideoPlayable = (videoAsset: AuditDataVideoAssetsFilesBody) =>
  isNotNil(videoAsset.originUrl) ||
  (videoAsset.variants?.filter((variant) => isNotNil(variant.url)).length ?? 0) > 0;

const precedingPlayableVideos = (videoAssets: AuditDataVideoAssetsFilesBody[], index: number) =>
  videoAssets.slice(0, index).filter(isVideoPlayable).length;

export function AuditVideos(props: AuditVideosProps) {
  const {videoAssets} = useAuditVideos(props.categoryId, props.paramDefinitionId);
  const {handleChangeCategory} = useConditionContext();
  const auditId = useSelector(selectActiveAuditId);
  const dispatch = useThunkDispatch();
  const {isDisabledForUser} = useConditionContext();
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.Idle);
  const {tasks, uploadProgress} = useVideoUploadQueueState();
  const {handleMultiMultiPartUpload} = useVideoUploadQueueAction();
  const [getVideoAsset] = useLazyGetVideoAssetQuery();
  const [uploadVideoAsset] = useUploadVideoAssetMutation();
  const [deleteVideoAsset] = useDeleteVideoAssetMutation();
  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();

  const [lightboxUploadImagesGalleryControls, {onOpen: onUploadImagesGalleryOpen}] = useLightbox(
    `auditVideos-${props.categoryId}-${props.paramDefinitionId}`
  );

  const lightboxVideoAssets: LightboxVideo[] | Nullish = videoAssets.filter(isVideoPlayable);

  // Subscribe to notifications to know when to fetch generated variants of uploaded videos
  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data: subscription}) => {
      const backgroundNotificationId: string | null =
        subscription.data?.onSendNotification?.backgroundNotificationId;

      if (isNotNil(backgroundNotificationId)) {
        getBackgroundNotification({id: backgroundNotificationId})
          .unwrap()
          .then((notification) => {
            if (notification.type === notificationTypes.FILE_STORAGE_FILE_VARIANT_UPLOADED) {
              const videoAssetId = videoAssets?.find(
                (asset) => asset.videoId === notification.payload.originalFileId
              )?.id;
              if (isNotNil(auditId) && isNotNil(videoAssetId)) {
                getVideoAsset({auditId, videoAssetId})
                  .unwrap()
                  .then((response) => {
                    dispatch(
                      updateVideoAssetData({
                        categoryId: props.categoryId,
                        paramDefinitionId: props.paramDefinitionId,
                        videoId: response.videoId,
                        asset: response,
                      })
                    );
                  })
                  .catch(handleApiError);
              }
            }
          })
          .catch(handleApiError);
      }
    },
  });

  // Updating or deleting the video the status of the audit should be changed to in progress
  const onVideoUploadedOrDeleted = () => {
    // This is not RTK Query – unwrap() is not available
    // eslint-disable-next-line no-restricted-syntax
    handleChangeCategory()
      .then(() => {
        dispatch(
          updateAuditState({
            state: LoadAuditDataResponseItemBody.state.IN_PROGRESS,
          })
        );
      })
      .catch(handleApiError);
  };

  const handleUploadFinished = (params: QueueVideoTask) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    const options = {
      videoId: params.connectionFileId,
      auditId,
      categoryId: props.categoryId,
      updatedAt: parseDate(Date.now()).toISOString(),
      vehicleAuditVideoAssetId: params.id,
      paramDefinitionId: props.paramDefinitionId,
    };

    uploadVideoAsset({auditId: options.auditId, body: options})
      .unwrap()
      .then((response) => {
        dispatch(
          addVideoAssets({
            categoryId: props.categoryId,
            paramDefinitionId: props.paramDefinitionId,
            assets: [{id: response.id, videoId: params.connectionFileId}],
          })
        );
        onVideoUploadedOrDeleted();
        showNotification.success(
          i18n.t('entity.inspection.labels.uploadCompleted', {filename: params.name})
        );
      })
      .catch(handleApiError);
  };

  const handleStartUpload = async (file: RcFile) => {
    if (!isFileVideo(file)) {
      showNotification.error(i18n.t('entity.inspection.errors.invalidFileType'));
      return;
    }

    setUploadState(UploadState.Uploading);

    const rawFile: RawFile = {
      id: randomLowerCaseUUID(),
      uri: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      mimeType: file.type,
    };

    await handleMultiMultiPartUpload(rawFile, handleUploadFinished);
    setUploadState(UploadState.Success);
  };

  const handleDeleteVideo = (videoAsset: AuditDataVideoAssetsFilesBody) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    deleteVideoAsset({auditId, videoAssetId: videoAsset.id})
      .unwrap()
      .then(() => {
        dispatch(
          deleteVideoAssets({
            categoryId: props.categoryId,
            paramDefinitionId: props.paramDefinitionId,
            videoIds: [videoAsset.videoId],
          })
        );
        onVideoUploadedOrDeleted();
        showNotification.success(i18n.t('entity.inspection.labels.deleteVideoAssetCompleted'));
      })
      .catch(handleApiError);
  };

  return (
    <Grid columns={8}>
      <Lightbox
        data-testid={suffixTestId('audit-uploadVideosGallery', props)}
        controls={lightboxUploadImagesGalleryControls}
        videos={lightboxVideoAssets}
      />
      {videoAssets?.map((videoAsset, index) => (
        <AuditVideo
          key={videoAsset.id}
          videoAsset={videoAsset}
          ratio={props.ratio}
          isLoading={!isVideoPlayable(videoAsset)}
          isDisabled={isDisabledForUser}
          onOpenPreview={() =>
            onUploadImagesGalleryOpen(precedingPlayableVideos(videoAssets, index))
          }
          onDeleteVideo={handleDeleteVideo}
          data-testid={suffixTestId('auditVideo', props)}
        />
      ))}

      {tasks.map((task) => {
        const progress =
          (uploadProgress.find((progress) => (progress.id = task.id))?.progress ?? 0) / 100;

        return (
          <Box
            key={task.id}
            width="100%"
            height="100%"
            ratio={props.ratio}
            padding={2}
            border="1px dashed"
            borderColor="palettes.neutral.80.100"
            borderRadius="xSmall"
          >
            <Center height="100%">
              <ProgressBar
                percentage={progress || undefined}
                indeterminate={progress === 0}
                small
              />
            </Center>
          </Box>
        );
      })}

      <Box width="100%" height="100%" ratio={props.ratio} position="relative" overflow="hidden">
        <Upload
          size="default"
          accept="video/*"
          type="card"
          uploadIcon="image/video_call"
          uploadText={`${props.isMandatory ? '*' : ''} ${i18n.t('entity.video.labels.add')}`}
          errorIcon="navigation/cancel"
          customRequest={({onSuccess}) => {
            onSuccess?.('', new XMLHttpRequest());
          }}
          onStart={handleStartUpload}
          uploadState={uploadState}
          isMultiple
          isDisabled={isDisabledForUser}
          data-testid={suffixTestId('uploadControl', props)}
        />
      </Box>
    </Grid>
  );
}

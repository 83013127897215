import {ReactElement, MutableRefObject} from 'react';

export type DraggableDataWithContextId<T> = {droppableId?: string; data: T};
export type MultipleSelectionComponentType = (count: number) => ReactElement;
export enum HoverPositions {
  Left = 'left',
  Right = 'right',
}
export type HoveredItemRefType = [string, HoverPositions];

export type ContextType<TDnDData> = {
  currentHoveredSide?: HoverPositions;
  contextState: MutableRefObject<DragAndDropContextType<TDnDData>>;
  selectedItems: {
    count: number;
    isSelected: boolean;
  };
  isDragged: boolean;
  droppableId: string;
  draggedItem: DragAndDropContextType<TDnDData>['draggedItem'];
  providerId: string;
  globalDragging: boolean;
};

// Draggable
export type DraggableProps<T> = {
  /**
   * Disables position recognition - when you hover over this item when you drag, this item only has `left` as `hoverSide` in `draggableProps`
   */
  disablePositions?: boolean;
  /**
   * Unique identifier used for recognizing dragged item
   */
  draggableId: string;
  /**
   * You can pass data props that will be used in ContextProvider - when `onDragEnd` is called in `Provider`, this will be passed to `onDragEnd` as parameter
   */
  data?: T;
  /**
   * If `true` disables drag functionality
   */
  disable?: boolean;
  /**
   * Function for generating Draggable and stationary element
   */
  children: any;
};

export type DraggedContentProps = {
  globalDragging?: boolean;
  isDragging: boolean;
  bounds: () => DOMRect | undefined;
  finishedCallback: (isFin: boolean) => void;
  children: ReactElement;
};

// Droppable
export type DroppableProps = {
  /**
   * ID of this droppable
   */
  droppableId: string;
  /**
   * @param elementProps - props that are meant to be passed to another element, selected element will behave as droppable
   * @param droppableState - droppable current state
   */
  children: (
    elementProps: DroppableChildrenElementProps,
    droppableState: DroppableChildrenProps
  ) => ReactElement;
};

export type DroppableChildrenElementProps = {
  ref: MutableRefObject<HTMLDivElement>;
};

export type DroppableChildrenProps = {
  /**
   * ID of this droppable (from `Droppable` props)
   */
  droppableId: string;
  /**
   * Info about dragged item
   */
  draggedItem?: {
    /**
     * Which droppable it is being dragged from
     */
    from: string;
    /**
     * Which droppable it is currently being moved over
     */
    currentlyIn: string;
  };
  /**
   * `true` when draggable is being moved over droppable but not over any other draggable (on empty space)
   */
  isHoveringOverEmpty: boolean;
};

export type DroppableContext = {
  droppableId: string;
};

// DnDProvider
export type DragAndDropProviderProps<T extends Record<string, unknown> = Record<string, unknown>> =
  {
    multipleSelectionComponent?: MultipleSelectionComponentType;
    selectedItems: string[];
    onDragStart?: (draggableId: string) => void;
    onDragEnd?: (
      draggable: DraggableDataWithContextId<T>[],
      position: number,
      dropItem?: DraggableDataWithContextId<T>,
      direction?: HoverPositions
    ) => void;
    onDragFail?: () => void;
    hoverBuffer?: number;
  };

export type DndStoreType = (
  activeDroppableRef: MutableRefObject<string>,
  onDragStart: DragAndDropProviderProps['onDragStart'],
  setHoveredItem: (draggableId?: string, direction?: HoverPositions) => void
) => {
  draggedItem: {dragId?: string | null; dropId?: string};
  setDraggedItem: (draggedItem: {dragId: string | null; dropId?: string}) => void;
  activeDroppable?: string | null;
  setActiveDroppable: (droppableId: string | null) => void;
  isHovered: boolean;
  setHoveredItem: (draggableId?: string, direction?: HoverPositions) => void;
};

export type DragAndDropContextType<T = Record<string, unknown>> = {
  globalDragging: boolean;
  multipleSelectionComponent?: MultipleSelectionComponentType;
  providerId: string | null;
  selectedItems: string[];
  register: (draggableId: string, data?: T, droppableId?: string) => void;
  unregister: (draggableId: string) => void;
  onDragStart?: (draggableId: string) => void;
  onDragEnd?: (draggableId: string) => void;
  lastHoveredItem: MutableRefObject<HoveredItemRefType | undefined>;
} & ReturnType<DndStoreType>;

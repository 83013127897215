import {PayloadAction} from '@reduxjs/toolkit';
import {AnyObject} from 'final-form';
import {showNotification} from 'platform/components';
import {all, put, takeLatest, takeLeading} from 'typed-redux-saga';

import {PublishingService} from '../../services/PublishingService';
import {
  ChangeCredentialsActionsType,
  EPlatformStatusAction,
  UpdatePlatformType,
} from '../../types/AdvertisementSettings';
import {AdvertisingPlatformCodeEnum} from '../../types/AdvertisingPlatformCodeEnum';
import {EditTipCarsClientDataRequestBody} from '../../types/EditTipCarsClientDataRequestBody';
import {FetchAdPlatformPayloadType} from '../../types/GeneralSettings';
import {callApiSaga} from '../../utils/api';
import {getErrorMessages} from '../../utils/getErrorMessage';
import {
  changeCredentialsError,
  changeCredentialsRequest,
  changeCredentialsSuccess,
  fetchAdSettingsRequest,
  fetchAdSettingsSuccess,
  fetchSpecificPlatformError,
  fetchSpecificPlatformRequest,
  fetchSpecificPlatformSuccess,
  fetchTipCarsClientData,
  requestTipCarsClientData,
  requestUpdateTipCarsClientData,
  updatedTipCarsClientData,
  updatePlatformRequest,
  updatePlatformSuccess,
} from './reducer';

function* fetchAdSettingsGen({payload: {branchId}}: PayloadAction<{branchId?: string}>): Generator {
  try {
    const adPlatformData = yield* callApiSaga(PublishingService.getList, {branchId});
    yield* put(fetchAdSettingsSuccess(adPlatformData || []));
  } catch (exception: any) {
    yield* put(fetchAdSettingsSuccess([]));
  }
}

function* updatePlatformGen({
  payload: {platformCode, branchId, action},
}: PayloadAction<UpdatePlatformType>): Generator {
  try {
    if (action === EPlatformStatusAction.ENABLE) {
      yield* callApiSaga(PublishingService.enableTenantPlatform, {
        platformCode,
        branchId,
      });
    } else if (action === EPlatformStatusAction.DISABLE) {
      yield* callApiSaga(PublishingService.disableTenantPlatform, {
        platformCode,
        branchId,
      });
    }

    yield* put(updatePlatformSuccess({platformCode, action} as UpdatePlatformType));
  } catch (exception: any) {
    showNotification.error(exception?.response?.data?.error?.message);
  }
}

function* fetchAdPlatform({payload}: PayloadAction<FetchAdPlatformPayloadType>): Generator {
  try {
    const {platformCode, branchId} = payload;

    let platformInformation;
    switch (platformCode as AdvertisingPlatformCodeEnum) {
      case AdvertisingPlatformCodeEnum.MYWEB:
        platformInformation = yield* callApiSaga(PublishingService.getMyWebPlatform, {branchId});
        break;
      case AdvertisingPlatformCodeEnum.SAUTO:
        platformInformation = yield* callApiSaga(PublishingService.getSAutoPlatform, {branchId});
        break;
      case AdvertisingPlatformCodeEnum.FACEBOOK:
        platformInformation = yield* callApiSaga(PublishingService.getFacebookPlatform, {branchId});
        break;
      case AdvertisingPlatformCodeEnum.TIPCARS:
        platformInformation = yield* callApiSaga(PublishingService.getTipCarsPlatform, {branchId});
        break;
      case AdvertisingPlatformCodeEnum.MOBILE_DE:
        platformInformation = yield* callApiSaga(PublishingService.getMobileDePlatform, {branchId});
        break;
      case AdvertisingPlatformCodeEnum.AUTOCARIS:
        platformInformation = yield* callApiSaga(PublishingService.getAutocarisPlatform, {
          branchId,
        });
        break;
      case AdvertisingPlatformCodeEnum.AUTOSOFT:
        platformInformation = yield* callApiSaga(PublishingService.getAutosoftPlatform, {branchId});
        break;
    }

    yield* put(
      fetchSpecificPlatformSuccess({
        extraFields: [],
        ...(platformInformation as any), // FIXME: !!
      })
    );
  } catch (error: any) {
    yield* put(fetchSpecificPlatformError(error));
  }
}

function* tryToChangeCredentials({
  payload: {platformCode, credentials, branchId},
}: PayloadAction<ChangeCredentialsActionsType>): Generator {
  try {
    let data;
    const requestBody: AnyObject = {requestBody: credentials, branchId};
    switch (platformCode as AdvertisingPlatformCodeEnum) {
      case AdvertisingPlatformCodeEnum.MYWEB:
        data = yield* callApiSaga(PublishingService.myWebAuthenticate, requestBody);
        break;
      case AdvertisingPlatformCodeEnum.SAUTO:
        data = yield* callApiSaga(PublishingService.sAutoAuthenticate, requestBody);
        break;
      case AdvertisingPlatformCodeEnum.FACEBOOK:
        data = yield* callApiSaga(PublishingService.facebookAuthenticate, requestBody);
        break;
      case AdvertisingPlatformCodeEnum.TIPCARS:
        data = yield* callApiSaga(PublishingService.tipCarsAuthenticate, requestBody);
        break;
      case AdvertisingPlatformCodeEnum.MOBILE_DE:
        data = yield* callApiSaga(PublishingService.mobileDeAuthenticate, requestBody);
        break;
      case AdvertisingPlatformCodeEnum.AUTOCARIS:
        data = yield* callApiSaga(PublishingService.autocarisAuthenticate, requestBody);
        break;
    }

    yield* put(changeCredentialsSuccess({data, code: platformCode}));
  } catch (error: any) {
    yield* put(changeCredentialsError({...error, code: platformCode}));
  }
}

function* updateTipCarsClientData({
  payload: {clientData, branchId},
}: PayloadAction<{clientData: EditTipCarsClientDataRequestBody; branchId?: string}>) {
  try {
    yield* callApiSaga(PublishingService.editTipCarsClientData, {
      requestBody: clientData,
      branchId,
    });
    showNotification.success();
  } catch (error: any) {
    showNotification.error(getErrorMessages(error).toString());
  }
  yield* put(updatedTipCarsClientData());
}

function* getTipCarsClientData({
  payload: {branchId},
}: PayloadAction<{branchId?: string}>): Generator {
  try {
    const adPlatformData = yield* callApiSaga(PublishingService.getTipCarsClientData, {branchId});
    yield* put(fetchTipCarsClientData(adPlatformData as EditTipCarsClientDataRequestBody));
  } catch (error: any) {
    showNotification.error(getErrorMessages(error).toString());
  }
}

export function* advertisementSettingsSaga(): Generator {
  yield* all([
    takeLeading(fetchAdSettingsRequest, fetchAdSettingsGen),
    takeLatest(fetchSpecificPlatformRequest, fetchAdPlatform),
    takeLatest(changeCredentialsRequest, tryToChangeCredentials),
    takeLatest(requestUpdateTipCarsClientData, updateTipCarsClientData),
    takeLatest(updatePlatformRequest, updatePlatformGen),
    takeLatest(requestTipCarsClientData, getTipCarsClientData),
  ]);
}

import {isNotNil} from 'ramda';

import {FullVehicle, FullVehicleResponseBody, VehicleTypeEnumObject} from '@omnetic-dms/api';

import {MAKE_OTHER, MODEL_FAMILY_OTHER} from '../../../../../constants/makes';
import {VehicleCreateFormStateWithNote} from './getRequestBody';
import {getRegistrationDate} from './vehicleDuplicatesHintsUtils';

export const getFormValues = (
  vehicle: FullVehicle | FullVehicleResponseBody
): VehicleCreateFormStateWithNote => {
  const featuresSet = [...new Set(vehicle?.features?.map((feat) => feat.key) ?? []).values()];

  return {
    ...vehicle,
    battery: vehicle.vehicle.battery,
    dimensions: vehicle.dimensions || {},
    engine: vehicle.engine || {},
    modelSpecification: vehicle.modelSpecification || {},
    state: vehicle?.state || {},
    sellingPrice: vehicle.sellingPrice.withoutVat?.amount,
    sellingPriceWithVat: vehicle.sellingPrice.withVat?.amount,
    sellingPriceVatRate: vehicle.sellingPrice.vatRate,
    purchasePrice: vehicle.purchasePrice.withoutVat?.amount,
    purchasePriceWithVat: vehicle.purchasePrice.withVat?.amount,
    purchasePriceVatRate: vehicle.purchasePrice.vatRate,
    type: vehicle.type as VehicleTypeEnumObject,
    features: featuresSet,
    make: (vehicle.make === MAKE_OTHER ? vehicle.customMake : vehicle.make) as string,
    modelFamily: (vehicle.modelFamily === MODEL_FAMILY_OTHER
      ? vehicle.customModelFamily
      : vehicle.modelFamily) as string,
    firstRegistrationDate: getRegistrationDate(vehicle),
    manufacturedOnYear: isNotNil(vehicle.manufacturedOnYear)
      ? // eslint-disable-next-line no-restricted-syntax
        (`${vehicle.manufacturedOnYear}` as unknown as number) // TODO fix types with BE
      : null,
    manufacturedOnMonth: isNotNil(vehicle.manufacturedOnMonth)
      ? // eslint-disable-next-line no-restricted-syntax
        (`${vehicle.manufacturedOnMonth}` as unknown as number) // TODO fix types with BE
      : null,
  };
};

interface MenuItem<T extends {id: string} = {id: string}> {
  id: string;
  content?: T[];
}

type MenuItemWithPin<T extends MenuItem> = T & {
  isPinned: boolean;
};

export function addPinsToMenuItems<TMenuItem extends MenuItem<TMenuItem>>(
  menuItems: TMenuItem[],
  pinnedMenuItems: string[]
): MenuItemWithPin<TMenuItem>[] {
  return menuItems.map((menuItem) => {
    const isPinned = pinnedMenuItems.includes(menuItem.id);
    return {
      ...menuItem,
      isPinned,
      content: menuItem.content ? addPinsToMenuItems(menuItem.content, pinnedMenuItems) : undefined,
    };
  });
}

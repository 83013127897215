import {Search} from 'platform/components';
import {Box, Heading, VStack} from 'platform/foundation';

import {useDeferredValue, useState} from 'react';

import i18n from '@omnetic-dms/i18n';

import {useRequiredParams} from 'shared';

import {AccessControlList} from '../../AccessControlList/AccessControlList';
import {RoleNameForm} from '../../RoleDetail/RoleNameForm';

export function RoleDetail() {
  const {roleId} = useRequiredParams();
  const [searchString, setSearchString] = useState<string | null>(null);

  const deferredSearch = useDeferredValue(searchString);

  return (
    <VStack spacing={5}>
      <RoleNameForm roleId={roleId} />

      <Heading size={4}>{i18n.t('page.settings.role.tabs.label.permissions')}</Heading>
      <Box width={60}>
        <Search value={searchString} onChange={setSearchString} />
      </Box>

      <AccessControlList roleId={roleId} searchString={deferredSearch} />
    </VStack>
  );
}

import {ThemeIconKey, Icon, isTouchable} from 'platform/foundation';
import {css} from 'styled-components';

import {MouseEventHandler} from 'react';

import {RequiredTestIdProps, suffixTestId} from 'shared';

export interface IconButtonProps extends RequiredTestIdProps {
  icon: ThemeIconKey;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function IconButton(props: IconButtonProps) {
  const isTouchableDevice = isTouchable();
  return (
    <button
      data-testid={suffixTestId('iconButton', props)}
      onClick={props.onClick}
      disabled={props.isDisabled}
      type="button"
      css={css`
        gap: ${({theme}) => theme.getSize(2)};
        padding: ${({theme}) => theme.getSize(2)};
        height: 100%;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};
        pointer-events: ${props.isDisabled ? 'none' : 'all'};
        color: ${({theme}) => theme.colors.general.white};
        background-color: ${({theme}) => theme.colors.palettes.black['900']['70']};
        border: none;
        opacity: ${props.isDisabled ? 0.2 : 0.6};
        transition: opacity 0.3s ease-out;

        ${!isTouchableDevice &&
        `
          &:hover {
            opacity: 1;
          }
        `}
      `}
    >
      <Icon color="general.white" value={props.icon} size={5} />
    </button>
  );
}

import {Switch} from 'platform/components';

import {
  useEnablePublishCebiaReportMutation,
  useDisablePublishCebiaReportMutation,
  useGetCebiaReportConfigQuery,
} from '@omnetic-dms/api';
import {handleApiError} from '@omnetic-dms/shared';

import {Nullish} from 'shared';

interface CebiaReportPublishSwitchProps {
  vehicleId: string;
  label?: string | Nullish;
}

export function CebiaReportPublishSwitch(props: CebiaReportPublishSwitchProps) {
  const {data} = useGetCebiaReportConfigQuery({vehicleId: props.vehicleId});
  const [enablePublishCebiaReport] = useEnablePublishCebiaReportMutation();
  const [disablePublishCebiaReport] = useDisablePublishCebiaReportMutation();

  const onChange = (value: boolean) => {
    if (value) {
      enablePublishCebiaReport({vehicleId: props.vehicleId}).unwrap().catch(handleApiError);
    } else {
      disablePublishCebiaReport({vehicleId: props.vehicleId}).unwrap().catch(handleApiError);
    }
  };

  return (
    <Switch
      isDisabled={!data}
      label={props.label}
      value={!!data?.publishCebiaReport}
      onChange={onChange}
    />
  );
}

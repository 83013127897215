import {match} from 'ts-pattern';

import {always, defaultTo, isNil} from 'ramda';

import {Nullish, precisionCalculation} from 'shared';

import {CalculateFrom} from '../types/CalculateFrom';
import {SupportedVatRateFormat} from '../types/SupportedVatRateFormat';

const {subtract, divide, multiply} = precisionCalculation;

// Convert "21" to 1.21
export const convertVatRateToNumber = (vatRate: SupportedVatRateFormat) => {
  const formattedVatRate = isNil(vatRate) ? 0 : Number(vatRate);

  return divide(100 + formattedVatRate, 100);
};

export const calculatePriceWithVat = (priceWithoutVat: number, vatRate: SupportedVatRateFormat) =>
  multiply(priceWithoutVat, convertVatRateToNumber(vatRate));

export const calculateMarkup = (priceWithoutVat: number, purchasePrice: number) => {
  if (isNil(purchasePrice) || purchasePrice === 0) {
    return 0;
  }

  return multiply(subtract(divide(priceWithoutVat, purchasePrice), 1), 100);
};

export const calculateMargin = (priceWithoutVat: number, purchasePrice: number) =>
  subtract(priceWithoutVat, purchasePrice);

export const calculateRabat = (priceWithoutVat: number, marge: number) => {
  if (isNil(priceWithoutVat) || isNil(marge) || priceWithoutVat === 0 || marge === 0) {
    return 0;
  }

  return multiply(divide(marge, priceWithoutVat), 100);
};

export const calculateDispensingMeasureUnit = (
  value: number,
  dispensingMeasureUnit: number | Nullish,
  calculateFrom?: CalculateFrom
) =>
  match(calculateFrom)
    .with('dispensing', always(multiply(value, defaultTo(1, dispensingMeasureUnit))))
    .with('warehouse', always(divide(value, defaultTo(1, dispensingMeasureUnit))))
    .otherwise(always(value));

export const priceCalculations = {
  convertVatRateToNumber,
  calculatePriceWithVat,
  calculateMarkup,
  calculateMargin,
  calculateDispensingMeasureUnit,
  calculateRabat,
};

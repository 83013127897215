import validatePhone from 'phone';
import {Chips, Dialog, Form, FormField, FormSubmitHandler} from 'platform/components';
import {Box, HStack, Show, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useState} from 'react';

import {drop, equals, not} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty, lengthGte} from 'ramda-adjunct';

import {CustomerDiscriminator, CustomerResponseBodyV2, PhoneNumber} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, yupString, TestIdProps} from 'shared';

import {usePhoneNumbers} from '../../../hooks/usePhoneNumbers';
import {useTenant} from '../../../hooks/useTenant';
import {CreateCustomerFormType} from '../types/CreateCustomerFormType';
import {CreateCustomerProps} from '../types/CreateCustomerProps';
import {SecondStepComponentType} from '../types/SecondStepComponentType';
import {LegalPersonForm} from './LegalPersonForm';
import {MatchingCustomers} from './MatchingCustomers';
import {OnExternalCustomerSelectData} from './MatchingExternalCustomer';
import {PhysicalPersonForm} from './PhysicalPersonForm';

const EU_COUNTRY_CODES =
  /^(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|GB|GR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)/;

const DISCRIMINATOR_CHIPS_OPTIONS = [
  {
    value: 'NATURAL_PERSON',
    label:
      i18n.t('entity.person.labels.physicalPerson') +
      '/' +
      i18n.t('entity.customer.labels.isSelfEmployed'),
  },
  {value: 'BUSINESS', label: i18n.t('general.labels.company')},
];

interface CustomerMatchOrCreateProps extends TestIdProps {
  secondStepComponentType: SecondStepComponentType;
  onCustomer: (customer: CustomerResponseBodyV2) => void;
  vehicleId?: string | Nullish;
}

export function CustomerMatchOrCreate(props: CustomerMatchOrCreateProps) {
  const [customer, setCustomer] = useState<CreateCustomerProps | null>(null);
  const {countriesOptions} = usePhoneNumbers();

  const clearCustomer = () => {
    setCustomer(null);
  };

  const onExternalCustomerSelect = (data: OnExternalCustomerSelectData) => {
    setCustomer({
      ...data,
      isSelfEmployed: false,
      // I can't safely assign phone number from external results since they don't come with prefixes or anything structured
      phoneNumber: null,
      customerGroups: [],
    });
  };

  const onSubmit: FormSubmitHandler<CreateCustomerFormType> = async (data) => {
    let isVatNumber = false;

    if (data.legalNumber) {
      const EUVatNumberRegex = new RegExp(EU_COUNTRY_CODES);
      isVatNumber = EUVatNumberRegex.test(data.legalNumber);
    }

    const splitedName = data.name?.split(' ');
    const lastName = splitedName?.[0] ?? null;
    const firstName = drop(1, splitedName ?? []).join(' ') ?? null;

    await setCustomer({
      isSelfEmployed: !isNilOrEmpty(data.legalNumber),
      customerDiscriminator: 'NATURAL_PERSON',
      firstName,
      lastName,
      tradeName: data.name,
      registrationNumber: isVatNumber ? null : data.legalNumber,
      vatNumber: isVatNumber ? data.legalNumber : null,
      email: data.email,
      phoneNumber: data.phoneNumber,
      customerGroups: [],
    });
  };

  const {tenantPhoneInfo} = useTenant();

  const setCustomerDiscriminator = (discriminator: string[] | Nullish) =>
    setCustomer({
      ...customer,
      customerDiscriminator: discriminator
        ? (discriminator[0] as CustomerDiscriminator)
        : 'NATURAL_PERSON',
    });

  const customerDiscriminatorChipsValue = [customer?.customerDiscriminator || 'NATURAL_PERSON'];

  return (
    <>
      <Dialog
        title={i18n.t('entity.customer.labels.customerInformation')}
        isOpen={isNotNil(customer)}
        onClose={clearCustomer}
        withAdditionalFooter
        data-testid={suffixTestId('dialog', props)}
      >
        <Show when={isNotNilOrEmpty(customer)}>
          <Chips
            onChange={setCustomerDiscriminator}
            value={customerDiscriminatorChipsValue}
            options={DISCRIMINATOR_CHIPS_OPTIONS}
            data-testid={suffixTestId('customerDiscriminatorChipsValue', props)}
          />
          <Space vertical={4} />
        </Show>
        <Show
          when={equals<CustomerDiscriminator | undefined>(
            customer?.customerDiscriminator,
            'NATURAL_PERSON'
          )}
        >
          <PhysicalPersonForm
            customer={customer!}
            onCreate={props.onCustomer}
            onClose={clearCustomer}
            data-testid={suffixTestId('physicalPersonForm', props)}
          />
        </Show>
        <Show when={customer?.customerDiscriminator === 'BUSINESS'}>
          <LegalPersonForm
            customer={customer!}
            onCreate={props.onCustomer}
            onClose={clearCustomer}
            data-testid={suffixTestId('legalPersonForm', props)}
          />
        </Show>
      </Dialog>
      <VStack spacing={3}>
        <Form<CreateCustomerFormType>
          onSubmit={onSubmit}
          schema={schema}
          defaultValues={{
            phoneNumber: {
              prefix: tenantPhoneInfo?.prefix,
              number: '',
              countryCode: tenantPhoneInfo?.countryCode,
            },
          }}
          data-testid={suffixTestId('form', props)}
        >
          {(control, formApi) => (
            <VStack spacing={4}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="name"
                    type="text"
                    label={i18n.t('entity.customer.labels.lastNameFirstNameCompany')}
                    data-testid={suffixTestId('name', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="legalNumber"
                    type="text"
                    label={`${i18n.t('entity.customer.labels.registrationNumber')}/${i18n.t(
                      'entity.invoice.labels.vatNumber'
                    )}`}
                    data-testid={suffixTestId('registrationNumber', props)}
                  />
                </Box>
              </HStack>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="phoneNumber"
                    type="phone"
                    isRequired
                    label={i18n.t('entity.phoneNumber.labels.number')}
                    countries={countriesOptions}
                    data-testid={suffixTestId('phoneNumber', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="email"
                    type="email"
                    isRequired
                    label={i18n.t('general.labels.emailAddress')}
                    data-testid={suffixTestId('email', props)}
                  />
                </Box>
              </HStack>
              <MatchingCustomers
                control={control}
                similarCustomersArg={getSimilarCustomersArg(formApi.watch())}
                onCustomerSelect={props.onCustomer}
                onExternalCustomerSelect={onExternalCustomerSelect}
                data-testid={suffixTestId('matchingCustomers', props)}
                vehicleId={props.vehicleId}
              />
            </VStack>
          )}
        </Form>
      </VStack>
    </>
  );
}

const schema = Yup.object().shape(
  {
    name: yupString,
    legalNumber: yupString,
    email: yupString.when('phoneNumber', {
      is: (phone?: PhoneNumber | Nullish) =>
        not(
          validatePhone(phone?.number || '', {
            country: phone?.countryCode,
            validateMobilePrefix: false,
          }).isValid
        ),
      then: (schema) => schema.required(i18n.t('general.validations.phoneOrEmail')),
      otherwise: (schema) => schema.optional(),
    }),
    phoneNumber: Yup.mixed().when('email', {
      is: (email?: Nullish) => isNilOrEmpty(email),
      then: (schema) =>
        schema
          .test(
            'isPhone',
            i18n.t('general.validations.invalidPhoneNumber'),
            (phone?: PhoneNumber | Nullish) => {
              if (isNilOrEmpty(phone?.number)) {
                return true;
              }
              return validatePhone(phone?.number || '', {
                country: phone?.countryCode,
                validateMobilePrefix: false,
              }).isValid;
            }
          )
          .test(
            'required',
            i18n.t('general.validations.phoneOrEmail'),
            (phone?: PhoneNumber | Nullish) => isNotNilOrEmpty(phone?.number)
          ),
      otherwise: (schema) =>
        schema.test(
          'isPhone',
          i18n.t('general.validations.invalidPhoneNumber'),
          (phone?: PhoneNumber | Nullish) => {
            if (isNilOrEmpty(phone?.number)) {
              return true;
            }
            return validatePhone(phone?.number || '', {
              country: phone?.countryCode,
              validateMobilePrefix: false,
            }).isValid;
          }
        ),
    }),
  },
  [['email', 'phoneNumber']]
);

const getValueToMatch = (value: string | Nullish) => {
  if (isNilOrEmpty(value) || typeof value !== 'string' || !lengthGte(2, value)) {
    return;
  }
  return value;
};

const getSimilarCustomersArg = (formData: CreateCustomerFormType) => ({
  name: getValueToMatch(formData.name),
  legalNumber: getValueToMatch(formData.legalNumber),
  email: getValueToMatch(formData.email),
  phoneNumber: getValueToMatch(
    formData.phoneNumber?.number &&
      `${formData.phoneNumber?.prefix ?? ''}${formData.phoneNumber?.number ?? ''}`
  ),
});

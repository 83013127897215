import {Card, DataStatus, FormControl, FormField} from 'platform/components';
import {Box, Grid, GridItem, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {useVehicleCatalogue} from '../../../../hooks/useVehicleCatalogue';
import {ServiceVehicleFormType} from '../../types/ServiceVehicleFormType';

interface VehicleDataProps extends RequiredTestIdProps {
  control: FormControl<ServiceVehicleFormType>;
  formApi: UseFormReturn<ServiceVehicleFormType>;
}

export function VehicleData(props: VehicleDataProps) {
  const vehicleTypeWatcher = props.formApi.watch('type');
  const [vehicleCatalogue, vehicleCatalogueState] = useVehicleCatalogue(vehicleTypeWatcher.at(0));

  const {serviceBookStateOptions, serviceBookTypeOptions} = vehicleCatalogue;

  return (
    <DataStatus isLoading={vehicleCatalogueState.isLoading} isError={vehicleCatalogueState.isError}>
      <Box padding={4}>
        <Grid columns={3}>
          <GridItem span={2}>
            <VStack spacing={4}>
              <Card>
                <VStack spacing={4}>
                  <Card
                    variant="inlineGrey"
                    title={i18n.t('entity.vehicle.labels.technicalInspection')}
                  >
                    <Grid columns={4}>
                      <GridItem>
                        <FormField
                          type="date"
                          label={i18n.t('entity.vehicle.labels.inspectionValidUntil')}
                          control={props.control}
                          name="technicalInspectionValidUntil"
                          data-testid={suffixTestId('technicalInspectionValidUntil', props)}
                        />
                      </GridItem>
                      <GridItem span={2}>
                        <Box paddingTop={6}>
                          <FormField
                            type="checkbox"
                            control={props.control}
                            name="isAcceptableForTechnicalInspection"
                            label={i18n.t('entity.vehicle.labels.acceptableTechnicalCondition')}
                            data-testid={suffixTestId('isAcceptableForTechnicalInspection', props)}
                          />
                        </Box>
                      </GridItem>
                    </Grid>
                  </Card>
                  <Card variant="inlineGrey" title={i18n.t('entity.vehicle.labels.warrantyUntil')}>
                    <VStack spacing={4}>
                      <Grid columns={4}>
                        <GridItem>
                          <FormField
                            type="date"
                            label={i18n.t('entity.vehicle.labels.warrantyUntil')}
                            control={props.control}
                            name="warrantyDate"
                            data-testid={suffixTestId('warrantyDate', props)}
                          />
                        </GridItem>
                        <GridItem>
                          <FormField
                            type="number"
                            label={i18n.t('entity.vehicle.labels.warrantyMileageLimit')}
                            control={props.control}
                            name="warrantyMileage"
                            suffix={i18n.t('general.metric.km')}
                            data-testid={suffixTestId('warrantyMileage', props)}
                          />
                        </GridItem>
                        <GridItem>
                          <FormField
                            type="date"
                            label={i18n.t('entity.vehicle.labels.serviceContractUntil')}
                            control={props.control}
                            name="serviceContractUntil"
                            data-testid={suffixTestId('serviceContractUntil', props)}
                          />
                        </GridItem>
                        <GridItem>
                          <FormField
                            type="number"
                            label={i18n.t('entity.vehicle.labels.serviceContractMileageLimit')}
                            control={props.control}
                            name="serviceContractMileageLimit"
                            suffix={i18n.t('general.metric.km')}
                            data-testid={suffixTestId('serviceContractMileageLimit', props)}
                          />
                        </GridItem>
                      </Grid>

                      <FormField
                        type="textarea"
                        label={i18n.t('entity.vehicle.labels.serviceContractNote')}
                        control={props.control}
                        name="serviceContractNote"
                        data-testid={suffixTestId('serviceContractNote', props)}
                        isResizable
                      />
                    </VStack>
                  </Card>
                  <Card variant="inlineGrey" title={i18n.t('entity.vehicle.labels.serviceBook')}>
                    <Grid columns={2}>
                      <GridItem>
                        <FormField
                          type="chips"
                          control={props.control}
                          name="serviceBookType"
                          label={i18n.t('general.labels.type')}
                          options={serviceBookTypeOptions || []}
                          data-testid={suffixTestId('serviceBookType', props)}
                          isDeselectable
                        />
                      </GridItem>
                      <GridItem>
                        <FormField
                          type="chips"
                          control={props.control}
                          name="serviceBookState"
                          label={i18n.t('entity.vehicle.labels.serviceBookStateLabel')}
                          options={serviceBookStateOptions || []}
                          data-testid={suffixTestId('serviceBookState', props)}
                          isDeselectable
                        />
                      </GridItem>
                    </Grid>
                  </Card>
                </VStack>
              </Card>
            </VStack>
          </GridItem>
        </Grid>
      </Box>
    </DataStatus>
  );
}

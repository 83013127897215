import {useCallback, useRef} from 'react';

import {isNotNil} from 'ramda';
import {isNilOrEmpty, isNumber} from 'ramda-adjunct';

import {useDataQueryIdContext} from '../context/useDataQueryIdContext';
import {ActionCallback} from '../types/ActionCallback';
import {ActionDefinitionObject} from '../types/ActionDefinition';
import {GridApi, RowClickedEvent} from '../types/AgGridTypes';
import {FeGridSettings, TransformedDefinitionApiResponse} from '../types/Api';
import {setSourceSearchParamsByGridCode} from '../utils/url/setSourceSearchParamsByGridCode';
import {useDeselectAll} from './useDeselectAll';
import {useHandleLink} from './useHandleLink';
import {useRedrawRows} from './useRedrawRows';
import {useRefreshCells} from './useRefreshCells';
import {useRefreshServerSideStore} from './useRefreshServerSideStore';
import {useRowSelection} from './useRowSelections';

/**
 * Returns handler for DG row clicking.
 *
 * Do NOT trigger callback when:
 * - ✓ clicked element have its own callback
 * - use event.stopPropagation in cells
 * - ✓ any cell is beeing edited (click is used to close edit mode)
 * - ✗ any popup menu is open (click is used to close the menu)
 * - ✓ user performed double click
 * Get correct callback by row definition and call it
 * 		- 1. row action
 * 		- 2. expander
 * 		- 3. selector
 */
export const useRowClickHandler = (
  gridApi: GridApi | undefined,
  settings: FeGridSettings,
  actionCallback: ActionCallback | undefined,
  actionsDefinition: ActionDefinitionObject | undefined,
  gridCode: string,
  definition: TransformedDefinitionApiResponse
) => {
  const handleLink = useHandleLink();
  const dataQueryContext = useDataQueryIdContext();
  const toggleSelectin = useRowSelection(gridApi, definition.behavior.rowSelectMode);
  const performedClicks = useRef(0);

  const refreshServerSideStore = useRefreshServerSideStore(gridApi);
  const refreshCells = useRefreshCells(gridApi);
  const redrawRows = useRedrawRows(gridApi);
  const deselectAll = useDeselectAll(gridApi);

  return useCallback(
    (eventParams: RowClickedEvent): void => {
      if (eventParams.event?.defaultPrevented || isNilOrEmpty(eventParams.data)) {
        return;
      }
      performedClicks.current = performedClicks.current + 1;
      const timeout = setTimeout(() => {
        if (performedClicks.current === 1) {
          const editingCellsLength = eventParams.api.getEditingCells()?.length ?? 0;
          if (editingCellsLength > 0) {
            return;
          }
          const selectedCells = eventParams.api.getCellRanges()?.[0]?.columns?.length;
          if (isNumber(selectedCells) && selectedCells > 1) {
            return;
          }

          const rowClick = eventParams.data?.actions?.rowClick;
          const rowSelectable = definition.behavior.rowSelectMode;

          const sourceSearchParams = setSourceSearchParamsByGridCode(
            gridCode,
            String(eventParams.data.rowNumber)
          );

          if (
            isNotNil(rowClick) &&
            actionCallback &&
            actionsDefinition &&
            actionsDefinition[rowClick]
          ) {
            const actionLink = actionCallback({
              actionKey: rowClick,
              rowId: eventParams.data.id,
              rowData: eventParams.data,
              refreshData: refreshServerSideStore,
              refreshCells,
              redrawRows,
              deselectAll,
              sourceSearchParams,
              queryId: dataQueryContext.dataQueryId ?? '',
            });
            if (typeof actionLink === 'string' && eventParams.event) {
              handleLink(actionLink, eventParams.event as MouseEvent);
            }
          } else if (rowSelectable !== 'NONE') {
            toggleSelectin(eventParams.node, !eventParams.node.isSelected());
            eventParams.api.refreshCells({
              suppressFlash: true,
              columns: ['eag-col-select'],
            });
          }
        }
        performedClicks.current = 0;
      }, 400);

      if (performedClicks.current > 1) {
        clearTimeout(timeout);
      }
    },
    [
      actionCallback,
      actionsDefinition,
      handleLink,
      toggleSelectin,
      redrawRows,
      refreshCells,
      refreshServerSideStore,
      deselectAll,
    ]
  );
};

import {ColumnPinnedType, ColumnState} from '@ag-grid-community/core';

import {indexBy, mergeDeepRight, values} from 'ramda';

import {ActivePreset, GetDataQueryResponse, OrderTypeLowercase} from '../types/Api';

export const getColumnState = (
  presetDetail: ActivePreset,
  presetFilters: GetDataQueryResponse,
  defaultState?: ColumnState[]
): ColumnState[] => {
  const colSort = presetFilters.order.map((sort) => ({
    colId: sort.columnKey,
    sort: sort.order.toLowerCase() as OrderTypeLowercase,
  }));

  const colState = presetDetail.columnsSettings.map((state) => ({
    colId: state.columnKey,
    hide: !state.visible,
    width: state.width,
    pinned: (state.pinned?.toLowerCase() || null) as ColumnPinnedType,
  }));

  const mergedState = values(
    mergeDeepRight(
      indexBy((state) => state.colId, colState),
      indexBy((sort) => sort.colId, colSort)
    )
  );

  if (!defaultState) {
    return mergedState;
  }

  return defaultState
    .sort((a, b) => {
      const indexA = colState?.findIndex((s) => s.colId === a.colId);
      const indexB = colState?.findIndex((s) => s.colId === b.colId);
      return indexA - indexB;
    })
    .map((state) => {
      const newState = mergedState?.find((s) => s.colId === state.colId);
      // New added columns (not saved in preset) was display in DG, thats why not found columns in presets are hidden and unpinned
      return newState ?? {...state, hide: true, pinned: null};
    });
};

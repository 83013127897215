import {
  getFilePreviewUrl,
  openDeleteDialog,
  openDialog,
  showNotification,
} from 'platform/components';
import {match} from 'ts-pattern';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

// using path is OK in this case
// eslint-disable-next-line no-restricted-imports
import {defaultTo, path, pipe} from 'ramda';
import {isFalse, isString, noop} from 'ramda-adjunct';

import {
  documentContextApi,
  useDeleteDocumentContextMutation,
  useGetEmailConfigQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, openFile} from 'shared';

import {ActionCallback} from 'features/datagrid';

import {SendEmail} from '../components/SendEmail/SendEmail';
import {downloadFile} from '../utils/downloadFile';
import {handleApiError} from '../utils/handleApiError';
import {printFile} from '../utils/printFile';

export const useGetDocumentDatagridActions = (customerId?: string | Nullish) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const {data: emailConfig} = useGetEmailConfigQuery();

  const [deleteDocument] = useDeleteDocumentContextMutation();

  const documentActionCallback: ActionCallback = ({actionKey, rowId, rowData, refreshData}) => {
    const url = path<string>(['fileName', 'value', 'remoteUrl'], rowData);

    if (!(isString(url) && isString(rowId))) {
      showNotification.error();
      return;
    }

    const fileId = path<string>(['fileName', 'value', 'id'])(rowData);
    const fileName = pipe(path<string>(['fileName', 'value', 'title']), defaultTo('file'))(rowData);
    const previewUrl = getFilePreviewUrl(url);

    match(actionKey)
      .with('preview', 'openOnNewTab', () => openFile(previewUrl))
      .with('download', () => downloadFile(url, {fileType: 'attachment'}))
      .with('print', () => printFile(previewUrl))
      .with('send_email', () => {
        if (!fileId) {
          showNotification.error();
          return;
        }

        if (isFalse(emailConfig?.enabled)) {
          showNotification.error(i18n.t('general.notifications.setupEmailProvider'));
          return;
        }

        openDialog(
          <SendEmail
            data-testid="datagridDocumentAction"
            customerId={customerId}
            documents={[
              {
                fileUri: url,
                fileId,
                fileName,
                title: fileName,
              },
            ]}
          />,
          {
            scrollBehavior: 'outside',
            title: i18n.t('general.labels.email'),
          }
        );
      })
      .with('delete', () =>
        openDeleteDialog({
          onConfirm: () => {
            deleteDocument({documentContextId: rowId})
              .unwrap()
              .then(refreshData)
              .then(() => {
                dispatch(
                  documentContextApi.util.invalidateTags([{type: 'documentsCount', id: id ?? ''}])
                );
              })
              .catch(handleApiError);
          },
        })
      )
      .otherwise(noop);
  };

  return [documentActionCallback];
};

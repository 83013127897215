import type {PermissionsRecordType} from '../permissions';

export const customerPermissions = {
  deleteCustomer: {
    actionId: 'DELETE',
    resourceId: 'CUSTOMER',
    scopes: [],
  },
  customerTagRead: {
    actionId: 'RECORD_TAG_VALUES_READ_RECORD_TAG_VALUES',
    resourceId: 'CUSTOMER',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  customerTagAddToRecord: {
    actionId: 'RECORD_TAG_VALUES_ADD_TAG_VALUE_TO_RECORD',
    resourceId: 'CUSTOMER',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  customerTagRemoveFromRecord: {
    actionId: 'RECORD_TAG_VALUES_REMOVE_TAG_VALUE_FROM_RECORD',
    resourceId: 'CUSTOMER',
    scopes: ['PARTICIPATION', 'BRANCH'],
  },
  customerTagAdd: {
    actionId: 'ADD_TAG_VALUE',
    resourceId: 'CUSTOMER_TAG',
    scopes: [],
  },
  customerTagUpdate: {
    actionId: 'UPDATE_TAG_VALUE',
    resourceId: 'CUSTOMER_TAG',
    scopes: [],
  },
  customerTagRemove: {
    actionId: 'REMOVE_TAG_VALUE',
    resourceId: 'CUSTOMER_TAG',
    scopes: [],
  },
  customerTagClearExpiration: {
    actionId: 'CLEAR_TAG_VALUE_EXPIRATION',
    resourceId: 'CUSTOMER_TAG',
    scopes: [],
  },
  customerTagSetExpiration: {
    actionId: 'SET_TAG_VALUE_EXPIRATION',
    resourceId: 'CUSTOMER_TAG',
    scopes: [],
  },
} satisfies PermissionsRecordType;

import {Action, Card, DataStatus, useDialog} from 'platform/components';

import {useCallback} from 'react';

import {mergeAll} from 'ramda';

import i18n from '@omnetic-dms/i18n';
import {Section, UploadDocumentsDialog, useGetDocumentDatagridActions} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {useWarehouseParams} from '../../../../hooks/useWarehouseParams';

interface DocumentsProps extends TestIdProps {
  isIssueNoteLoading: boolean;
}

export function Documents(props: DocumentsProps) {
  const {issueNoteId} = useWarehouseParams();

  const [rowActions] = useGetDocumentDatagridActions();

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => mergeAll([filter, {warehouseIssueNoteId: issueNoteId}]),
    [issueNoteId]
  );

  const documentActions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      leftIcon: 'content/add_circle',
      title: i18n.t('entity.warehouse.actions.addDocument'),
      onClick: openUploadDialog,
      'data-testid': suffixTestId('actions.addDocument', props),
    },
  ];

  return (
    <Section data-testid={suffixTestId('wrapper', props)}>
      <DataStatus isLoading={props.isIssueNoteLoading}>
        <Card title={i18n.t('general.labels.documents')} actions={documentActions}>
          <DataGrid
            gridCode="document-context-list-warehouse-issue-note"
            ref={dataGridRef}
            actionCallback={rowActions}
            queryModifier={queryModifier}
            autoHeight
            data-testid="documentContextListWarehouseIssueNoteDatagrid"
          />
        </Card>
      </DataStatus>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={refreshDataGrid}
        contextTarget="warehouse-issue-note"
        contextId={issueNoteId}
        bulkContext={[{target: 'warehouse-issue-note', targetId: issueNoteId}]}
        data-testid={suffixTestId('dialog.uploadDocument', props)}
      />
    </Section>
  );
}

import {
  GenerateCebiaReportApiArg,
  GenerateCebiaReportApiResponse,
  GetAutoTracerUrlApiArg,
  GetAutoTracerUrlApiResponse,
  GetCebiaReportApiArg,
  GetCebiaReportApiResponse,
  GetCebiaReportsApiArg,
  GetCebiaReportsApiResponse,
  GetCebiaReportUrlApiArg,
  GetCebiaReportUrlApiResponse,
  GetLastAutotracerReportApiArg,
  GetLastAutotracerReportApiResponse,
  GetManufactureYearApiArg,
  GetManufactureYearApiResponse,
} from '../types/api';
import {omneticApi} from './baseApi/omneticApi';

export const cebiaApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getAutoTracerUrl: build.query<GetAutoTracerUrlApiResponse, GetAutoTracerUrlApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/cebia/auto-tracer-url`,
        params: {
          vin: queryArg.vin,
          vehicleMark: queryArg.vehicleMark,
          vehicleModel: queryArg.vehicleModel,
          mileage: queryArg.mileage,
          makeYear: queryArg.makeYear,
        },
      }),
    }),
    getLastAutotracerReport: build.query<
      GetLastAutotracerReportApiResponse,
      GetLastAutotracerReportApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/cebia/last-autotracer-report`,
      }),
    }),
    getCebiaReportUrl: build.query<GetCebiaReportUrlApiResponse, GetCebiaReportUrlApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/cebia/cebia-report-url`,
        params: {
          vin: queryArg.vin,
          vehicleMark: queryArg.vehicleMark,
          vehicleModel: queryArg.vehicleModel,
          mileage: queryArg.mileage,
          makeYear: queryArg.makeYear,
        },
      }),
    }),
    generateCebiaReport: build.mutation<GenerateCebiaReportApiResponse, GenerateCebiaReportApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/cebia/cebia-report/${queryArg.vehicleId}`,
        method: 'POST',
        body: queryArg.generateCebiaReportRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'CebiaReport', vehicleId: queryArg.vehicleId},
      ],
    }),
    getCebiaReport: build.query<GetCebiaReportApiResponse, GetCebiaReportApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/cebia/cebia-report/${queryArg.vehicleId}`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'CebiaReport', vehicleId: queryArg.vehicleId},
      ],
    }),
    getCebiaReports: build.query<GetCebiaReportsApiResponse, GetCebiaReportsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/cebia-report`,
      }),
      providesTags: (result, error, queryArg) => [
        {type: 'CebiaReport', vehicleId: queryArg.vehicleId},
      ],
    }),
    getManufactureYear: build.query<GetManufactureYearApiResponse, GetManufactureYearApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/manufacture-time/${queryArg.vin}`,
      }),
    }),
  }),
});

export const {
  useLazyGetAutoTracerUrlQuery,
  useGetLastAutotracerReportQuery,
  useGetCebiaReportUrlQuery,
  useGenerateCebiaReportMutation,
  useGetCebiaReportQuery,
  useGetCebiaReportsQuery,
  useGetManufactureYearQuery,
} = cebiaApi;

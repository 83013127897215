import {isNilOrEmpty} from 'ramda-adjunct';

import {useGetArticleQuery, useGetWarehouseQuery} from '@omnetic-dms/api';

import {Nullish, TestIdProps} from 'shared';

import {BasketItem} from './BasketItem';

interface MaterialBasketItemProps extends TestIdProps {
  id: string;
  name: string;
  quantity: number;
  subtitle: string;
  warehouseId?: string | Nullish;
  articleId?: string | Nullish;
  onEditClick: () => void;
}

export function MaterialBasketItem(props: MaterialBasketItemProps) {
  const {data: warehouse} = useGetWarehouseQuery(
    {warehouseId: props.warehouseId ?? ''},
    {skip: isNilOrEmpty(props.warehouseId)}
  );
  const {data: article} = useGetArticleQuery(
    {articleId: props.articleId ?? '', warehouseId: props.warehouseId ?? ''},
    {skip: isNilOrEmpty(props.articleId) || isNilOrEmpty(props.warehouseId)}
  );

  return (
    <BasketItem
      id={props.id}
      name={props.name}
      quantity={props.quantity}
      subtitle={props.subtitle}
      parameters={[warehouse?.name, article?.storageLocation]}
      onEditClick={props.onEditClick}
      data-testid={props['data-testid']}
    />
  );
}

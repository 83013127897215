import {DataStatus, Form, FormField} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';

import {head} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {useGetManufacturersQuery, useGetSuppliersQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  getOptionsFromManufacturers,
  getOptionsFromSuppliers,
  getSearchTypeOptions,
} from '@omnetic-dms/shared';

import {TestIdProps, suffixTestId} from 'shared';

import {FormToDataGridConnectorProps} from 'features/datagrid';

type PriceListExternalFiltersForm = Partial<{
  catalogNumber: string;
  searchType: string;
  name: string;
  manufacturer: string;
  supplier: string;
}>;

type PriceListExternalFiltersFormProps = FormToDataGridConnectorProps & TestIdProps;

export function PriceListExternalFilters(props: PriceListExternalFiltersFormProps) {
  const {
    data: manufacturers,
    isLoading: areManufacturersLoading,
    isError: hasManufacturersError,
  } = useGetManufacturersQuery();

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const isLoading = areManufacturersLoading || areSuppliersLoading;
  const hasError = hasManufacturersError || hasSuppliersError;

  const searchType = isArray(props.values.searchType)
    ? head(props.values.searchType)
    : props.values.searchType;

  const filteringMethodOptions = getSearchTypeOptions();
  const defaultFilteringMethod = filteringMethodOptions[0].value;

  const values = {
    ...props.values,
    searchType: searchType ?? defaultFilteringMethod,
  };

  return (
    <DataStatus isLoading={isLoading} isError={hasError}>
      <Form<PriceListExternalFiltersForm> values={values} onChange={props.onChange}>
        {(control) => (
          <VStack spacing={4}>
            <Grid columns={3} align="flex-end">
              <GridItem span={2}>
                <FormField
                  control={control}
                  name="catalogNumber"
                  type="text"
                  label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                  data-testid={suffixTestId('inputs.catalogueNumber', props)}
                />
              </GridItem>
              <GridItem span={1}>
                <FormField
                  control={control}
                  name="searchType"
                  type="choice"
                  options={filteringMethodOptions}
                  isNotClearable
                  data-testid={suffixTestId('inputs.filteringMethod', props)}
                />
              </GridItem>
            </Grid>
            <FormField
              control={control}
              name="name"
              type="text"
              label={i18n.t('entity.warehouse.labels.name')}
              data-testid={suffixTestId('inputs.name', props)}
            />
            <FormField
              control={control}
              name="manufacturer"
              type="choice"
              label={i18n.t('entity.warehouse.labels.manufacturer')}
              options={getOptionsFromManufacturers(manufacturers)}
              data-testid={suffixTestId('inputs.manufacturer', props)}
            />
            <FormField
              control={control}
              name="supplier"
              type="choice"
              label={i18n.t('entity.warehouse.labels.supplier')}
              options={getOptionsFromSuppliers(suppliers)}
              data-testid={suffixTestId('inputs.supplier', props)}
            />
          </VStack>
        )}
      </Form>
    </DataStatus>
  );
}

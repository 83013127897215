import {z} from 'zod';

export const PriceSchema = z.object({
  amount: z.number().optional(),
  currency: z.string().optional(),
});

export const ProfitSchema = PriceSchema.extend({margin: z.number().optional()});

export const ProfitabilityOrderTypeSchema = z.object({
  salesBasePrice: PriceSchema.optional(),
  discount: PriceSchema.optional(),
  sellingPrice: PriceSchema.optional(),
  profit: ProfitSchema.optional(),
});

export const SummarySchema = z.object({
  totalTurnover: PriceSchema.optional(),
  averageTurnoverPerServiceCase: PriceSchema.optional(),
  averageTurnoverPerServiceOrder: PriceSchema.optional(),
  totalProfit: PriceSchema.extend({margin: z.number().optional()}).optional(),
});

export const ServiceOrdersProfitabilitySchema = z.object({
  commercial: ProfitabilityOrderTypeSchema.optional(),
  warranty: ProfitabilityOrderTypeSchema.optional(),
  insurance: ProfitabilityOrderTypeSchema.optional(),
  contract: ProfitabilityOrderTypeSchema.optional(),
  claims: ProfitabilityOrderTypeSchema.optional(),
  internal: ProfitabilityOrderTypeSchema.optional(),
});
export const GetHistoryOrderProfitabilityApiResponseSchema = z.object({
  summary: SummarySchema.optional(),
  serviceOrders: ServiceOrdersProfitabilitySchema.optional(),
});

export type GetHistoryOrderProfitabilityApiResponse = z.infer<
  typeof GetHistoryOrderProfitabilityApiResponseSchema
>;

export const GetHistoryOrderProfitabilityApiArgSchema = z.object({vehicleId: z.string()});

export type GetHistoryOrderProfitabilityApiArg = z.infer<
  typeof GetHistoryOrderProfitabilityApiArgSchema
>;

import {z} from 'zod';

export const BasePriceSchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

export type BasePrice = z.infer<typeof BasePriceSchema>;

export const PriceWithAndWithoutVatSchema = z.object({
  withoutVat: BasePriceSchema,
  withVat: BasePriceSchema,
});

export type PriceWithAndWithoutVat = z.infer<typeof PriceWithAndWithoutVatSchema>;

export const MechanicSchema = z.object({
  id: z.string(),
  name: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string().optional(),
});

export type Mechanic = z.infer<typeof MechanicSchema>;

export const AssignedMechanicSchema = z.object({
  id: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string().nullish(),
});

export type AssignedMechanic = z.infer<typeof AssignedMechanicSchema>;

export const BasketTooltipSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export type BasketTooltip = z.infer<typeof BasketTooltipSchema>;

import {Button, ButtonGroup} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

interface WatermarkRepublishAdsDialogProps {
  createWatermark: ({
    setToAllVehicles,
    republishAds,
  }: {
    setToAllVehicles: boolean;
    republishAds: boolean;
  }) => Promise<void>;
  isLoading: boolean;
}

export function WatermarkRepublishAdsDialog(props: WatermarkRepublishAdsDialogProps) {
  return (
    <VStack spacing={1}>
      <Text>{i18n.t('entity.watermark.republishAdsDialog.description')}</Text>
      <ButtonGroup align="right">
        <Button
          data-testid="watermark-republichAds-dialog-cancel"
          variant="secondary"
          onClick={() => props.createWatermark({setToAllVehicles: true, republishAds: false})}
          isDisabled={props.isLoading}
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          data-testid="watermark-republichAds-dialog-confirm"
          variant="primary"
          onClick={() => props.createWatermark({setToAllVehicles: true, republishAds: true})}
          isLoading={props.isLoading}
          title={i18n.t('general.actions.confirm')}
        />
      </ButtonGroup>
    </VStack>
  );
}

import {
  Button,
  Card,
  closeCurrentDialog,
  closeDialog,
  openDialog,
  TabProps,
  TabsHeader,
} from 'platform/components';
import {Box, HStack, Show, VStack} from 'platform/foundation';

import {useState} from 'react';

import {GetWarehousesResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';

import {composePath, suffixTestId, TestIdProps, useNavigate} from 'shared';

import {useRefreshDataGrid} from 'features/datagrid';

import {ArticleCreate} from '../../../ArticleCreate/ArticleCreate';
import {RequestTabMaterialPriceListTab} from './RequestTabMaterialPriceListTab';
import {RequestTabMaterialWarehouseTab} from './RequestTabMaterialWarehouseTab';

interface MaterialListProps extends TestIdProps {
  onWarehouseDispatch: (warehouseArticleId: string) => Promise<void>;
  onWarehouseRequest: (warehouseArticleId: string) => Promise<void>;
  onWarehouseNonBinding: (warehouseArticleId: string) => Promise<void>;
  availableWarehouses: GetWarehousesResponse;
}

const TABS_IDS = {
  warehouseTab: 'directSale-addMaterial-warehouseTab',
  priceListTab: 'directSale-addMaterial-priceListTab',
};

export function RequestTabMaterialList(props: MaterialListProps) {
  const navigate = useNavigate();
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();
  const [activeTabId, setActiveTabId] = useState(TABS_IDS.warehouseTab);

  const tabs: TabProps[] = [
    {
      id: TABS_IDS.warehouseTab,
      title: i18n.t('entity.warehouse.labels.warehouse'),
      content: (
        <RequestTabMaterialWarehouseTab
          onWarehouseDispatch={props.onWarehouseDispatch}
          onWarehouseRequest={props.onWarehouseRequest}
          onWarehouseNonBinding={props.onWarehouseNonBinding}
          availableWarehouses={props.availableWarehouses}
          data-testid={suffixTestId('tabs.warehouse', props)}
          dataGridRef={dataGridRef}
        />
      ),
    },
    {
      id: TABS_IDS.priceListTab,
      title: i18n.t('entity.warehouse.labels.priceList'),
      content: (
        <RequestTabMaterialPriceListTab
          onWarehouseRequest={props.onWarehouseRequest}
          onWarehouseNonBinding={props.onWarehouseNonBinding}
          onWarehouseDispatch={props.onWarehouseDispatch}
          data-testid={suffixTestId('tabs.priceList', props)}
        />
      ),
    },
  ];

  const activeTabContent = tabs.find(
    (tab) => tab.id === (activeTabId ?? TABS_IDS.warehouseTab)
  )?.content;

  const handleCreateArticle = () => {
    openDialog(
      <ArticleCreate
        onArticleCreate={(createdArticleData) => {
          if (createdArticleData.submittedButton === 'CREATE_AND_EDIT') {
            return navigate(
              composePath(warehouseRoutes.articleDetailOverview, {
                params: {
                  warehouseId: createdArticleData.formValues.warehouseId,
                  id: createdArticleData.articleId,
                },
              })
            );
          }

          closeCurrentDialog();
          refreshDataGrid();
        }}
        submitButtonTitle={i18n.t('general.labels.createAndAdd')}
        onClose={() => closeDialog('createArticleDialog')}
        hasCreateAndEditButton
        data-testid={testIds.warehouse.priceList('dialogs.createArticle')}
      />,
      {
        id: 'createArticleDialog',
        title: i18n.t('entity.warehouse.labels.createNewArticle'),
        withAdditionalFooter: true,
      }
    );
  };

  return (
    <Card title={i18n.t('entity.warehouse.labels.itemsList')} isFullHeight>
      <VStack spacing={4} height="100%">
        <HStack justify="space-between">
          <TabsHeader
            variant="condensed"
            tabs={tabs}
            activeTabId={activeTabId}
            onChange={setActiveTabId}
            data-testid={suffixTestId('tabs', props)}
          />
          <Show when={activeTabId === TABS_IDS.warehouseTab}>
            <Button
              variant="secondary"
              leftIcon="content/add_circle"
              onClick={handleCreateArticle}
              title={i18n.t('entity.warehouse.labels.createNewArticle')}
              data-testid={suffixTestId('button.createNewArticle', props)}
            />
          </Show>
        </HStack>
        <Box height="100%" flex={1}>
          <Card key={activeTabId} isFullHeight>
            {activeTabContent}
          </Card>
        </Box>
      </VStack>
    </Card>
  );
}

import {Card, Separator, DataStatus, Alert} from 'platform/components';
import {Box, Display, HStack, VStack, Text, Show} from 'platform/foundation';

import {isNil} from 'ramda';

import {VehicleStockOverviewResponseBody, useGetVehicleStockOverviewQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';
import {useDataQuery, useGetCurrentBranch} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, composePath, parseDate, suffixTestId, useNavigate} from 'shared';

interface VehicleWarehouseProps extends TestIdProps {
  branchId: string | undefined;
}

const FROM_DATE = '2000-01-01';
const DAYS_TO_EXPIRE = 30;

export function VehicleWarehouse(props: VehicleWarehouseProps) {
  const navigate = useNavigate();
  const {activeBranchId} = useGetCurrentBranch();

  const {
    data: vehicleStockOverview,
    isLoading: isVehicleStockOverviewLoading,
    isError: isVehicleStockOverviewError,
  } = useGetVehicleStockOverviewQuery(
    {
      branchId: props.branchId,
    },
    {refetchOnMountOrArgChange: true}
  );

  const {queryParams: queryParamsValidUntil} = useDataQuery('vehicle', {
    // Filter vehicles with MOT expiration this month
    filters: {
      validUntil: {relativeInputKey: 'NEXT_N_DAYS', relativeInputValue: DAYS_TO_EXPIRE},
      saleState: ['for-sale', 'selling'],
      archived: ['0'],
      branch: [activeBranchId],
    },
  });

  const yesterdaysDate = ((d) =>
    parseDate(d.setDate(d.getDate() - 1))
      .toISOString()
      .split('T')[0])(new Date());

  const {queryParams: queryParamsExpired} = useDataQuery('vehicle', {
    // Filter available vehicles for sale with expired MOT
    filters: {
      validUntil: {from: FROM_DATE, to: yesterdaysDate},
      saleState: ['for-sale', 'selling'],
      archived: ['0'],
    },
    order: [{columnKey: 'createdAt', order: 'DESC'}],
  });

  const onWarningAlertClick = (queryParams?: string | Record<string, string | Nullish>) => {
    navigate(composePath(vehiclesRoutes.home, {queryParams}));
  };

  const columns = getColumns(vehicleStockOverview);
  const numberOfCarsWhichMotExpirating = vehicleStockOverview?.motExpirationWarning ?? 0;
  const numberOfCarsWhichMotExpired = vehicleStockOverview?.motExpiredWarning ?? 0;

  return (
    <Card title={i18n.t('page.homepage.labels.vehicleWarehouse')} variant="defaultWithoutBorder">
      <Box>
        <DataStatus
          minHeight="100%"
          isLoading={isVehicleStockOverviewLoading}
          isError={isVehicleStockOverviewError}
        >
          <VStack spacing={4}>
            <HStack>
              {columns.map((column, index) => {
                const isNotLast = index !== columns.length - 1;
                return (
                  <HStack basis="100%" align="stretch" spacing={4} key={column.label}>
                    <VStack spacing={4} justify="space-between" grow={1}>
                      <Text color="secondary" size="xSmall">
                        {column.label}
                      </Text>
                      <Display size={1}>{String(column.value)}</Display>
                    </VStack>
                    <Show when={isNotLast}>
                      <Separator spacing={4} orientation="vertical" />
                    </Show>
                  </HStack>
                );
              })}
            </HStack>
            <Show when={numberOfCarsWhichMotExpirating > 0}>
              <Alert
                variant="warning"
                type="inline"
                title={i18n.t('page.homepage.vehicleWarehouse.labels.motExpiresIn30Days')}
                message={i18n.t('page.homepage.vehicleWarehouse.labels.carCount', {
                  count: numberOfCarsWhichMotExpirating,
                })}
                hyperlinks={[
                  {
                    title: i18n.t('page.homepage.labels.view'),
                    onClick: () => onWarningAlertClick(queryParamsValidUntil),
                  },
                ]}
                data-testid={suffixTestId('motExpiresIn30DaysAlert', props)}
              />
            </Show>
            <Show when={numberOfCarsWhichMotExpired > 0}>
              <Alert
                variant="error"
                type="inline"
                title={i18n.t('page.homepage.vehicleWarehouse.labels.motExpired')}
                message={i18n.t('page.homepage.vehicleWarehouse.labels.carCount', {
                  count: numberOfCarsWhichMotExpired,
                })}
                hyperlinks={[
                  {
                    title: i18n.t('page.homepage.labels.view'),
                    onClick: () => onWarningAlertClick(queryParamsExpired),
                  },
                ]}
                data-testid={suffixTestId('motExpiredAlert', props)}
              />
            </Show>
          </VStack>
        </DataStatus>
      </Box>
    </Card>
  );
}

function getColumns(vehicleStockOverview: VehicleStockOverviewResponseBody | Nullish) {
  if (isNil(vehicleStockOverview)) {
    return [];
  }

  const {totalForSale, buying, selling} = vehicleStockOverview;

  return [
    {
      label: i18n.t('page.homepage.vehicleWarehouse.labels.warehouseTotal'),
      value: totalForSale,
    },
    {
      label: i18n.t('page.homepage.vehicleWarehouse.labels.inSale'),
      value: selling,
    },
    {
      label: i18n.t('page.homepage.vehicleWarehouse.labels.inBuying'),
      value: buying,
    },
  ];
}

import {Form, FormField} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps} from 'shared';

import {MatchingVehicles} from './MatchingVehicles';

type VehicleFinderFormType = {
  licencePlate: string;
  vin: string;
};

interface VehicleMatchOrCreateProps extends TestIdProps {
  onVehicleSelect?: (vehicleId: string) => Promise<void> | void;
}

export function VehicleMatchOrCreate(props: VehicleMatchOrCreateProps) {
  return (
    <Form<VehicleFinderFormType> data-testid={suffixTestId('form', props)}>
      {(control, formApi) => (
        <VStack spacing={4}>
          <Grid columns={3}>
            <GridItem span={1}>
              <FormField
                control={control}
                name="licencePlate"
                type="text"
                label={i18n.t('entity.vehicle.labels.licensePlate')}
                data-testid={suffixTestId('licencePlate', props)}
              />
            </GridItem>
            <GridItem span={2}>
              <FormField
                control={control}
                name="vin"
                type="text"
                maxLength={17}
                isCounterVisible
                label={i18n.t('entity.vehicle.labels.vinCode')}
                data-testid={suffixTestId('vin', props)}
              />
            </GridItem>
          </Grid>
          <MatchingVehicles
            vin={formApi.watch('vin')}
            registrationPlateContains={formApi.watch('licencePlate')}
            onVehicleSelect={(vehicleId) => props.onVehicleSelect?.(vehicleId)}
            data-testid={suffixTestId('matchingVehicles', props)}
          />
        </VStack>
      )}
    </Form>
  );
}

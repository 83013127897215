import {useEffect, useState} from 'react';

import {head, isNil} from 'ramda';
import {isArray, isNilOrEmpty, isNull} from 'ramda-adjunct';

import {useGetEmployeeMechanicsQuery, AssignedMechanic} from '@omnetic-dms/api';

import {Nullish} from 'shared';

interface UseBasketMechanicProps {
  authorizationProfileId: string | Nullish;
  assignedMechanicId: string | Nullish;
}

export function useBasketMechanic(props: UseBasketMechanicProps) {
  const [mechanicPayload, setMechanicPayload] = useState<AssignedMechanic | null>(null);

  const [mechanicError, setMechanicError] = useState<string | undefined>(undefined);

  const [isMechanicCleared, setIsMechanicCleared] = useState<boolean>(false);

  const {data: mechanics, isLoading: areMechanicsLoading} = useGetEmployeeMechanicsQuery(
    {authorizationProfileId: props.authorizationProfileId as string},
    {skip: isNilOrEmpty(props.authorizationProfileId)}
  );

  useEffect(() => {
    if (isNilOrEmpty(props.assignedMechanicId)) {
      return;
    }

    const lastSelectedMechanic = mechanics?.employees?.find(
      (mechanic) => mechanic?.id === props.assignedMechanicId
    );

    if (isNil(lastSelectedMechanic)) {
      setMechanicPayload(null);
      return;
    }

    setMechanicPayload({
      id: lastSelectedMechanic.id as string,
      isDefault: true,
      costCenterId: lastSelectedMechanic.costCenterId ?? null,
    });
  }, [props.assignedMechanicId, mechanics?.employees]);

  const mechanicId = mechanicPayload ? mechanicPayload.id : null;

  const inputMechanicId = isMechanicCleared ? null : mechanicId;

  const handleMechanicChange = (mechanicId: string | number | string[] | null) => {
    const parsedValue = (isArray(mechanicId) ? head(mechanicId) : mechanicId) as string | null;

    setIsMechanicCleared(isNull(parsedValue));

    const selectedMechanic = mechanics?.employees?.find((mechanic) => mechanic?.id === parsedValue);

    if (isNil(selectedMechanic)) {
      setMechanicPayload(null);
      return;
    }

    setMechanicPayload({
      id: selectedMechanic.id as string,
      isDefault: true,
      costCenterId: selectedMechanic.costCenterId ?? null,
    });

    setMechanicError(undefined);
  };

  return {
    mechanics,
    areMechanicsLoading,
    mechanicPayload,
    inputMechanicId,
    handleMechanicChange,
    mechanicError,
    setMechanicError,
  };
}

import {
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Right, VStack} from 'platform/foundation';

import {
  useGetTipCarsDefaultSettingsQuery,
  useSetTipCarsDefaultSettingsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, queryParams} from '@omnetic-dms/shared';

import {useQueryState} from 'shared';

type PlatformTipCarsVinFormData = {
  vinHidden: boolean;
};

export function PlatformTipCarsVin() {
  const [branchId] = useQueryState(queryParams.BRANCH_ID);
  const {data, isLoading, isError} = useGetTipCarsDefaultSettingsQuery({
    branchId: branchId ?? undefined,
  });
  const [saveSettings] = useSetTipCarsDefaultSettingsMutation();

  const onSubmit: FormSubmitHandler<PlatformTipCarsVinFormData> = (values) =>
    saveSettings({...values, branchId: branchId ?? undefined})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  return (
    <Card title={i18n.t('general.labels.defaultSettings')}>
      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<PlatformTipCarsVinFormData> defaultValues={data} onSubmit={onSubmit}>
          {(control) => (
            <VStack spacing={4}>
              <FormField
                control={control}
                name="vinHidden"
                type="switch"
                label={i18n.t('page.settings.labels.vinHidden')}
              />
              <Right>
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.save')}
                />
              </Right>
            </VStack>
          )}
        </Form>
      </DataStatus>
    </Card>
  );
}

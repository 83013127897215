import {DataStatus} from 'platform/components';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {cebiaApi, FullVehicle} from '@omnetic-dms/api';
import {testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {
  getCebiaAutotraceAndReport,
  getCebiaEquipmentCredentials,
  getCustomTenantCatalogue,
  loadCarDetailsVehicleDetailRequest,
  selectCloneVehicleData,
  useThunkDispatch,
  VehicleCreateContext,
  VehicleCreateForm,
} from '@omnetic-dms/teas';

import {composePath, suffixTestId, TestIdProps} from 'shared';

export function CloneVehicle(props: TestIdProps) {
  const [searchParams] = useSearchParams();
  const {id, ...vehicle} = useSelector(selectCloneVehicleData) || {};

  const navigate = useNavigate();
  const dispatch = useThunkDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const vehicleId = searchParams.get('id');

    if (!vehicleId) {
      navigate(vehiclesRoutes.home);

      return;
    }

    Promise.all([
      dispatch(loadCarDetailsVehicleDetailRequest({vehicleId})),
      dispatch(getCustomTenantCatalogue.action({})),
      dispatch(getCebiaEquipmentCredentials()),
      dispatch(getCebiaAutotraceAndReport()),
      dispatch(cebiaApi.endpoints.getCebiaReport.initiate({vehicleId})),
    ]).then(() => {
      setIsLoading(false);
    });
  }, [searchParams, dispatch, navigate]);

  return (
    <DataStatus isLoading={isLoading}>
      <VehicleCreateContext
        onClose={() => navigate(composePath(vehiclesRoutes.detail, {params: {id}}))}
        onCreate={(id) =>
          navigate(composePath(vehiclesRoutes.detail, {params: {id}}), {
            state: {ignoreUnmountEvents: true},
          })
        }
      >
        <VehicleCreateForm
          clonedVehicleId={id}
          vehicle={vehicle as FullVehicle}
          onVehicleSelect={(selectedVehicle) => {
            navigate(composePath(vehiclesRoutes.edit, {params: {id: selectedVehicle?.id}}));
          }}
          cyId={testIds.vehicles.clone('cloneVehicle')}
          data-testid={suffixTestId('vehicleCreateForm', props)}
        />
      </VehicleCreateContext>
    </DataStatus>
  );
}

import {
  Alert,
  AlertProps,
  DataStatus,
  openConfirmDialog,
  showNotification,
} from 'platform/components';
import {Show, VStack} from 'platform/foundation';
import {match, Pattern} from 'ts-pattern';

import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {
  always,
  assocPath,
  F,
  filter,
  find,
  flatten,
  head,
  includes,
  indexBy,
  isNil,
  reject,
  T,
  toPairs,
  uniq,
} from 'ramda';
import {isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  AddressRequestBodyV2,
  BulkContext,
  BulkContextItem,
  businessCaseApi,
  BusinessCaseInternalType,
  BusinessCaseState,
  BusinessCaseType,
  CheckoutDeputyPersonResponseBody,
  ConsentStatus,
  ContractInformationResponseBody,
  CreateCustomerAddressV2ApiArg,
  CreateCustomerContractInformationApiArg,
  documentContextApi,
  gdprApi,
  omneticApi,
  OrderResponseBody,
  PatchCustomerAddressV2ApiArg,
  PatchCustomerContactApiArg,
  PatchCustomerContractInformationApiArg,
  PersonRequestBody,
  PersonResponseBodyV2,
  saleVehicleApi,
  SelectDeputyPersonRequestBody,
  TypeOfSale,
  useAddCheckoutOrderPurchasePaymentMutation,
  useCancelOrderMutation,
  useChangeTypeOfSaleCheckoutOrderMutation,
  useCloseContractMutation,
  useCreateCustomerAddressV2Mutation,
  useCreateCustomerContactMutation,
  useCreateCustomerContractInformationMutation,
  useCreateDocumentMutation,
  useCreateInternalInvoiceDocumentDocumentMutation,
  useCreatePaymentFileMutation,
  useDeleteCheckoutOrderPurchasePaymentMutation,
  useDeletePaymentFileMutation,
  useGetBusinessCaseQuery,
  useGetBusinessCaseSettingsQuery,
  useGetCheckoutForBusinessCaseQuery,
  useGetConsentListQuery,
  useGetCustomerV2Query,
  useGetSaleVehicleQuery,
  useGetUsersQuery,
  useGetVehicleQuery,
  useIssueCheckoutOrderPaymentMutation,
  useLazyGetSaleVehicleWarehouseInformationQuery,
  useLazyGetVehicleInsuranceQuery,
  usePatchCheckoutOrderPaymentMutation,
  usePatchCustomerAddressV2Mutation,
  usePatchCustomerContactMutation,
  usePatchCustomerContractInformationMutation,
  useRecordPaidCheckoutOrderPaymentMutation,
  useSelectBusinessCaseCheckoutOrderContractInformationMutation,
  useSelectBusinessCaseCheckoutOrderDeputyPersonsMutation,
  useSetVehicleInsuranceMutation,
  useWithdrawalPurchasePaymentMutation,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds} from '@omnetic-dms/routes';
import {
  handleApiError,
  queryParams,
  useBusinessCaseActions,
  useLazyGetAuthorizationProfilesAndCKKPermissions,
  usePermissions,
  useVehicleWarehouse,
} from '@omnetic-dms/shared';
import {
  FileDataTypeEnum,
  OrderPaymentRequestBody,
  PaymentDiscriminatorEnum,
  PaymentStateEnum,
  personRequestToResponse,
  personResponseToResponse,
  UploadedFile,
  useThunkDispatch,
} from '@omnetic-dms/teas';

import {buildArray, composePath, Nullish, useRequiredParams} from 'shared';

import {DocumentType} from '../../components/Checkout/types/types';
import {getIsMaxBuyingPriceLowerThanBuyingPrice} from '../../utils/buyingPrice';
import {getPurchaseVehicleIdFromBusinessCase} from '../../utils/getPurchaseVehicleIdFromBusinessCase';
import {getSaleVehicleIdFromBusinessCase} from '../../utils/getSaleVehicleIdFromBusinessCase';
import {CheckoutContractDeputyPersonFormState} from '../Checkout/Billing/CheckoutContractDeputyPerson';
import {CheckoutContractInformationFormState} from '../Checkout/Billing/CheckoutContractInformationForm';
import {CheckoutPersonContractInformationFormState} from '../Checkout/Billing/CheckoutPersonContractInformationForm';
import {Checkout} from '../Checkout/Checkout';
import {ContractInformationFormState} from '../ContractInformation/ContractInformationForm';
import {InsuranceBanner} from '../InsuranceBanner';

const getTemplateIds = (templates: Record<string, DocumentType[]>) => {
  const pairs = toPairs(templates);
  const filteredPairs = pairs.filter(([type]) => type !== FileDataTypeEnum.GDPR_MARKETING_CONSENT);
  const documentTypes = filteredPairs.map((pair) => pair[1]);
  const flatDocumentTypes = flatten(documentTypes);
  const ids = flatDocumentTypes.map((type) => type.selectedTemplate?.id);
  const uniqIds = uniq(ids);
  return reject(isNil, uniqIds);
};

const findOrder = (orders: OrderResponseBody[]) =>
  find(
    (order) =>
      match(order.orderDiscriminator)
        .with('PURCHASE_BROKERAGE_SALE', 'SALE', 'PURCHASE', always(true))
        .otherwise(always(false)),
    orders
  );

const findFiles = (order: OrderResponseBody) =>
  filter(
    (file) =>
      match(file.documentType)
        .with(
          'PURCHASE_BROKERAGE_CONTRACT',
          'SALE_BROKERAGE_CONTRACT',
          'PURCHASE_CONTRACT',
          'SALE_CONTRACT',
          'PURCHASE_BROKERAGE_HANDOVER_PROTOCOL',
          always(true)
        )
        .otherwise(always(false)),
    order.files
  );

const CHECKOUT_PAYMENT_TAB_INDEX = '2';

const getContractFiles = (orders: OrderResponseBody[]) => {
  if (!orders) {
    return null;
  }
  const foundOrder = findOrder(orders);
  if (!foundOrder) {
    return null;
  }
  return findFiles(foundOrder);
};

export const BusinessCaseCheckout: FC = () => {
  const {id: businessCaseId} = useRequiredParams();
  const {data: businessCase, isLoading: isBusinessCaseLoading} = useGetBusinessCaseQuery({
    businessCaseId,
  });
  const {
    data: checkoutInfo,
    isLoading: isCheckoutLoading,
    isError: isCheckoutError,
  } = useGetCheckoutForBusinessCaseQuery({businessCaseId});

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
  } = useGetCustomerV2Query(
    {customerId: businessCase?.customerId ?? ''},
    {skip: isNilOrEmpty(businessCase?.customerId)}
  );

  const isBrokeredSale = !!businessCase?.brokerageBusinessCaseId;
  const isBrokerage = businessCase?.businessCaseInternalType === 'PURCHASE_BROKERAGE';

  const order = checkoutInfo?.orders?.[0];
  const checkoutFiles = order?.files;
  const {data: users} = useGetUsersQuery();
  const usersById = indexBy((user) => user.id, users ?? []);

  const {data: businessCaseSettingsData} = useGetBusinessCaseSettingsQuery();
  const [getVehicleInsurance] = useLazyGetVehicleInsuranceQuery();
  const [setVehicleInsurance] = useSetVehicleInsuranceMutation();
  const [getWarehouseInfo] = useLazyGetSaleVehicleWarehouseInformationQuery();
  const [issueCheckoutOrderPayment] = useIssueCheckoutOrderPaymentMutation();
  const [addCheckoutOrderPayment] = useAddCheckoutOrderPurchasePaymentMutation();
  const [deleteCheckoutOrderPayment] = useDeleteCheckoutOrderPurchasePaymentMutation();
  const [patchCustomerContact] = usePatchCustomerContactMutation();
  const [createCustomerContact] = useCreateCustomerContactMutation();
  const [createCustomerContractInformation] = useCreateCustomerContractInformationMutation();
  const [patchCustomerContractInformation] = usePatchCustomerContractInformationMutation();
  const [patchCustomerAddressV2] = usePatchCustomerAddressV2Mutation();
  const [createCustomerAddressV2] = useCreateCustomerAddressV2Mutation();
  const [selectContractInformation] =
    useSelectBusinessCaseCheckoutOrderContractInformationMutation();
  const [selectDeputyPerson] = useSelectBusinessCaseCheckoutOrderDeputyPersonsMutation();
  const [patchCheckoutOrderPayment] = usePatchCheckoutOrderPaymentMutation();
  const [withdrawCheckoutOrderPayment] = useWithdrawalPurchasePaymentMutation();
  const [cancelCheckoutOrderPayment] = useCancelOrderMutation();
  const [recordPaidCheckoutOrderPayment] = useRecordPaidCheckoutOrderPaymentMutation();
  const [createCheckoutOrderPaymentFile] = useCreatePaymentFileMutation();
  const [deleteCheckoutOrderPaymentFile] = useDeletePaymentFileMutation();
  const [createInternalInvoiceDocumentDocument] =
    useCreateInternalInvoiceDocumentDocumentMutation();
  const [changeTypeOfSaleCheckoutOrder] = useChangeTypeOfSaleCheckoutOrderMutation();
  const [closeContractBusinessCase] = useCloseContractMutation();
  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();

  const [canReadPurchase, canIssueFromVehicleWarehouse, canCancelIssuingWarehouse] = usePermissions(
    {
      permissionKeys: [
        'viewBusinessCasePurchase',
        'vehicleIssueFromVehicleWarehouse',
        'vehicleCancelIssuingWarehouse',
      ],
    }
  );

  const {isActionEnabled} = useBusinessCaseActions(businessCaseId ?? '');

  const saleVehicleId = getSaleVehicleIdFromBusinessCase(businessCase);
  const purchaseVehicleId = getPurchaseVehicleIdFromBusinessCase(businessCase);

  const {data: saleVehicle} = useGetSaleVehicleQuery(
    {vehicleId: saleVehicleId ?? ''},
    {skip: isNilOrEmpty(saleVehicleId)}
  );
  const {data: purchaseVehicle} = useGetSaleVehicleQuery(
    {vehicleId: purchaseVehicleId ?? ''},
    {skip: isNilOrEmpty(purchaseVehicleId)}
  );

  const {data: vehicleData} = useGetVehicleQuery(
    {vehicleId: saleVehicle?.vehicleId ?? purchaseVehicle?.vehicleId ?? ''},
    {skip: isNilOrEmpty(saleVehicle?.vehicleId) && isNilOrEmpty(purchaseVehicle?.vehicleId)}
  );
  const navigate = useNavigate();

  const price = businessCase?.offers?.[0]?.purchaseVehicles?.[0]?.buying;
  const maxBuyingPrice = price?.maxBuyingPrice?.amount;
  const hasPurchaseVehicle = isNotNil(getPurchaseVehicleIdFromBusinessCase(businessCase));
  const shouldValidateMaximumBuyingPrice =
    businessCaseSettingsData?.maximalPurchasePriceRequired &&
    businessCase?.businessCaseInternalType === 'BUYING' &&
    hasPurchaseVehicle;

  const isMaxBuyingPriceMissing = shouldValidateMaximumBuyingPrice && isNil(maxBuyingPrice);
  const isMaxBuyingPriceLowerThanBuyingPrice =
    shouldValidateMaximumBuyingPrice && getIsMaxBuyingPriceLowerThanBuyingPrice(price);
  const isMaxBuyingPriceIncorrect = isMaxBuyingPriceMissing || isMaxBuyingPriceLowerThanBuyingPrice;

  const {data: consents} = useGetConsentListQuery({customerId: businessCase?.customerId});
  // TODO - T20-39108 - SALE_VEHICLE_STATES - 12
  const isUnsuccessfulBrokeragePurchase = match<
    [BusinessCaseInternalType | Nullish, BusinessCaseState | Nullish],
    boolean
  >([businessCase?.businessCaseInternalType, businessCase?.businessCaseState])
    .with(['PURCHASE_BROKERAGE', 'UNSUCCESSFUL'], T)
    .otherwise(F);

  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [searchParams] = useSearchParams();

  const refreshDocumentContext = () => {
    dispatch(
      documentContextApi.util.invalidateTags([{type: 'documentsCount', id: businessCaseId ?? ''}])
    );
  };

  const wasWarehouseMovementPrompted = useRef(false);

  const {
    availableActions: availableVehicleMovementActions,
    isVehicleWarehouseEnabled,
    openStockOutDialog,
    openCancelStockOutDialog,
  } = useVehicleWarehouse({
    vehicleId: saleVehicle?.vehicleId ?? '',
    saleVehicleId: saleVehicle?.id,
    vehicleWarehouseId: saleVehicle?.vehicleWarehouse?.id,
    onMovementComplete: refreshDocumentContext,
  });

  const createdCorrectiveTaxDocumentType = searchParams.get(
    queryParams.BUSINESS_CASE_CHECKOUT_CREATED_CORRECTIVE_DOCUMENT_TYPE
  ) as 'full' | 'partial';

  const dispatch = useThunkDispatch();

  // TODO - T20-39107 - SALE_VEHICLE_STATES - 10
  const notFinishedBuyout =
    includes(saleVehicle?.buyingState, ['buying', 'for-pricing', 'priced']) &&
    !includes(businessCase?.businessCaseState, ['CLOSED', 'UNSUCCESSFUL']);

  // TODO - T20-39107 - SALE_VEHICLE_STATES - 9
  const soldOrNotForSale =
    businessCase?.businessCaseState === 'OFFER' &&
    includes(saleVehicle?.sellingState, ['sold', 'not-for-sale']);

  const documentBulkContext: BulkContext | undefined = buildArray<BulkContextItem>([
    {
      target: 'business-case',
      targetId: businessCaseId,
    },
  ]).when(isNotNil(saleVehicle?.vehicleId) || isNotNil(purchaseVehicle?.vehicleId), {
    target: 'vehicle',
    targetId: saleVehicle?.vehicleId ?? purchaseVehicle?.vehicleId ?? '',
  });

  const promptVehicleWarehouseOperation = useCallback(() => {
    if (
      !isVehicleWarehouseEnabled ||
      wasWarehouseMovementPrompted.current ||
      isNil(saleVehicle) ||
      !canCancelIssuingWarehouse
    ) {
      return;
    }

    wasWarehouseMovementPrompted.current = true;

    getWarehouseInfo({
      vehicleId: saleVehicle.vehicleId,
      saleVehicleId: saleVehicle.id,
    })
      .unwrap()
      .then((warehouseInfo) => {
        if (isNil(warehouseInfo.issuedAt)) {
          return;
        }

        openConfirmDialog({
          text: i18n.t('entity.vehicleWarehouse.modal.cancelStockOutDescription'),
          onConfirm: openCancelStockOutDialog,
        });
      })
      .catch(handleApiError);
  }, [getWarehouseInfo, isVehicleWarehouseEnabled, openCancelStockOutDialog, saleVehicle]);

  const getPathToPurchaseBrokerage = () => {
    const base = composePath(businessCaseRoutes.checkout, {
      params: {
        id: businessCase?.brokerageBusinessCaseId,
      },
    });

    const url = new URL(base, window.location.href);

    url.searchParams.append(queryParams.BUSINESS_CASE_CHECKOUT, CHECKOUT_PAYMENT_TAB_INDEX);

    return url.href;
  };

  /**
   * @param customerId - when is Nullish, then invalidation of customer shouldn't be processed
   */
  const refreshBusinessCaseCheckoutInfo = (customerId: string | Nullish) => {
    if (isNotNil(customerId)) {
      dispatch(omneticApi.util.invalidateTags([{type: 'Customer', id: customerId}]));
    }

    dispatch(
      omneticApi.util.invalidateTags([
        'Checkout',
        'offer',
        {type: 'BusinessCaseActions', id: businessCaseId},
        {type: 'BusinessCaseDetail', id: businessCaseId},
        {
          type: 'vehicleWarehouseInformation',
          id: saleVehicle?.vehicleId,
        },
      ])
    );
  };

  const selectContract = (
    orderId: string,
    customerContractInformationId: string,
    selectedIdentityCardIds: string[] = []
  ) => {
    selectContractInformation({
      businessCaseId,
      orderId,
      selectContractInformationRequestBody: {
        customerContractInformationId,
        selectedIdentityCardIds,
      },
    })
      .unwrap()
      .catch(handleApiError);

    selectDeputyPerson({
      businessCaseId,
      orderId,
      selectDeputyPersonsRequestBody: {deputyPersons: []},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const getIsFirstOrder = (orderId: string) => {
    const firstOrder = head(
      filter((order) => order.orderDiscriminator === 'SALE', checkoutInfo?.orders ?? [])
    );

    return firstOrder?.id === orderId;
  };

  const promptCancelFleetInsurance = () =>
    new Promise<void>((resolve) => {
      if (!saleVehicle?.vehicleId) {
        resolve();
        return;
      }

      getVehicleInsurance({vehicleId: saleVehicle.vehicleId})
        .unwrap()
        .then((insurance) => {
          if (isNil(insurance.fleetInsurance)) {
            resolve();
            return;
          }

          openConfirmDialog({
            text: i18n.t('entity.vehicleInsurance.modal.cancelFleetInsuranceDescription'),
            onConfirm: () =>
              setVehicleInsurance({
                vehicleId: saleVehicle.vehicleId,
                data: {customerInsurance: null, fleetInsurance: null},
              })
                .unwrap()
                .then(() => {
                  showNotification.success(
                    i18n.t('entity.vehicleInsurance.notifications.fleetInsuranceCanceled')
                  );
                })
                .catch(handleApiError),
            onCloseComplete: resolve,
          });
        })
        .catch(resolve);
    });

  const promptVehicleWarehouseStockOut = () => {
    const isBcInStockOutPhase = match<
      [BusinessCaseType | Nullish, BusinessCaseState | Nullish],
      boolean
    >([businessCase?.businessCaseType, businessCase?.businessCaseState])
      .with(
        ['SELLING', 'CONTRACT'],
        ['SELLING', 'OFFER'],
        ['SELLING', 'DEPOSIT_PAID'],
        ['SELLING', 'CLOSED'],
        T
      )
      .otherwise(F);

    if (
      isBcInStockOutPhase &&
      isNotNilOrEmpty(saleVehicle?.vehicleId) &&
      isNotNil(saleVehicle?.vehicleWarehouse?.id) &&
      availableVehicleMovementActions.issue
    ) {
      openConfirmDialog({
        text: i18n.t('entity.vehicleWarehouse.modal.stockOutDescription'),
        onConfirm: openStockOutDialog,
      });
    }
  };

  const handleCreateCheckoutContractInformation =
    (orderId: string) =>
    async ({
      personInfo,
      bankAccounts,
      businessInfo,
      legalForm,
    }: CheckoutContractInformationFormState): Promise<ContractInformationResponseBody | null> => {
      if (isNil(businessCase)) {
        showNotification.error();
        return null;
      }
      const contractArgs: CreateCustomerContractInformationApiArg = {
        customerId: businessCase?.customerId,
        contractInformationRequestBody: {
          legalForm,
          businessInfo,
          bankAccounts,
          personId: personInfo.personId,
          customFieldsPayload: [],
        },
      };

      const contract = await createCustomerContractInformation(contractArgs)
        .unwrap()
        .catch(handleApiError);

      if (!contract) {
        return null;
      }

      if (personInfo.personId && personInfo.person) {
        const patchContactArgs = {
          customerId: businessCase?.customerId,
          contactId: personInfo.personId,
          personRequestBody: personInfo.person,
        };
        patchCustomerContact(patchContactArgs).unwrap().catch(handleApiError);
      }

      if (contract.id) {
        await selectContract(orderId, contract.id, personInfo?.selectedIdentityCardIds);
      }

      return contract;
    };

  const handleCreateAdditionalCheckoutContractInformation =
    (customerId: string) =>
    async (
      values: CheckoutContractInformationFormState
    ): Promise<ContractInformationResponseBody | null> => {
      const contractArgs: CreateCustomerContractInformationApiArg = {
        customerId,
        contractInformationRequestBody: {
          legalForm: values.legalForm,
          businessInfo: values.businessInfo,
          bankAccounts: values.bankAccounts,
          personId: values.personInfo?.personId,
          customFieldsPayload: [],
        },
      };

      const contract = await createCustomerContractInformation(contractArgs)
        .unwrap()
        .catch(handleApiError);

      if (!contract) {
        return null;
      }

      if (values.personInfo?.personId && values.personInfo?.person && isNotNil(businessCase)) {
        const patchContactArgs = {
          customerId,
          contactId: values.personInfo.personId,
          personRequestBody: values.personInfo.person,
        };
        patchCustomerContact(patchContactArgs).unwrap().catch(handleApiError);
      }

      return contract;
    };

  const handleSelectContract = async (
    orderId: string,
    contractInformationId: string
  ): Promise<void> => {
    const information = customer?.contractInformation?.find(
      (info) => info.id === contractInformationId
    );

    const selectedIdentityCardIds: string[] = [];

    if (isNotNilOrEmpty(information?.person?.identityCards?.[0]?.id)) {
      // we should always provide first identity card as selected
      selectedIdentityCardIds.push(information!.person!.identityCards[0].id);
    }

    await selectContract(orderId, contractInformationId, selectedIdentityCardIds);
  };

  const handleEditCheckoutContractInformation =
    (customerId: string, contractInformationId: string, orderId: string, isContractChange = true) =>
    async (
      values: CheckoutContractInformationFormState
    ): Promise<ContractInformationResponseBody | null> => {
      const contractArgs: PatchCustomerContractInformationApiArg = {
        customerId,
        contractInformationId,
        contractInformationRequestBody: {
          legalForm: values.legalForm,
          businessInfo: {
            businessAddressId: values.businessInfo?.businessAddressId ?? null,
            businessInfoData: {
              countryOfRegistrationCode:
                values.businessInfo?.businessInfoData?.countryOfRegistrationCode ?? null,
              registrationNumber: values.businessInfo?.businessInfoData?.registrationNumber ?? null,
              vatNumber: values.businessInfo?.businessInfoData?.vatNumber ?? null,
              tradeName: values.businessInfo?.businessInfoData?.tradeName ?? null,
              fileNumber: values.businessInfo?.businessInfoData?.fileNumber ?? null,
            },
          },
          bankAccounts: values.bankAccounts,
          personId: values.personInfo.personId,
          customFieldsPayload: [],
        },
      };

      const contract = await patchCustomerContractInformation(contractArgs)
        .unwrap()
        .catch(handleApiError);
      if (!contract) {
        return null;
      }

      if (isNotNil(values.personInfo.personId) && isNotNilOrEmpty(values.personInfo.person)) {
        const args: PatchCustomerContactApiArg = {
          customerId,
          contactId: values.personInfo.personId,
          personRequestBody: values.personInfo.person!,
        };
        patchCustomerContact(args).unwrap().catch(handleApiError);
      }

      if (isContractChange) {
        await selectContract(orderId, contract!.id, values.personInfo?.selectedIdentityCardIds);

        await selectContractInformation({
          businessCaseId,
          orderId,
          selectContractInformationRequestBody: {
            customerContractInformationId: contract.id,
            selectedIdentityCardIds: reject(
              isNil,
              contract.person?.identityCards?.map((identityCard) => identityCard.id) ?? []
            ) as string[],
          },
        });
      }

      return contract;
    };

  const handleEditContractInformation =
    (contractInformationId: string, orderId: string) =>
    async ({
      contractInformation,
      person,
    }: ContractInformationFormState): Promise<ContractInformationResponseBody | null> => {
      if (isNil(businessCase)) {
        showNotification.error();
        return null;
      }

      const contractArgs: PatchCustomerContractInformationApiArg = {
        customerId: businessCase?.customerId,
        contractInformationId,
        contractInformationRequestBody: {
          legalForm: contractInformation?.legalForm,
          businessInfo: contractInformation?.businessInfo,
          bankAccounts: contractInformation?.bankAccounts,
          personId: contractInformation?.personId,
          customFieldsPayload: [],
        },
      };

      const contract = await patchCustomerContractInformation(contractArgs)
        .unwrap()
        .catch(handleApiError);

      if (!contract) {
        return null;
      }

      if (contractInformation.personId && person) {
        const requestBody: PersonRequestBody = {
          ...person,
          identityCards: person.identityCards.map((identityCard) => ({
            id: isNotNilOrEmpty(identityCard.id?.trim()) ? identityCard.id : null,
            cardData: identityCard.cardData,
          })),
        };

        const args: PatchCustomerContactApiArg = {
          customerId: businessCase.customerId,
          contactId: contractInformation.personId,
          personRequestBody: requestBody,
        };
        await patchCustomerContact(args)
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError);
      }

      await selectContractInformation({
        businessCaseId,
        orderId,
        selectContractInformationRequestBody: {
          customerContractInformationId: contract.id,
          selectedIdentityCardIds: reject(
            isNil,
            contract.person?.identityCards?.map((identityCard) => identityCard.id) ?? []
          ) as string[],
        },
      });

      return contract;
    };

  const handleSelectDeputyPerson =
    (orderId: string) =>
    async ({deputyPersons}: CheckoutContractDeputyPersonFormState): Promise<OrderResponseBody> => {
      let updatedOrder = await selectDeputyPerson({
        orderId,
        businessCaseId,
        selectDeputyPersonsRequestBody: {
          deputyPersons: deputyPersons
            .filter((customerPersonId) => customerPersonId !== '')
            .map((customerPersonId) => ({
              customerPersonId,
              selectedIdentityCardIds: [],
            })),
        },
      }).unwrap();

      if (updatedOrder && deputyPersons.includes('')) {
        updatedOrder = assocPath(
          ['contractInformation', 'deputyPersons'],
          [
            ...updatedOrder.contractInformation.deputyPersons,
            ...deputyPersons
              .filter((customerPersonId) => customerPersonId === '')
              .map(() => ({}) as CheckoutDeputyPersonResponseBody),
          ],
          updatedOrder
        );
      }
      return updatedOrder;
    };

  const handleSubmitDeputyPerson =
    (orderId: string) =>
    async (values: CheckoutPersonContractInformationFormState): Promise<OrderResponseBody> => {
      const order = checkoutInfo?.orders.find((order) => order.id === orderId);

      const deputyPersons: SelectDeputyPersonRequestBody[] =
        order?.contractInformation.deputyPersons.map((dep) => ({
          customerPersonId: dep.customerPerson.id,
          selectedIdentityCardIds: dep.selectedIdentityCards.map((card) => card.id),
        })) ?? [];

      const deputyIndex = deputyPersons.findIndex(
        (deputy) => deputy.customerPersonId === values.personId
      );

      if (deputyIndex !== -1 && values.personId) {
        deputyPersons[deputyIndex] = {
          customerPersonId: values.personId,
          selectedIdentityCardIds: values.selectedIdentityCardIds ?? [],
        };
      } else if (values.personId) {
        deputyPersons.push({
          customerPersonId: values.personId,
          selectedIdentityCardIds: values.selectedIdentityCardIds ?? [],
        });
      }

      const updatedOrder = await selectDeputyPerson({
        orderId,
        businessCaseId,
        selectDeputyPersonsRequestBody: {
          deputyPersons,
        },
      }).unwrap();

      if (values.personId && values.person && isNotNil(businessCase)) {
        const args: PatchCustomerContactApiArg = {
          customerId: businessCase.customerId,
          contactId: values.personId,
          personRequestBody: values.person,
        };
        patchCustomerContact(args).unwrap().catch(handleApiError);
      }

      return updatedOrder;
    };

  const handlePersonSubmit = (
    personRequestBody: PersonRequestBody,
    contactId: string | null
  ): Promise<PersonResponseBodyV2 | null> => {
    if (isNil(businessCase)) {
      showNotification.error();
      return Promise.resolve(null);
    }

    if (isNotNil(contactId)) {
      return patchCustomerContact({
        customerId: businessCase?.customerId,
        contactId,
        personRequestBody,
      })
        .unwrap()
        .then((contact) => personResponseToResponse(contactId, customer?.addresses, contact))
        .catch((error) => {
          handleApiError(error);
          return null;
        });
    } else {
      return createCustomerContact({
        customerId: businessCase?.customerId,
        personRequestBody,
      })
        .unwrap()
        .then((contact) => personResponseToResponse(contact.id, customer?.addresses, contact))
        .catch((error) => {
          handleApiError(error);
          return null;
        });
    }
  };

  const handleAdditionalPersonSubmit = (
    customerId: string,
    personRequestBody: PersonRequestBody,
    contactId: string | null
  ): Promise<PersonResponseBodyV2 | null> => {
    if (isNotNil(contactId)) {
      return patchCustomerContact({
        customerId,
        contactId,
        personRequestBody,
      })
        .unwrap()
        .then(() => {
          refreshBusinessCaseCheckoutInfo(customerId);
          return personRequestToResponse(contactId, customer?.addresses, personRequestBody);
        })
        .catch((error) => {
          handleApiError(error);
          return null;
        });
    }

    const args = {
      customerId,
      personRequestBody,
    };
    return createCustomerContact(args)
      .unwrap()
      .then((person) => {
        refreshBusinessCaseCheckoutInfo(customerId);
        return personRequestToResponse(person.id, customer?.addresses, personRequestBody);
      })
      .catch((error) => {
        handleApiError(error);
        return null;
      });
  };

  const handleAddressSubmit = (
    addressRequestBody: AddressRequestBodyV2,
    addressId?: string | null
  ): Promise<string | null> => {
    if (isNil(businessCase)) {
      showNotification.error();
      return Promise.resolve(null);
    }

    if (isNotNil(addressId)) {
      const patchCustomerArgs: PatchCustomerAddressV2ApiArg = {
        customerId: businessCase?.customerId,
        addressRequestBody,
        addressId,
      };
      return patchCustomerAddressV2(patchCustomerArgs)
        .unwrap()
        .then(() => addressId!)
        .catch((error) => {
          handleApiError(error);
          return null;
        });
    }
    const createCustomerArgs: CreateCustomerAddressV2ApiArg = {
      customerId: businessCase?.customerId,
      addressRequestBody,
    };
    return createCustomerAddressV2(createCustomerArgs)
      .unwrap()
      .then((result) => result.id)
      .catch((error) => {
        handleApiError(error);
        return null;
      });
  };

  const handleAdditionalPersonAddressSubmit = (
    customerId: string,
    addressRequestBody: AddressRequestBodyV2,
    addressId?: string | null
  ): Promise<string | null> => {
    if (isNotNil(addressId)) {
      const patchCustomerArgs: PatchCustomerAddressV2ApiArg = {
        customerId,
        addressRequestBody,
        addressId,
      };
      return patchCustomerAddressV2(patchCustomerArgs)
        .unwrap()
        .then(() => {
          refreshBusinessCaseCheckoutInfo(null);
          return addressId!;
        })
        .catch((error) => {
          handleApiError(error);
          return null;
        });
    }
    const createCustomerArgs: CreateCustomerAddressV2ApiArg = {
      customerId,
      addressRequestBody,
    };
    return createCustomerAddressV2(createCustomerArgs)
      .unwrap()
      .then((result) => {
        refreshBusinessCaseCheckoutInfo(null);
        return result.id;
      })
      .catch((error) => {
        handleApiError(error);
        return null;
      });
  };

  const handlePatchCheckoutOrderPayment = (
    orderId: string,
    paymentId: string,
    orderPayment: OrderPaymentRequestBody
  ): Promise<OrderResponseBody> => {
    if (!checkoutInfo) {
      throw new Error('Checkout info not found');
    }

    return patchCheckoutOrderPayment({
      checkoutId: checkoutInfo.id,
      orderId,
      paymentId,
      orderPaymentRequestBody: orderPayment,
    }).unwrap();
  };

  const handleIssueCheckoutOrderPayment = async (
    orderId: string,
    paymentId: string,
    issuedOn: string
  ): Promise<void> => {
    if (!checkoutInfo) {
      throw new Error('Checkout info not found');
    }
    try {
      const payment = await issueCheckoutOrderPayment({
        checkoutId: checkoutInfo.id,
        orderId,
        businessCaseId,
        paymentId,
        issueCheckoutOrderPaymentRequestBody: {
          issuedOn,
        },
      }).unwrap();

      if (
        payment.paymentDiscriminator === PaymentDiscriminatorEnum.BALANCE &&
        payment.paymentState === PaymentStateEnum.PENDING &&
        getIsFirstOrder(orderId)
      ) {
        await promptCancelFleetInsurance();
        if (canIssueFromVehicleWarehouse) {
          promptVehicleWarehouseStockOut();
        }
      }
    } catch (error) {
      return handleApiError(error as any);
    }
  };

  const handleWithdrawCheckoutOrderPayment = async (
    orderId: string,
    paymentId: string
  ): Promise<void> => {
    if (!checkoutInfo) {
      throw new Error('Checkout info not found');
    }

    await withdrawCheckoutOrderPayment({
      checkoutId: checkoutInfo.id,
      orderId,
      paymentId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleCancelCheckoutOrderPayment = async (
    orderId: string,
    paymentId: string
  ): Promise<void> => {
    if (!checkoutInfo) {
      throw new Error('Checkout info not found');
    }

    await cancelCheckoutOrderPayment({
      checkoutId: checkoutInfo.id,
      orderId,
      paymentId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleRecordPaidCheckoutOrderPayment = async (
    orderId: string,
    paymentId: string,
    payDate: string
  ): Promise<void> => {
    if (!checkoutInfo) {
      throw new Error('Checkout info not found');
    }

    await recordPaidCheckoutOrderPayment({
      checkoutId: checkoutInfo.id,
      orderId,
      paymentId,
      orderPaymentRecordPaidRequestBody: {payDate},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleAddAnotherPayment = (checkoutId: string, orderId: string) =>
    addCheckoutOrderPayment({
      checkoutId,
      orderId,
    })
      .unwrap()
      .then(() => refreshBusinessCaseCheckoutInfo(null))
      .catch(handleApiError);

  const handleDeletePurchasePayment = (checkoutId: string, orderId: string, paymentId: string) =>
    deleteCheckoutOrderPayment({
      checkoutId,
      orderId,
      paymentId,
    })
      .unwrap()
      .then(() => refreshBusinessCaseCheckoutInfo(null))
      .catch(handleApiError);

  const onInvoiceUpload = async (
    orderId: string,
    paymentId: string,
    {fileId, name, originalUri: fileUri}: UploadedFile
  ): Promise<void> => {
    if (!checkoutInfo) {
      throw new Error('Checkout info not found');
    }

    await createCheckoutOrderPaymentFile({
      checkoutId: checkoutInfo.id,
      orderId,
      paymentId,
      fileData: {
        fileId,
        remoteId: fileId,
        fileFileId: fileId,
        name,
        fileUri,
        documentType: FileDataTypeEnum.PAYMENT_ATTACHMENT,
        createdAt: null,
        createdBy: null,
      },
    })
      .unwrap()
      .then(() => refreshBusinessCaseCheckoutInfo(null))
      .catch(handleApiError);
  };

  const handleDeleteInvoice = async (
    orderId: string,
    paymentId: string,
    fileId: string
  ): Promise<void> => {
    if (!checkoutInfo) {
      throw new Error('Checkout info not found');
    }

    await deleteCheckoutOrderPaymentFile({
      checkoutId: checkoutInfo.id,
      orderId,
      paymentId,
      fileId,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleCreateInternalInvoiceDocumentDocument = async (
    orderId: string,
    templateId: string,
    paymentId: string
  ): Promise<void> => {
    if (!checkoutInfo) {
      throw new Error('Checkout info not found');
    }

    await createInternalInvoiceDocumentDocument({
      internalInvoiceDocumentRequestBody: {
        orderId,
        checkoutId: checkoutInfo.id,
        templateId,
        paymentId,
      },
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleChangeTypeOfSaleVehicle = async (orderId: string, typeOfSale: TypeOfSale) => {
    if (!checkoutInfo) {
      return;
    }

    await changeTypeOfSaleCheckoutOrder({
      orderId,
      checkoutId: checkoutInfo.id,
      orderTypeOfSaleRequestBody: {typeOfSale},
    })
      .unwrap()
      .catch(handleApiError);
  };

  // Transition from offer to contract
  const handleCloseContract = async () => {
    await closeContractBusinessCase({businessCaseId})
      .unwrap()
      .then(() => showNotification.success(i18n.t('page.businessCase.labels.movedToContract')))
      .catch(handleApiError);

    if (!saleVehicle?.vehicleId) {
      return;
    }

    dispatch(
      saleVehicleApi.endpoints.getSaleVehicle.initiate(
        {
          vehicleId: saleVehicle?.vehicleId,
        },
        {forceRefetch: true}
      )
    );
    handleSearchAuthorizationProfiles(
      {
        vehicleMake: vehicleData?.make ?? '',
        vehicleType: vehicleData?.type ?? null,
        vehicleModelFamily: vehicleData?.vehicle?.modelFamily ?? null,
        vehicleModelFamilyGroup: vehicleData?.vehicle?.modelFamilyGroup ?? null,
      },
      businessCase?.customerId,
      {bcNumber: businessCase?.code, bcCreatedAt: businessCase?.createdAt}
    );
  };

  const [createCheckoutDocuments] = useCreateDocumentMutation();

  const handleCreateDocuments =
    (orderId: string, refreshDocuments: () => void) =>
    async (documentsByType: Record<string, DocumentType[]>): Promise<void> => {
      if (!checkoutInfo || isNil(businessCase)) {
        return;
      }

      setIsLoadingDocuments(true);

      const templateIds = getTemplateIds(documentsByType);

      await createCheckoutDocuments({orderId, checkoutId: checkoutInfo.id, templateIds})
        .unwrap()
        .then((data) => {
          if (!isActionEnabled('CLOSE_CONTRACT')) {
            return;
          }

          if (isNilOrEmpty(data.orders)) {
            return;
          }

          const contractFiles = getContractFiles(data.orders);

          if (isNilOrEmpty(contractFiles)) {
            return;
          }

          openConfirmDialog({
            text: i18n.t('page.businessCase.labels.proceedToContractQuestion'),
            onConfirm: handleCloseContract,
            'data-testid': testIds.businessCase?.checkout('proceedToContractQuestion'),
          });
        })
        .catch(handleApiError);

      if (documentsByType[FileDataTypeEnum.GDPR_MARKETING_CONSENT]?.length) {
        await dispatch(
          gdprApi.endpoints.batchCreateConsent.initiate({
            customerId: businessCase?.customerId,
            body: documentsByType[FileDataTypeEnum.GDPR_MARKETING_CONSENT].map((document) => ({
              customerContractInformationId:
                order?.contractInformation?.customerContractInformation?.id ?? '',
              consentTypeId: document.id ?? '',
              templateTitle: document.selectedTemplate?.title ?? '',
              templateFileId: document.selectedTemplate?.id ?? '',
              customerId: businessCase?.customerId,
              businessCaseId,
            })),
          })
        );
      }

      setIsLoadingDocuments(false);
      refreshDocuments();
    };

  const onCheckoutInfoChange = async (isFullCorrectiveDocument?: boolean) => {
    if (isNil(businessCase)) {
      return;
    }

    if (isFullCorrectiveDocument) {
      promptVehicleWarehouseOperation();
    }

    await dispatch(
      businessCaseApi.util.invalidateTags([
        {type: 'BusinessCaseActions', id: businessCaseId},
        {type: 'BusinessCaseDetail', id: businessCaseId},
        'offer',
      ])
    );
  };

  useEffect(() => {
    if (wasWarehouseMovementPrompted.current || createdCorrectiveTaxDocumentType !== 'full') {
      return;
    }

    promptVehicleWarehouseOperation();
  }, [createdCorrectiveTaxDocumentType, promptVehicleWarehouseOperation]);

  const documents = useMemo(() => {
    const consentFiles = (consents ?? [])
      .filter((consent) => ConsentStatus.AGREED === consent.status)
      .map((consent) => ({
        fileId: consent.id,
        fileFileId: consent.id,
        name: consent.fileName,
        fileUri: consent.fileUri,
        remoteId: null,
        createdAt: consent.createdAt,
        createdBy: consent.createdBy,
        documentType: FileDataTypeEnum.GDPR_MARKETING_CONSENT,
      }));

    const files = (checkoutFiles ?? []).map((file) => {
      const user = usersById[file?.createdBy ?? ''];
      return {
        ...file,
        documentType: file.documentType as FileDataTypeEnum,
        createdBy: [user?.firstName, user?.lastName].join(' '),
      };
    });

    return [...files, ...consentFiles];
  }, [checkoutFiles, consents, usersById]);

  // TODO - T20-39107 - SALE_VEHICLE_STATES - 11
  const alert = match<
    [boolean, boolean, string | Nullish, BusinessCaseState | Nullish],
    AlertProps | null
  >([
    notFinishedBuyout,
    soldOrNotForSale,
    businessCase?.brokerageBusinessCaseId,
    businessCase?.businessCaseState,
  ])
    .with([true, false, Pattern.any, Pattern.any], () => ({
      title: i18n.t('entity.businessCase.labels.notFinishedBuyout'),
      variant: 'info',
      'data-testid': testIds.businessCase?.checkout('notFinishedBuyout'),
    }))
    .with([false, true, Pattern.any, Pattern.any], () => ({
      title: i18n.t('entity.businessCase.labels.alreadySoldVehicle'),
      variant: 'info',
      'data-testid': testIds.businessCase?.checkout('alreadySoldVehicle'),
    }))
    .with(
      [Pattern.boolean, Pattern.boolean, Pattern.string, 'CONTRACT'],
      [Pattern.boolean, Pattern.boolean, Pattern.string, 'CLOSED'],
      [Pattern.boolean, Pattern.boolean, Pattern.string, 'UNSUCCESSFUL'],
      () => ({
        title: i18n.t('entity.businessCase.labels.brokerageSaleFinished'),
        variant: 'warning',
        'data-testid': testIds.businessCase?.checkout('brokerageSaleFinished'),
        hyperlinks: [
          {
            description: i18n.t('entity.businessCase.labels.finishBrokerage'),
            title: i18n.t('entity.businessCase.labels.finishBrokerage'),
            href: getPathToPurchaseBrokerage(),
            size: 'small',
          },
        ],
      })
    )
    .otherwise(always(null));

  return (
    <VStack width="100%">
      <DataStatus
        minHeight="100%"
        isLoading={isBusinessCaseLoading || isCheckoutLoading || isCustomerLoading}
        isEmpty={!checkoutInfo || isNilOrEmpty(customer)}
        isError={isCustomerError || isCheckoutError}
        emptyMessage={i18n.t`entity.checkout.notifications.checkoutNotAvailable`}
      >
        <>
          {isNotNil(checkoutInfo) && isNotNil(customer) && isNotNil(businessCase) && (
            <Checkout
              alert={
                <>
                  <Show when={alert && canReadPurchase}>
                    <Alert
                      type="banner"
                      {...alert}
                      data-testid={testIds.businessCase?.checkout('checkoutAlert')}
                    />
                  </Show>
                  <Show
                    when={
                      businessCase?.businessCaseType === 'SELLING' ||
                      businessCase?.businessCaseType === 'SWAP'
                    }
                    whenFeatureEnabled={featureFlags.SALES_INSURANCE_COMPARISON_V1}
                  >
                    <InsuranceBanner />
                  </Show>
                  <Show when={isMaxBuyingPriceIncorrect}>
                    <Alert
                      type="banner"
                      data-testid={testIds.businessCase?.checkout('error-banner')}
                      variant="error"
                      title={
                        isMaxBuyingPriceMissing
                          ? i18n.t('page.businessCase.labels.maximumPrice.missing')
                          : i18n.t('page.businessCase.labels.maximumPrice.overLimit')
                      }
                      hyperlinks={[
                        {
                          title: i18n.t('page.businessCase.labels.maximumPrice.vehicleValuation'),
                          onClick: () => {
                            navigate(
                              composePath(businessCaseRoutes.buying, {
                                params: {id: businessCaseId},
                              })
                            );
                          },
                        },
                      ]}
                    />
                  </Show>
                </>
              }
              businessCaseId={businessCaseId}
              disabledTabIds={isMaxBuyingPriceIncorrect ? ['DOCUMENTS', 'PAYMENT'] : undefined}
              branchId={businessCase?.branchId}
              readonly={!businessCase?.actions.checkoutEditPaymentDetails}
              invoicable
              customerId={businessCase.customerId}
              checkoutInfo={checkoutInfo}
              documents={documents}
              isBrokeredSale={isBrokeredSale}
              isBrokerage={isBrokerage}
              isUnsuccessfulBrokeragePurchase={isUnsuccessfulBrokeragePurchase}
              isLoadingDocuments={isLoadingDocuments}
              onInvoiceUpload={onInvoiceUpload}
              onCheckoutInfoChange={onCheckoutInfoChange}
              refreshBusinessCaseCheckoutInfo={refreshBusinessCaseCheckoutInfo}
              documentContextId={businessCaseId}
              documentContextTarget="business-case"
              documentBulkContext={documentBulkContext}
              handleDeleteInvoice={handleDeleteInvoice}
              handlePersonSubmit={handlePersonSubmit}
              handleAdditionalPersonSubmit={handleAdditionalPersonSubmit}
              handleAddressSubmit={handleAddressSubmit}
              handleAdditionalPersonAddressSubmit={handleAdditionalPersonAddressSubmit}
              handleCreateCheckoutContractInformation={handleCreateCheckoutContractInformation}
              handleCreateAdditionalCheckoutContractInformation={
                handleCreateAdditionalCheckoutContractInformation
              }
              handleEditCheckoutContractInformation={handleEditCheckoutContractInformation}
              handleEditContractInformation={handleEditContractInformation}
              handleSubmitDeputyPerson={handleSubmitDeputyPerson}
              handleSelectDeputyPerson={handleSelectDeputyPerson}
              handleSelectContract={handleSelectContract}
              handlePatchCheckoutOrderPayment={handlePatchCheckoutOrderPayment}
              handleIssueCheckoutOrderPayment={handleIssueCheckoutOrderPayment}
              handleWithdrawCheckoutOrderPayment={handleWithdrawCheckoutOrderPayment}
              handleCancelCheckoutOrderPayment={handleCancelCheckoutOrderPayment}
              handleRecordPaidCheckoutOrderPayment={handleRecordPaidCheckoutOrderPayment}
              handleCreateInternalInvoiceDocumentDocument={
                handleCreateInternalInvoiceDocumentDocument
              }
              handleCreateDocuments={handleCreateDocuments}
              refreshDocumentContext={refreshDocumentContext}
              handleChangeTypeOfSaleVehicle={handleChangeTypeOfSaleVehicle}
              handleAddAnotherPayment={handleAddAnotherPayment}
              handleDeletePurchasePayment={handleDeletePurchasePayment}
              data-testid={testIds.businessCase?.checkout('checkout')}
              saleVehicle={saleVehicle}
              purchaseVehicleId={purchaseVehicle?.vehicleId}
            />
          )}
        </>
      </DataStatus>
    </VStack>
  );
};

import {useEventListener} from 'platform/components';

import {useRef, useState} from 'react';

import {splitAt} from 'ramda';

import {useOnMount} from 'shared';

export const MENU_ITEM_GAP = 4;
export const MENU_ITEM_HEIGHT = 32;
export const OVERFLOW_BUTTON_SPOT = 1;

interface UseSidebarMenuItemsOverflowProps {
  pinnedMenuItems: string[];
}

export function useSidebarMenuItemsOverflow(props: UseSidebarMenuItemsOverflowProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const additionalContentContainerRef = useRef<HTMLDivElement | null>(null);
  const [overflowingMenuItemsCount, setOverflowingMenuItemsCount] = useState(0);

  const handleSidebarOverflow = () => {
    if (!containerRef.current) {
      return;
    }

    const additionalMenuItemsCount = Math.floor(
      (additionalContentContainerRef.current?.clientHeight ?? 0) / MENU_ITEM_HEIGHT
    );
    const sidebarHeight = containerRef.current.clientHeight;
    const allMenuItemsCount =
      props.pinnedMenuItems.length + OVERFLOW_BUTTON_SPOT + additionalMenuItemsCount;

    const availableSlots = Math.floor(sidebarHeight / (MENU_ITEM_HEIGHT + MENU_ITEM_GAP));
    const overflowCount = Math.max(0, allMenuItemsCount - availableSlots);

    setOverflowingMenuItemsCount(overflowCount);
  };

  useEventListener(window, 'resize', handleSidebarOverflow);
  useOnMount(handleSidebarOverflow);

  const [visiblePinnedMenuItems, overflownPinnedMenuItems] = splitAt(
    Math.max(0, props.pinnedMenuItems.length - overflowingMenuItemsCount),
    props.pinnedMenuItems
  );

  return {
    containerRef,
    additionalContentContainerRef,
    visiblePinnedMenuItems,
    overflownPinnedMenuItems,
  };
}

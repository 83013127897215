import {Card, Form, FormButton, FormField, FormSubmitHandler} from 'platform/components';
import {Grid, Right, Space, Text} from 'platform/foundation';
import {object, string} from 'yup';

import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

export interface PlatformAuthenticationFormData {
  username: string;
  password: string;
}

export type PlatformAuthenticationFormProps = {
  defaultValues: PlatformAuthenticationFormData | Nullish;
  onSubmit: FormSubmitHandler<PlatformAuthenticationFormData>;
};

const DEFAULT_VALUES: PlatformAuthenticationFormData = {
  username: '',
  password: '',
};

export function PlatformAuthenticationForm(props: PlatformAuthenticationFormProps) {
  return (
    <Card title={i18n.t('general.labels.authentication')}>
      <Text>{i18n.t('page.settings.labels.authenticationDescription')}</Text>
      <Space vertical={4} />
      <Form<PlatformAuthenticationFormData>
        defaultValues={props.defaultValues ?? DEFAULT_VALUES}
        schema={schema}
        onSubmit={props.onSubmit}
      >
        {(control) => (
          <>
            <Grid columns={2}>
              <FormField
                control={control}
                name="username"
                type="text"
                label={i18n.t('page.advertisement.labels.username')}
              />
              <FormField
                control={control}
                name="password"
                type="password"
                label={i18n.t('general.labels.password')}
              />
            </Grid>
            <Space vertical={4} />
            <Right>
              <FormButton control={control} type="submit" title={i18n.t('general.actions.save')} />
            </Right>
          </>
        )}
      </Form>
    </Card>
  );
}

const schema = object({
  username: string().nullable().required(),
  password: string().nullable().required(),
});

import * as Yup from 'yup';

import i18n from '@omnetic-dms/i18n';
import {EditTipCarsClientDataRequestBody} from '@omnetic-dms/teas';

const nullableMaxLength = (length: number) =>
  Yup.string()
    .nullable()
    .max(length, i18n.t('general.validations.maximumLength', {codeMaxLength: length}));

//@ts-expect-error The types of 'companyName.__outputType' are incompatible between these types.
export const $TipCarsClientDataRequestBody: Yup.SchemaOf<EditTipCarsClientDataRequestBody> =
  Yup.object().shape({
    email: Yup.string()
      .required(i18n.t('general.notifications.errorFieldRequired'))
      .email(i18n.t('general.notifications.errorEmailInvalid'))
      .max(80, i18n.t('general.validations.maximumLength', {codeMaxLength: 80})),
    companyName: nullableMaxLength(50),
    address: nullableMaxLength(32),
    postalCode: nullableMaxLength(5),
    city: nullableMaxLength(32),
    phone: nullableMaxLength(32),
    fax: nullableMaxLength(24),
    website: nullableMaxLength(100),
    note: nullableMaxLength(480),
  });

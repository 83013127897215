import {
  ListSentToastNotificationsApiResponse,
  ListSentToastNotificationsApiArg,
  PatchUnreadSentToastNotificationsApiArg,
  PatchSentToastNotificationApiResponse,
  PatchSentToastNotificationApiArg,
  GetSentToastNotificationApiResponse,
  GetSentToastNotificationApiArg,
} from '../types/messaging';
import {omneticApi} from './baseApi/omneticApi';

export const messagingApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    listSentToastNotifications: build.query<
      ListSentToastNotificationsApiResponse,
      ListSentToastNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/toast-notification`,
        params: {limit: queryArg.limit, after: queryArg.after},
      }),
      providesTags: (result) =>
        result?.notifications
          ? [
              ...result.notifications.map(({id}) => ({type: 'Notification' as const, id})),
              {type: 'Notification', id: 'LIST'},
            ]
          : [{type: 'Notification', id: 'LIST'}],
    }),

    getSentToastNotification: build.query<
      GetSentToastNotificationApiResponse,
      GetSentToastNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/toast-notification/${queryArg.id}`,
      }),
    }),

    getSentBackgroundNotification: build.query<
      GetSentToastNotificationApiResponse,
      GetSentToastNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/background-notification/${queryArg.id}`,
      }),
    }),

    patchUnreadSentToastNotifications: build.mutation<
      unknown,
      PatchUnreadSentToastNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/toast-notification/unread`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'Notification', id: 'LIST'}],
    }),

    patchSentToastNotification: build.mutation<
      PatchSentToastNotificationApiResponse,
      PatchSentToastNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/messaging/notification/toast-notification/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: [{type: 'Notification', id: 'LIST'}],
    }),
  }),
});

export const {
  useListSentToastNotificationsQuery,
  useGetSentToastNotificationQuery,
  useLazyGetSentBackgroundNotificationQuery,
  useLazyGetSentToastNotificationQuery,
  usePatchUnreadSentToastNotificationsMutation,
  usePatchSentToastNotificationMutation,
} = messagingApi;
